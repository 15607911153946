/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const USER_BY_BILLING_RECEIVER = gql`
  mutation ReceiverByBillingProfile($profile: BillingProfileInput!) {
    billingProfile: ReceiverByBillingProfile(profile: $profile) {
      id
      MH_PersonType {
        id
      }
      ID_Number
      Name
      Email
      PhoneCodCountry
      PhoneNumber
      User {
        id
      }
      MH_Neighborhood {
        id
        Name
        District {
          id
          Name
          Canton {
            id
            Name
            Province {
              id
              Name
            }
          }
        }
      }
      OtherSigns
    }
  }
`;

export const UPDATE_PROFILE_EB = gql`
  mutation updateProfileEB($userMH: UserMH) {
    BillingProfile: updateProfileEB(userMH: $userMH) {
      idUser
      fullName
      userName
      email
      about
      country
      MH_User
      MH_Password
      FileEB {
        idFile
        md5
        name
        timestamp
        size
        idUser
        fileType
        type
        pin
      }
      Session {
        idSession
        idUser
        sessionKey
        ip
        lastAccess
      }
      Created_At
      Updated_At
    }
  }
`;

export const UPDATE_PIN = gql`
  mutation updatePin($id: ID!, $pin: String!) {
    updatePin(id: $id, pin: $pin) {
      idFile
      md5
      name
      timestamp
      size
      idUser
      fileType
      type
      pin
    }
  }
`;

export const PATCH_BILLING_PROFILE = gql`
  mutation patchBillingProfile($billingProfile: BillingProfileInput) {
    BillingProfile: patchBillingProfile(billingProfile: $billingProfile) {
      id
      Name
      FK_User
      MH_PersonType {
        id
        Name_PersonType
      }
      ID_Number
      ComercialName
      Email
      PhoneCodCountry
      PhoneNumber
      MH_Province {
        id
        Name
        id_MH
      }
      MH_Canton {
        id
        Name
        id_MH
      }
      MH_District {
        id
        Name
        id_MH
      }
      MH_Neighborhood {
        id
        Name
        id_MH
      }
      OtherSigns
    }
  }
`;

export const TAVUEL_USER_BY_BILLING_PROFILE = gql`
  mutation tavuelUserByBillingProfile(
    $profile: BillingProfileInput!
    $FK_Place: ID!
  ) {
    BillingProfile: tavuelUserByBillingProfile(
      profile: $profile
      FK_Place: $FK_Place
    ) {
      id
      UserBillingProfile {
        id
        MH_PersonType {
          id
        }
        ID_Number
        Name
        Email
        PhoneCodCountry
        PhoneNumber
        User {
          id
        }
        MH_Neighborhood {
          id
          Name
          District {
            id
            Name
            Canton {
              id
              Name
              Province {
                id
                Name
              }
            }
          }
        }
        OtherSigns
      }
    }
  }
`;
