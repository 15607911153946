import React from "react";
// import { render } from "react-dom";
import { createRoot } from "react-dom/client";
import "./styles/index.scss";
import "./styles/mixings.scss";
import { ApolloProvider } from "@apollo/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { client } from "./graphql";
import Pages from "./pages";
import { AuthProvider } from "./context/AuthContext";
import reportWebVitals from "./reportWebVitals";
import "./icons";
import ElectronicProvider from "./context/ElectronicContext";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <React.StrictMode>
    <ToastContainer />
    <ApolloProvider client={client}>
      <Router>
        <AuthProvider>
          <ElectronicProvider>
            <Pages />
          </ElectronicProvider>
        </AuthProvider>
      </Router>
    </ApolloProvider>
  </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
