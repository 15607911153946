/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_BILLCONFIGURATION_EVENTS = gql`
  query getBillsConfigurationEvents(
    $FK_Place: ID!
    $Frequency: Int
    $Active: Boolean
    $Result: String
    $startDate: DateTime
    $endDate: DateTime
  ) {
    getBillsConfigurationEvents(
      FK_Place: $FK_Place
      Frequency: $Frequency
      Active: $Active
      Result: $Result
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      FK_Bill
      Result
      Execution_Time
      Error_Message
      Time_Execute_Process
      BillConfiguration {
        id
        date
        Frequency
        Active
        Next_Execution
      }
      Bill {
        id
        User {
          Person {
            Name_Person
          }
        }
      }
    }
  }
`;
