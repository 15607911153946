import React from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// Context
import { Box } from "@mui/material";
import { useAuth } from "../../../../context";
// Components
import RegisterReceiver from "../../../RegisterReceiver";

function ProfileBill({ initialReceiver, setBillingProfile, setModalOpen }) {
  // states
  const { state: authState } = useAuth();

  return (
    <Box
      className="modal-content-padding container-modal"
      sx={{ minWidth: { xs: "100%", sm: "40rem", md: "40rem", xl: "50rem" } }}
    >
      <RegisterReceiver
        isAdmin
        FK_User={authState?.user?.TavuelUser?.id}
        handleChangeAddress={() => null}
        handleOnClosed={() => setModalOpen(false)}
        initialReceiver={initialReceiver}
        setBillingProfile={setBillingProfile}
        setModalOpen={setModalOpen}
        title="Perfil de facturación"
      />
    </Box>
  );
}

ProfileBill.propTypes = {
  initialReceiver: PropTypes.any,
  setModalOpen: PropTypes.func,
  setBillingProfile: PropTypes.func.isRequired,
};
ProfileBill.defaultProps = {
  initialReceiver: null,
  setModalOpen: () => null,
};

export default ProfileBill;
