/* eslint-disable react/jsx-props-no-spreading */

import React, { useState } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
// External component
import FormControl from "@mui/material/FormControl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";
// Graphql
import { useApolloClient } from "@apollo/client";
import { customToast as toast } from "../../utils";
import { GET_CLIENTS_BY_CRITERIA } from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import styleAsynSelect from "../CabysCodeSelect/styles";
import "../ProductSelect/ProductSelect.scss";
import "../PaymentComponet/Receiver/SelectProfile/SelectProfile.scss";

function CustomOption(value) {
  const { data } = value;
  const selectIcon = TypeId => (TypeId === "1" ? "user" : "building");
  return (
    <components.Option {...value}>
      {data && (
        <div className="favorite-container">
          <div key={data.ID_Number} className="favorite-card ">
            <div className="flex">
              <FontAwesomeIcon
                icon={selectIcon(data.MH_PersonType?.id)}
                size="lg"
              />
              &nbsp;&nbsp;
              <h3>{data.Name},</h3>
              &nbsp;
              <h3>{data.ID_Number}</h3>
            </div>
          </div>
        </div>
      )}
    </components.Option>
  );
}

function BillingClientSelect({ register, placeId, clientList }) {
  const { LineDetailPlace } = useElectronic();
  const theme = localStorage.getItem("theme");
  const client = useApolloClient();
  const [defaultOptions, setDefaultOptions] = useState([]);

  const handleChange = newValue => {
    const clientExist = clientList?.find(
      index => index?.BillingProfile?.ID_Number === newValue?.ID_Number,
    );
    if (!clientExist) {
      register(newValue);
    } else {
      toast.error(
        "Ya existe un usuario con el número de identificación proporcionado asociado al grupo.",
      );
    }
  };

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_CRITERIA,
      variables: {
        criteria: inputValue,
        FK_Place: LineDetailPlace?.id,
      },
    });

    const clients = data?.clients;

    const result = Array.isArray(clients)
      ? clients.map(BillingProfile => ({
          id: BillingProfile.id,
          Name: BillingProfile?.Name,
          idUser: BillingProfile?.User?.id,
          ID_Number: BillingProfile?.ID_Number,
          Email: BillingProfile?.Email,
          MH_PersonType: BillingProfile?.MH_PersonType,
          MH_Neighborhood: BillingProfile?.MH_Neighborhood,
          PhoneNumber: BillingProfile?.PhoneNumber,
          PhoneCodCountry: BillingProfile?.PhoneCodCountry,
          value: BillingProfile.id,
          label: "Buscar cliente...",
        }))
      : [];
    setDefaultOptions(result);
    return result;
  };

  return (
    <FormControl fullWidth style={{ maxWidth: "30rem" }}>
      <AsyncSelect
        cacheOptions
        className="input principal-input without-padding"
        classNamePrefix={`${theme}`}
        components={{ Option: CustomOption }}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar..."
        styles={styleAsynSelect}
        onChange={handleChange}
      />
    </FormControl>
  );
}

BillingClientSelect.defaultProps = {};

BillingClientSelect.propTypes = {
  register: PropTypes.func.isRequired,
  placeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  clientList: PropTypes.array.isRequired,
};

export default BillingClientSelect;
