import React, { useCallback, useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// External components
import { Box } from "@mui/material";
// Graphql
import { useLazyQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import {
  GET_EB_BY_FILTERS,
  GET_BILLS,
  DELETE_BILL_AND_RELATIVES,
  GET_ELECTRONICS_BILLS_NEW,
  GET_CREDIT_NOTES,
  GET_DEBIT_NOTES,
} from "../../graphql";
// Componets
import {
  DocumentSearch,
  DocumentSearchForm,
  DocumentSearchBill,
  ConfirmationModal,
} from "../../components";
// Context
import { useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { customToast } from "../../utils";
// Enums
import {
  DOCUMENT_SEARCH_MODULES,
  ELECTRONIC_BILLS_DOCUMENT_TYPES,
  ELECTRONIC_BILL_FLOW_TYPES,
} from "../../Enums/ElectronicBill";
import { BILL_STATES } from "../../Enums/Bill";
// SCSS
import "./DocumentSearchPage.scss";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";
import client, { client_EB_api } from "../../graphql/client";
import MenuDocumentSearch from "../../components/MenuDocumentSearch";

function DocumentSearchPage() {
  const [toggleState, setToggleState] = useState(1);
  const [searchForm, setSearchForm] = useState({
    User_Name: "",
    User_ID: "",
    Document_Type: null,
    Document_State: null,
    EmitedDay: dayjs(),
  });
  const [electronicCreditDebitData, setElectronicCreditDebitData] = useState({
    Ebills: [],
    isLoading: false,
  });

  const location = useLocation();
  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  const [deleteGroup] = useMutation(DELETE_BILL_AND_RELATIVES);
  const [getElectronicBillExpenses, dataExpense] = useLazyQuery(
    GET_EB_BY_FILTERS,
    {
      fetchPolicy: "network-only",
    },
  );
  const [queryBill, dataBills] = useLazyQuery(GET_BILLS, {
    fetchPolicy: "network-only",
    onCompleted: resp => {
      if (resp.getBills.Bills.length > 0) return;
      toast.error("No se encontraron facturas");
    },
  });
  const getCreditNotes = useCallback(async formData => {
    setElectronicCreditDebitData(prev => ({
      ...prev,
      isLoading: true,
    }));
    const resp = await client_EB_api.query({
      query: GET_CREDIT_NOTES,
      variables: {
        whereParams: {
          comparisons: [
            [
              "FK_AcceptanceState",
              "=",
              formData.Document_State?.toString() || undefined,
            ],
            [
              "Name_Person",
              "like",
              formData.User_Name !== "" ? `%${formData.User_Name}%` : undefined,
              "and",
            ],
            [
              "DocumentId_Person",
              "like",
              formData.User_ID !== "" ? `%${formData.User_ID}%` : undefined,
              "and",
            ],
          ],
          inMonth: formData.EmitedDay
            ? formatToDate(formData.EmitedDay)
            : undefined,
        },
      },
    });
    setElectronicCreditDebitData(prev => ({
      ...prev,
      isLoading: false,
    }));
    return resp.data.getCreditNotesNew;
  }, []);
  const getDebitNotes = useCallback(async formData => {
    setElectronicCreditDebitData(prev => ({
      ...prev,
      isLoading: true,
    }));
    const { data } = await client_EB_api.query({
      query: GET_DEBIT_NOTES,
      variables: {
        WhereParams: {
          comparisons: [
            [
              "FK_AcceptanceState",
              "=",
              formData.Document_State?.toString() || undefined,
            ],
            [
              "Name_Person",
              "like",
              formData.User_Name !== "" ? `%${formData.User_Name}%` : undefined,
              "and",
            ],
            [
              "DocumentId_Person",
              "like",
              formData.User_ID !== "" ? `%${formData.User_ID}%` : undefined,
              "and",
            ],
          ],
          inMonth: formData.EmitedDay
            ? formatToDate(formData.EmitedDay)
            : undefined,
        },
      },
    });
    setElectronicCreditDebitData(prev => ({
      ...prev,
      isLoading: false,
    }));
    return data?.debitNotesData;
  }, []);
  const getElectronicBills = useCallback(async formData => {
    setElectronicCreditDebitData(prev => ({
      ...prev,
      isLoading: true,
    }));
    const resp = await client.query({
      query: GET_ELECTRONICS_BILLS_NEW,
      variables: {
        getElectronicBillsInput: {
          where: {
            equals: {
              FK_ElectronicBillDocumentType:
                formData.Document_Type || undefined,
              FK_AcceptanceState: formData.Document_State || undefined,
              FK_OwnerPlace: 3,
            },
            inMonth: formData.EmitedDay
              ? formatToDate(formData.EmitedDay)
              : undefined,
            customEquals: [
              {
                columnName: "DocumentId_Person",
                equality: "like",
                value:
                  formData.User_ID !== "" ? `%${formData.User_ID}%` : undefined,
              },
              {
                columnName: "Name_Person",
                equality: "like",
                value:
                  formData.User_Name !== ""
                    ? `%${formData.User_Name}%`
                    : undefined,
              },
              {
                columnName: "FK_ElectronicBillDocumentType",
                equality: "<>",
                value: ELECTRONIC_BILLS_DOCUMENT_TYPES.DEBIT_NOTE,
              },
              {
                columnName: "FK_ElectronicBillDocumentType",
                equality: "<>",
                value: ELECTRONIC_BILLS_DOCUMENT_TYPES.CREDIT_NOTE,
              },
            ],
          },
          orderBy: [
            {
              columnName: "EmitedDay",
              order: "DESC",
            },
          ],
        },
      },
      fetchPolicy: "no-cache",
    });
    setElectronicCreditDebitData(prev => ({
      ...prev,
      isLoading: false,
    }));
    return resp.data.getElectronicBillsNew.ElectronicBills;
  }, []);

  const handleDeleteDocument = async documentId => {
    try {
      const reponse = await deleteGroup({
        variables: {
          FK_Bill: documentId,
        },
        refetchQueries: [
          {
            query: GET_BILLS,
            variables: {
              getBillsNewInput: {
                where: {
                  equals: {
                    FK_Place: LineDetailPlace?.id,
                    FK_BillState: BILL_STATES.DRAFT,
                  },
                  extra: {
                    Client_ID: searchForm.User_ID,
                    ClientName: searchForm.User_Name,
                  },
                  inMonth: [
                    {
                      columnName: "Created_At",
                      date: formatToDate(searchForm.EmitedDay),
                    },
                  ],
                },
              },
            },
          },
        ],
      });
      if (!reponse?.data) {
        customToast.error("Error al eliminar la factura.");
        return;
      }
      customToast.success("Factura eliminada correctamente.");
      setModalOpen(false);
    } catch (err) {
      customToast.error("Error al eliminar la factura.");
    }
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const handleConfirmDelete = id => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea eliminar este borrador? Presione confirmar para eliminar"
        header="¿Desea eliminar este borrador?"
        onCancel={cancelDelete}
        onConfirm={() => handleDeleteDocument(id)}
      />,
    );
  };

  useEffect(() => {
    const routes = [
      {
        route: "/DocumentSearch/electronicBill",
        value: DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS,
      },
      {
        route: "/DocumentSearch/proforma",
        value: DOCUMENT_SEARCH_MODULES.BILLS,
      },
      {
        route: "/DocumentSearch/electronicBillExpenses",
        value: DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES,
      },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [location.pathname]);

  const formatToDate = EmitedDay => {
    if (EmitedDay) {
      const date = EmitedDay.toISOString().split("T")[0].split("-");
      return date[1].toString().concat("-01-").concat(date[0].toString());
    }
    return null;
  };
  const isValidForm = () => Object.values(searchForm).find(value => !!value);

  const handleSearch = async () => {
    if (!isValidForm()) {
      toast.error("Por favor, seleccione al menos un filtro");
      return;
    }
    if (toggleState === DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS) {
      setElectronicCreditDebitData({
        Ebills: [],
        isLoading: false,
      });
      if (
        searchForm.Document_Type ===
        ELECTRONIC_BILLS_DOCUMENT_TYPES.CREDIT_NOTE.toString()
      ) {
        const creditNotes = await getCreditNotes(searchForm);
        setElectronicCreditDebitData(prev => ({
          ...prev,
          Ebills: creditNotes,
        }));
        return;
      }
      if (
        searchForm.Document_Type ===
        ELECTRONIC_BILLS_DOCUMENT_TYPES.DEBIT_NOTE.toString()
      ) {
        const debitNotes = await getDebitNotes(searchForm);
        setElectronicCreditDebitData(prev => ({
          ...prev,
          Ebills: debitNotes,
        }));
        return;
      }
      if (!searchForm.Document_Type) {
        const creditNotes = await getCreditNotes(searchForm);
        const debitNotes = await getDebitNotes(searchForm);
        const electronicBills = await getElectronicBills(searchForm);
        electronicBills.concat(creditNotes, debitNotes);

        setElectronicCreditDebitData(prev => ({
          ...prev,
          Ebills: electronicBills.sort((a, b) => a.EmitedDay - b.EmitedDay),
        }));
        return;
      }
      const electronicBills = await getElectronicBills(searchForm);
      setElectronicCreditDebitData(prev => ({
        ...prev,
        Ebills: electronicBills,
      }));
      return;
    }
    if (toggleState === DOCUMENT_SEARCH_MODULES.BILLS) {
      queryBill({
        variables: {
          getBillsNewInput: {
            where: {
              equals: { FK_Place: LineDetailPlace?.id, FK_BillState: 14 },
              extra: {
                Client_ID: searchForm.User_ID,
                ClientName: searchForm.User_Name,
              },
              inMonth: [
                {
                  columnName: "Created_At",
                  date: formatToDate(searchForm.EmitedDay),
                },
              ],
            },
          },
        },
      });
      return;
    }
    if (toggleState === DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES) {
      getElectronicBillExpenses({
        variables: {
          filters: {
            EmitterId: searchForm.User_ID,
            FK_DocumentType: searchForm.Document_Type,
            EmitterName: searchForm.User_Name,
            FK_docState: searchForm.Document_State,
            EmitedDay: formatToDate(searchForm.EmitedDay),
            FK_OwnerPlace: LineDetailPlace.id,
            BillFlowType: ELECTRONIC_BILL_FLOW_TYPES.EXPENSES,
            orderBy: [
              {
                columnName: "EmitedDay",
                order: "DESC",
              },
            ],
          },
        },
      });
    }
  };

  const billsSortedAscendant = sortArrayItemsByDate(
    dataBills?.data?.getBills?.Bills ?? [],
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "Created_At",
    },
  );

  return (
    <Box className="content-flex-column">
      <DocumentSearchForm
        documentForm={searchForm}
        toggleState={toggleState}
        onChange={setSearchForm}
        onSearch={handleSearch}
      />
      <MenuDocumentSearch />
      <Box>
        <Routes>
          <Route
            element={
              <DocumentSearch
                electronicBills={electronicCreditDebitData}
                isLoading={electronicCreditDebitData.isLoading}
              />
            }
            path="electronicBill"
          />
          <Route
            element={
              <DocumentSearchBill
                bills={billsSortedAscendant}
                handleDeleteDocument={handleConfirmDelete}
                isLoading={dataBills?.loading}
              />
            }
            path="proforma"
          />
          <Route
            element={
              <DocumentSearch
                electronicBills={dataExpense.data}
                isLoading={dataExpense.loading}
              />
            }
            path="electronicBillExpenses"
          />
          <Route
            element={
              <DocumentSearch
                electronicBills={electronicCreditDebitData}
                isLoading={electronicCreditDebitData.isLoading}
              />
            }
            path=""
          />
        </Routes>
      </Box>
    </Box>
  );
}

export default DocumentSearchPage;
