import React from "react";
import PropTypes from "prop-types";
// Graphql
// SCSS
import { Card, Typography } from "@mui/material";
import { validateRoute } from "../../../../utils/helpers";

function CardProductSelected({
  product,
  onClickDecrement,
  onClickIncrement,
  currentSymbolCoin,
  toExchangeRate,
}) {
  return (
    <Card
      color="primary"
      sx={{
        minWidth: "20.813rem",
        width: "20.813rem",
        maxHeight: "6.938rem",
        paddingY: "1rem",
        paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
        gap: "1rem",
        display: "grid",
        gridTemplateColumns: "repeat(3, 1fr)",
        alignItems: "center",
      }}
    >
      <div>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
          }}
          typography="modeColor"
          variant="subtitle1"
        >
          {product.label}
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
          }}
          typography="modeColor"
          variant="subtitle1"
        >
          {product.description}
        </Typography>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "100px",
          }}
          typography="modeColor"
          variant="subtitle1"
        >
          {currentSymbolCoin}
          {toExchangeRate(product?.unit_price).toFixed(3)}
        </Typography>
      </div>

      <div className="content-flex-row-between" style={{ width: "5rem" }}>
        <div
          className="products-buttons"
          role="button"
          tabIndex={0}
          onClick={onClickDecrement}
          onKeyDown={() => null}
        >
          <Typography
            sx={{ cursor: "pointer" }}
            typography="modeColor"
            variant="subtitle1"
          >
            -
          </Typography>
        </div>
        <Typography typography="modeColor" variant="subtitle1">
          {product.quantity}
        </Typography>
        <div
          className="products-buttons"
          role="button"
          tabIndex={0}
          onClick={onClickIncrement}
          onKeyDown={() => null}
        >
          <Typography
            sx={{ cursor: "pointer" }}
            typography="modeColor"
            variant="subtitle1"
          >
            +
          </Typography>
        </div>
      </div>
      <div className="product-img-option ">
        <img alt="product-pic" src={validateRoute(product)} />
      </div>
    </Card>
  );
}

CardProductSelected.propTypes = {
  product: PropTypes.object.isRequired,
  onClickIncrement: PropTypes.func,
  onClickDecrement: PropTypes.func,
  currentSymbolCoin: PropTypes.any,
  toExchangeRate: PropTypes.any,
};
CardProductSelected.defaultProps = {
  onClickIncrement: () => null,
  onClickDecrement: () => null,
  currentSymbolCoin: null,
  toExchangeRate: null,
};

export default CardProductSelected;
