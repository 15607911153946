import React from "react";
import { useNavigate } from "react-router-dom";
// External components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import { Box, Card, Typography } from "@mui/material";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_USERS_BY_PLACE } from "../../../graphql";
// Components
import AddNewUser from "./AddNewUser/AddNewUser";
// Helper
import { isEmptyImage } from "../../../utils/helpers";
// Context
import { useAuth, useModal } from "../../../context";
import { useElectronic } from "../../../context/ElectronicContext";
// SCSS
import "./Users.scss";

function Users() {
  const { setModalOpen } = useModal();

  const { LineDetailPlace } = useElectronic();

  const { data: usersByPlace, refetch } = useQuery(GET_USERS_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const { permissions, state } = useAuth();
  const navigate = useNavigate();

  const { haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/security", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Usuarios"));
    navigate(`/`);
  }

  const handleUpdateUser = user => {
    if (!haveActionPermission("Edit", "/security", LineDetailPlace)) return;
    const newUserRoles = { ...user };
    const filteRolesByPlace = user.Roles?.filter(
      r => r?.Place.id === LineDetailPlace.id,
    );
    newUserRoles.Roles = filteRolesByPlace;
    setModalOpen(
      true,
      <AddNewUser
        componentMode="edit"
        editUser={newUserRoles}
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Actualizar rol de usuario"
      />,
    );
  };

  return (
    <Box className="content-flex-cards">
      {usersByPlace?.user?.map(
        user =>
          user?.id !== state?.user?.TavuelUser.id && (
            <Card
              key={user?.id}
              color="primary"
              sx={{
                width: "20.813rem",
                height: "6.938rem",
                paddingY: "1rem",
                paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
                display: "flex",
                flexDirection: "row",
                justifyContent: "start",
                alignItems: "center",
                gap: "1rem",
              }}
              onClick={() => handleUpdateUser(user)}
            >
              <Box>
                {isEmptyImage(user?.FirebaseUser?.Picture) ? (
                  <FontAwesomeIcon className="icon-size-card" icon="user" />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      alt="imagen de perfil"
                      className="icon-size-card"
                      src={user?.FirebaseUser?.Picture}
                      style={{ borderRadius: "50%" }}
                    />
                  </Box>
                )}
              </Box>
              <Box
                key={user?.FirebaseUser?.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "230px",
                  }}
                  typography="modeColor"
                  variant="h6"
                >
                  {user?.FirebaseUser?.Name}
                </Typography>
                <Typography
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    width: "230px",
                  }}
                  typography="modeColor"
                  variant="subtitle1"
                  width="100%"
                >
                  {user?.FirebaseUser?.Email}
                </Typography>
              </Box>
            </Card>
          ),
      )}
    </Box>
  );
}

export default Users;
