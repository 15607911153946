export const normalizeEBillDetails = electronicBill => {
  const temp = { ...electronicBill };
  if (temp.Bill) temp.Bill.TotalBill = electronicBill?.Bill?.Total_Bill || 0;
  temp.details = electronicBill?.Bill?.Detail.map(detail => {
    const buffer = { ...detail, Quantity: detail.Quantity_BillDetail };
    delete buffer.Quantity_BillDetail;
    return buffer;
  });
  delete temp.ElectronicBillDetail;
  if (temp.Bill) delete temp.Bill.Total_Bill;
  return temp;
};
export const a = "";
