import { useQuery } from "@apollo/client";
import { useCallback, useEffect, useState } from "react";
import { useAuth } from "../context";
import { GET_ALL_COINS } from "../graphql";

const useCoinDetails = () => {
  const {
    state: { user },
  } = useAuth();
  const { data: getCoinsData } = useQuery(GET_ALL_COINS);
  const [currentCoin, setCurrentCoin] = useState("1");

  const [currentSymbolCoin, setCurrentSymbolCoin] = useState("#");
  useEffect(() => {
    if (!user?.TavuelUser?.DefaultCoin) return;

    setCurrentCoin(user.TavuelUser.DefaultCoin.id);
    setCurrentSymbolCoin(user.TavuelUser.DefaultCoin.Symbol_Coin);
  }, [user]);

  const resetDefaultCoin = useCallback(() => {
    setCurrentCoin(user.TavuelUser.DefaultCoin.id);
    setCurrentSymbolCoin(user.TavuelUser.DefaultCoin.Symbol_Coin);
  }, [user]);
  const handleCoinChange = value => {
    const coin = getCoinsData.coins.find(e => e.id === value);
    if (!coin) return;
    setCurrentCoin(value);
    setCurrentSymbolCoin(coin.Symbol_Coin);
  };

  const toExchangeRate = useCallback(
    amount => {
      if (!getCoinsData?.coins) return 0.0;
      const coin = getCoinsData.coins.find(e => e.id === currentCoin);
      const formattedAmount = coin ? amount / coin.Sell_Coin : amount;
      return parseFloat(formattedAmount);
    },
    [getCoinsData, currentCoin],
  );

  const toLocalExchangeRate = (amount, coin) => {
    if (!coin) return 0.0;
    const formattedAmount = coin ? amount / coin.Sell_Coin : amount;
    return parseFloat(formattedAmount).toFixed(3);
  };

  const toDolar = useCallback(
    amount => {
      if (!getCoinsData?.coins) return 0;
      const coin = getCoinsData.coins.find(e => e.id === currentCoin);
      return parseFloat(`${amount * (coin ? coin.Sell_Coin : 1)}`);
    },
    [currentCoin, getCoinsData],
  );

  return {
    currentCoin,
    currentSymbolCoin,
    handleCoinChange,
    toExchangeRate,
    toLocalExchangeRate,
    resetDefaultCoin,
    getCoinsData,
    toDolar,
  };
};

export default useCoinDetails;
