import { useState } from "react";
// External components
import { Typography, Button, Box } from "@mui/material";
import { registerLocale } from "react-datepicker";
import Es from "date-fns/locale/es";
import { CSVLink } from "react-csv";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { DatePicker } from "@mui/x-date-pickers";
import { GET_ELECTRONICS_BILLS_BY_DATE_AND_ACCEPTED } from "../../graphql";
// Components
import ExpenseCard from "./components";
// Context
import { useAuth } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { customToast } from "../../utils";
import { generateElectronicBillsCSVData } from "../../utils/CSVHelper";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";
import LandingPage from "../../pages/LandingPage";
// SCSS
import "./ExpensesManagement.scss";

function ExpensesManagement() {
  const [selectedDate, setSelectedDate] = useState();
  const [expenses, setExpenses] = useState([]);

  const { state: authState } = useAuth();
  const { LineDetailPlace } = useElectronic();

  registerLocale("Es", Es);

  const [getElectronicBillsAccepted, { loading }] = useLazyQuery(
    GET_ELECTRONICS_BILLS_BY_DATE_AND_ACCEPTED,
    {
      onCompleted: response =>
        setExpenses(
          response?.expenses.map(expense => ({ ...expense, selected: true })),
        ),
    },
    {
      fetchPolicy: "network-only",
    },
  );

  const handleGetElectronicBills = async () => {
    await getElectronicBillsAccepted({
      variables: {
        place_id: LineDetailPlace.id,
        date: selectedDate,
      },
    });
  };

  const handleSubmit = () => {
    if (authState.user.TavuelUser.id && selectedDate) {
      if (LineDetailPlace.id !== 0) {
        handleGetElectronicBills();
      } else {
        customToast.warning("Seleccione un lugar, por favor.");
      }
    }
  };

  const { data: dataToCSV, headers: headersToCSV } =
    generateElectronicBillsCSVData(
      expenses !== undefined && expenses?.length > 0
        ? expenses.filter(exp => exp.selected)
        : [],
    );

  const handleDownloadCSV = () => {
    const selected = expenses.filter(exp => exp.selected);
    if (!selected?.length) {
      customToast.warning(
        "Debe seleccionar un gasto para realizar la descarga.",
      );
      return false;
    }
    return selected;
  };

  const handleSelect = (id, value) => {
    // Selecciona la factura indicada en el estado expenses
    setExpenses(prev =>
      prev.map(expense =>
        expense.id === id ? { ...expense, selected: value } : expense,
      ),
    );
  };

  const expensesSortedAscendant = sortArrayItemsByDate(expenses, {
    mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
    targetKeyName: "EmitedDay",
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "1rem",
          justifyContent: "start",
          alignItems: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <DatePicker
            color="primary"
            label="Mes y año de emisión"
            size="small"
            sx={{
              "& .MuiFormLabel-root": {
                transform: " translate(14px, 5px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                padding: "24px 14px 10px 14px ",
              },
            }}
            value={selectedDate}
            variant="filled"
            views={["month", "year"]}
            onChange={newDate => {
              setSelectedDate(newDate);
            }}
          />
          <Button
            color="primary"
            disabled={!selectedDate}
            size="large"
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </Box>
        {expenses?.length > 0 && (
          <CSVLink
            className="CSVLink"
            data={dataToCSV}
            filename="Lista de gastos aceptados.csv"
            headers={headersToCSV}
            style={{
              paddingTop: "20px",
              fontSize: "20px",
              width: "100%",
            }}
            target="_blank"
            onClick={handleDownloadCSV}
          >
            Descargar Lista de Facturas
          </CSVLink>
        )}
      </Box>
      {selectedDate && (
        <div className="content-flex-row">
          <Typography align="start" typography="modeColor" variant="subtitle1">
            <span className="has-text-weight-bold"> Facturas de:</span>
            {new Intl.DateTimeFormat("es-ES", { month: "long" }).format(
              selectedDate,
            )}
            /{selectedDate.$y}
          </Typography>
        </div>
      )}
      <div className="content-flex-cards">
        {loading && expenses?.length === 0 && <LandingPage />}
        {expenses?.length === 0 && !loading ? (
          <Typography align="start" typography="modeColor" variant="subtitle1">
            ¡No se encontraron Egresos!
          </Typography>
        ) : (
          expensesSortedAscendant?.map(item => (
            <ExpenseCard
              key={item?.id}
              handleSelect={handleSelect}
              item={item}
            />
          ))
        )}
      </div>
    </Box>
  );
}
export default ExpensesManagement;
