/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const VALIDATE_CREDENTIALS_D104_CONFIGURATION = gql`
  query validateCredentialsD104Configuration(
    $user: String
    $password: String
    $tiv: TIVValidationInput
  ) {
    validateCredentialsD104Configuration(
      user: $user
      password: $password
      TIV: $tiv
    )
  }
`;
