import { gql } from "@apollo/client";

export const CHECK_TOKEN_EMAIL = gql`
  mutation checkTokenEmail($idEmail: ID, $type: Int) {
    email: checkTokenEmail(idEmail: $idEmail, type: $type)
  }
`;

export const GET_TOKEN_EMAIL = gql`
  mutation getTokenEmail(
    $googleAuthId: ID
    $code: String
    $type: Int
    $place: ID
  ) {
    email: getTokenEmail(
      googleAuthId: $googleAuthId
      code: $code
      type: $type
      place: $place
    ) {
      id
      email
      type
      place {
        id
        Code
        Name_Place
        Logo
      }
    }
  }
`;

export const DELETE_TOKEN_EMAIL = gql`
  mutation deleteTokenEmail($idEmail: ID, $type: Int) {
    email: deleteTokenEmail(idEmail: $idEmail, type: $type)
  }
`;

export const UPDATE_PLACE_TOKEN_EMAIL = gql`
  mutation updatePlaceTokenEmail($idEmail: ID, $FK_Place: ID) {
    email: updatePlaceTokenEmail(idEmail: $idEmail, FK_Place: $FK_Place)
  }
`;
