/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable new-cap */
import React, { useState } from "react";
// External components
import { Button, CircularProgress, Typography } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_RECENT_PRODUCTS_FEATURE_BY_USER_AND_MODULE } from "../../graphql";
// Context
import { useAuth } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Components
import { ReportProductCard, SelectFilterProduct } from "./components";
import Loading from "../Loading";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "./ProductsReport.scss";

function ProductsReport() {
  const { state, formatAmount, formatAmountWithSymbol } = useAuth();
  const user = state.user.TavuelUser;
  const { DefaultCoin } = user;
  const { LineDetailPlace } = useElectronic();
  const [loadingPDF, setLoadingPDF] = useState(false);
  const { loading, data: recentlyProductsByUser } = useQuery(
    GET_RECENT_PRODUCTS_FEATURE_BY_USER_AND_MODULE,
    {
      variables: {
        id: user?.id,
        origin: "Conta-Fast",
        place: LineDetailPlace.id,
      },
    },
  );

  const getUrl = product => {
    const route = product.Files?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateRoute = product => {
    const route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    return route;
  };

  const validateRecent = () => {
    if (recentlyProductsByUser?.features?.length === 0) {
      return true;
    }
    return false;
  };

  const getDetails = product => {
    let details = null;
    product.Details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  const validateVAT = VAT => {
    if (VAT && VAT !== "") {
      return `${VAT}%`;
    }
    return "Sin datos";
  };

  const newImage = src => {
    const image = document.createElement("img");
    if (src === "") {
      image.src =
        "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
      return image;
    }
    image.src = src;
    return image;
  };

  const getImages = () => {
    const images = [];
    recentlyProductsByUser?.features?.forEach(product => {
      const source = validateRoute(product);
      const img = newImage(source);
      images.push(img);
    });
    return images;
  };

  const generatePDF = () => {
    try {
      const doc = new jsPDF();
      doc.setFont("times");

      const tableRows = [];
      const isColon = DefaultCoin?.Symbol_Coin === "₡";

      recentlyProductsByUser?.features.forEach(index => {
        const ticketData = [
          "",
          index?.Product?.Name_Product || "Sin datos",
          index?.Product?.Description_Product || "Sin datos",
          getDetails(index) || "Sin datos",
          index?.Product?.InventoryDetail?.UnitOfMeasurement?.Symbol ||
            "Sin datos",
          isColon
            ? formatAmount(index?.ProductPrice?.Price_ProductPrice)
            : formatAmountWithSymbol(index?.ProductPrice?.Price_ProductPrice) ||
              "Sin datos",
          index?.ProductPrice?.Cabys?.Code || "Sin datos",
          validateVAT(index?.ProductPrice?.Tariff?.Percent),
        ];
        tableRows.push(ticketData);
      });

      let count = 0;
      doc.autoTable({
        columns: [
          "Imagen",
          "Producto",
          "Descripción",
          "Detalles",
          "Unidad de medida",
          isColon ? "Precio (Colones)" : "Precio",
          "Código Cabys",
          "IVA %",
        ],
        columnStyles: {
          0: {
            fillColor: [245, 245, 245],
          },
          1: {
            fillColor: [245, 245, 245],
          },
          2: {
            fillColor: [245, 245, 245],
          },
          3: {
            fillColor: [245, 245, 245],
          },
          4: {
            fillColor: [245, 245, 245],
          },
          5: {
            fillColor: [245, 245, 245],
          },
          6: {
            fillColor: [245, 245, 245],
          },
          7: {
            fillColor: [245, 245, 245],
          },
        },
        body: tableRows,
        theme: "grid",
        margin: { top: 30 },
        startY: 30,
        styles: {
          halign: "center",
          font: "times",
          valign: "middle",
          lineColor: [3, 7, 16],
        },
        headStyles: {
          fillColor: [245, 245, 245],
          textColor: [3, 7, 16],
          lineColor: [3, 7, 16],
          lineWidth: 0.01,
          cellPadding: {
            top: 4,
            right: 1,
            bottom: 4,
            left: 1,
          },
          valign: "middle",
          halign: "center",
        },
        alternateRowStyles: { fillColor: [231, 215, 252] },
        tableLineColor: [1, 35, 76],
        tableLineWidth: 0.1,
        bodyStyles: { minCellHeight: 20 },
        // eslint-disable-next-line object-shorthand
        didDrawCell: data => {
          if (data.column.index === 0 && data.cell.section === "body") {
            const images = getImages();
            const img = images[count];
            if (count < 10) {
              if (img.src !== "") {
                const dim =
                  data.cell.height - data.cell.padding("vertical") - 4;
                doc.addImage(img, data.cell.x, data.cell.y + 4, dim, dim);
              }
            }
            count += 1;
          }
        },
      });

      doc.text("Conta-Fast", 15, 15);

      doc.text("10 productos más recientes", 15, 20);
      doc.text(`${state?.user?.displayName}`, 15, 25);
      doc.text(`${format(new Date(), "yyyy-MM-dd")}`, 174, 15);

      doc.save(`productos_${state?.user?.displayName}.pdf`);

      toast.success("Reporte generado con éxito");
      setLoadingPDF(false);
    } catch (error) {
      toast.error("Error al generar el reporte");
      setLoadingPDF(false);
    }
  };

  const handleDownload = () => {
    if (
      recentlyProductsByUser?.features &&
      recentlyProductsByUser?.features?.length > 0
    ) {
      generatePDF();
    }
  };

  return (
    <div className="content-flex-column">
      <SelectFilterProduct />
      <Button
        disabled={validateRecent()}
        sx={{
          "width": "18rem",
          "justifyContent": "start",
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
          "&:focus": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },
        }}
        onClick={() => {
          setLoadingPDF(true);
          setTimeout(() => {
            handleDownload();
          }, 0);
        }}
      >
        <Typography
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            cursor: "pointer",
            fontWeight: "600",
          }}
          typography="modeColor"
          variant="subtitle1"
        >
          10 productos más recientes
          {loadingPDF ? (
            <CircularProgress disableShrink size={30} />
          ) : (
            <FontAwesomeIcon icon="download" size="1x" />
          )}
        </Typography>
      </Button>
      <div className="content-flex-cards">
        {recentlyProductsByUser?.features?.length > 0 && (
          <ReportProductCard productList={recentlyProductsByUser?.features} />
        )}
        {loading && (
          <div>
            <Loading />
          </div>
        )}
        {recentlyProductsByUser?.features?.length === 0 && (
          <Typography typography="modeColor" variant="h6">
            No hay productos recientes
          </Typography>
        )}
        {recentlyProductsByUser?.features?.length === 0 && (
          <Typography typography="modeColor" variant="h6">
            Aún no hay productos registrados en el sistema
          </Typography>
        )}
      </div>
    </div>
  );
}

export default ProductsReport;
