import React, {
  createContext,
  useState,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

import { useApolloClient, useMutation } from "@apollo/client";
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signInWithPopup,
  signOut,
  updatePassword,
} from "firebase/auth";

import { useNavigate } from "react-router-dom";
import { combine, customToast as toast, formatNumber } from "../utils";
import {
  FIREBASE_AUTH,
  CHECK_AUTH_QUERY,
  REGISTER_PERSON,
  REGISTER_USER_ADMIN,
  UPDATE_USER_FULL_INFORMATION,
  UPDATE_USER_PROFILE_PICTURE,
  UPDATE_DEFAULT_COIN,
  GET_USER_BY_UID,
  FORGOT_PASSWORD_MUTATION,
  GET_FIREBASE_USER_BY_EMAIL,
  // COMPLETE_PASSWORD_RESET_MUTATION,
} from "../graphql";

import { auth, googleAuthProvider } from "../firebase/firebase-config";
import usePermissions from "../hooks/usePermissions";
import { useLocalStorage } from "../hooks";

export const AuthContext = createContext(null);

const initialState = {
  isLoggedIn: false,
  isLoginPending: false,
  loginError: null,
  user: { userid: null },
};

export function AuthProvider({ children }) {
  // side bar
  const [isSideBarOpen, setIsSideBarOpen] = useLocalStorage(`MENU_OPEN`, false);
  const [state, setState] = useState(initialState);
  const [login] = useMutation(FIREBASE_AUTH);
  const [registerPerson] = useMutation(REGISTER_PERSON);
  const [registerUserAdmin] = useMutation(REGISTER_USER_ADMIN);
  const [updateUser] = useMutation(UPDATE_USER_FULL_INFORMATION);
  const [updateUserProfilePicture] = useMutation(UPDATE_USER_PROFILE_PICTURE);
  const [updateDefaultCoin] = useMutation(UPDATE_DEFAULT_COIN);
  const [requestPasswordReset] = useMutation(FORGOT_PASSWORD_MUTATION);
  // const [completePasswordReset] = useMutation(COMPLETE_PASSWORD_RESET_MUTATION);
  const client = useApolloClient();
  const navigate = useNavigate();
  const [idPlaces, setIdPlaces] = useState(0);

  const permissions = usePermissions(state.user?.TavuelUser?.id);

  const { refetchPermissionsByUserAndPlace } = permissions;

  const handleUser = useCallback(
    async userAuth => {
      // Inicia la carga
      const { token, signInProvider } = await userAuth.getIdTokenResult();

      // Termina la carga
      const {
        displayName,
        email,
        phoneNumber,
        photoURL,
        uid,
        emailVerified,
        providerId,
      } = userAuth;
      Cookies.set("token", token, { sameSite: true });
      const {
        data: { firebaseUser: loginData = { token: "" } },
      } = await login({
        variables: {
          user: {
            Email: email,
            Picture: photoURL,
            Name: displayName,
            Verified_Email: emailVerified,
            UID: uid,
            Provider_Id: providerId,
            AcceptTerms: true,
            Locale: window.navigator.userLanguage || window.navigator.language,
          },
        },
      });

      setState(prev => ({
        ...prev,
        user: {
          ...prev.user,
          displayName,
          email,
          phoneNumber,
          photoURL: loginData.Picture,
          uid,
          signInProvider,
          TavuelUser: loginData.TavuelUser,
          googleAuthId: loginData.id,
        },
        isLoggedIn: true,
      }));
    },
    [login],
  );

  const handleUserExternal = useCallback(
    async (token, userUid, idPlace) => {
      Cookies.set("token", token, { sameSite: true });

      const {
        data: { user },
      } = await client.query({
        query: GET_USER_BY_UID,
        variables: {
          uid: userUid,
        },
      });

      const {
        Name,
        Email,
        Phone_Number,
        Picture,
        UID,
        Verified_Email,
        Provider_Id,
      } = user;

      const {
        data: { firebaseUser: loginData = { token: "" } },
      } = await login({
        variables: {
          user: {
            Email,
            Picture,
            Name,
            Verified_Email,
            UID,
            Provider_Id,
            AcceptTerms: true,
            Locale: window.navigator.userLanguage || window.navigator.language,
          },
        },
      });

      const displayName = Name;
      const email = Email;
      const phoneNumber = Phone_Number;
      setState(prev => ({
        ...prev,
        user: {
          ...prev.user,
          displayName,
          email,
          phoneNumber,
          photoURL: loginData.Picture,
          UID,
          TavuelUser: loginData.TavuelUser,
          googleAuthId: loginData.id,
        },
        isLoggedIn: true,
      }));

      if (idPlace != null) {
        setIdPlaces(idPlace);
        navigate("/control-user-role");
      }
      refetchPermissionsByUserAndPlace();
    },
    [client, login, navigate, refetchPermissionsByUserAndPlace],
  );

  const [checking, setChecking] = useState(true);
  const [isLoading, setIsLoggedIn] = useState(true);

  useEffect(() => {
    onAuthStateChanged(auth, userAuth => {
      if (userAuth?.uid) {
        handleUser(userAuth);
        // dispatch(login(user.uid, user.displayName));
        setIsLoggedIn(true);
        refetchPermissionsByUserAndPlace();
      } else {
        setIsLoggedIn(false);
      }
      setChecking(false);
    });
  }, [
    handleUser,
    refetchPermissionsByUserAndPlace,
    setChecking,
    setIsLoggedIn,
  ]);

  const handleLogin = useCallback(
    async (emailp, password) => {
      try {
        setState(prev => ({ ...prev, isLoginPending: true }));

        const userCredential = await signInWithEmailAndPassword(
          auth,
          emailp,
          password,
        );
        handleUser(userCredential.user);
      } catch (err) {
        toast.error(err.message);
        setState(prev => ({ ...prev, loginError: err.message }));
      } finally {
        setState(prev => ({ ...prev, isLoginPending: false }));
      }
    },
    [handleUser],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCheckEmailAuth = async email => {
    try {
      // verify if email has a user in firebase
      const {
        data: { user },
      } = await client.query({
        query: GET_FIREBASE_USER_BY_EMAIL,
        variables: {
          email,
        },
      });
      return user;
    } catch (err) {
      toast.error(err.message);
      return false;
    }
  };

  const handleSendEmailReset = useCallback(
    async (email, uid) => {
      try {
        // send email
        const isSent = await requestPasswordReset({
          variables: {
            email,
            uid,
          },
        });
        return isSent;
      } catch (err) {
        toast.error(err.message);
        return false;
      }
    },
    [requestPasswordReset],
  );

  const handleVerifyPassword = async (emailp, password) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        emailp,
        password,
      );
      return userCredential;
    } catch (err) {
      toast.error(err.message);
      return null;
    }
  };

  const handleLoginWithCustomToken = async token => {
    try {
      const userCredential = await signInWithCustomToken(auth, token);
      return userCredential.user;
    } catch (error) {
      toast.error(error.message);
      return null;
    }
  };

  const changePassword = async password => {
    updatePassword(auth.currentUser, password)
      .then(() => {
        toast.success("Cambio de contraseña exitoso");
        return true;
      })
      .catch(error => {
        toast.error("Error al actualizar contraseña");
        return null;
      });
  };

  const handleLoginGoogle = useCallback(() => {
    setState(prev => ({ ...prev, isLoginPending: true }));
    signInWithPopup(auth, googleAuthProvider)
      .then(userCredential => {
        handleUser(userCredential.user);
      })
      .catch(err => {
        toast.error(err.message);
        setState(prev => ({ ...prev, loginError: err.message }));
      })
      .finally(() => {
        setState(prev => ({ ...prev, isLoginPending: false }));
      });
  }, [handleUser]);

  const handleLogout = useCallback(() => {
    signOut(auth).then(() => {
      Cookies.remove("token");
      setState(prev => ({ ...prev, ...initialState }));
    });
  }, []);

  const handleCheckAuth = useCallback(async () => {
    try {
      setState(prev => ({ ...prev, isLoginPending: true }));
      const {
        data: { checkAuth = false },
      } = await client.query({
        query: CHECK_AUTH_QUERY,
        fetchPolicy: "network-only",
      });

      if (!checkAuth) {
        throw new Error(`Not authorized.`);
      }
    } catch (err) {
      if (state.isLoggedIn) {
        handleLogout();
      }
    } finally {
      setState(prev => ({ ...prev, isLoginPending: false }));
    }
  }, [client, handleLogout, state?.isLoggedIn]);

  const handleRegisterPerson = useCallback(
    async userRegister => {
      try {
        const {
          data: { registeredPersonAdmin },
        } = await registerPerson({
          variables: {
            user: { Email: state.user.email, ...userRegister },
          },
        });
        setState(prev => ({
          ...prev,
          user: {
            ...prev.user,
            TavuelUser: registeredPersonAdmin,
          },
          isLoggedIn: true,
        }));

        return toast.success("Usuario registrado correctamente");
      } catch (error) {
        return toast.error("Error, No se pudo registrar el usuario con Google");
      }
    },
    [registerPerson, state.user.email],
  );

  const handleRegisterUser = useCallback(
    async userRegister => {
      try {
        const {
          data: { registeredUser },
        } = await registerUserAdmin({
          variables: {
            user: {
              ...userRegister,
              Locale:
                window.navigator.userLanguage || window.navigator.language,
              BirthDate_Person: new Date(userRegister.BirthDate_Person),
            },
          },
        });
        setState(prev => ({
          ...prev,
          user: { ...prev.user, TavuelUser: registeredUser },
        }));
        handleLogin(userRegister.Email, userRegister.Password);
        toast.success("Usuario registrado correctamente");
        return true;
      } catch (error) {
        toast.error("Error, No se pudo registrar el usuario ");
        return false;
      }
    },
    [handleLogin, registerUserAdmin],
  );

  const formatAmountWithSymbol =
    (amount => {
      const { DefaultCoin } = state.user.TavuelUser;
      let formattedAmount = `${amount}`;

      if (DefaultCoin) {
        formattedAmount = amount / DefaultCoin.Sell_Coin;
        formattedAmount = `${DefaultCoin?.Symbol_Coin}${formatNumber(
          formattedAmount.toFixed(2),
        )}`;
      }
      return formattedAmount;
    },
    []);

  const formatAmount = useCallback(
    amount => {
      const { DefaultCoin } = state.user.TavuelUser;
      let formattedAmount = amount;
      if (DefaultCoin) {
        formattedAmount = amount / DefaultCoin.Sell_Coin;
        formattedAmount = formatNumber(formattedAmount.toFixed(2));
      }
      return formattedAmount;
    },
    [state.user.TavuelUser],
  );

  const handleUpdateUser = useCallback(
    async user => {
      try {
        const {
          data: {
            updatedUser: { Name, Phone_Number, User },
          },
        } = await updateUser({ variables: { user } });
        setState(prev =>
          combine(prev, {
            user: {
              displayName: Name,
              phoneNumber: Phone_Number,
              TavuelUser: { ...User },
            },
          }),
        );
        toast.success("Datos actualizados");
      } catch (error) {
        toast.error(error.message);
      }
    },
    [updateUser],
  );

  const handleUpdatePicture = useCallback(
    async (Route_File, FirebaseId) => {
      await updateUserProfilePicture({
        variables: {
          Route_File,
          FirebaseId,
        },
      });
      setState(prev => combine(prev, { user: { photoURL: Route_File } }));
    },
    [updateUserProfilePicture],
  );

  const handleUpdateCoin = useCallback(
    async defaultCoin => {
      const { data } = await updateDefaultCoin({
        variables: {
          FK_Coin: defaultCoin,
        },
      });
      if (data?.coin) {
        toast.success("Moneda actualizada con éxito");
        setState(prev =>
          combine(prev, { user: { TavuelUser: { DefaultCoin: data?.coin } } }),
        );
      }
    },
    [updateDefaultCoin],
  );

  const valueTorReturn = useMemo(
    () => ({
      state,
      handleLogin,
      handleLogout,
      handleCheckAuth,
      handleLoginGoogle,
      handleRegisterUser,
      handleRegisterPerson,
      handleVerifyPassword,
      handleUpdateUser,
      handleUpdatePicture,
      handleUpdateCoin,
      checking,
      isLoading,
      setState,
      formatAmount,
      formatAmountWithSymbol,
      handleUserExternal,
      idPlaces,
      handleCheckEmailAuth,
      changePassword,
      handleLoginWithCustomToken,
      handleSendEmailReset,
      permissions,
      isSideBarOpen,
      setIsSideBarOpen,
      refetchPermissionsByUserAndPlace,
    }),
    [
      checking,
      formatAmount,
      formatAmountWithSymbol,
      handleCheckAuth,
      handleCheckEmailAuth,
      handleLogin,
      handleLoginGoogle,
      handleLogout,
      handleRegisterPerson,
      handleRegisterUser,
      handleSendEmailReset,
      handleUpdateCoin,
      handleUpdatePicture,
      handleUpdateUser,
      handleUserExternal,
      idPlaces,
      isLoading,
      isSideBarOpen,
      permissions,
      setIsSideBarOpen,
      state,
      refetchPermissionsByUserAndPlace,
    ],
  );

  return (
    <AuthContext.Provider value={valueTorReturn}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
