import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// External components
import { Box, Card, Typography, Button } from "@mui/material";

// Utils
import { formatCurrency } from "../../utils";
// SCSS
import "./BillResumeCard.scss";
// Img
import deleteIcon from "../../assets/icons/delete_icon.svg";

function BillResumeCard({ bill, handleDeleteDocument }) {
  const navigate = useNavigate();

  return (
    <Card
      color="secondary"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "0.1rem",
        height: "197px",
        width: { xs: "100%", sm: "333px" },
        padding: "1rem",
      }}
    >
      <Typography align="start" variant="subtitle1">
        <span className="has-text-weight-bold"> Emisor: </span>{" "}
        {bill.Place.Name_Place || "--"}
      </Typography>
      <Typography align="start" variant="subtitle1">
        <span className="has-text-weight-bold"> Receptor: </span>{" "}
        {bill.User?.Person?.Name_Person || "No seleccionado"}
      </Typography>
      <Typography align="start" variant="subtitle1">
        <span className="has-text-weight-bold"> Cédula Receptor: </span>{" "}
        {bill.User?.Person?.DocumentId_Person || "--"}
      </Typography>
      <Typography align="start" variant="subtitle1">
        <span className="has-text-weight-bold"> Moneda: </span>{" "}
        {bill?.Coin?.Name_Coin || "--"}
      </Typography>
      <Typography align="start" variant="subtitle1">
        <span className="has-text-weight-bold"> Total: </span>{" "}
        {formatCurrency(
          parseFloat(bill.Total_Bill, 10),
          bill?.Coin?.Code_Coin ? bill?.Coin?.Code_Coin : "CRC",
        )}
      </Typography>
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}
      >
        <Button
          outlined
          className="btn-animate"
          sx={{ padding: "0px", justifyContent: "start" }}
          onClick={() => handleDeleteDocument(bill?.id)}
        >
          <img
            alt="Eliminar"
            className="icon-button icon-color"
            src={deleteIcon}
          />
        </Button>
        <Button
          outlined
          color="primary"
          sx={{ padding: "0px" }}
          onClick={() => navigate(`/electronic-bills/${bill.id}/Draft`)}
        >
          <Typography
            align="center"
            fontWeight={600}
            typography="modeButtonsecondLight"
            variant="subtitle2"
          >
            Ver documento
          </Typography>
        </Button>
      </Box>
    </Card>
  );
}

BillResumeCard.propTypes = {
  bill: PropTypes.object.isRequired,
  handleDeleteDocument: PropTypes.func,
};

BillResumeCard.defaultProps = {
  handleDeleteDocument: e => e,
};

export default BillResumeCard;
