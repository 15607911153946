import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useMutation, useQuery } from "@apollo/client";
import {
  Box,
  FormControlLabel,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Table,
  Checkbox,
} from "@mui/material";
import { GET_SUBMODULES_BY_MODULE } from "../../../../graphql/submodule/submodule.queries";
import {
  CREATE_ROL_WITH_SUBMODULE,
  UPDATE_ROL_WITH_SUBMODULE,
} from "../../../../graphql/role/role.mutation";
import { useElectronic } from "../../../../context/ElectronicContext";
import { GET_ROLE_PERMISSION_BY_ROLE } from "../../../../graphql";
import "./NewOrEditRole.scss";
import InputForm from "../../../InputForm/InputForm";
import FormWithButtons from "../../../FormWithButttons/FormWithButtons";
import { customToast } from "../../../../utils";

function NewOrEditRole({
  setCloseModal,
  title,
  refetch,
  componentMode,
  editRole,
}) {
  const isCreateMode = componentMode === "create";

  const [nameRole, setNameRole] = useState("");
  const [submodules, setSubmodules] = useState([]);

  const { LineDetailPlace } = useElectronic();
  // mutation
  const [createRoleWithSubmodules] = useMutation(CREATE_ROL_WITH_SUBMODULE);
  const [updateRoleWithSubmodules] = useMutation(UPDATE_ROL_WITH_SUBMODULE);

  // queries
  const { data: permissionsSubmodulesByRole, refetch: refetchPermissions } =
    useQuery(GET_ROLE_PERMISSION_BY_ROLE, {
      variables: {
        FK_Role: editRole.id,
      },
    });

  const { data: submodulesByModule } = useQuery(GET_SUBMODULES_BY_MODULE, {
    variables: {
      FK_Module: 11,
    },
  });
  useEffect(() => {
    if (isCreateMode) {
      if (submodulesByModule?.getSubmodulesByModule) {
        const newSubmodules = submodulesByModule?.getSubmodulesByModule.map(
          role => ({
            id: role.id,
            Name_Submodule: role.Name_Submodule,
            Route_Submodule: role.Route_Submodule,
            access: false,
            actions: {
              read: false,
              create: false,
              edit: false,
              delete: false,
              all: false,
            },
          }),
        );
        setSubmodules(newSubmodules);
      }
    } else {
      setNameRole(editRole.Name_Role);
      if (submodulesByModule?.getSubmodulesByModule) {
        const newSubmodules = submodulesByModule?.getSubmodulesByModule.map(
          role => ({
            id: role.id,
            Name_Submodule: role.Name_Submodule,
            Route_Submodule: role.Route_Submodule,
            access: getAcccess(
              permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
              role.id,
            ),
            actions: {
              read: haveSubmoduleAction(
                "1",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              create: haveSubmoduleAction(
                "2",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              edit: haveSubmoduleAction(
                "3",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              delete: haveSubmoduleAction(
                "4",
                permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
                role.id,
              ),
              all: false,
            },
          }),
        );
        setSubmodules(newSubmodules);
      }
    }
    return () => {};
  }, [
    componentMode,
    editRole,
    isCreateMode,
    permissionsSubmodulesByRole?.getRolePermissionSubmoduleByRole,
    submodulesByModule,
  ]);

  const getAcccess = (permissions, sub) => {
    const access = permissions?.find(
      permission => permission.FK_Submodule === sub,
    );
    return !!access;
  };

  const haveSubmoduleAction = (FK_Permission, permissions, FK_Submodule) => {
    const action = permissions?.find(
      permission =>
        permission.FK_Submodule === FK_Submodule &&
        (permission.FK_Permission === FK_Permission ||
          permission.FK_Permission === "5"),
    );
    return !!action;
  };
  const handleChangeSubmodule = pos => {
    const updatedCheckedState = submodules.map((item, index) =>
      index === pos
        ? {
            id: item.id,
            Name_Submodule: item.Name_Submodule,
            Route_Submodule: item.Route_Submodule,
            access: !item.access,
            actions: {
              read: false,
              create: false,
              edit: false,
              delete: false,
              all: false,
            },
          }
        : item,
    );
    setSubmodules(updatedCheckedState);
  };

  const handleChangePermissionSubmodule = (pos, event) => {
    const updatedCheckedState = submodules.map((item, index) =>
      index === pos
        ? {
            id: item.id,
            Name_Submodule: item.Name_Submodule,
            Route_Submodule: item.Route_Submodule,
            access: item.access,
            actions: actionsChange(item.actions, event.target.name),
          }
        : item,
    );
    setSubmodules(updatedCheckedState);
  };

  const actionsChange = (actionsObject, action) => {
    const actions = actionsObject;
    if (action === "read") {
      actions[action] = !actionsObject[action];
      if (actionsObject.read === false) {
        actions.edit = false;
        actions.all = false;
        actions.delete = false;
        actions.create = false;
      }
    } else {
      if (actionsObject.read !== true) actions.read = true;
      actions[action] = !actionsObject[action];
    }
    return actions;
  };

  const handleCancel = useCallback(() => {
    setCloseModal(false);
  }, [setCloseModal]);

  const createRole = useCallback(async () => {
    try {
      const { data } = await createRoleWithSubmodules({
        variables: {
          role: {
            Name_Role: nameRole,
            Value_Role: 1,
            FK_Place: LineDetailPlace.id,
          },
          submodules,
        },
      });
      if (data) {
        customToast.success("¡Rol registrado con éxito!");
        handleCancel();
        refetch();
      } else {
        customToast.error("Error al registrar el Rol");
      }
    } catch (err) {
      customToast.error("Error al registrar el Rol");
    }
  }, [
    createRoleWithSubmodules,
    nameRole,
    LineDetailPlace.id,
    submodules,
    handleCancel,
    refetch,
  ]);

  const updateRole = useCallback(async () => {
    try {
      const { data } = await updateRoleWithSubmodules({
        variables: {
          role: {
            Name_Role: nameRole,
            Value_Role: 1,
            FK_Place: LineDetailPlace.id,
            id: editRole.id,
          },
          submodules,
        },
      });
      if (data.role) {
        customToast.success("¡Rol actualizado con éxito!");
        handleCancel();
        refetch();
        refetchPermissions();
      } else {
        customToast.error("Error al actualizar el rol");
      }
    } catch (err) {
      customToast.error("Error al actualizar el rol");
    }
  }, [
    LineDetailPlace.id,
    editRole.id,
    handleCancel,
    nameRole,
    refetch,
    refetchPermissions,
    submodules,
    updateRoleWithSubmodules,
  ]);

  const handleSaveAndUpdateRole = async e => {
    e.preventDefault();
    if (nameRole === "") {
      customToast.error("Debe completar los datos solicitados.");
    } else if (isCreateMode) {
      createRole();
    } else {
      updateRole();
      refetch();
    }
  };

  useEffect(() => {
    refetchPermissions();
  }, [refetchPermissions]);

  return (
    <Box
      className="container-modal"
      sx={{
        overflow: "auto",
        height: "620px",
        maxWidth: { xs: "350px", sm: "520px" },
      }}
    >
      <Box className="modal-content-padding">
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          {title}
        </Typography>
        <InputForm
          color="primary"
          id="nameRole"
          label=" Nombre del rol"
          name="nameRole"
          size="small"
          type="text"
          value={nameRole}
          variant="filled"
          onChange={e => setNameRole(e.target.value)}
        />
        {nameRole && (
          <Typography
            fontWeight="bold"
            typography="modeColor"
            variant="subtitle2"
            width="100%"
          >
            {nameRole}
          </Typography>
        )}
        <Typography
          align="center"
          typography="modeColor"
          variant="subtitle2"
          width="100%"
        >
          ¿A cuáles módulos tiene acceso el rol?
        </Typography>
        <Box>
          <TableContainer
            color="primary"
            sx={{
              backgroundColor: "transparent",
              padding: "1rem",
            }}
          >
            <Table
              color="primary"
              sx={{
                backgroundColor: "transparent",
              }}
              variant="outlined"
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      typography="modeColor"
                      variant="subtitle2"
                    >
                      Módulo
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      typography="modeColor"
                      variant="subtitle2"
                    >
                      Acceso
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      typography="modeColor"
                      variant="subtitle2"
                    >
                      Leer
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      typography="modeColor"
                      variant="subtitle2"
                    >
                      Crear
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      typography="modeColor"
                      variant="subtitle2"
                    >
                      Editar
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderBottom: "none",
                    }}
                  >
                    <Typography
                      fontWeight="bold"
                      typography="modeColor"
                      variant="subtitle2"
                    >
                      Eliminar
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {submodules?.map((item, index) => (
                  <TableRow key={item.id} color="primary">
                    <TableCell
                      color="primary"
                      sx={{
                        borderBottom: index === 7 && "none",
                      }}
                    >
                      <Typography typography="modeColor" variant="caption">
                        {item.Name_Submodule}
                      </Typography>
                    </TableCell>
                    <TableCell
                      color="primary"
                      sx={{
                        borderBottom: index === 7 && "none",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.access}
                            name="access"
                            size="10px"
                            onChange={() => handleChangeSubmodule(index)}
                          />
                        }
                        sx={{ margin: "0px", textAlign: "center" }}
                      />
                    </TableCell>
                    <TableCell
                      color="primary"
                      sx={{
                        borderBottom: index === 7 && "none",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.actions.read}
                            disabled={!item.access}
                            name="read"
                            size="10px"
                            onChange={e =>
                              handleChangePermissionSubmodule(index, e)
                            }
                          />
                        }
                        sx={{ margin: "0px" }}
                      />
                    </TableCell>
                    <TableCell
                      color="primary"
                      sx={{
                        borderBottom: index === 7 && "none",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.actions.create}
                            disabled={!item.access}
                            name="create"
                            size="10px"
                            onChange={e =>
                              handleChangePermissionSubmodule(index, e)
                            }
                          />
                        }
                        sx={{ margin: "0px" }}
                      />
                    </TableCell>
                    <TableCell
                      color="primary"
                      sx={{
                        borderBottom: index === 7 && "none",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.actions.edit}
                            disabled={!item.access}
                            name="edit"
                            size="10px"
                            onChange={e =>
                              handleChangePermissionSubmodule(index, e)
                            }
                          />
                        }
                        sx={{ margin: "0px" }}
                      />
                    </TableCell>
                    <TableCell
                      color="primary"
                      sx={{
                        borderBottom: index === 7 && "none",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={item.actions.delete}
                            disabled={!item.access}
                            name="delete"
                            size="10px"
                            onChange={e =>
                              handleChangePermissionSubmodule(index, e)
                            }
                          />
                        }
                        sx={{ margin: "0px" }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <FormWithButtons
          buttonsAlignment="center"
          cancelText="Cancelar"
          submitText={isCreateMode ? "Guardar" : "Actualizar"}
          onCancel={handleCancel}
          onSubmit={handleSaveAndUpdateRole}
        />
      </Box>
    </Box>
  );
}

NewOrEditRole.propTypes = {
  setCloseModal: PropTypes.func,
  title: PropTypes.string.isRequired,
  refetch: PropTypes.func.isRequired,
  componentMode: PropTypes.string.isRequired,
  editRole: PropTypes.object,
};

NewOrEditRole.defaultProps = {
  setCloseModal: e => e,
  editRole: { id: 0 },
};

export default NewOrEditRole;
