import React from "react";
import PropTypes from "prop-types";
import "../../../../styles/index.scss";

import SelectProduct from "../../../ProductSelect";

function LineDetail({ goBack }) {
  return <SelectProduct goBack={goBack} />;
}

LineDetail.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default LineDetail;
