import { gql } from "@apollo/client";

export const CHECK_AUTH_QUERY = gql`
  query checkAuth {
    checkAuth {
      UID
    }
  }
`;

export const GET_USER_SECURITIES = gql`
  query userSecurities(
    $where: UserSecurityWhereInput
    $distinct: [UserSecurityScalarFieldEnum!]
  ) {
    userSecurities(where: $where, distinct: $distinct) {
      groupcode
    }
  }
`;

export const GET_USER_BY_UID = gql`
  query userByUID($uid: ID!) {
    user: userByUID(uid: $uid) {
      id
      Email
      Name
      Picture
      UID
      Phone_Number
      Provider_Id
    }
  }
`;
export const GET_FIREBASE_USER_BY_EMAIL = gql`
  query userByEmail($email: String!) {
    user: userByEmail(email: $email) {
      id
      Email
      Name
      Picture
      UID
      Phone_Number
      Provider_Id
    }
  }
`;
