import React from "react";
import PropTypes from "prop-types";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_DOCUMENT_TYPES } from "../../graphql";
// Components
import { Select } from "../Common";

function DocumentTypeSelect({ name, selected_value, onChange, className }) {
  const { loading, error, data } = useQuery(GET_ALL_DOCUMENT_TYPES);

  const Options = data?.documentTypes
    ? [
        ...data.documentTypes.map(docType => ({
          value: docType.id,
          label: docType.Description,
        })),
        { value: null, label: "Cualquiera" },
      ]
    : [{ value: null, label: "Cualquiera" }];

  return (
    <Select
      className={className}
      defaultValue={selected_value}
      disabled={!!error}
      label="Tipo de Documento"
      name={name}
      options={Options}
      placeholder={loading ? "Cargando..." : "Tipo de Documento"}
      value={selected_value}
      onChange={({ value }) => onChange(name, value)}
    />
  );
}

DocumentTypeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected_value: PropTypes.string,
  className: PropTypes.string,
};
DocumentTypeSelect.defaultProps = {
  className: "",
  selected_value: null,
};

export default DocumentTypeSelect;
