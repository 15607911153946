/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_CLIENTS_BY_PLACE = gql`
  query getClient($FK_Place: ID!) {
    client: getClientByIdPlace(FK_Place: $FK_Place) {
      id
      BillingProfile {
        id
        Name
        ID_Number
        Email
        PhoneNumber
        PhoneCodCountry
        OtherSigns
        User {
          id
        }
        MH_PersonType {
          id
          Name_PersonType
        }
        MH_Neighborhood {
          id
          Name
          District {
            id
            Name
            Canton {
              id
              Name
              Province {
                id
                Name
              }
            }
          }
        }
      }
      Created_By {
        id
      }
    }
  }
`;

export const GET_CLIENTS_BY_CRITERIA = gql`
  query getByCriteria($criteria: String, $FK_Place: ID!) {
    clients: findBillingClientByCriteria(
      criteria: $criteria
      FK_Place: $FK_Place
    ) {
      id
      Name
      ID_Number
      Email
      PhoneNumber
      User {
        id
      }
      MH_PersonType {
        id
        Name_PersonType
      }
      MH_Neighborhood {
        id
        Name
        District {
          id
          Name
          Canton {
            id
            Name
            Province {
              id
              Name
            }
          }
        }
      }
    }
  }
`;
