import { Progress } from "rbx";
import React from "react";
import PropTypes from "prop-types";
import {
  faCircleNotch,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CustomLoading({
  loadingTexts,
  warningTexts,
  errorTexts,
  loadingState,
  circleLoad,
  vCentered,
  centered,
  fullWidth,
  className,
}) {
  const textTypes = {
    loading: loadingTexts,
    warning: warningTexts,
    error: errorTexts,
  };

  const getProperTextMap = () => {
    if (loadingState !== "") {
      return textTypes[loadingState].map(text => (
        <div key={text} className="has-text-centered">
          {text}
        </div>
      ));
    }
    return (
      <div key={loadingState} className="has-text-centered">
        Cargando...
      </div>
    );
  };

  const getProperIcon = loadState => {
    if (loadState === "error") return "times";
    if (loadState === "warning") return faExclamationTriangle;
    return null;
  };

  const getProperLoadingIcon = () => {
    const errorIcon = getProperIcon(loadingState);
    if (errorIcon) {
      return (
        <FontAwesomeIcon
          icon={errorIcon}
          size="5x"
          style={{ color: "#0c2d55" }}
        />
      );
    }
    return !circleLoad ? (
      <Progress
        className={`column ${
          !fullWidth
            ? "is-four-fifths-mobile is-half-tablet"
            : "is-mobile is-full"
        }`}
        color="primary"
        size="medium"
      />
    ) : (
      <FontAwesomeIcon
        spin
        icon={faCircleNotch}
        size="5x"
        style={{ color: "#0c2d55" }}
      />
    );
  };

  return (
    <div
      className={`${vCentered ? "hero is-fullheight" : "section"} ${className}`}
    >
      <div className={vCentered && "hero-body"}>
        <div className={centered && "container"}>
          <div className="is-mobile columns is-centered">
            {getProperLoadingIcon()}
          </div>
          {getProperTextMap()}
        </div>
      </div>
    </div>
  );
}

CustomLoading.propTypes = {
  loadingTexts: PropTypes.array,
  warningTexts: PropTypes.array,
  errorTexts: PropTypes.array,
  loadingState: PropTypes.string,
  circleLoad: PropTypes.bool,
  vCentered: PropTypes.bool,
  centered: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
};
CustomLoading.defaultProps = {
  loadingTexts: [],
  warningTexts: [],
  errorTexts: [],
  loadingState: "loading",
  circleLoad: false,
  vCentered: false,
  centered: false,
  fullWidth: false,
  className: "",
};

export default CustomLoading;
