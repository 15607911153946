import { format } from "date-fns";
import PropTypes from "prop-types";
// External components
import { Box, Button, Card, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Utils
import { formatCurrency } from "../../utils";
// SCSS
import "./EBResumeCard.scss";

function EBResumeCard({ item, actions }) {
  const withoutReceptor = item?.ElectronicBillDocumentType?.id === "1";
  return (
    <Card
      color={withoutReceptor ? "primary" : "lightSecondary"}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "0.1rem",
        height: "259px",
        width: { xs: "100%", sm: "333px" },
        padding: "1rem",
      }}
    >
      <Box
        sx={{
          height: "200px",
        }}
      >
        <Typography
          align="start"
          typography={withoutReceptor ? "modeColor" : "defaultLight"}
          variant="subtitle1"
        >
          <span className="has-text-weight-bold"> Documento: </span>{" "}
          {item?.ElectronicBillDocumentType?.Description}
        </Typography>
        <Typography
          align="start"
          typography={withoutReceptor ? "modeColor" : "defaultLight"}
          variant="subtitle1"
        >
          <span className="has-text-weight-bold"> Emisor:</span>{" "}
          {item.Place?.Name_Place}
        </Typography>

        {withoutReceptor === "1" && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "0.1rem",
            }}
          >
            <Typography
              align="start"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: { xs: "200px", sm: "300px" },
              }}
              typography="modeColor"
              variant="subtitle1"
            >
              <span className="has-text-weight-bold"> Receptor: </span>{" "}
              {item.Bill?.User?.Person?.Name_Person}
            </Typography>{" "}
            <Typography
              align="start"
              typography="modeColor"
              variant="subtitle1"
            >
              <span className="has-text-weight-bold"> Cédula Receptor: </span>{" "}
              {item.Bill?.User?.Person?.DocumentId_Person}
            </Typography>
          </Box>
        )}
        <Typography
          align="start"
          typography={withoutReceptor ? "modeColor" : "defaultLight"}
          variant="subtitle1"
        >
          <span className="has-text-weight-bold"> Moneda: </span>{" "}
          {item?.Coin?.Name_Coin}
        </Typography>
        <Typography
          align="start"
          typography={withoutReceptor ? "modeColor" : "defaultLight"}
          variant="subtitle1"
        >
          <span className="has-text-weight-bold"> Total: </span>{" "}
          {formatCurrency(
            parseFloat(item.TotalBill, 10),
            item?.Coin?.Code_Coin ? item?.Coin?.Code_Coin : "CRC",
          )}
        </Typography>
        {item?.EmitedDay && (
          <Typography
            align="start"
            typography={withoutReceptor ? "modeColor" : "defaultLight"}
            variant="subtitle1"
          >
            <span className="has-text-weight-bold"> Fecha: </span>{" "}
            {format(new Date(item?.EmitedDay), "dd/MM/yyyy hh:mm a")}
          </Typography>
        )}
      </Box>

      {actions.map(action =>
        action.isIcon ? (
          <FontAwesomeIcon
            icon={action.icon}
            size="2x"
            title={action.text}
            onClick={action.onClick}
          />
        ) : (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
          >
            <Button
              key={item.id}
              outlined
              color="primary"
              sx={{ padding: "0px" }}
              onClick={action.onClick}
            >
              <Typography
                align="center"
                fontWeight={600}
                typography={withoutReceptor ? "modeButton" : "modeButtonLight"}
                variant="subtitle2"
              >
                {action.text}
              </Typography>
            </Button>
          </Box>
        ),
      )}
    </Card>
  );
}

EBResumeCard.propTypes = {
  item: PropTypes.object.isRequired,
  actions: PropTypes.array,
};
EBResumeCard.defaultProps = {
  actions: [],
};

export default EBResumeCard;
