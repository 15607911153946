/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const REGISTER_NEW_CLIENTXGROUP = gql`
  mutation createClient($data: ClientGroupXUserInput) {
    client: createClientGroupXUser(data: $data) {
      id
      BillingProfile {
        id
        Name
        Email
        ID_Number
        PhoneCodCountry
        PhoneNumber
        OtherSigns
        User {
          id
        }
        MH_PersonType {
          id
          Name_PersonType
        }
        MH_Neighborhood {
          id
          Name
          District {
            id
            Name
            Canton {
              id
              Name
              Province {
                id
                Name
              }
            }
          }
        }
      }
    }
  }
`;
export const REGISTER_NEW_CLIENTSXGROUP = gql`
  mutation createClient($clients: [ClientGroupXUserInput]) {
    clients: createMultipleClientsGroupXUser(clients: $clients)
  }
`;
export const DELETE_CLIENTXGROUP = gql`
  mutation deleteClientsGroupXUser($id: ID!) {
    deleteClientGroupXUser(id: $id)
  }
`;

export const DELETE_MULTIPLE_CLIENTXGROUP = gql`
  mutation deleteClientsGroupXUserByUser($FK_Users: [ID]!) {
    deleteClientGroupXUserByUser(FK_Users: $FK_Users)
  }
`;
