import React, { useEffect, useContext } from "react";
import PropTypes from "prop-types";
// External components
import { ElectronicContext } from "../../context/ElectronicContext";
import SelectInput from "../SelectInput/SelectInput";

function IvaSelect({ value, name, onChange, disabled }) {
  const { tariff } = useContext(ElectronicContext);
  useEffect(() => {
    if (value !== undefined || !tariff?.tariff) return;
    onChange(name, { Name: "SinSeleccionar", id: 0, Percent: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tariff, name, value]);

  return (
    <SelectInput
      data={tariff?.tariff}
      disabled={disabled}
      name={name}
      value={value}
      onChange={e => onChange(e.target.name, e.target.value)}
    />
  );
}

IvaSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

IvaSelect.defaultProps = {
  disabled: false,
};

export default IvaSelect;
