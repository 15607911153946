import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// Graphql
import { client_EB_api } from "../../graphql/client";
import { GET_ALL_DISTRICTS } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";
import SelectInput from "../SelectInput/SelectInput";

function DistrictSelect({
  value,
  name,
  onChange,
  label,
  disabled,
  className,
  canton,
}) {
  const [districts, setDistricts] = useState([]);

  const getDistricts = useCallback(async () => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_DISTRICTS,
      variables: {
        FK_Canton: canton,
      },
    });
    setDistricts(data.districts);
  }, [canton]);

  useEffect(() => {
    getDistricts();
  }, [getDistricts]);

  return (
    <SelectInput
      data={districts}
      disabled={disabled}
      name={name}
      value={value}
      onChange={e => onChange(e.target.name, e.target.value)}
    />
  );
}

DistrictSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  canton: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

DistrictSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default DistrictSelect;
