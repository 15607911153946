/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_SUBMODULES_BY_PLACE_AND_MODULE_AND_ROLE = gql`
  query getSubmodulesByPlaceAndModuleAndRole(
    $FK_Place: ID!
    $FK_Module: ID!
    $FK_Role: ID!
  ) {
    getSubmodulesByPlaceAndModuleAndRole(
      FK_Place: $FK_Place
      FK_Module: $FK_Module
      FK_Role: $FK_Role
    ) {
      id
      Name_Submodule
      Permissions {
        Name_Permission
      }
    }
  }
`;

export const GET_ROLES_BY_PLACE = gql`
  query getRolesByPlace($FK_Place: ID!) {
    getRolesByPlace(FK_Place: $FK_Place) {
      id
      Name_Role
      Place {
        id
      }
    }
  }
`;
