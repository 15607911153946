import { UserRole } from "../../components";

function ManageUsersPage() {
  return (
    <div>
      <UserRole />
    </div>
  );
}

export default ManageUsersPage;
