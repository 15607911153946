/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_PERSON_TYPES = gql`
  query getAllPersonType {
    personTypes: getAllPersonType {
      id
      Name_PersonType
    }
  }
`;
