import React from "react";
// Components
import { Products } from "../../components";

function ProductsPage() {
  return (
    <div>
      <Products />
    </div>
  );
}

export default ProductsPage;
