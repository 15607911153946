import PropTypes from "prop-types";
// External components
import { Field, Control, Label } from "rbx";
import Select from "react-select";

function Input({
  placeholder,
  onChange,
  label,
  value,
  className,
  options,
  isMulti,
}) {
  return (
    <Field className={className} kind="group">
      <Control expanded>
        <Label className="has-text-white" textColor="grey-dark">
          {label}
        </Label>
        <Select
          defaultValue={value}
          isMulti={isMulti}
          options={options}
          placeholder={placeholder}
          theme={theme => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: "#1d4b9640",
              neutral20: "#01234c",
            },
          })}
          onChange={onChange}
        />
      </Control>
    </Field>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string,
  options: PropTypes.array.isRequired,
  isMulti: PropTypes.bool,
};

Input.defaultProps = {
  onChange: e => e,
  label: "",
  className: "",
  isMulti: false,
  value: [],
};

export default Input;
