import { gql } from "@apollo/client";

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $place: ID!
    $data: String
    $user: ID!
    $module: ID!
  ) {
    notification: createNotification(
      place: $place
      data: $data
      user: $user
      module: $module
    )
  }
`;

export const SAVE_NOTIFICATION_USER = gql`
  mutation saveNotificationUser(
    $id: ID
    $FK_User: ID
    $FK_Notification: ID
    $Readed_At: DateTime
    $Created_At: DateTime
    $Updated_At: DateTime
  ) {
    response: saveNotificationUser(
      id: $id
      FK_User: $FK_User
      FK_Notification: $FK_Notification
      Readed_At: $Readed_At
      Created_At: $Created_At
      Updated_At: $Updated_At
    )
  }
`;
