import React, { useState } from "react";
import PropTypes from "prop-types";
// Context
import { Card, Typography } from "@mui/material";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { formatNumber } from "../../utils/ReportUtils";

function ExpansionPanelHeader({ bill }) {
  const [isActive, setIsActive] = useState(false);
  const { useCoinDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;

  return (
    <Card
      color={!isActive ? "borderbottom" : "borderbottomActive"}
      sx={{ height: !isActive ? "48px" : "100%", minHeight: "48px" }}
    >
      <Card
        key={bill.InternConsecutive}
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          paddingX: "1rem",
          background: "transparent",
          boxShadow: "none",
          alignItems: "center",
          height: !isActive ? "100%" : "auto",
          minHeight: "48px",
        }}
        tabIndex="0"
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
      >
        <Typography
          style={{ cursor: "pointer" }}
          typography="modeColor"
          variant="body2"
        >
          {formatNumber(toExchangeRate(bill?.TotalBill))}
          {"  "}
          {formatNumber(toExchangeRate(bill?.TotalTaxes))}
        </Typography>
        <Typography
          fontWeight={600}
          style={{ cursor: "pointer" }}
          typography="modeColor"
          variant="h6"
        >
          {isActive ? "-" : "+"}
        </Typography>
      </Card>
      {isActive && (
        <Card
          color="optionCardSelect"
          sx={{
            margin: "1rem",
            padding: "0.2rem",
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            minHeight: "48px",
          }}
        >
          <Typography typography="modeColor" variant="body2">
            <b> Total Mercancías Exentas: </b>
            {formatNumber(toExchangeRate(bill?.TotalGoodsExempt))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Total Mercancías Gravadas: </b>
            {formatNumber(toExchangeRate(bill?.TotalGoodsTaxed))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Total Servicios Exentos: </b>
            {formatNumber(toExchangeRate(bill?.TotalServicesExempt))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Total Servicios Gravados: </b>
            {formatNumber(toExchangeRate(bill?.TotalServicesTaxed))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Total Gravado: </b>
            {formatNumber(toExchangeRate(bill?.TotalTaxed))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Total Exento: </b>
            {formatNumber(toExchangeRate(bill?.TotalExempt))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Impuestos: </b>
            {formatNumber(toExchangeRate(bill?.TotalTaxes))}
          </Typography>
          <Typography typography="modeColor" variant="body2">
            <b>Total Comprobante: </b>
            {formatNumber(toExchangeRate(bill?.TotalBill))}
          </Typography>
        </Card>
      )}
    </Card>
  );
}
ExpansionPanelHeader.propTypes = {
  bill: PropTypes.object.isRequired,
};
export default ExpansionPanelHeader;
