import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// External components
import { Box, Button, Grid, Input, Typography } from "@mui/material";
// Context
import { useMutation } from "@apollo/client";
import { UPDATE_COMPANY } from "../../graphql";
import { useAuth, useModal } from "../../context";
// Components
import CropperPlace from "../CropperPlace";
import FormButtons from "../FormWithButttons/FormWithButtons";
// Utils
import { customToast as toast } from "../../utils";
// Helpers
import { validateRoute } from "../../utils/helpers";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";
import InputForm from "../InputForm/InputForm";

function CompanyRegister({ setCompanySelected, company, refetchCompany }) {
  // states
  const { state: authState } = useAuth();

  const [newCompany, setNewCompany] = useState({
    Name_Company: company.Name_Company,
    Description_Company: company.Description_Company,
    FK_User: authState.user.TavuelUser.id,
    FK_Plan: 10,
    id: company.id,
  });
  const [upImg, setUpImg] = useState("");
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [open, setOpen] = useState(false);

  const { setModalOpen: setModalOpenCropper } = useModal();

  const canvas = useRef(null);

  const [updateCompany] = useMutation(UPDATE_COMPANY);

  const handleChange = (name, value) => {
    setNewCompany(prev => ({ ...prev, [name]: value }));
  };

  const validateImgRoute = () => {
    if (firebaseURL === "") {
      return validateRoute(company);
    }
    return firebaseURL;
  };

  const update = useCallback(
    async companyUpdate => {
      try {
        const { data } = await updateCompany({
          variables: {
            company: companyUpdate,
          },
        });
        if (data) {
          toast.success("¡Compañía actualizada con éxito!");
        }
      } catch (err) {
        toast.error("Error al actualizar la compañía");
      }
    },
    [updateCompany],
  );

  const handleSubmit = e => {
    e.preventDefault();
    if (
      newCompany.Name_Company !== "" &&
      newCompany.Description_Company !== ""
    ) {
      update(newCompany);
      refetchCompany();
      setCompanySelected("");
    } else {
      toast.error(
        "Debe completar toda la información solicitada para el registro.",
      );
    }
  };

  const handleCancel = () => {
    setCompanySelected("");
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setNewCompany(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpenCropper(
        true,
        <CropperPlace
          isCompany
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpenCropper(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpenCropper, upImg]);
  return (
    <form onSubmit={handleSubmit}>
      <Grid
        container
        spacing={3}
        sx={{ paddingX: { xs: 2, sm: 10, md: 25, lg: 45, xl: 60 } }}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            textAlign="center"
            typography="modeColor"
            variant="h5"
          >
            Actualizar Compañía
          </Typography>
        </Grid>

        <Grid item textAlign="center" xs={12}>
          <Button
            component="label"
            sx={{ aspectRatio: "square", width: 111, listStyle: "none" }}
          >
            <Input
              hidden
              accept="image/*"
              id="fileSelectorImg"
              type="file"
              onChange={hadleOnSelectFile}
            />
            <Box
              component="img"
              src={validateImgRoute()}
              sx={{ objectFit: "cover" }}
            />
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputForm
                color="primary"
                id="Name_Company"
                label="Nombre"
                name="Name_Company"
                placeholder="Nombre"
                type="text"
                value={newCompany.Name_Company}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <InputForm
                color="primary"
                id="Description_Company"
                label="Descripción"
                name="Description_Company"
                placeholder="Descripción"
                type="text"
                value={newCompany.Description_Company}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <FormButtons
            buttonsAlignment="end"
            buttonsSpace={3}
            submitText="Actualizar"
            onCancel={handleCancel}
          />
        </Grid>
      </Grid>
    </form>
  );
}

CompanyRegister.propTypes = {
  setCompanySelected: PropTypes.func,
  company: PropTypes.object.isRequired,
  refetchCompany: PropTypes.func.isRequired,
};

CompanyRegister.defaultProps = {
  setCompanySelected: () => null,
};

export default CompanyRegister;
