import { useMutation } from "@apollo/client";
import { useElectronic } from "../context/ElectronicContext";
import { CREATE_DRAFT_FEE, CREATE_FEE } from "../graphql";
import { client_EB_api } from "../graphql/client";

const useExportationBill = () => {
  const [createTempBill] = useMutation(CREATE_DRAFT_FEE);
  const {
    useCoinDetail,
    electronicBill,
    LineDetailPlace,
    receiver,
    lineDetail,
    exportationBasicInfo,
  } = useElectronic();
  const { currentCoin, toDolar } = useCoinDetail;

  const getSubTotal = (price, quantity) =>
    parseFloat(price) * parseInt(quantity, 10);

  const getDiscountAmount = discounts =>
    discounts?.reduce(
      (acc, discount) => parseFloat(acc) + parseFloat(discount),
      0,
    ) ?? 0;

  const handleCreatDraftFEE = async () =>
    createTempBill({
      variables: {
        createDraftFEEInput: {
          billInput: {
            FK_Coin: currentCoin,
            FK_SellCondition: electronicBill.Sell_Condition,
            FK_PaymentMethod: electronicBill.Payment_Method,
            FK_User: receiver?.user?.id,
            FK_Place: LineDetailPlace?.id,
          },
          productInput: lineDetail.map(detail => ({
            unitPrice: detail.unit_price,
            subTotal: getSubTotal(detail.unit_price, detail.quantity),
            Name: detail.label,
            MeasurementUnit: detail.unit_Measure?.id,
            totalPrice: detail.total,
            quantity: parseInt(detail.quantity, 10),
            discount: getDiscountAmount(detail?.discountAmount),
            productFeature_FK: detail.value,
            BaseTax: toDolar(parseFloat(detail?.BaseTax).toFixed(4)) || 0,
            Factor: parseFloat(detail?.Factor || 0).toFixed(4),
            FK_Tax: detail?.tax?.id,
            FK_Tariff: detail?.Tariff?.id,
            Cabys: detail?.cabys?.id,
          })),
        },
      },
    });

  const handleCreateFEE = async id =>
    client_EB_api.mutate({
      mutation: CREATE_FEE,
      variables: {
        fecDataInput: {
          ID: id,
          ExpireDate: exportationBasicInfo.expirationDate,
          PurchaseOrden: exportationBasicInfo.purchaseOrder,
          Arancel: exportationBasicInfo?.arrancel,
        },
      },
    });

  return { handleCreatDraftFEE, handleCreateFEE };
};
export default useExportationBill;
