import React, { useEffect, useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
// External components
import { useQuery } from "@apollo/client";
import { Box, Button, Tab, Tabs } from "@mui/material";
import { toast } from "react-toastify";
// components
import Inventories from "../../components/Inventories";
import Users from "../../components/Security/Users/Users";
import Roles from "../../components/Security/Roles/Roles";
import ResetPassword from "../../components/ResetPassword";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./Security.scss";
import "../../styles/index.scss";
import NewOrEditRole from "../../components/Security/Roles/NewOrEditRole/NewOrEditRole";

import {
  GET_ROLES_BY_PLACE,
  GET_USERS_BY_PLACE,
  GET_USER_BY_EMAIL,
  client,
} from "../../graphql";
import { useAuth, useModal } from "../../context";
import UserSearch from "../../components/Security/Users/UserSearch";
import AddNewUser from "../../components/Security/Users/AddNewUser/AddNewUser";

function SecurityPage() {
  const [toggleState, setToggleState] = useState(1);
  const { setModalOpen } = useModal();
  const { LineDetailPlace } = useElectronic();
  const location = useLocation();
  const isSecurityResetUrl = window.location?.pathname.includes(
    "/security/resetPassword/",
  );
  const { permissions } = useAuth();

  const { haveActionPermission } = permissions;

  const { refetch } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const { data: usersByPlace, refetch: refetchusersByPlace } = useQuery(
    GET_USERS_BY_PLACE,
    {
      variables: {
        FK_Place: LineDetailPlace.id,
      },
    },
  );

  const handleSearchUserByEmail = async search => {
    try {
      if (search?.trim()?.length === 0 || !search) {
        toast.error("Debe completar los datos solicitados.");
        return;
      }
      const res = await client.query({
        query: GET_USER_BY_EMAIL,
        variables: {
          criteria: search,
        },
        fetchPolicy: "network-only",
      });
      if (res?.data?.user?.length === 0) {
        toast.error("No se encontró ningún usuario.");
        return;
      }
      const userExists = usersByPlace?.user?.find(
        user => user?.FirebaseUser?.Email === res?.data?.user[0].Email,
      );
      if (userExists) {
        toast.error("El usuario ya se encuentra agregado.");
        return;
      }
      setModalOpen(
        true,
        <AddNewUser
          componentMode="UserExists"
          editUser={res?.data?.user[0]}
          refetch={refetchusersByPlace}
          setCloseModal={setModalOpen}
          title="Agregar usuario"
        />,
      );
    } catch (err) {
      toast.error("Error al buscar usuario");
    }
  };

  const handleNewRole = e => {
    e.preventDefault();
    setModalOpen(
      true,
      <NewOrEditRole
        componentMode="create"
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Agregar Rol"
      />,
    );
  };

  const handleAddUser = e => {
    e.preventDefault();
    setModalOpen(true, <UserSearch handleSearch={handleSearchUserByEmail} />);
  };
  const handleNewUser = e => {
    e.preventDefault();
    setModalOpen(
      true,
      <AddNewUser
        componentMode="create"
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Agregar usuario"
      />,
    );
  };

  useEffect(() => {
    const routes = [
      { route: "/security/roles", value: 1 },
      { route: "/security/users", value: 2 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [location.pathname]);

  return LineDetailPlace.id === 0 && !isSecurityResetUrl ? (
    <Inventories />
  ) : (
    <div className="content-flex-column">
      <div className="content-flex-column">
        <Box
          sx={{
            "display": "flex",
            "alignItems": { xs: "start", sm: "center" },
            "justifyContent": { xs: "start", sm: "space-between" },
            "flexDirection": { xs: "column", sm: "row" },
            "gap": "1rem",
            "& .tabs:not(:last-child)": {
              marginBottom: "0",
            },
          }}
        >
          <Tabs
            sx={{
              minHeight: "37px",
              display: "flex",
              flexGrow: 1,
              alignItems: "center",
            }}
          >
            <Tab
              as={Link}
              className={toggleState === 1 ? "active" : "tabs"}
              color="primary"
              label="Roles"
              sx={{
                minHeight: "37px",
                height: "37px",
                margin: "0px",
                padding: "0px",
                display: "flex",
                justifyContent: "center",
              }}
              to="/security/roles"
            />
            <Tab
              as={Link}
              className={toggleState === 2 ? "active" : "tabs"}
              color="secondary"
              label=" Usuarios"
              sx={{
                minHeight: "37px",
                height: "37px",
                margin: "0px",
                padding: "0px",
                display: "flex",
                justifyContent: "center",
              }}
              to="/security/users"
            />
          </Tabs>
          {haveActionPermission("Create", "/security", LineDetailPlace) && (
            <Box>
              {toggleState === 1 ? (
                <Button
                  color="secondary"
                  sx={{ height: { xs: "45px", sm: "50px" } }}
                  variant="contained"
                  onClick={e => handleNewRole(e)}
                >
                  Agregar Nuevo Rol
                </Button>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: { xs: "start", sm: "center" },
                    justifyContent: { xs: "start", sm: "space-between" },
                    flexDirection: { xs: "column", sm: "row" },
                    gap: "1rem",
                  }}
                >
                  <Button
                    color="secondary"
                    sx={{ height: { xs: "45px", sm: "50px" } }}
                    variant="contained"
                    onClick={e => handleNewUser(e)}
                  >
                    Agregar nuevo usuario
                  </Button>
                  <Button
                    color="primary"
                    sx={{ height: { xs: "45px", sm: "50px" } }}
                    variant="contained"
                    onClick={e => handleAddUser(e)}
                  >
                    Agregar usuario existente
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </div>

      <Box>
        <Routes>
          <Route element={<Roles />} path="roles" />
          <Route element={<Users />} path="users" />
          <Route element={<ResetPassword />} path="resetPassword/:token" />
          <Route element={<Roles />} path="" />
        </Routes>
      </Box>
    </div>
  );
}

SecurityPage.propTypes = {};

export default SecurityPage;
