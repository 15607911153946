import React from "react";
import PropTypes from "prop-types";
// External components
import { Table } from "rbx";

function CustomTable({ data }) {
  const a = [
    { name: "Linea" },
    { name: "Detalle" },
    { name: "Cantidad" },
    { name: "Precio unitario" },
    { name: "Precio Unitario" },
    { name: "monto" },
    { name: "Descuento" },
    { Total: "" },
  ];

  return (
    <Table fullwidth narrow>
      <Table.Head>
        <Table.Row>
          {a.map(column => (
            <Table.Heading>
              <abbr>{column.name}</abbr>
            </Table.Heading>
          ))}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        <Table.Row>
          <Table.Cell>38</Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  );
}
CustomTable.propTypes = {
  data: PropTypes.array,
};
CustomTable.defaultProps = {
  data: [],
};
export default CustomTable;
