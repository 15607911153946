import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Card, Checkbox, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation } from "@apollo/client";
import { DELETE_DRAFT_DETAIL, UPDATE_DETAILL_QUANTITY } from "../../graphql";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./ProductCard.scss";
import "react-alice-carousel/lib/alice-carousel.css";
// Utils
import {
  getDetails,
  hadleAddDiscounts,
  handleCheck,
  handleDecrement,
  handleDeleteProduct,
  handleIncrement,
  handleProductList,
} from "../../utils/ProductUtils";
import TransformImages from "../../utils/TransformImages";
import {
  BILL_TYPES_URL_PARAMS,
  ElectronicBillTypes,
} from "../../Enums/ElectronicBill";

function ProductCard({ list, setDataTable, selectables, carousel, type }) {
  const [updateQuantity] = useMutation(UPDATE_DETAILL_QUANTITY);
  const [deleteDetail] = useMutation(DELETE_DRAFT_DETAIL);
  const { setModalOpen } = useModal();
  const { electronicBill, useCoinDetail } = useElectronic();
  const { currentSymbolCoin, toExchangeRate } = useCoinDetail;
  const { isSideBarOpen } = useAuth();
  const [, setCaro_key] = useState("caro_key");
  const { billType } = useParams();

  useEffect(() => {
    setTimeout(() => {
      setCaro_key(prev => (prev === "caro_key" ? "1" : "caro_key"));
    }, 300);
  }, [isSideBarOpen]);

  const getUrl = product => {
    const route = product.url?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateRoute = product => {
    let route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    route = TransformImages(product.url, 1);
    return route;
  };

  const handleChangeTax = (value, product) => {
    if (value < 99999.9999) {
      if (parseFloat(value).toString().split(".")[1]?.length) {
        if (parseFloat(value).toString().split(".")[1]?.length < 5) {
          handleProductList(product.id, "BaseTax", setDataTable, list, value);
        } else {
          return;
        }
      }
      handleProductList(product.id, "BaseTax", setDataTable, list, value);
    }
  };

  return (
    <Card
      color="primary"
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: { sx: "1rem", md: "2rem", xl: "3rem" },
        gap: "1rem",
        overflow: "auto",
        minHeight: "300px",
        maxHeight: "700px",
      }}
    >
      {list?.map(product => (
        <div key={product.id}>
          <Card
            color="primary"
            sx={{
              padding: {
                xs: 1,
                md: 2,
              },
              display: "grid",
              gridTemplateColumns: "repeat(6, 1fr)",
              gap: 2,
            }}
            variant="outlined"
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography
                className="ellipsis one-line "
                typography="modeColor"
                variant="subtitle1"
              >
                {product?.description !== " "
                  ? product?.description
                  : "Sin nombre"}
              </Typography>
              <Typography
                sx={{ textAlign: "end" }}
                typography="modeColor"
                variant="subtitle1"
              >
                {currentSymbolCoin}
                {toExchangeRate(parseFloat(product.total)).toFixed(3)}
              </Typography>
              <Typography
                className="ellipsis one-line"
                typography="modeColor"
                variant="subtitle1"
              >
                {getDetails(product) || "Sin detalles"}
              </Typography>
              <div style={{ display: "flex", justifyContent: "end" }}>
                <div
                  className="content-flex-row-between"
                  style={{ width: "5rem" }}
                >
                  <div
                    className="products-buttons"
                    disabled={type === ElectronicBillTypes.DebitNote}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      billType !== BILL_TYPES_URL_PARAMS.DEBIT_NOTE &&
                        handleDecrement({
                          id: product.id,
                          quantity: product.quantity,
                          setDataTable,
                          list,
                          selectables,
                          updateQuantity,
                          electronicBill,
                        });
                    }}
                    onKeyDown={() => null}
                  >
                    <Typography
                      sx={{ cursor: "pointer" }}
                      typography="modeColor"
                      variant="subtitle1"
                    >
                      -
                    </Typography>
                  </div>
                  <Typography typography="modeColor" variant="subtitle1">
                    {product.quantity}
                  </Typography>
                  <div
                    className="products-buttons"
                    disabled={type === ElectronicBillTypes.DebitNote}
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      handleIncrement(
                        product?.id,
                        setDataTable,
                        list,
                        selectables,
                        updateQuantity,
                        electronicBill,
                      );
                    }}
                    onKeyDown={() => null}
                  >
                    <Typography
                      sx={{ cursor: "pointer" }}
                      typography="modeColor"
                      variant="subtitle1"
                    >
                      +
                    </Typography>
                  </div>
                </div>
              </div>
              <Typography typography="modeColor" variant="subtitle1">
                {product.Tariff.Percent} %
              </Typography>

              <div className="content-left">
                {product?.tax?.MH_Code === "07" && (
                  <div className="product-base ">
                    <h2 className="details-product">Base Imponente:</h2>
                    <input
                      type="number"
                      value={product?.BaseTax || 0}
                      onChange={e => handleChangeTax(e.target.value, product)}
                    />
                  </div>
                )}
                {product?.tax?.MH_Code === "08" && (
                  <div className="product-base ">
                    <Typography typography="modeColor" variant="subtitle1">
                      Monto Factor
                    </Typography>
                    <input
                      type="number"
                      value={product?.Factor || 0}
                      onChange={e =>
                        handleProductList(
                          product.id,
                          "Factor",
                          setDataTable,
                          list,
                          e.target.value,
                        )
                      }
                    />
                  </div>
                )}
              </div>
              {type !== ElectronicBillTypes.DebitNote && (
                <Button
                  color="primary"
                  disabled={type === ElectronicBillTypes.DebitNote}
                  size="small"
                  sx={{ width: "10rem", marginTop: "1rem", cursor: "pointer" }}
                  variant="outlined"
                  onClick={() =>
                    hadleAddDiscounts(
                      product,
                      setModalOpen,
                      useCoinDetail,
                      list,
                      setDataTable,
                    )
                  }
                >
                  Descuento
                </Button>
              )}
              {/*
                  This code should remain commented out for now.
                  <Icon
                    className="hover-table-options items-center"
                    disabled={type === ElectronicBillTypes.DebitNote}
                    size="small"
                    onClick={() =>
                      hadleAddTaxes(
                        product,
                        setModalOpen,
                        useCoinDetail,
                        list,
                        setDataTable,
                        getTaxesData,
                      )
                    }
                  >
                    <FontAwesomeIcon icon="edit" size="1x" />
                  </Icon> */}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                gridColumn: "span 4",
              }}
            >
              <img
                alt="product"
                className="imgProduct"
                src={validateRoute(product)}
              />
            </div>
            {selectables && (
              <div>
                <Checkbox
                  checked={product.checked || false}
                  className="checkbox"
                  disabled={
                    !product.valid || (product.checked && type === "DebitNote")
                  }
                  label="text"
                  name="DebitNote"
                  sx={{
                    "&.Mui-checked": {
                      color: "#2CA72A",
                    },
                  }}
                  onChange={e =>
                    handleCheck(
                      product.id,
                      !product.checked,
                      setDataTable,
                      list,
                    )
                  }
                />
              </div>
            )}
            {!selectables && (
              <FontAwesomeIcon
                className="awesomeIcon-color"
                disabled={type === ElectronicBillTypes.DebitNote}
                icon="times"
                size="1x"
                onClick={() =>
                  handleDeleteProduct(
                    product,
                    setDataTable,
                    list,
                    selectables,
                    deleteDetail,
                  )
                }
              />
            )}
          </Card>
        </div>
      ))}
    </Card>
  );
}
ProductCard.propTypes = {
  setDataTable: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
  selectables: PropTypes.bool,
  carousel: PropTypes.bool,
  type: PropTypes.string,
};
ProductCard.defaultProps = {
  selectables: false,
  carousel: false,
  type: "",
};

export default ProductCard;
