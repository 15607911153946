const azureInformation = {
  clientID: process.env.REACT_APP_AZURE_CLIENT_ID,
  redirectURL: process.env.REACT_APP_AZURE_REDIRECT,
  scope:
    "openid offline_access https://graph.microsoft.com/mail.read https://graph.microsoft.com/user.read https://graph.microsoft.com/User.ReadWrite https://graph.microsoft.com/User.ReadBasic.all User.Read.All https://graph.microsoft.com/User.ReadWrite.All https://graph.microsoft.com/Directory.Read.All https://graph.microsoft.com/Directory.ReadWrite.All",
  codeChallenge:
    "YTFjNjI1OWYzMzA3MTI4ZDY2Njg5M2RkNmVjNDE5YmEyZGRhOGYyM2IzNjdmZWFhMTQ1ODg3NDcxY2NlY2Nl",
};

const getAzureCode = () =>
  `https://login.microsoftonline.com/common/oauth2/v2.0/authorize
    ?client_id=${azureInformation.clientID}
    &redirect_uri=${azureInformation.redirectURL}
    &response_type=code
    &scope=${azureInformation.scope}
    &response_mode=query
    &code_challenge=${azureInformation.codeChallenge}`;

export default getAzureCode;
