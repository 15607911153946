/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_CLIENTS_BY_GROUP = gql`
  query getClientsByIdGroup($FK_ClientGroup: ID!) {
    clients: getClientsByIdGroup(FK_ClientGroup: $FK_ClientGroup) {
      id
      BillingProfile {
        id
        Name
        Email
        ID_Number
        IDForeign
        PhoneCodCountry
        PhoneNumber
        OtherSigns
        User {
          id
        }
        MH_PersonType {
          id
          Name_PersonType
        }
        MH_Province {
          id
          Name
          id_MH
        }
        MH_Canton {
          id
          Name
          id_MH
        }
        MH_District {
          id
          Name
          id_MH
        }

        MH_Neighborhood {
          id
          Name
          District {
            id
            Name
            Canton {
              id
              Name
              Province {
                id
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_CLIENT_BY_Id_PLACE = gql`
  query getClientsByIdPlace($FK_Place: ID!) {
    clients: getClientsByIdPlace(FK_Place: $FK_Place) {
      id
      FirebaseUser {
        Picture
      }
      UserBillingProfile {
        id
        Name
        ID_Number
        Email
      }
    }
  }
`;
