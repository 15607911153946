import React from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  CartesianGrid,
  XAxis,
  YAxis,
  ResponsiveContainer,
} from "recharts";

function BarChartComponent({ dataXKey, dataYKey, data, angle }) {
  return (
    <ResponsiveContainer>
      <BarChart data={data} margin={{ top: 5, right: 10, bottom: 5 }}>
        <XAxis
          axisLine={false}
          dataKey={dataXKey}
          height={100}
          interval={0}
          tick={{ angle, textAnchor: "start", dominantBaseline: "ideographic" }}
          width={100}
        />
        <YAxis axisLine={false} />
        <CartesianGrid stroke="#396E9C" vertical={false} />
        <Bar barSize={85} dataKey={dataYKey} fill="#396E9C" radius={4} />
      </BarChart>
    </ResponsiveContainer>
  );
}

BarChartComponent.propTypes = {
  dataXKey: PropTypes.string.isRequired,
  dataYKey: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  angle: PropTypes.number,
};

BarChartComponent.defaultProps = {
  angle: 0,
};

export default BarChartComponent;
