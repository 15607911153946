import { TARIFF_CODES, TAX_CODES } from "../Enums/ElectronicBill";

export const productInitialState = {
  Product_Code: "",
  Cabys_Code: "",
  Name_Product: "",
  Quantity: "",
  Description: "",
  Unit_Of_Measure: { Id: 1, Symbol: "Unid" },
  Discount: "",
  Unit_Price: "",
  IVA: "",
  Other_Taxes: "",
  Total_Line: "",
};
export const receiverInitialState = {
  id: "",
  Receiver_Id: "",
  Receiver_Name: "",
  Receiver_Email: "",
  Receiver_PhoneCode: "52",
  Receiver_PhoneNumber: "",
  Receiver: "",
  Receiver_PersonType: "",
  user: { Id: "" },
};
export const expenseFormInitialState = {
  id: "",
  expense: "",
  amount: null,
  typeAmount: "1",
  oldTypeAmount: "1",
  discount: null,
  iva: null,
  otherIva: null,
  quantity: null,
  measurmentUnit: {
    Name_UnitOfMeasurement: "Unidad",
    Symbol: "Unid",
    id: "1",
  },
  factor: null,
  baseTax: null,
  tariffAmount: null,
  tax: {
    Description_Tax: "Impuesto de Valor Agregado (IVA)",
    MH_Code: "01",
    id: "1",
  },
};

export const electronicBillInitialState = {
  Coin: "1",
  Due_Date: new Date().toISOString().substr(0, 10),
  Purchase_Order: "",
  Payment_Method: "1",
  CreditPaymentCondition: 1,
  OtherSellCondition: "",
  OtherPaymentMethod: "",
  Sell_Condition: "1",
  Other_SellCondition: "",
  ActivityCode: "",
};

export const addressInitialState = {
  Province: "",
  Canton: "",
  District: "",
  Neighborhood: "",
  Other: "",
};

export const summaryExpensesInitialState = {
  subTotal: 0,
  iva: 0,
  discount: 0,
  total: 0,
};

export const defaultTax = {
  Description_Tax: "Impuesto de Valor Agregado (IVA)",
  MH_Code: TAX_CODES.IVA,
  id: "1",
};

export const defaultTariff = {
  Code: TARIFF_CODES.EXENT,
  Name: "Tarifa 0%",
  Percent: 0,
  id: "2",
};

export const billConfigurationEmptyState = {
  date: new Date(),
  Hour: "00:00",
  Frequency: "1",
  Day: new Date().getDate(),
  Month: new Date().getMonth() + 1,
  Year: new Date().getFullYear(),
  Minute: new Date().getMinutes(),
  Hours: new Date().getHours(),
  Active: false,
};

export const exportationBillInitialState = {
  expirationDate: (() => {
    const currentDate = new Date();

    return `${currentDate.getFullYear()}-${
      currentDate.getMonth() + 1 < 10 ? 0 : ""
    }${currentDate.getMonth() + 1}-${
      currentDate.getDate() < 10 ? 0 : ""
    }${currentDate.getDate()}`;
  })(),
  purchaseOrder: "",
  arrancel: "",
};
