import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography, Box, Button, Card } from "@mui/material";
// Context
import { useAuth, useModal } from "../../../../context";
import { useElectronic } from "../../../../context/ElectronicContext";
// Hooks
import useEmail from "../../../../hooks/useEmail";
// Utils
import { customToast as toast } from "../../../../utils";
import getImageEmail from "../../../../utils/GetImageEmail";
// SCSS
import "./AccountManagement.scss";
import { emailLoginFunction } from "../../../../utils/MailConnector";

function AccountManagement({ openModal, CardAccount, NewAccount }) {
  const [accounts, setAccounts] = useState([]);
  const [checkAccountSelected, setCheckAccountSelected] = useState(true);
  const { setModalOpen } = useModal();
  const { getEmailsAccounts, deleteTokenEmail, checkTokenEmail } = useEmail();
  const { accountSelected, setAccountSelected } = useElectronic();
  const { state } = useAuth();
  const { user } = state;

  const checkLoginMail = useCallback(
    async account => {
      try {
        const res = await checkTokenEmail(
          account?.id,
          parseInt(account?.type, 10),
        );
        setCheckAccountSelected(res?.data?.email);
        return res?.data?.email;
      } catch (err) {
        return false;
      }
    },
    [checkTokenEmail],
  );

  const getCssImage = () => {
    const namesCss = {
      1: "img-zoho",
      2: "img-gmail",
      3: "img-outlook",
    };
    return namesCss[accountSelected.type];
  };

  const allAccounts = useCallback(async () => {
    try {
      const res = await getEmailsAccounts(user.googleAuthId);
      setAccounts(res?.data?.data);
    } catch (err) {
      toast.error(err);
    }
  }, [getEmailsAccounts, user.googleAuthId]);

  useEffect(() => {
    user?.googleAuthId && allAccounts();
    accountSelected.id !== 0 && checkLoginMail(accountSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeAccountSelected = async account => {
    setAccountSelected(account);
    if (await checkLoginMail(account)) openModal(false);
  };

  const handleLogout = async () => {
    try {
      const { data } = await deleteTokenEmail(
        accountSelected.id,
        Number(accountSelected.type),
      );
      if (data) {
        setAccounts(
          accounts.filter(account => account.id !== accountSelected.id),
        );
        setAccountSelected({ id: 0 });
        allAccounts();
        toast.success("¡Sesión cerrada con éxito!");
      }
    } catch (err) {
      toast.error("¡Ocurrió un error al cerrar sesión!");
    }
  };

  const handleEmailLogIn = () => {
    const login = emailLoginFunction[accountSelected.type];
    window.location.href = login();
  };

  return (
    <div className="container-modal ">
      <div className="modal-content-size">
        {accountSelected.id !== 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <button
              className="content-flex-end"
              type="button"
              onClick={() => setModalOpen(false)}
            >
              <FontAwesomeIcon
                className="awesomeIcon-color icon-color"
                icon="times"
                size="sm"
              />
            </button>
            <Typography
              align="center"
              fontWeight={600}
              typography="modeColor"
              variant="h5"
            >
              Correo Activo
            </Typography>
            <Card
              color="primaryText"
              sx={{
                display: "flex",
                flexDirection: "row",
                maxWidth: "245px",
                height: "48px",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Box sx={{ width: "22px", height: "13px" }}>
                <img
                  alt="icon"
                  className={getCssImage()}
                  src={getImageEmail(accountSelected.type)}
                />
              </Box>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  width: "150px",
                }}
                typography="defaultLight"
                variant="subtitle1"
              >
                {accountSelected?.email}
              </Typography>
            </Card>
            {checkAccountSelected ? (
              <Button
                className="account-selected-button"
                color="primary"
                onClick={() => handleLogout()}
              >
                <Typography
                  align="center"
                  fontWeight={600}
                  typography="modeButton"
                  variant="subtitle2"
                >
                  Cerrar sesión
                </Typography>
              </Button>
            ) : (
              <div className="account-log-out-container">
                <Button
                  className="account-selected-button"
                  color="primary"
                  onClick={() => handleEmailLogIn()}
                >
                  Iniciar sesión
                </Button>
                <Typography typography="modeColor" variant="subtitle2">
                  La sesión ha expirado, inicie seción de nuevo.
                </Typography>
              </div>
            )}
          </Box>
        )}
        {!accounts &&
          accounts.map(account => (
            <CardAccount
              key={account?.id}
              account={account}
              handleChange={handleChangeAccountSelected}
              isSelected={accountSelected?.id === account.id}
            />
          ))}
        {accounts?.length === 0 && (
          <Typography align="center" typography="modeColor" variant="subtitle2">
            Para Iniciar sesión presione &lsquo;Agregar&lsquo;
          </Typography>
        )}
        <div className="content-flex-row">
          <Button
            color="primary"
            variant="contained"
            onClick={() =>
              setModalOpen(
                true,
                <NewAccount flow={false} openModal={setModalOpen} />,
              )
            }
          >
            Agregar
          </Button>
        </div>
      </div>
    </div>
  );
}

AccountManagement.propTypes = {
  openModal: PropTypes.func.isRequired,
  CardAccount: PropTypes.func.isRequired,
  NewAccount: PropTypes.func.isRequired,
};

export default AccountManagement;
