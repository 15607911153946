import React from "react";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";

function PersonSection({ person, isExportationBill }) {
  return person ? (
    <div
      style={{
        padding: 0,
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <Typography typography="modeColor" variant="sutitle01">
        <span className="has-text-weight-bold">Nombre:</span> {person.Name}
      </Typography>
      <Typography typography="modeColor" variant="sutitle01">
        <span className="has-text-weight-bold">Nombre Comercial:</span>{" "}
        {person.ComercialName || "--"}
      </Typography>
      <Typography typography="modeColor" variant="sutitle01">
        <span className="has-text-weight-bold">Telefono:</span>{" "}
        {person?.PhoneNumber || "---"}
      </Typography>
      {!isExportationBill && (
        <Typography typography="modeColor" variant="sutitle01">
          <span className="has-text-weight-bold">Distrito: </span>{" "}
          {person.MH_District?.Name || "---"}
        </Typography>
      )}
      <Typography typography="modeColor" variant="sutitle01">
        <span className="has-text-weight-bold">Cedula: </span>{" "}
        {person.ID_Number || "--"}
      </Typography>
      <Typography typography="modeColor" variant="sutitle01">
        <span className="has-text-weight-bold">E-mail: </span> {person.Email}
      </Typography>
      {!isExportationBill && (
        <React.Fragment>
          <Typography typography="modeColor" variant="sutitle01">
            <span className="has-text-weight-bold">Provincia: </span>{" "}
            {person?.MH_Province?.Name || "---"}
          </Typography>
          <Typography typography="modeColor" variant="sutitle01">
            <span className="has-text-weight-bold">Cantón: </span>{" "}
            {person.MH_Canton.Name || "--"}
          </Typography>
          <Typography typography="modeColor" variant="sutitle01">
            <span className="has-text-weight-bold">Barrio:</span>{" "}
            {person.MH_Neighborhood.Name || "---"}
          </Typography>
        </React.Fragment>
      )}
      {!isExportationBill && (
        <Typography typography="modeColor" variant="sutitle01">
          <span className="has-text-weight-bold">Otras señas:</span>{" "}
          {person.OtherSigns || "  --"}
        </Typography>
      )}
    </div>
  ) : (
    ""
  );
}

PersonSection.propTypes = {
  person: PropTypes.object.isRequired,
  isExportationBill: PropTypes.object,
};

PersonSection.defaultProps = {
  isExportationBill: false,
};

export default PersonSection;
