const zohoInformation = {
  clientID: process.env.REACT_APP_ZOHO_CLIENT_ID,
  redirectURL: process.env.REACT_APP_ZOHO_REDIRECT,
  scope:
    "ZohoMail.folders.READ,ZohoMail.messages.READ,ZohoMail.folders.UPDATE,ZohoMail.accounts.READ,ZohoMail.attachments.READ",
};

const getCodeZoho = () =>
  `https://accounts.zoho.com/oauth/v2/auth?client_id=${zohoInformation.clientID}&access_type=offline` +
  `&response_type=code&scope=${zohoInformation.scope}&redirect_uri=${zohoInformation.redirectURL}&prompt=consent`;

export default getCodeZoho;
