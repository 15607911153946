import React from "react";
import { Routes, Route } from "react-router-dom";
import ClientReceiver from "./Receiver/ClientReceiver";
import PaymentComponent from "./PaymentComponent";
import Inventories from "../Inventories";
import Detail from "./detail";

function PaymentComponentRouter(props) {
  return (
    <Routes>
      <Route element={<Detail />} path="details" />
      <Route element={<Inventories />} path="inventories" />
      <Route element={<ClientReceiver />} path="client" />
      <Route element={<PaymentComponent />} path=":id?/:billType?" />
    </Routes>
  );
}

export default PaymentComponentRouter;
