const ButtonStylesDark = {
  MuiButton: {
    variants: [
      // WITH COLOR
      {
        props: props =>
          props.variant === "contained" && props.color === "primary",
        style: {
          "color": "#ffff",
          "background": "rgba(57, 110, 156, 1)",
          "&:hover": {
            background: "rgba(45, 84, 119, 1)",
          },
          "&:focus": {
            background: "rgba(57, 110, 156, 1)",
            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.5)",
          },
        },
      },
      {
        props: props =>
          props.variant === "contained" && props.color === "secondary",
        style: {
          "color": "#ffff",
          "background": "rgba(23, 56, 89, 1) ",
          "&:hover": {
            background: "rgba(45, 84, 119, 1)",
          },
          "&:focus": {
            background: "rgba(57, 110, 156, 1)",
            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.5)",
          },
        },
      },
      {
        props: props =>
          props.variant === "contained" && props.color === "light",
        style: {
          "color": "#000",
          "background": "#F5F5F5",
          "&:hover": {
            background: "#D2D2D2",
          },
          "&:focus": {
            background: "#D2D2D2",
          },
        },
      },
      {
        props: props =>
          props.variant === "contained" && props.color === "option",
        style: {
          "color": "#000",
          "background": "#091928",
          "box-shadow": "3px 4px 9px 0px #00000059 inset",
          "&:hover": {
            background: "#0F2942",
          },
          "&:focus": {
            background: "#0F2942",
          },
        },
      },
      // LINE COLOR
      {
        props: props => props.variant === "outlined",
        style: {
          color: "#ffff",
          borderColor: "#ffff",
        },
      },
      // BUTTON MENU
      {
        props: props => props.color === "arrowButton",
        style: {
          background:
            "linear-gradient(to right, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",
          boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
          borderRadius: 0,
          border: "none",
        },
      },
    ],
    styleOverrides: {
      root: {
        "textTransform": "none",
        "fontSize": "16px",
        "@media (max-width:600px)": {
          fontSize: "14px",
        },
      },
    },
  },
};

const ButtonStylesLight = {
  MuiButton: {
    variants: [
      // WITH COLOR
      {
        props: props =>
          props.variant === "contained" && props.color === "primary",
        style: {
          "color": "#ffff",
          "background": "rgba(57, 110, 156, 1)",
          "&:hover": {
            background: "rgba(45, 84, 119, 1)",
          },
          "&:focus": {
            background: "rgba(57, 110, 156, 1)",
            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.5)",
          },
        },
      },
      {
        props: props =>
          props.variant === "contained" && props.color === "secondary",
        style: {
          "color": "#ffff",
          "background": "rgba(18, 59, 100, 1)",
          "&:hover": {
            background: "rgba(45, 84, 119, 1)",
          },
          "&:focus": {
            background: "rgba(57, 110, 156, 1)",
            boxShadow: "0 0 0 0.2rem rgba(0, 123, 255, 0.5)",
          },
        },
      },
      {
        props: props =>
          props.variant === "contained" && props.color === "light",
        style: {
          "color": "#000",
          "background": "#D2D2D2",
          "&:hover": {
            background: "CBCBCB",
          },
          "&:focus": {
            background: "CBCBCB",
          },
        },
      },
      {
        props: props =>
          props.variant === "contained" && props.color === "option",
        style: {
          "color": "#000",
          "background": "#F6F9FE",
          "border": "1px solid #123B64",
          "&:hover": {
            background: "#2D5477",
          },
          "&:focus": {
            background: "#2D5477",
          },
        },
      },
      // LINE COLOR
      {
        props: props => props.variant === "outlined",
        style: {
          color: "#000",
          borderColor: "#000",
        },
      },
      // BUTTON MENU
      {
        props: props => props.color === "arrowButton",
        style: {
          background:
            " linear-gradient(270deg, #F6F9FE 14.64%, rgba(246, 249, 254, 0.49758) 56.81%, rgba(246, 249, 254, 0) 97.81%)",
          border: "none",
          opacity: 0.8,
          borderRadius: 0,
        },
      },
    ],
    styleOverrides: {
      root: {
        "textTransform": "none",
        "fontSize": "16px",
        "@media (max-width:600px)": {
          fontSize: "14px",
        },
      },
    },
  },
};

export { ButtonStylesDark, ButtonStylesLight };
