import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_ALL_TARIFF = gql`
  query getAllTariff {
    tariff: getAllTariff {
      id
      Name
      Code
      Percent
    }
  }
`;
