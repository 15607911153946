import React from "react";
import PropTypes from "prop-types";
// Components
import Inventories from "../../components/Inventories";
// Context
import { useElectronic } from "../../context/ElectronicContext";

function InventoryRequireRoute({ element: Component, ...rest }) {
  const { LineDetailPlace } = useElectronic();
  return LineDetailPlace.id === 0 ? <Inventories /> : <Component />;
}

InventoryRequireRoute.propTypes = {
  element: PropTypes.any.isRequired,
};

export default InventoryRequireRoute;
