import React from "react";
// Components
import { ClientsGroupsXPlace } from "../../components";

function ClientsPage() {
  return (
    <div className="report-items">
      <ClientsGroupsXPlace />
    </div>
  );
}
export default ClientsPage;
