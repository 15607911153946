const SELL_CONDITION = {
  CONTADO: { ID: "1", NAME: "Contado" },
  CREDITO: { ID: "2", NAME: "Crédito" },
  CONSIGNACION: { ID: "3", NAME: "Consignación" },
  APARTADO: { ID: "4", NAME: "Apartado" },
  ARRENDAMIENTO_OPCION_COMPRA: {
    ID: "5",
    NAME: "Arrendamiento con opción de compra",
  },
  ARRENDAMIENTO_FUNC_FIN: {
    ID: "6",
    NAME: "Arrendamiento en función financiera",
  },
  COBRO_FAVOR_TERCERO: { ID: "7", NAME: "Cobro a favor de un tercero" },
  SERVICIOS_ESTADO_CREDITO: {
    ID: "8",
    NAME: "Servicios prestados al estado a crédito",
  },
  PAGO_SERVICIOS_ESTADO: {
    ID: "9",
    NAME: "Pago del servicios prestados al estado",
  },
  OTROS: { ID: "10", NAME: "Otros" },
};
export default SELL_CONDITION;
