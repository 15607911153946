import React from "react";
import { Routes, Route } from "react-router-dom";
import SelectCompany from "../CompanySelect";
import PlacesList from "./PlacesList";
import PlacesRegister from "../PlacesRegister";
import PlacesUpdate from "../PlacesUpdate";

function PlacesListRouter(props) {
  return (
    <Routes>
      <Route element={<SelectCompany />} path="new-place/companies" />
      <Route element={<PlacesList />} path="" />
      <Route element={<PlacesRegister />} path="new-place" />
      <Route element={<PlacesUpdate />} path="update-place/:id" />
    </Routes>
  );
}

export default PlacesListRouter;
