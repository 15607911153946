import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control, Label } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_PERSON_TYPES } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";

function PersonTypeSelect({
  value,
  name,
  onChange,
  label,
  disabled,
  className,
}) {
  const { data: getAllPersonType } = useQuery(GET_ALL_PERSON_TYPES);

  return (
    <Control className={className}>
      <Label>{label}</Label>
      <Select.Container fullwidth>
        <Select
          className="selector"
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => onChange(e.target.name, e.target.value)}
        >
          {getAllPersonType?.personTypes?.map(personType => (
            <Select.Option key={personType?.id} value={personType?.id}>
              {personType.Name_PersonType}
            </Select.Option>
          ))}
        </Select>
      </Select.Container>
    </Control>
  );
}

PersonTypeSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PersonTypeSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default PersonTypeSelect;
