import { useApolloClient } from "@apollo/client";
import { useAsyncDebounce } from "react-table";

const useAsyncSearch = () => {
  const client = useApolloClient();

  const getResults = async (newQuery, newVariables) => {
    const { data } = await client.query({
      query: newQuery,
      variables: newVariables,
    });
    return data;
  };
  const DebouncedLoad = (loadOptions, time) =>
    useAsyncDebounce(value => loadOptions(value), time);

  return { getResults, DebouncedLoad };
};

export default useAsyncSearch;
