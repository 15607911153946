import React from "react";
import { Box, Card, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { formatNumber } from "../../utils/ReportUtils";
import { useElectronic } from "../../context/ElectronicContext";

function IvaReportSubsection({ data, title, totals, isTotalsSection }) {
  const { useCoinDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;
  return isTotalsSection ? (
    <Card
      color="primary"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: { xs: "0.5rem", sm: "1rem" },
        gap: "1rem",
      }}
    >
      <Typography
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        {title}
      </Typography>

      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
        typography="modeColor"
        variant="subtitle1"
        width="100%"
      >
        <b>Total Gravado: </b>
        {formatNumber(toExchangeRate(totals.TotalGrav)?.toFixed(2))}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
        typography="modeColor"
        variant="subtitle1"
        width="100%"
      >
        <b>Exento: </b>
        {formatNumber(toExchangeRate(totals.TotalExempt)?.toFixed(2))}
      </Typography>
      {totals?.Taxes?.filter(tax => tax.Amount !== 0).map(tax => (
        <Typography
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
          }}
          typography="modeColor"
          variant="subtitle1"
          width="100%"
        >
          <b>
            {tax.Name} {formatNumber(toExchangeRate(tax.Total)?.toFixed(2))}
          </b>
          {formatNumber(toExchangeRate(tax.Amount)?.toFixed(2))}
        </Typography>
      ))}
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
        typography="modeColor"
        variant="subtitle1"
        width="100%"
      >
        <b>Total impuestos:</b>
        {formatNumber(toExchangeRate(totals.TotalTaxes)?.toFixed(2))}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
        typography="modeColor"
        variant="subtitle1"
        width="100%"
      >
        <b>Total parcial: </b>
        {formatNumber(toExchangeRate(totals.TotalNetSell)?.toFixed(2))}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
        }}
        typography="modeColor"
        variant="subtitle1"
        width="100%"
      >
        <b>Total</b>
        {formatNumber(toExchangeRate(totals.TotalBill)?.toFixed(2))}
      </Typography>
    </Card>
  ) : (
    <Card
      color="primary"
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: { xs: "0.5rem", sm: "1rem" },
      }}
    >
      <Typography
        fontWeight={600}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <Typography
          fontWeight={600}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="subtitle1"
          width="100%"
        >
          Tarifa
        </Typography>
        <Typography
          fontWeight={600}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="subtitle1"
          width="100%"
        >
          Monto
        </Typography>
        <Typography
          fontWeight={600}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="subtitle1"
          width="100%"
        >
          Total
        </Typography>
      </Box>
      {data
        .filter(tax => tax.Total !== 0)
        .map(tariff => (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              paddingY: "0.4rem",
            }}
          >
            <Typography
              fontWeight={600}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              typography="modeColor"
              variant="subtitle1"
              width="100%"
            >
              {tariff.Name}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              typography="modeColor"
              variant="subtitle1"
              width="100%"
            >
              {formatNumber(toExchangeRate(tariff.Amount)?.toFixed(2))}
            </Typography>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
              typography="modeColor"
              variant="subtitle1"
              width="100%"
            >
              {formatNumber(toExchangeRate(tariff.Total)?.toFixed(2))}
            </Typography>
          </Box>
        ))}
      {/* TOTALS */}
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          paddingY: "0.4rem",
        }}
        typography="modeColor"
        variant="subtitle1"
      >
        <b>Total: </b>
        {formatNumber(toExchangeRate(totals.TotalDataTaxed))}
      </Typography>
      <Typography
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          paddingY: "0.4rem",
        }}
        typography="modeColor"
        variant="subtitle1"
      >
        <b>Exento: </b>
        {formatNumber(toExchangeRate(totals.TotalDataExempt))}
      </Typography>
    </Card>
  );
}

IvaReportSubsection.propTypes = {
  data: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  totals: PropTypes.object.isRequired,
  isTotalsSection: PropTypes.bool,
};

IvaReportSubsection.defaultProps = {
  isTotalsSection: false,
};

export default IvaReportSubsection;
