import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useElectronic } from "../../../context/ElectronicContext";
import LineDetail from "./LineDetail";

function Detail() {
  const { lineDetail, setLineDetail, LineDetailPlace } = useElectronic();
  const navigate = useNavigate();
  useEffect(() => {
    if (!LineDetailPlace?.id || LineDetailPlace.id === 0) {
      navigate("/electronic-bills");
    }
  });

  return (
    <LineDetail
      LineDetailPlace={LineDetailPlace}
      goBack={() => navigate(-1)}
      lineDetail={lineDetail}
      setLineDetail={setLineDetail}
    />
  );
}

Detail.propTypes = {};

export default Detail;
