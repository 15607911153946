/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_CABYS = gql`
  query getCabys($cabysCode: String) {
    cabys: getByMH_id(cabysCode: $cabysCode) {
      id
    }
  }
`;

export const GET_CABYS_BY_CRITERIA = gql`
  query getByCriteria($criteria: String) {
    cabys: getCabysByCriteria(criteria: $criteria) {
      id
      Code
      Name
      Percent
      IsRestricted
    }
  }
`;
