import { Tab, Tabs } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { DOCUMENT_SEARCH_MODULES } from "../../Enums/ElectronicBill";
// IMG
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import arrowRight from "../../assets/icons/arrowRight.svg";

function MenuDocumentSearch() {
  const [toggleState, setToggleState] = useState(1);
  const tabsRef = useRef(null);
  const [viewButton, setViewButton] = useState();
  const [dimensions, setDimensions] = useState({
    scrollWidth: 0,
    clientWidth: 0,
  });

  const scroll = direction => {
    if (direction === "left") setViewButton(2);
    if (direction === "right") setViewButton(3);
  };

  const checkOverflow = () => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth } = tabsRef.current;
      setDimensions({ scrollWidth, clientWidth });
    }
  };

  const heightMenuButton = () => {
    if (toggleState === 1) return "460px";
    if (toggleState === 2) return "330px";
    return "395px";
  };

  const TopButton = heightMenuButton();

  useEffect(() => {
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dimensions.scrollWidth < 495) {
      setViewButton(2);
    } else {
      setViewButton(0);
    }
  }, [dimensions]);

  return (
    <div ref={tabsRef} className="content-flex-row-between">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          gap: "1rem",
          justifyContent: viewButton !== 2 ? "space-between" : "start",
        }}
      >
        <button
          style={{
            border: "none",
            background:
              "linear-gradient(to right, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",

            cursor: "pointer",
            display:
              dimensions.scrollWidth > 495 || viewButton === 2
                ? "none"
                : "flex",
            position: "absolute",
            transform: "translateX(-50%)",
            top: TopButton,
            zIndex: "1",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
            right: "280px",
          }}
          type="button"
          onClick={() => scroll("left")}
        >
          <img
            alt="izquierda"
            src={arrowLeft}
            style={{ width: "18px", height: "18px" }}
          />
        </button>
        <Tabs
          sx={{
            "height": { xs: "45px", sm: "50px" },

            "& .MuiTabs-flexContainer": {
              gap: "1rem",
            },
            "margin": 0,
            "width": "100%",
          }}
        >
          <Tab
            as={Link}
            className={`${toggleState === 1 ? "active" : "tabs"}`}
            color="option"
            label="Facturas Electronicas"
            sx={{
              height: { xs: "45px", sm: "50px" },
              padding: 1,
              maxHeight: "auto",
              minHeight: "auto",
              justifyContent: "center",
              display:
                viewButton !== 2 && dimensions.scrollWidth < 495
                  ? "none"
                  : "flex",
              borderRadius: " 5px",
            }}
            to="/DocumentSearch/electronicBill"
            onClick={() =>
              setToggleState(DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS)
            }
          />
          <Tab
            as={Link}
            className={`${toggleState === 2 ? "active" : "tabs"}`}
            color="option"
            label="Borradores"
            sx={{
              height: { xs: "45px", sm: "50px" },
              padding: 1,
              maxHeight: "auto",
              minHeight: "auto",
              justifyContent: "center",
              display:
                viewButton !== 2 && dimensions.scrollWidth < 495
                  ? "none"
                  : "flex",
              borderRadius: " 5px",
            }}
            to="/DocumentSearch/proforma"
            onClick={() => setToggleState(DOCUMENT_SEARCH_MODULES.BILLS)}
          />
          <Tab
            as={Link}
            className={`${toggleState === 3 ? "active" : "tabs"}`}
            color="option"
            label="Facturas electronicas de gastos"
            sx={{
              height: { xs: "45px", sm: "50px" },
              padding: 1,
              maxHeight: "auto",
              minHeight: "auto",
              justifyContent: "center",
              display: "flex",
              borderRadius: " 5px",
            }}
            to="/DocumentSearch/electronicBillExpenses"
            onClick={() =>
              setToggleState(DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES)
            }
          />
        </Tabs>
        <button
          style={{
            border: "none",
            background:
              "linear-gradient(to left, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",
            cursor: "pointer",
            display:
              dimensions.scrollWidth > 495 || viewButton === 3
                ? "none"
                : "flex",
            position: "absolute",
            right: "5px",
            top: TopButton,
            zIndex: "10",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
          }}
          tabIndex={0}
          type="button"
          onClick={() => scroll("right")}
        >
          <img
            alt="derecha"
            src={arrowRight}
            style={{ width: "18px", height: "18px" }}
          />
        </button>
      </div>
    </div>
  );
}

export default MenuDocumentSearch;
