// External
import dayjs from "dayjs";
import { Box, Card, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// import TimePicker from "react-time-picker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import TimePicker from "react-time-picker";
import "./ScheduledBillCard.scss";
import PropTypes from "prop-types";
import SelectForm from "../SelectForm/SelectForm";
import { formatData } from "../../utils/helpers";
import { BILL_CONFIGURATION_FREQUENCY_TEXT } from "../../Enums/BillConfiguration";

function ScheduledBillCard({ id, updateValues, data }) {
  const optionFrequency = Object.entries(BILL_CONFIGURATION_FREQUENCY_TEXT);
  const handleOnChange = (name, date) => updateValues(name, date);

  return (
    <Card
      color="primary"
      sx={{
        minHeight: "123px",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      <Typography
        fontWeight={600}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        Factura programada
      </Typography>
      <Box
        sx={{
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(3, 1fr)",
          },
        }}
      >
        <DatePicker
          color="primary"
          format="DD/MM/YYYY"
          label="Fecha"
          minDate={dayjs().startOf("day")}
          minutesStep={15}
          size="small"
          sx={{
            "maxWidth": "180px",
            "& .MuiFormLabel-root": {
              transform: " translate(14px, 5px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              padding: "24px 14px 10px 14px ",
            },
          }}
          value={dayjs(data?.date).utc()}
          variant="filled"
          views={["day", "month", "year"]}
          onChange={newDate => {
            handleOnChange(
              "date",
              newDate.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            );
          }}
        />
        <TimePicker
          color="primary"
          desktopModeMediaQuery="@media (min-width: 0px)"
          format="h:mm A"
          label="Hora"
          minTime={dayjs().startOf("day")}
          minutesStep={15}
          name="Hour"
          renderInput={params => (
            <TextField inputProps={{ ...params.inputProps, readOnly: true }} />
          )}
          size="small"
          sx={{
            "maxWidth": "180px",
            "& .MuiFormLabel-root": {
              transform: " translate(14px, 5px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              padding: "24px 14px 10px 14px ",
            },
          }}
          value={dayjs(data.date).utc()}
          variant="filled"
          onChange={newTime => {
            handleOnChange(
              "date",
              newTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
            );
          }}
        />
        <Box sx={{ maxWidth: "180px" }}>
          <SelectForm
            color="tertiary"
            data={formatData(optionFrequency, 1, 0)}
            label="Frecuencia"
            name="measurmentUnit"
            size="small"
            value={data.Frequency || ""}
            variant="filled"
            onChange={e => {
              updateValues("Frequency", e.target.value);
            }}
          />
        </Box>
      </Box>
    </Card>
  );
}

ScheduledBillCard.propTypes = {
  id: PropTypes.string,
  updateValues: PropTypes.func.isRequired,
  data: PropTypes.object,
};

ScheduledBillCard.defaultProps = {
  id: null,
  data: [],
};

export default ScheduledBillCard;
