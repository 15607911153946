const TableStylesDark = {
  MuiTableContainer: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          "border": "1px solid #fff",
          "border-radius": "0.5rem",
        },
      },
    ],
  },

  MuiTableRow: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          height: "1.5rem",
        },
      },
    ],
  },
  MuiTableCell: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          borderBottom: "1px solid #fff",
        },
      },
    ],
  },
  MuiTable: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-root": {
          padding: "0.2rem !important",
          width: " 3rem",
          textAlign: "center",
        },
      },
    },
  },
};

const TableStylesLight = {
  MuiTableContainer: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          "border": "1px solid #000",
          "border-radius": "0.5rem",
        },
      },
    ],
  },
  MuiTableRow: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          height: "1.5rem",
        },
      },
    ],
  },
  MuiTableCell: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          borderBottom: "1px solid #000",
        },
      },
    ],
  },

  MuiTable: {
    styleOverrides: {
      root: {
        "& .MuiTableCell-root": {
          padding: "0.2rem !important",
          width: "3rem",
          textAlign: "center",
        },
      },
    },
  },
};

export { TableStylesDark, TableStylesLight };
