import React, { useState, useEffect, useRef, useCallback } from "react";
// External components
import { useNavigate } from "react-router-dom";
import { Box, Button, Grid, Input, Typography } from "@mui/material";
// Graphql
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import {
  REGISTER_NEW_PLACE_AND_USER_ROLE,
  GET_PLACE_BY_NAME,
  GET_ALL_COMPANIES,
} from "../../graphql";
// Context
import { useModal, useAuth } from "../../context";
// Components
import SelectActivityCode from "../ActivityCodeSelect";
import CropperPlace from "../CropperPlace";
import UserToPlace from "../UserToPlace";
// Utils
import { customToast as toast, encrypt } from "../../utils";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import usePlaces from "../../hooks/usePlaces";

function PlacesRegister() {
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [newPlace, setPlace] = useState({
    Name_Place: "",
    Description_Place: "",
    State_Place: "1",
    FK_Company: 0,
    Code: 0,
    Files: null,
    Contacts: [],
    PlaceTags: [],
    Gmail: "",
    Gmail_App: "",
  });
  const [company, setCompany] = useState();
  const { GetPLacesByUserIdAndModuleId } = usePlaces();
  const navigate = useNavigate();
  const [passIsViible, setPassIsVisible] = useState(false);
  const { state } = useAuth();
  const user = state.user.TavuelUser;
  const { setModalOpen } = useModal();

  const { data: getCompanyData } = useQuery(GET_ALL_COMPANIES, {
    variables: {
      id: user?.id,
    },
  });

  const [dataPlaces, refetch] = GetPLacesByUserIdAndModuleId(user?.id);

  const canvas = useRef(null);

  const [getplaceByName, { data: placebyName }] =
    useLazyQuery(GET_PLACE_BY_NAME);
  const [registerPlace] = useMutation(REGISTER_NEW_PLACE_AND_USER_ROLE);

  const validateRoute = () => {
    if (firebaseURL === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    return firebaseURL;
  };

  const handleChange = (name, value) => {
    setPlace(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event, newValue) => {
    setPlace(prev => ({ ...prev, Code: newValue.value }));
  };

  const register = useCallback(async () => {
    try {
      const defaultAddress = {
        Name_Address: "new place",
        Description_Address: "new place",
        State_Address: 3,
      };

      const { data } = await registerPlace({
        variables: {
          place: { ...newPlace, Gmail_App: encrypt(newPlace.Gmail_App) },
          address: defaultAddress,
          FK_User: user?.id,
          Origin: "Conta-Fast",
        },
      });
      if (data?.place) {
        refetch();
        toast.success("¡Lugar registrado con éxito!");
      } else {
        const resp = await getplaceByName({
          variables: {
            name: newPlace?.Name_Place,
          },
        });
        if (resp) toast.error("Ya existe un lugar con ese nombre");
        if (placebyName?.place?.Company?.id) {
          setIsOpen(true);
        }
      }
    } catch (err) {
      toast.error("Error al registrar el lugar");
    }
  }, [
    registerPlace,
    newPlace,
    user?.id,
    refetch,
    getplaceByName,
    placebyName?.place?.Company?.id,
  ]);

  const handleSubmit = e => {
    e.preventDefault();
    if (newPlace.Name_Place !== "" && newPlace.Description_Place !== "") {
      register();
    } else {
      toast.error("Debe completar los datos solicitados.");
    }
    navigate(-1);
  };
  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <CropperPlace
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setPlace(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (placebyName?.place?.id) {
      if (placebyName?.place?.Company?.id) {
        setIsOpen(true);
      }
    }
  }, [placebyName]);

  useEffect(() => {
    if (isOpen) {
      setModalOpen(
        true,
        <UserToPlace
          place={placebyName?.place}
          onClose={() => {
            setModalOpen(false);
          }}
        />,
      );
      setIsOpen(false);
    }
  }, [placebyName?.place, isOpen, setModalOpen]);

  // COMPANY DATA
  useEffect(() => {
    setCompany(getCompanyData?.companies[0]);
    setPlace(prev => ({
      ...prev,
      FK_Company: getCompanyData?.companies[0].id,
    }));
    if (getCompanyData?.companies.length === 0) {
      setCompany(dataPlaces?.places[0].Company);
    }
  }, [dataPlaces?.places, getCompanyData, getCompanyData?.companies]);
  return (
    <form className="profile-page-container" onSubmit={handleSubmit}>
      <Grid
        container
        margin={0}
        sx={{ paddingX: { xs: 2, md: 7, lg: 11, xl: 17 } }}
      >
        <Grid item xs={12}>
          <Typography
            fontWeight={600}
            marginBottom={5}
            textAlign="center"
            typography="modeColor"
            variant="h5"
          >
            Registro de Lugares
          </Typography>
        </Grid>
        <Grid item textAlign="center" xs={12}>
          <Button
            component="label"
            sx={{ aspectRatio: "square", width: 111, listStyle: "none" }}
          >
            <Input hidden type="file" onChange={hadleOnSelectFile} />
            <Box
              component="img"
              src={validateRoute()}
              sx={{ objectFit: "cover" }}
            />
          </Button>
        </Grid>

        <Grid item paddingTop={5} xs={12}>
          <Grid container spacing={3}>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                label="Compañía"
                size="small"
                type="text"
                typeInput="text"
                value={company?.Name_Company}
                // value={companyName}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <SelectActivityCode
                defaultValue={{ label: "", value: "" }}
                handleChange={handleSelectChange}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Name_Place"
                label="Nombre"
                name="Name_Place"
                placeholder="Nombre"
                size="small"
                type="text"
                typeInput="text"
                value={newPlace?.Name_Place}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Gmail"
                label="Correo Gmail"
                name="Gmail"
                placeholder="Correo Gmail"
                size="small"
                type="email"
                typeInput="email"
                value={newPlace.Gmail}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Description_Place"
                label="Descripción"
                name="Description_Place"
                placeholder="Descripción"
                size="small"
                type="text"
                typeInput="text"
                value={newPlace?.Description_Place}
                onChange={e => handleChange(e.target.name, e.target.value)}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <InputForm
                color="primary"
                id="Gmail_App"
                label="Contraseña de aplicación Gmail"
                name="Gmail_App"
                placeholder="Contraseña de aplicación Gmail"
                size="small"
                type={passIsViible ? "text" : "password"}
                typeInput="password"
                value={newPlace.Gmail_App}
                onChange={e => handleChange(e.target.name, e.target.value)}
                onClickPassword={() => setPassIsVisible(prev => !prev)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item paddingTop={5} xs={12}>
          <FormWithButtons
            buttonsAlignment="end"
            buttonsSpace={3}
            onCancel={handleCancel}
          />
        </Grid>
      </Grid>
    </form>
  );
}
export default PlacesRegister;
