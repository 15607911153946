import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";

function ClickCapture({ onOutsideClick, children, className }) {
  const node = useRef();

  useEffect(() => {
    const handleClick = e => {
      if (node.current.contains(e.target)) {
        return;
      }
      onOutsideClick();
    };
    document.addEventListener("mousedown", handleClick, false);
    return () => {
      document.removeEventListener("mousedown", handleClick, false);
    };
  }, [onOutsideClick]);

  return (
    <div ref={node} className={className}>
      {children}
    </div>
  );
}
ClickCapture.propTypes = {
  onOutsideClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

ClickCapture.defaultProps = {
  onOutsideClick: () => null,
  children: null,
  className: "",
};

export default ClickCapture;
