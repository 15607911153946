import React from "react";
import { Card, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "react-tooltip";
// Context
import { useAuth } from "../../context";
// SCSS
import "../PaymentComponet/Receiver/ClientReceiver/ClientCard/ClientCard.scss";
import "../../pages/IncomePage/IncomePage.scss";
import "../ProductCard/ProductCard.scss";
import "./ClientsCard.scss";
import { useElectronic } from "../../context/ElectronicContext";

function ClientsCard({ clientList, setClientList, editClient, deleteClient }) {
  const { permissions } = useAuth();
  const { LineDetailPlace } = useElectronic();
  const { haveActionPermission } = permissions;
  const navigate = useNavigate();
  const selectIcon = TypeId => {
    if (TypeId === "1") {
      return "user";
    }
    return "building";
  };

  const handleSelectClient = clientSelected => {
    if (!haveActionPermission("Edit", "/products", LineDetailPlace?.id)) return;
    localStorage.setItem("clientSelected", JSON.stringify(clientSelected));
    navigate(`billing-profile`);
  };
  return (
    <Box className="content-flex-cards">
      {clientList
        ?.filter(item => item?.BillingProfile !== null)
        .map(client => (
          <Card
            key={client?.BillingProfile?.id}
            color="primary"
            sx={{
              width: "20.813rem",
              height: "9.75rem",
              paddingY: "1rem",
              paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="content-flex-row-between">
              <button type="button" onClick={() => handleSelectClient(client)}>
                <FontAwesomeIcon
                  icon={selectIcon(client?.BillingProfile?.MH_PersonType?.id)}
                  size="sm"
                />
              </button>
              <Typography
                fontWeight={600}
                typography="modeColor"
                variant="subtitle1"
              >
                {client?.BillingProfile?.Name}
              </Typography>
              <button type="button" onClick={() => deleteClient(client?.id)}>
                <FontAwesomeIcon icon="times" size="sm" />
                <Tooltip id="ButtonDelete" type="info">
                  <span>Eliminar cliente del grupo</span>
                </Tooltip>
              </button>
            </div>

            <button
              key={client?.BillingProfile?.id}
              className="content-flex-column"
              type="button"
              onClick={() => handleSelectClient(client)}
            >
              <Typography typography="modeColor" variant="subtitle2">
                {client?.BillingProfile?.MH_PersonType?.Name_PersonType}
              </Typography>
              <Typography typography="modeColor" variant="subtitle2">
                {client?.BillingProfile?.ID_Number}
              </Typography>
              <Typography typography="modeColor" variant="subtitle2">
                {client?.BillingProfile?.Email}
              </Typography>
            </button>
          </Card>
        ))}
    </Box>
  );
}
ClientsCard.defaultProps = {
  clientList: [],
  setClientList: () => {},
};
ClientsCard.propTypes = {
  clientList: PropTypes.array,
  setClientList: PropTypes.func,
  editClient: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
};

export default ClientsCard;
