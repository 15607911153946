import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const GET_NOTIFICATIONS_BY_PLACE_AND_USER = gql`
  query getNotificationsByPlaceAndUser($place: ID!, $user: ID!, $module: ID!) {
    notifications: getNotificationsByPlaceAndUser(
      place: $place
      user: $user
      module: $module
    ) {
      id
      Data
      FK_Place
      FK_Module
      Created_At
      Updated_At
      State_Notification
      Notification_User {
        id
        Readed_At
      }
    }
  }
`;
