import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_UNIT_OF_MEASURE } from "../../graphql";
// Utils
import { sortBy } from "../../utils";
import InputForm from "../InputForm/InputForm";
import SelectInput from "../SelectInput/SelectInput";

function UnitMeasureSelect({
  value,
  name,
  secondaryMeasureValue,
  secondaryMeasureName,
  onChange,
  disabled,
  className,
}) {
  const [measureSelected] = useState(value);
  const { data: getAllUnitOfMeasure } = useQuery(GET_ALL_UNIT_OF_MEASURE);

  const handleChange = (measureName, measureValue) => {
    onChange(measureName, measureValue);
  };
  useEffect(() => {
    if (value !== undefined || !getAllUnitOfMeasure?.unitsOfMeasurement) return;
    onChange(name, getAllUnitOfMeasure.unitsOfMeasurement[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllUnitOfMeasure, value]);

  const options = useMemo(() => {
    if (Array.isArray(getAllUnitOfMeasure?.unitsOfMeasurement)) {
      return sortBy(
        getAllUnitOfMeasure?.unitsOfMeasurement.map(unit => ({
          ...unit,
          Name: unit.Name_UnitOfMeasurement,
        })),
        "Name",
      );
    }
    return [];
  }, [getAllUnitOfMeasure?.unitsOfMeasurement]);

  return (
    <div className={className}>
      <SelectInput
        data={options}
        disabled={disabled}
        name={name}
        value={value}
        onChange={e => handleChange(e.target.name, e.target.value)}
      />
      {measureSelected?.Symbol === "Otros" && (
        <InputForm
          autoComplete="off"
          disabled={disabled}
          name={secondaryMeasureName}
          placeholder="Otra Unidad de medida"
          size="small"
          type="text"
          value={secondaryMeasureValue}
          onChange={e => onChange(e.target.name, e.target.value)}
        />
      )}
    </div>
  );
}

UnitMeasureSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  secondaryMeasureValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  secondaryMeasureName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

UnitMeasureSelect.defaultProps = {
  disabled: false,
  className: "",
};
export default UnitMeasureSelect;
