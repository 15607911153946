import { TAX_CODES } from "../Enums/ElectronicBill";

export const getTotal = (subTotal, taxedAmout) => subTotal + taxedAmout;

export const getSubTotal = (unitPrice, discountAsDecimal) =>
  unitPrice * (1 - discountAsDecimal);

export const getDiscountAmout = (unitPrice, discountAsDecimal) =>
  unitPrice * discountAsDecimal;
export const getTaxedCalculatorStrategy = MH_Code => {
  if (MH_Code === TAX_CODES.IVA_BIENES_USADOS) {
    return ({ subTotal, factor }) => subTotal * (factor - 1);
  }
  if (MH_Code === TAX_CODES.IVA_CALCULO_ESPECIAL) {
    return ({ porcentajeTarifa, baseTax }) =>
      baseTax * (porcentajeTarifa / 100);
  }
  return ({ subTotal, porcentajeTarifa }) =>
    subTotal * (porcentajeTarifa / 100);
};

/**
 * @param { object } product
 * @param { object } product.tax
 * @param { string } product.tax  - tax MH_Code
 * @param { number } product.porcentajeTarifa - Int e.g. 1,2,3,4...13
 * @param { number } product.baseTax - float  representa la tarifa base del producto e.g. 302,12
 * @param { number } product.subTotal - float subTotal del producto e.g. 200,19
 * @param { number } product.factor - float factor del product e.h 1,0003
 * @returns {float} taxed amount
 */

export const getTaxedAmount = product => {
  const calculateTax = getTaxedCalculatorStrategy(product.taxCode);
  return calculateTax(product);
};
