import { useLazyQuery } from "@apollo/client";
import { Box, Card, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { DatePicker } from "@mui/x-date-pickers";
import { useLocation } from "react-router-dom";
import { GET_PENDING_SCHEDULED_BILLS } from "../../graphql";
import useElectronicBill from "../../hooks/useElectronicBill";
import ScheduledBill from "../ScheduledBill/ScheduledBill";
import {
  BILL_CONFIGURATION_FREQUENCY_TEXT,
  RESULT_BILL_CONFIGURATION_EVENTS,
} from "../../Enums/BillConfiguration";
import { GET_BILLCONFIGURATION_EVENTS } from "../../graphql/billConfigurationEvents";
import SelectForm from "../SelectForm/SelectForm";
import { formatData } from "../../utils/helpers";
import { GET_CANCELED_SCHEDULED_BILLS } from "../../graphql/billConfiguration/billConfiguration.query";

const optionFrequency = Object.entries(BILL_CONFIGURATION_FREQUENCY_TEXT);

function ScheduledBillSearch() {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  const { LineDetailPlace } = useElectronicBill();
  const { pathname } = useLocation();
  const [scheduledBillSearchState, setScheduledBillSearchState] = useState({
    startDate: dayjs().utc().startOf("day"),
    endDate: dayjs().utc().endOf("day"),
    frequency: "1",
  });

  const handleChangeScheduledBillSearch = (name, value) =>
    setScheduledBillSearchState(prev => ({ ...prev, [name]: value }));

  const getActiveStatePendings = path => path === "pendings";
  const getActiveStateComplete = path => path === "complete";
  const getActiveStateFailed = path => path === "failed";

  const [
    getBillConfiguration,
    { data: pendingScheduledBillsData, loading: loadingBill },
  ] = useLazyQuery(GET_PENDING_SCHEDULED_BILLS);

  const [
    getSuccessBillConfiguration,
    { data: SuccessBillsConfiguration, loading: loadingSuccess },
  ] = useLazyQuery(GET_BILLCONFIGURATION_EVENTS);

  const [
    getFailedBillConfiguration,
    { data: FailedBillsConfiguration, loading: loadingFailed },
  ] = useLazyQuery(GET_BILLCONFIGURATION_EVENTS);

  const [
    getCanceledScheduledBills,
    {
      data: canceledScheduledBillsData,
      loading: loadingCanceledScheduledBills,
    },
  ] = useLazyQuery(GET_CANCELED_SCHEDULED_BILLS);

  const getData = () => {
    if (pathname === "/ScheduledBill/pendings") {
      getBillConfiguration({
        variables: {
          SearchInput: {
            where: {
              equals: {
                FK_Place: LineDetailPlace.id,
                Frequency: scheduledBillSearchState.frequency,
                Active: getActiveStatePendings(pathname.split("/")[2]),
              },
              between: {
                columnName: "date",
                value: [
                  scheduledBillSearchState?.startDate,
                  scheduledBillSearchState?.endDate,
                ],
              },
            },
          },
        },
      });
    }
    if (pathname === "/ScheduledBill/complete") {
      getSuccessBillConfiguration({
        variables: {
          FK_Place: LineDetailPlace.id,
          Frequency: scheduledBillSearchState.frequency,
          Active: getActiveStateComplete(pathname.split("/")[2]),
          Result: RESULT_BILL_CONFIGURATION_EVENTS.SUCCESS,
          startDate: scheduledBillSearchState?.startDate,
          endDate: scheduledBillSearchState?.endDate,
        },
      });
    }
    if (pathname === "/ScheduledBill/failed") {
      getFailedBillConfiguration({
        variables: {
          FK_Place: LineDetailPlace.id,
          Frequency: scheduledBillSearchState.frequency,
          Active: getActiveStateFailed(pathname.split("/")[2]),
          Result: RESULT_BILL_CONFIGURATION_EVENTS.FAILED,
          startDate: scheduledBillSearchState?.startDate,
          endDate: scheduledBillSearchState?.endDate,
        },
      });
    }
    if (pathname === "/ScheduledBill/canceled") {
      getCanceledScheduledBills({
        variables: {
          SearchInput: {
            FK_Place: LineDetailPlace.id,
            Frequency: scheduledBillSearchState.frequency,
            whereBetweenDates: {
              startDate: scheduledBillSearchState?.startDate,
              endDate: scheduledBillSearchState?.endDate,
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, scheduledBillSearchState]);

  return (
    <div className="content-flex-column">
      <Card
        color="primary"
        sx={{
          padding: "1rem",
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "visible",
        }}
      >
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Facturas programadas
        </Typography>
        <Box
          sx={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              sm: "repeat(3, 1fr)",
            },
          }}
        >
          <DatePicker
            color="secondary"
            format="DD/MM/YYYY"
            label="Fecha de inicio"
            size="small"
            sx={{
              "maxWidth": "180px",
              "& .MuiFormLabel-root": {
                transform: " translate(14px, 5px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                padding: "24px 14px 10px 14px ",
              },
            }}
            value={scheduledBillSearchState?.startDate}
            variant="filled"
            views={["day", "month", "year"]}
            onChange={newDate => {
              handleChangeScheduledBillSearch(
                "startDate",
                dayjs(newDate).utc().startOf("day"),
              );
            }}
          />
          <DatePicker
            color="secondary"
            format="DD/MM/YYYY"
            label="Fecha final"
            size="small"
            sx={{
              "maxWidth": "180px",
              "& .MuiFormLabel-root": {
                transform: " translate(14px, 5px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                padding: "24px 14px 10px 14px ",
              },
            }}
            value={scheduledBillSearchState.endDate}
            variant="filled"
            views={["day", "month", "year"]}
            onChange={newDate => {
              handleChangeScheduledBillSearch(
                "endDate",
                dayjs(newDate).utc().endOf("day"),
              );
            }}
          />
          <Box sx={{ maxWidth: "180px" }}>
            <SelectForm
              color="tertiary"
              data={formatData(optionFrequency, 1, 0)}
              label="Frecuencia"
              name="measurmentUnit"
              size="small"
              value={scheduledBillSearchState.frequency || ""}
              variant="filled"
              onChange={e => {
                handleChangeScheduledBillSearch("frequency", e.target.value);
              }}
            />
          </Box>
        </Box>
      </Card>
      <ScheduledBill
        BillConfiguration={pendingScheduledBillsData}
        CanceledBillsConfiguration={canceledScheduledBillsData}
        FailedBillsConfiguration={FailedBillsConfiguration}
        SuccessBillsConfiguration={SuccessBillsConfiguration}
        loading={
          loadingBill ||
          loadingSuccess ||
          loadingFailed ||
          loadingCanceledScheduledBills
        }
        pathname={pathname}
      />
    </div>
  );
}

export default ScheduledBillSearch;
