import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

function FormWithButtons({
  onCancel,
  onSubmit,
  cancelText,
  submitText,
  buttonsSpace,
  buttonsAlignment,
  displayCancel,
  displaySubmit,
}) {
  return (
    <Stack
      direction="row"
      display="flex"
      gap="1rem"
      justifyContent={buttonsAlignment}
      spacing={buttonsSpace}
    >
      <Button
        disabled={displayCancel}
        sx={{
          height: 50,
        }}
        type="button"
        variant="outlined"
        onClick={onCancel}
      >
        {cancelText}
      </Button>
      <Button
        color="primary"
        disabled={displaySubmit}
        sx={{
          height: 50,
        }}
        type="submit"
        variant="contained"
        onClick={onSubmit}
      >
        {submitText}
      </Button>
    </Stack>
  );
}

FormWithButtons.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  cancelText: PropTypes.string,
  submitText: PropTypes.string,
  buttonsSpace: PropTypes.number,
  buttonsAlignment: PropTypes.string,
  displayCancel: PropTypes.bool,
  displaySubmit: PropTypes.bool,
};

FormWithButtons.defaultProps = {
  cancelText: "Cancelar",
  submitText: "Guardar",
  buttonsSpace: 0,
  buttonsAlignment: "center",
  displayCancel: false,
  displaySubmit: false,
};

export default FormWithButtons;
