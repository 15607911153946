import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Button, Box } from "@mui/material";
// Graphql
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_CLIENTS_BY_GROUP,
  REGISTER_NEW_CLIENTXGROUP,
  DELETE_CLIENTXGROUP,
} from "../../graphql";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Components
import ClientsCard from "../ClientsCard";
import SelectBillingClient from "../BillingClientSelect";
// Utils
import { customToast as toast } from "../../utils";
import ConfirmationModal from "../ConfirmationModal";
import SearchInput from "../Products/components/SearchInput";
// SCSS
import "../PaymentComponet/Receiver/ClientReceiver/ClientReceiver.scss";
import "../ClientsGroupsXPlace/ClientsGroupsXPlace.scss";
import "./ClientsXGroup.scss";

function ClientsXGroup() {
  const [search, setSearch] = useState("");
  const [clientSearch, setClientSearch] = useState([]);
  const [clientList, setClientList] = useState([]);
  const navigate = useNavigate();
  const { setModalOpen } = useModal();
  const { id, name } = useParams();
  const { LineDetailPlace } = useElectronic();
  const { permissions } = useAuth();

  const { haveActionPermission } = permissions;

  const [registerGroup] = useMutation(REGISTER_NEW_CLIENTXGROUP);
  const [deleteClients] = useMutation(DELETE_CLIENTXGROUP);
  const { setModalOpen: setDeleteConfirmationModal } = useModal();

  const {
    loading,
    error,
    data: clientsByIdGroup,
  } = useQuery(GET_ALL_CLIENTS_BY_GROUP, {
    variables: {
      FK_ClientGroup: id,
    },
  });

  const deleteClient = useCallback(
    async idClient => {
      setDeleteConfirmationModal(
        true,
        <ConfirmationModal
          body="¿Desea eliminar este usuario de tu grupo?"
          header="¿Eliminar usuario?"
          onCancel={() => setDeleteConfirmationModal(false)}
          onConfirm={async () => {
            try {
              setModalOpen(false);
              const { data } = await deleteClients({
                variables: {
                  id: idClient,
                },
                refetchQueries: [
                  {
                    query: GET_ALL_CLIENTS_BY_GROUP,
                    variables: {
                      FK_ClientGroup: id,
                    },
                  },
                ],
              });
              if (data) {
                toast.success("¡Cliente eliminado del grupo con éxito!");
              }
            } catch (err) {
              toast.error("Error al eliminar el cliente");
            }
          }}
        />,
      );
    },

    [setDeleteConfirmationModal, setModalOpen, deleteClients, id],
  );

  useEffect(() => {
    setClientList(
      clientsByIdGroup?.clients?.map(index => ({
        ...index,
        selected: false,
      })),
    );
  }, [clientsByIdGroup]);

  const handleNewClient = () => {
    navigate(`billing-profile/${id}`);
  };

  const register = useCallback(
    async newClient => {
      const dataInput = {
        FK_User: newClient?.idUser,
        FK_ClientGroup: id,
      };
      const isInList = clientsByIdGroup?.clients?.find(
        index => index?.BillingProfile?.id === newClient?.id,
      );
      if (!isInList) {
        try {
          setModalOpen(false);
          const { data } = await registerGroup({
            variables: {
              data: dataInput,
            },
            refetchQueries: [
              {
                query: GET_ALL_CLIENTS_BY_GROUP,
                variables: {
                  FK_ClientGroup: id,
                },
              },
            ],
          });
          if (data) {
            toast.success("¡Cliente agregado al grupo con éxito!");
          }
        } catch (err) {
          toast.error("Error al agregar el cliente");
        }
      } else {
        toast.success("¡El cliente ya se encuentra registrado!");
      }
    },
    [registerGroup, setModalOpen, id, clientsByIdGroup],
  );

  const handleSearch = value => {
    setSearch(value);
    setClientSearch(
      clientList.filter(
        clientItem =>
          clientItem?.BillingProfile?.Name?.toUpperCase()
            .trim()
            .includes(value?.toUpperCase().trim()) ||
          clientItem?.BillingProfile?.Email?.toUpperCase().includes(
            value?.toUpperCase(),
          ) ||
          clientItem?.BillingProfile?.ID_Number?.toUpperCase().includes(
            value?.toUpperCase(),
          ),
      ),
    );
  };

  return (
    <div className="content-flex-column">
      {haveActionPermission("Create", "/clients", LineDetailPlace) && (
        <div className="content-flex-column">
          <Typography
            fontWeight={600}
            typography="modeColor"
            variant="subtitle1"
          >
            Agregar cliente existente:
          </Typography>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              gap: "1rem",
              justifyContent: "space-between",
              alignItems: "end",
            }}
          >
            <SelectBillingClient
              clientList={clientsByIdGroup?.clients}
              placeId={LineDetailPlace?.id}
              register={register}
            />
            <Button
              color="primary"
              sx={{ width: { xs: "174px", sm: "100%", md: "271px" } }}
              type="button"
              variant="contained"
              onClick={() => handleNewClient()}
            >
              Agregar Nuevo Cliente
            </Button>
          </Box>
        </div>
      )}
      <Typography fontWeight={600} typography="modeColor" variant="subtitle1">
        Clientes de {name}
      </Typography>
      <SearchInput
        disableImg
        className="widthInputSearch"
        label="Buscar clientes"
        value={search}
        onChange={({ target: { value } }) => handleSearch(value)}
      />
      <div className="list-container">
        {clientsByIdGroup?.clients?.length !== 0 &&
          !loading &&
          !error &&
          search?.length !== 0 &&
          (clientSearch.length !== 0 ? (
            <ClientsCard
              clientList={clientSearch}
              deleteClient={deleteClient}
              setClientList={setClientList}
            />
          ) : (
            <Typography align="left" typography="modeColor" variant="subtitle1">
              No hay clientes que coincidan con {search}.
            </Typography>
          ))}
        {search?.length === 0 && (
          <ClientsCard
            clientList={clientList}
            deleteClient={deleteClient}
            setClientList={setClientList}
          />
        )}
        {(!clientsByIdGroup?.clients?.length || loading || error) && (
          <Typography align="center" typography="modeColor" variant="subtitle1">
            Aún no hay clientes registrados para {name}.
          </Typography>
        )}
      </div>
    </div>
  );
}

ClientsXGroup.propTypes = {};

export default ClientsXGroup;
