import { useState, useEffect } from "react";
// External components
import { Box, Button, Content, Control, Field, Image, Input, Media } from "rbx";
import { toast } from "react-toastify";
// Graphql
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_USERS_BY_PLACE, GET_USER_BY_EMAIL } from "../../graphql";
// Components
import Loading from "../Loading";
import { NewOrEditUserRole as NewOrEditUser } from "./components";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useAuth, useModal } from "../../context";
// SCSS
import "./UserRole.scss";
import "../../styles/index.scss";

function UserRole() {
  const [search, setSearch] = useState("");
  const [user, setUser] = useState(null);

  const { setModalOpen } = useModal();
  const { idPlaces } = useAuth();
  const { LineDetailPlace } = useElectronic();

  const [getUserByEmail, { data: getUserByEmailCriteria }] =
    useLazyQuery(GET_USER_BY_EMAIL);
  const {
    data: getUsersByPlace,
    loading,
    refetch,
  } = useQuery(GET_USERS_BY_PLACE, {
    variables: {
      FK_Place: idPlaces,
    },
  });

  useEffect(() => {
    getUserByEmail({
      variables: {
        criteria: search,
      },
    });
    const result = getUserByEmailCriteria?.user?.find(u => u.Email === search);
    setUser(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const getRoleName = (placeMember, index) => {
    let userRolee = false;
    const result = placeMember?.Roles?.reduce((acc, placeRole) => {
      if (!userRolee && placeRole.Place.id === LineDetailPlace.id) {
        userRolee = getUsersByPlace?.user[index]?.UserRoles.find(
          r => r.FK_Role === placeRole.id,
        );
        acc.push(<small key={index}>{placeRole.Name_Role}</small>);
      }
      return acc;
    }, []);
    return result;
  };

  const handleNewUser = (users, band) => {
    if (user != null || !band) {
      setSearch("");
      setModalOpen(
        true,
        <NewOrEditUser
          NewOrEdit={band}
          refetch={refetch}
          setModalOpen={setModalOpen}
          title={band ? "Agregar Usuario" : "Editar Usuario"}
          user={users}
        />,
      );
    } else {
      toast.error("No se encontró el usuario.");
    }
  };

  const handleChange = value => {
    setSearch(value);
  };

  return (
    <div>
      <p className="textSearch">
        <strong> Email: </strong>
      </p>
      <div className="searchUser">
        <div className="form-item">
          <Field>
            <Control>
              <Input
                rounded
                autoComplete="off"
                color="dark"
                name="search"
                placeholder="Búsqueda..."
                type="text"
                value={search}
                onChange={({ target: { value } }) => handleChange(value)}
              />
            </Control>
          </Field>
        </div>
        <div className="form-item">
          <Button
            color="primary"
            size="small"
            onClick={() => {
              handleNewUser(user, true);
            }}
          >
            Agregar
          </Button>
        </div>
      </div>
      <div className="grid">
        {loading && (
          <div>
            <Loading />
          </div>
        )}
        {getUsersByPlace?.user.map((element, index) => (
          <Box
            key={element?.id}
            className={index !== 0 ? "Box-hover" : "Box"}
            onClick={() => {
              index !== 0 && handleNewUser(element, false);
            }}
          >
            <Media>
              <Media.Item align="left">
                <Image.Container size={64}>
                  <Image alt="Image" src={element?.FirebaseUser?.Picture} />
                </Image.Container>
              </Media.Item>
              <Media.Item>
                <Content>
                  <div>
                    <strong>{element?.FirebaseUser?.Name}</strong>{" "}
                    <div>{getRoleName(element, index)}</div>
                  </div>
                </Content>
              </Media.Item>
            </Media>
          </Box>
        ))}
      </div>
    </div>
  );
}

export default UserRole;
