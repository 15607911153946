/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_PROVINCES = gql`
  query allProvinces {
    provinces: getAllProvinces {
      id
      Name
      id_MH
    }
  }
`;
