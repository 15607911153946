export const calculateTaxes = (price, percent) => {
  const value = price * (percent / 100);
  return value;
};

export const formatNumber = number => {
  const n = new Intl.NumberFormat("ja-JP").format(number);
  return n;
};

export const formatDates = (year, month, day, hour, minute, second) => {
  const FDate = new Date(year, month, day, hour, minute, second);

  FDate.setMinutes(FDate.getUTCMinutes() - FDate.getTimezoneOffset());

  return FDate;
};
