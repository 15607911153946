import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
// External components
import { Card, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useMutation } from "@apollo/client";
import { UPDATE_TEMP_CLIENT } from "../../../../../graphql/bill/bill.mutation";
// Context
import { useElectronic } from "../../../../../context/ElectronicContext";
// SCSS
import "./ClientCard.scss";

function ClientCard({ clientList }) {
  const { setReceiver, setAddress, electronicBill, setClientGroup } =
    useElectronic();
  const navigate = useNavigate();

  const [updateClient] = useMutation(UPDATE_TEMP_CLIENT);

  const selectIcon = TypeId => {
    if (TypeId === "1") {
      return "user";
    }
    return "building";
  };

  const handleSelectClient = newValue => {
    const billingProfile = {
      id: newValue.id,
      Receiver_Id: newValue.ID_Number,
      Receiver_Name: newValue.Name,
      Receiver_Email: newValue.Email,
      Receiver_PhoneCode: newValue.PhoneCodCountry,
      Receiver_PhoneNumber: newValue.PhoneNumber,
      Receiver_PersonType: newValue.MH_PersonType,
      user: { id: newValue.User.id },
    };

    const address = {
      Province: newValue.MH_Neighborhood.District.Canton.Province,
      Canton: newValue.MH_Neighborhood.District.Canton,
      District: newValue.MH_Neighborhood.District,
      Neighborhood: newValue.MH_Neighborhood,
      Other: newValue.OtherSigns,
    };

    if (electronicBill.id) {
      updateClient({
        variables: {
          id: electronicBill.id,
          FK_User: newValue.User.id,
        },
      });
    }

    setAddress(address);
    setReceiver(billingProfile);
    setClientGroup({ id: 0 });
    navigate(-1);
  };

  return (
    <div className="content-flex-cards">
      {clientList?.map(client => (
        <Card
          key={client.BillingProfile.id}
          color="primary"
          sx={{
            width: "20.813rem",
            paddingY: "1rem",
            paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            cursor: "pointer",
          }}
          onClick={() => {
            handleSelectClient(client.BillingProfile);
          }}
        >
          <div className="content-flex-row-start">
            <button type="button" onClick={() => handleSelectClient(client)}>
              <FontAwesomeIcon
                icon={selectIcon(client?.BillingProfile?.MH_PersonType?.id)}
                size="sm"
              />
            </button>
            <Typography typography="modeColor" variant="subtitle1">
              {client?.BillingProfile?.Name}
            </Typography>
          </div>
          <div className="content-flex-column">
            <Typography typography="modeColor" variant="subtitle2">
              {client?.BillingProfile?.MH_PersonType?.Name_PersonType}
            </Typography>
            <Typography typography="modeColor" variant="subtitle2">
              {client?.BillingProfile?.ID_Number}
            </Typography>
            <Typography typography="modeColor" variant="subtitle2">
              {client?.BillingProfile?.Email}
            </Typography>
          </div>
        </Card>
      ))}
    </div>
  );
}
ClientCard.defaultProps = {
  clientList: [],
};
ClientCard.propTypes = {
  clientList: PropTypes.array,
};

export default ClientCard;
