import PropTypes from "prop-types";
// External components
import { Field, Control, Input as IInput, Label, Textarea } from "rbx";

function Input({
  className,
  label,
  placeholder,
  onChange,
  defaultValue,
  type,
  name,
  textarea,
  disabled,
}) {
  return (
    <Field className={className} kind="group">
      <Control expanded>
        <Label className="has-text-white" textColor="grey-dark">
          {label}
        </Label>
        {textarea ? (
          <Textarea
            fixedSize
            disabled={disabled}
            name={name}
            placeholder={placeholder ?? label}
            type={type}
            value={defaultValue}
            onChange={onChange}
          />
        ) : (
          <IInput
            disabled={disabled}
            name={name}
            placeholder={placeholder ?? label}
            type={type}
            value={defaultValue}
            onChange={onChange}
          />
        )}
      </Control>
    </Field>
  );
}

Input.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  textarea: PropTypes.bool,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  onChange: e => e,
  label: "",
  className: "",
  type: "text",
  textarea: false,
  placeholder: null,
  disabled: false,
};

export default Input;
