import React from "react";
import PropTypes from "prop-types";
// Graphql
import { useQuery } from "@apollo/client";
import { getAllCountries } from "../../graphql";
// SCSS
import "./CountrySelect.scss";
import SelectForm from "../SelectForm/SelectForm";
import { formatDataCountry } from "../../utils/helpers";

function CountrySelect({
  value,
  name,
  onChange,
  label,
  disabled,
  className,
  textColor,
  color,
}) {
  const { data: getCountryData } = useQuery(getAllCountries);
  return (
    <SelectForm
      color={color}
      data={formatDataCountry(getCountryData?.countries, "Name_Country")}
      disabled={disabled}
      label={label}
      name={name}
      size="small"
      type="country"
      value={value}
      variant="filled"
      onChange={e => onChange(e.target.name, e.target.value)}
    />
  );
}

CountrySelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  textColor: PropTypes.string,
  color: PropTypes.string,
};

CountrySelect.defaultProps = {
  name: "",
  disabled: false,
  className: "",
  textColor: "",
  color: "secondary",
};

export default CountrySelect;
