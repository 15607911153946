/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
// External components
import {
  Box,
  Typography,
  CircularProgress,
  Button,
  Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { components } from "react-select";
// Graphql
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_PRODUCTS_BY_PLACE_CRITERIA_LIMIT } from "../../graphql";
import {
  UPDATE_BILL_DETAIL,
  UPDATE_DETAILL_QUANTITY,
} from "../../graphql/bill/bill.mutation";
// Components

import CardProductSelected from "../Products/components/CardProduct/CardProductSelected";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { handleDecrement, handleIncrement } from "../../utils/ProductUtils";
import { validateRoute } from "../../utils/helpers";
// SCSS
import "react-spring-bottom-sheet/dist/style.css";
import "./ProductSelect.scss";
import SearchInput from "../Products/components/SearchInput";

import { BILL_TYPES_URL_PARAMS } from "../../Enums/ElectronicBill";

function CustomOption({ currentSymbolCoin, toExchangeRate, ...value }) {
  const { data } = value;
  const option = data;

  const getDetails = product => {
    let details = null;
    product.details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  return (
    <components.Option {...value}>
      {option && (
        <div className="option-container option-item">
          <div className="option-content-left">
            <div className="product-header">
              <h3>{option.description}</h3>
              <h4>{getDetails(option)}</h4>
              <h2>
                {currentSymbolCoin}
                {toExchangeRate(option.unit_price).toFixed(3)}
              </h2>
            </div>
          </div>
          <div className="product-img-option ">
            <img alt="product-pic" src={validateRoute(option)} />
          </div>
        </div>
      )}
    </components.Option>
  );
}

CustomOption.propTypes = {
  currentSymbolCoin: PropTypes.any.isRequired,
  toExchangeRate: PropTypes.any.isRequired,
};

function ProductSelect({ goBack }) {
  const [getProducts, { data, loading: queryLoading }] = useLazyQuery(
    GET_PRODUCTS_BY_PLACE_CRITERIA_LIMIT,
  );

  const [updateQuantity] = useMutation(UPDATE_DETAILL_QUANTITY);
  const { billType } = useParams();
  const [search, setSearch] = useState("");
  const [dataProduct, setDataProduct] = useState("");
  const [list, setList] = useState([]);

  const {
    lineDetail,
    setLineDetail,
    LineDetailPlace,
    electronicBill,
    useCoinDetail,
  } = useElectronic();
  const { currentSymbolCoin, toExchangeRate } = useCoinDetail;

  const [saveDetail] = useMutation(UPDATE_BILL_DETAIL);

  const handleChange = (name, value) => {
    setSearch(value);
  };

  const handleSaveProductDetail = productDetail => {
    if (!electronicBill.id) return;
    saveDetail({
      variables: {
        id: electronicBill.id,
        detail: {
          id: productDetail.detail_id,
          FK_ProductFeature: productDetail.id,
          Quantity_BillDetail: productDetail.quantity,
          Total_BillDetail: productDetail.total,
          SubTotal_BillDetail: productDetail.unit_price,
        },
      },
    });
  };

  const handleAddProducts = () => {
    if (lineDetail.length > 0) {
      const newLineDetail = list.reduce((prev, item) => {
        const oldProductDetail = lineDetail.find(
          product => product.id === item.id,
        );
        if (oldProductDetail) {
          const newProductDetail = {
            ...oldProductDetail,
            quantity: item.quantity + oldProductDetail.quantity,
            total: (
              item.unit_price *
              (oldProductDetail.quantity + item.quantity)
            ).toFixed(3),
          };
          handleSaveProductDetail(newProductDetail);
          prev.push(newProductDetail);
          return prev;
        }
        handleSaveProductDetail(item);
        prev.push({ ...item });
        return prev;
      }, []);

      setLineDetail(prev => [
        ...prev.filter(
          detail =>
            !newLineDetail.map(_detail => _detail.id).includes(detail.id),
        ),
        ...newLineDetail,
      ]);
    } else {
      list.forEach(item => handleSaveProductDetail(item));
      setLineDetail(list);
    }
    goBack();
    setList([]);
  };

  const handleAddQuantityList = (product, type, typeData, setData, Data) => {
    if (type === "+") {
      handleIncrement(
        product.id,
        setData,
        Data,
        updateQuantity,
        electronicBill,
      );
      if (typeData !== "list") addProductToList(product.id);
      else {
        handleIncrement(
          product.id,
          setDataProduct,
          dataProduct,
          updateQuantity,
          electronicBill,
        );
      }
    }
    if (type === "-" && billType !== BILL_TYPES_URL_PARAMS.DEBIT_NOTE) {
      if (typeData === "list") {
        handleDecrement({
          id: product.id,
          quantity: product.quantity,
          setDataTable: setData,
          list: Data,
          updateQuantity,
          electronicBill,
          removeOption: true,
        });
        removeProductFromList(product.id, setList, "list");
        handleDecrement({
          id: product.id,
          quantity: product.quantity,
          setDataTable: setDataProduct,
          list: dataProduct,
          updateQuantity,
          electronicBill,
          removeOption: true,
        });
      } else {
        handleDecrement({
          id: product.id,
          quantity: product.quantity,
          setDataTable: setDataProduct,
          list: dataProduct,
          updateQuantity,
          electronicBill,
          removeOption: true,
        });
        removeProductFromList(product.id, setList, "data");
      }
    }
  };

  const addProductToList = numberProduct => {
    const newProduct = dataProduct.find(
      product => product.id === numberProduct,
    );

    setList(prevList => {
      const productExists = prevList.find(
        product => product.id === numberProduct,
      );

      if (productExists) {
        return prevList.map(product =>
          product.id === numberProduct
            ? { ...product, quantity: product.quantity + 1 }
            : product,
        );
      }
      return [{ ...newProduct, quantity: 1 }, ...prevList];
    });
  };

  const removeProductFromList = (numberProduct, setData, typeData) => {
    setData(prevList => {
      const productExists = prevList.find(
        product => product.id === numberProduct,
      );
      if (productExists) {
        if (productExists.quantity >= 1 && typeData === "list") {
          return prevList.map(product =>
            product.id === numberProduct
              ? {
                  ...product,
                  quantity: product.quantity,
                }
              : product,
          );
        }
        if (productExists.quantity > 1 && typeData === "data") {
          return prevList.map(product =>
            product.id === numberProduct
              ? {
                  ...product,
                  quantity: product.quantity - 1,
                }
              : product,
          );
        }
        return prevList.filter(product => product.id !== numberProduct);
      }
      return prevList;
    });
  };

  useEffect(() => {
    if (search === "") return;
    getProducts({
      variables: {
        FK_Place: LineDetailPlace.id,
        criteria: search,
        limit: 10,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, setList]);

  useEffect(() => {
    if (data && !queryLoading) {
      const result = Array.isArray(data?.products)
        ? data?.products.map(item => {
            const { Details, Product, ProductPrice } = item;
            return {
              id: item.id,
              description: Product?.Description_Product,
              cabys: ProductPrice?.Cabys,
              Tariff: ProductPrice?.Tariff,
              unit_price: ProductPrice?.Price_ProductPrice.toFixed(3),
              quantity: 0,
              discount: [0],
              tax: ProductPrice?.Tax,
              taxes: [],
              totalTaxes: 0,
              total: ProductPrice?.Price_ProductPrice.toFixed(3),
              unit_Measure: item?.InventoryDetail?.UnitOfMeasurement,
              product_Code: "",
              url: item?.Files,
              details: Details,
              value: item.id,
              label: Product?.Name_Product,
            };
          })
        : [];
      setDataProduct(result);
    }
  }, [data, queryLoading]);

  return (
    <Box className="content-flex-column">
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Box sx={{ maxWidth: "31rem" }}>
          <SearchInput
            className="searchInput"
            label="Buscar Producto"
            value={search}
            onChange={({ target: { value, name } }) =>
              handleChange(name, value)
            }
          />
        </Box>
        <Button
          color="secondary"
          size="large"
          sx={{ width: "100px", display: list.length !== 0 ? "flex" : "none" }}
          variant="contained"
          onClick={handleAddProducts}
        >
          Agregar
        </Button>
      </Box>{" "}
      {list.length > 0 && (
        <Typography fontWeight="600" typography="modeColor" variant="subtitle1">
          Productos seleccionados
        </Typography>
      )}
      <Box
        sx={{
          "display": "flex",
          "flexDirection": "row",
          "maxWidth": "100%",
          "overflowY": "auto",
          "gap": "1rem",
          "paddingBottom": "10px",
          "&::-webkit-scrollbar": {
            height: "5px",
            cursor: "pointer",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "rgba(57, 110, 156, 1)",
            borderRadius: "8px",
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
          },
        }}
      >
        {list &&
          Array.isArray(list) &&
          list.map(product => (
            <CardProductSelected
              key={product.id}
              currentSymbolCoin={currentSymbolCoin}
              product={product}
              toExchangeRate={toExchangeRate}
              onClickDecrement={() =>
                handleAddQuantityList(product, "-", "list", setList, list)
              }
              onClickIncrement={() =>
                handleAddQuantityList(product, "+", "list", setList, list)
              }
            />
          ))}
      </Box>
      {list.length > 0 && <Divider color="secondary" />}
      <div className="content-flex-cards">
        {!queryLoading ? (
          Array.isArray(dataProduct) &&
          dataProduct.map(product => (
            <CardProductSelected
              key={product.id}
              currentSymbolCoin={currentSymbolCoin}
              product={product}
              toExchangeRate={toExchangeRate}
              onClickDecrement={() =>
                handleAddQuantityList(
                  product,
                  "-",
                  "data",
                  setDataProduct,
                  dataProduct,
                )
              }
              onClickIncrement={() =>
                handleAddQuantityList(
                  product,
                  "+",
                  "data",
                  setDataProduct,
                  dataProduct,
                )
              }
            />
          ))
        ) : (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress disableShrink size={80} />
          </Box>
        )}
        {!queryLoading && dataProduct.length === 0 && (
          <Typography>
            No se encontró ningún producto con ese nombre o descripción!
          </Typography>
        )}
      </div>
    </Box>
  );
}

ProductSelect.propTypes = {
  goBack: PropTypes.func.isRequired,
};

export default ProductSelect;
