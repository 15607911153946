import { gql } from "@apollo/client";

// eslint-disable-next-line import/prefer-default-export
export const REGISTER_NEW_PRODUCT = gql`
  mutation createProductComplete(
    $product: ProductCompleteInput
    $FK_Place: ID
    $file: FileInput
  ) {
    product: createProductComplete(
      product: $product
      FK_Place: $FK_Place
      file: $file
    ) {
      Name_Product
      Clicks_Product
      Restricted_Product
    }
  }
`;
