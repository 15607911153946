const CardStylesDark = {
  MuiCard: {
    variants: [
      // WITH COLOR
      {
        props: props => props.color === "baseColor",
        style: {
          background: "rgba(3, 7, 16, 1)",
          boxShadow: "none",
        },
      },
      {
        props: props => props.color === "primary",
        style: {
          color: "#ffff",
          background: "rgba(9, 25, 40, 1)",
        },
      },
      // Simulate input
      {
        props: props => props.color === "borderbottom",
        style: {
          background: "#081623",
          borderBottom: "1px solid #FFFFFF",
        },
      },
      {
        props: props => props.color === "borderbottomActive",
        style: {
          background: "#081623",
          border: "1px solid #284864",
        },
      },
      {
        props: props => props.color === "optionCardSelect",
        style: {
          background: "#060E1B",
        },
      },
      {
        props: props => props.color === "optionCardReport",
        style: {
          background: "rgba(6, 14, 27, 1)",
        },
      },
      // WITHOUT COLOR
      {
        props: props => props.color === "secondary",
        style: {
          color: "#ffff",
          background: "#153655",
        },
      },
      {
        props: props =>
          props.variant === "outlined" && props.color === "primary",
        style: {
          "borderRadius": "5px ",
          "border": "1px solid rgba(57, 110, 156, 1)",
          "color": "#ffff",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props =>
          props.variant === "outlined" && props.color === "tertiary",
        style: {
          "borderRadius": "5px ",
          "border": "1px solid #173859",
          "color": "#ffff",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props =>
          props.variant === "outlined" && props.color === "tertiaryAcento",
        style: {
          "borderRadius": "5px ",
          "border": "1px solid #396E9C",
          "color": "#ffff",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props =>
          props.variant === "outlined" && props.color === "tertiaryOnly",
        style: {
          "border": "1px solid rgba(255, 255, 255, 1)",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      // UPDATE FILE
      {
        props: props => props.color === "file",
        style: {
          "borderRadius": "5px ",
          "border": "1px dashed #FFFFFF9E",
          "color": "#ffff",
          "background": "rgba(9, 25, 40, 1)",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props => props.color === "secondaryFile",
        style: {
          borderRadius: "5px ",
          color: "#ffff",
          background: "rgba(9, 25, 40, 1)",
        },
      },
      {
        props: props => props.color === "primaryText",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(23, 56, 89, 1)",
          border: "1px solid rgba(255, 255, 255, 0.08)",
          boxShadow: " 4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        },
      },
      // HIGHT SECONDARY COLOR
      {
        props: props => props.color === "lightSecondary",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(21, 54, 85, 1)",
        },
      },
      // INVENTORY CARDS
      {
        props: props => props.color === "primaryInventory",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(9, 25, 40, 1)",
          border: "1px solid rgba(255, 255, 255, 0.08)",
          boxShadow: " 4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        },
      },
      {
        props: props => props.color === "secondaryInventory",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(9, 25, 40, 1)",
          border: "1px solid rgba(255, 255, 255, 0.08)",
          boxShadow: " 4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        },
      },
      {
        props: props => props.color === "whiteOnly",
        style: {
          background: "rgba(255, 255, 255, 1)",
          color: "rgba(3, 7, 16, 1)",
          border: "1px solid rgba(9, 25, 40, 1)",
        },
      },
    ],
  },
  // LINE
  MuiDivider: {
    variants: [
      // WITH COLOR
      {
        props: props => props.color === "primary",
        style: {
          background: "#396E9C",
        },
      },
      {
        props: props => props.color === "secondary",
        style: {
          " border-width": "0.5px",
          "background": "rgba(255, 255, 255, 1)",
        },
      },
      {
        props: props => props.color === "tertiary",
        style: {
          " border-width": "0.5px",
          "background": "rgba(255, 255, 255, 1)",
        },
      },
      {
        props: props => props.color === "tertiaryOnly",
        style: {
          " border-width": "0.5px",
          "background": "rgba(9, 25, 40, 1)",
        },
      },
    ],
  },
};

const CardStylesLight = {
  MuiCard: {
    variants: [
      // WITH COLOR
      {
        props: props => props.color === "baseColor",
        style: {
          background: "rgba(246, 249, 254, 1)",
          boxShadow: "none",
        },
      },
      {
        props: props => props.color === "primary",
        style: {
          color: "rgba(3, 7, 16, 1)",
          background: "#C8CFD6",
        },
      },
      {
        props: props => props.color === "secondary",
        style: {
          color: "rgba(3, 7, 16, 1)",
          background: "var(--Card, rgba(200, 207, 214, 1))",
        },
      },
      // WITH COLOR AND BORDER
      {
        props: props =>
          props.variant === "outlined" && props.color === "primary",
        style: {
          "background": "transparent",
          "borderRadius": "5px ",
          "border": "1px solid rgba(57, 110, 156, 1)",
          "color": "#ffff",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      // Simulate input
      {
        props: props => props.color === "borderbottom",
        style: {
          color: "rgba(3, 7, 16, 1)",
          background: "#F6F9FE",
          borderBottom: "1px solid #123B64",
        },
      },
      {
        props: props => props.color === "borderbottomActive",
        style: {
          color: "rgba(3, 7, 16, 1)",
          background: "#C8CFD6",
          border: "1px solid #123B64",
        },
      },
      {
        props: props => props.color === "optionCardSelect",
        style: {
          background: "#F6F9FE",
          border: "1px solid #123B64",
        },
      },
      {
        props: props => props.color === "optionCardReport",
        style: {
          background: "rgba(200, 207, 214, 1)",
        },
      },
      // WITHOUT COLOR
      {
        props: props =>
          props.variant === "outlined" && props.color === "tertiary",
        style: {
          "borderRadius": "5px ",
          "border": "1px solid #123B64",
          "color": "#ffff",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props =>
          props.variant === "outlined" && props.color === "tertiaryAcento",
        style: {
          "borderRadius": "5px ",
          "border": "1px solid #396E9C",
          "color": "#ffff",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props =>
          props.variant === "outlined" && props.color === "tertiaryOnly",
        style: {
          "border": "1px solid #173859",
          "background": "transparent",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      // UPDATE FILE
      {
        props: props => props.color === "file",
        style: {
          "borderRadius": "5px ",
          "border": "1px dashed #123B64",
          "color": "#ffff",
          "background": "#E6EAEF",
          "&:hover": {
            background: "transparent",
          },
          "&:focus": {
            background: "transparent",
          },
        },
      },
      {
        props: props => props.color === "secondaryFile",
        style: {
          borderRadius: "5px ",
          color: "#ffff",
          background: "#123B64",
        },
      },
      {
        props: props => props.color === "primaryText",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(23, 56, 89, 1)",
          border: "1px solid rgba(255, 255, 255, 0.08)",
          boxShadow: " 4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        },
      },
      {
        props: props => props.color === "lightSecondary",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(21, 54, 85, 1)",
          boxShadow: "3px 4px 9px 0px rgba(0, 0, 0, 0.35) inset",
        },
      },
      // INVENTORY CARDS
      {
        props: props => props.color === "primaryInventory",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(18, 59, 100, 1)",
          border: "1px solid rgba(255, 255, 255, 0.08)",
          boxShadow: " 4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        },
      },
      {
        props: props => props.color === "secondaryInventory",
        style: {
          borderRadius: "4px",
          color: "#ffff",
          background: "rgba(9, 25, 40, 1)",
          border: "1px solid rgba(255, 255, 255, 0.08)",
          boxShadow: " 4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        },
      },
      {
        props: props => props.color === "whiteOnly",
        style: {
          color: "rgba(3, 7, 16, 1)",
          background: "#F6F9FE",
          border: "1px solid rgba(18, 59, 100, 1)",
        },
      },
    ],
  },

  // LINE
  MuiDivider: {
    variants: [
      // WITH COLOR
      {
        props: props => props.color === "primary",
        style: {
          background: "#26659B",
        },
      },

      {
        props: props => props.color === "secondary",
        style: {
          " border-width": "0.5px",
          "background": "rgba(9, 25, 40, 1)",
        },
      },

      {
        props: props => props.color === "tertiary",
        style: {
          " border-width": "0.5px",
          "background": "rgba(3, 7, 16, 1)",
        },
      },
      {
        props: props => props.color === "tertiaryOnly",
        style: {
          " border-width": "0.5px",
          "background": "rgba(9, 25, 40, 1)",
        },
      },
    ],
  },
};

export { CardStylesDark, CardStylesLight };
