/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_SUBMODULES_BY_MODULE = gql`
  query getSubmodulesByModule($FK_Module: ID!) {
    getSubmodulesByModule(FK_Module: $FK_Module) {
      id
      Name_Submodule
      Route_Submodule
    }
  }
`;
