/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_CANTONS = gql`
  query getAllCantons($FK_Province: ID!) {
    cantons: getAllCantons(FK_Province: $FK_Province) {
      id
      Name
      id_MH
    }
  }
`;
