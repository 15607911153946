import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Card,
  Typography,
  LinearProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import * as converter from "xml-js";
// Hooks
import useZoho from "../../../../hooks/useEmail";
// Context
import { useElectronic } from "../../../../context/ElectronicContext";
// Utils
import { toZohoMonths } from "../../../../utils/helpers";
// Enum
import EMAIL_PROVIDERS from "../../../../Enums/EmailToken";
// SCSS
import "./ZohoSearch.scss";
import InputForm from "../../../InputForm/InputForm";

function ZohoSearch({
  setIsLogged,
  setXmls,
  quantityEmails,
  setQuantityEmails,
  nextPage,
  setNextPage,
}) {
  const [isLoadingBills, setIsLoadingBills] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [checkAccountSelected, setCheckAccountSelected] = useState(true);
  const limitSelectValues = ["10", "20", "30", "40", "50", "Todas"];

  const { dates, setDates, accountSelected } = useElectronic();
  const { getEmails, checkTokenEmail } = useZoho();

  const checkLoginZoho = useCallback(
    async account => {
      try {
        const res = await checkTokenEmail(
          account?.id,
          parseInt(account?.type, 10),
        );
        setCheckAccountSelected(res?.data?.email);
        return res?.data?.email;
      } catch (err) {
        return false;
      }
    },
    [checkTokenEmail],
  );

  const searchDocuemnts = async () => {
    setNoResults(false);
    if (isInvalidForm()) {
      toast.error("Hay errores, por favor revise las fechas");
      return;
    }
    setIsLoadingBills(true);
    const { data: isTokenValid } = await checkTokenEmail(
      accountSelected?.id,
      parseInt(accountSelected?.type, 10),
    );
    if (!isTokenValid.zoho) {
      setIsLogged(false);
    }
    const { data } = await getEmails(
      parseInt(accountSelected?.id, 10),
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.startDate.value)
        : dates.startDate.value.replaceAll("-", "/"),
      accountSelected?.type === EMAIL_PROVIDERS.Zoho
        ? toZohoMonths(dates.endDate.value)
        : dates.endDate.value.replaceAll("-", "/"),
      quantityEmails,
      1,
      "0",
      parseInt(accountSelected?.type, 10),
    );
    const xmls = data.data.map(email => getXMLFromEmailFiles(email.files));
    if (accountSelected?.type === EMAIL_PROVIDERS.Gmail && xmls?.length > 0) {
      setNextPage(data.data[0].nextPage?.toString());
    }
    setXmls(xmls.flat());
    setIsLoadingBills(false);
    if (data.data.length === 0) {
      setNoResults(true);
    }
  };

  const getXMLFromEmailFiles = emailFiles =>
    emailFiles
      .map(file => {
        try {
          const xml = {
            ...JSON.parse(
              converter.xml2json(
                accountSelected.type === EMAIL_PROVIDERS.Gmail ||
                  accountSelected.type === EMAIL_PROVIDERS.Outlook
                  ? decodeBase64(file.content)
                  : file.content,
                { compact: true },
              ),
            ),
            isOutOfDateRange: false,
          };
          const invoiceDate = new Date(
            xml?.FacturaElectronica?.FechaEmision?._text.split("T")[0],
          );
          const startDate = new Date(dates.startDate.value);
          const endDate = new Date(dates.endDate.value);
          if (
            isInvalidDate(invoiceDate) ||
            invoiceDate > endDate ||
            invoiceDate < startDate
          ) {
            xml.isOutOfDateRange = true;
          }
          return xml;
        } catch (e) {
          return { error: true };
        }
      })
      .filter(xmlJSON => !xmlJSON.error && !xmlJSON.MensajeHacienda);

  const decodeBase64 = str => {
    const response = str.replace(/_/g, "/").replace(/-/g, "+");
    return atob(response);
  };

  const handleSetDate = (name, date) => {
    setNoResults(false);
    setDates(prev => ({ ...prev, [name]: { ...prev[name], value: date } }));
  };

  const handleSetErrors = (name, error) => {
    setDates(prev => ({ ...prev, [name]: { ...prev[name], errors: error } }));
  };

  const handleSetFormErrors = (name, value) => {
    if (isEmptyDate(name)) {
      handleSetErrors(name, "El campo no puede estar vacio");
      return true;
    }
    if (isInvalidDate()) {
      handleSetErrors(
        name,
        "La fecha de inicio debe de ser menor o igual a la final",
      );
      return true;
    }

    if (checkIsFutureDate(value)) {
      handleSetErrors(
        name,
        "No se puede buscar un documento a una fecha futura a la actual",
      );
      return true;
    }

    handleSetErrors(name, "");
    return false;
  };

  const isInvalidForm = () =>
    handleSetFormErrors("startDate") || handleSetFormErrors("endDate");

  const isEmptyDate = name => dates[name].value === "";

  const isInvalidDate = () =>
    new Date(dates.endDate.value).getTime() <
    new Date(dates.startDate.value).getTime();

  const checkIsFutureDate = dateText =>
    new Date().getTime() < new Date(dateText).getTime();

  const areThereAnyFutureDate =
    checkIsFutureDate(dates.startDate.value) ||
    checkIsFutureDate(dates.endDate.value);

  useEffect(() => {
    accountSelected.id !== 0 && checkLoginZoho(accountSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSelected]);

  return (
    <React.Fragment>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <InputForm
          color="primary"
          label="Desde"
          name="startDate"
          size="small"
          type="date"
          value={dates.startDate.value}
          onBlur={({ target: { name, value } }) => {
            handleSetFormErrors(name, value);
            !isInvalidDate() &&
              handleSetFormErrors("endDate", dates.endDate.value);
          }}
          onChange={({ target: { name, value } }) => {
            handleSetDate(name, value);
          }}
        />

        <InputForm
          color="primary"
          label="Hasta"
          name="endDate"
          size="small"
          type="date"
          value={dates.endDate.value}
          onBlur={({ target: { name, value } }) => {
            handleSetFormErrors(name, value);
            !isInvalidDate() &&
              handleSetFormErrors("startDate", dates.startDate.value);
          }}
          onChange={({ target: { name, value } }) => handleSetDate(name, value)}
        />

        <FormControl fullWidth sx={{ minWidth: 120 }} variant="filled">
          <InputLabel>Cantidad de facturas</InputLabel>
          <Select
            color="secondary"
            label="Cantidad de facturas"
            name="quantityEmails"
            size="small"
            value={quantityEmails}
            variant="filled"
            onChange={({ target: { name, value } }) => {
              setQuantityEmails(value);
            }}
          >
            {limitSelectValues.map(index => (
              <MenuItem key={index} value={index}>
                {index}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box>
          <Button
            color="secondary"
            disabled={
              isLoadingBills || areThereAnyFutureDate || !checkAccountSelected
            }
            sx={{ height: "48px" }}
            variant="contained"
            onClick={() => searchDocuemnts()}
          >
            Buscar
          </Button>
        </Box>
      </Box>

      {noResults && (
        <Card color="secondaryFile" sx={{ padding: "1rem", margin: "1rem" }}>
          <Typography typography="defaultLight" variant="sutitle1">
            No se encontraron resultados para los parámetros selecionados
          </Typography>
        </Card>
      )}
      {isLoadingBills && (
        <LinearProgress value={isLoadingBills} variant="determinate" />
      )}
    </React.Fragment>
  );
}

ZohoSearch.propTypes = {
  setIsLogged: PropTypes.func.isRequired,
  setXmls: PropTypes.func.isRequired,
  quantityEmails: PropTypes.string.isRequired,
  setQuantityEmails: PropTypes.func.isRequired,
  nextPage: PropTypes.string.isRequired,
  setNextPage: PropTypes.func.isRequired,
};

export default ZohoSearch;
