import React from "react";
import PropTypes from "prop-types";
// External components
import { Help } from "rbx";

function Copyright({ color, align }) {
  return (
    <Help align={align} color={color}>
      Conta-Fast © {new Date().getFullYear()} Tavuel, 2021.
    </Help>
  );
}

Copyright.propTypes = {
  color: PropTypes.string,
  align: PropTypes.string,
};

Copyright.defaultProps = {
  color: "white",
  align: "center",
};

export default Copyright;
