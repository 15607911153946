import React, { useState, useEffect } from "react";
// Components
import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import Login from "../../components/Login";
import Register from "../../components/Register";
import ForgotPassword from "../../components/ForgotPassword";
// Context
import { useAuth } from "../../context";
// Utils
import { getAllCountries } from "../../graphql";
import Loading from "../../components/Loading";

function LandingPage() {
  const [state, setState] = useState({ email: "", password: "" });
  const [loginSection, setLoginSection] = useState(true);

  const { data: getCountryData } = useQuery(getAllCountries);
  const {
    handleLogin,
    handleCheckAuth,
    handleLoginGoogle,
    handleRegisterUser,
    checking,
    isLoading,
  } = useAuth();
  const [openViewForgotPassword, setOpenViewForgotPassword] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const handleChange = (name, value) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (loginSection) {
      handleLogin(state.email, state.password);
    }
  };

  const handleGoogle = e => {
    if (loginSection) {
      handleLoginGoogle();
    }
  };

  const openForgotPassword = isOpenView => {
    setOpenViewForgotPassword(isOpenView);
  };

  useEffect(() => {
    handleCheckAuth();
  }, [handleCheckAuth]);

  if (checking || isLoading) {
    return <Loading />;
  }

  return (
    <div className="background-page">
      <form
        className={
          loginSection
            ? "principal-container landing-page-container"
            : "principal-container landing-page-container-register"
        }
        onSubmit={handleSubmit}
      >
        {openViewForgotPassword && (
          <ForgotPassword setModalOpen={openForgotPassword} />
        )}
        {loginSection && !openViewForgotPassword && (
          <Login
            handleChange={handleChange}
            handleGoogle={handleGoogle}
            loginSection={loginSection}
            openForgotPassword={openForgotPassword}
            setLoginSection={setLoginSection}
            state={state}
          />
        )}
        {!loginSection && (
          <Register
            getCountryData={getCountryData}
            handleRegisterUser={handleRegisterUser}
            loginSection={loginSection}
            setIsLoadingRegister={setIsLoadingRegister}
            setLoginSection={setLoginSection}
          />
        )}
      </form>
      {isLoadingRegister && (
        <CircularProgress
          disableShrink
          size={100}
          sx={{ position: "absolute" }}
        />
      )}
    </div>
  );
}

LandingPage.propTypes = {};

export default LandingPage;
