/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_PERMISSION_BY_SUBMODULE_AND_ROLE = gql`
  query getPermissionsBySubmoduleAndRole($FK_Submodule: ID!, $FK_Role: ID!) {
    getPermissionsBySubmoduleAndRole(
      FK_Submodule: $FK_Submodule
      FK_Role: $FK_Role
    ) {
      id
      Name_Permission
    }
  }
`;
