import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, Box } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { useNavigate } from "react-router-dom";
import errorIcon from "../../assets/icons/error_icon.svg";
import succesIcon from "../../assets/icons/succes_icon.svg";
import "./NotificationCard.scss";

function NotificationCard({
  tabIndex,
  title,
  icon,
  body,
  link,
  date,
  isOpened,
}) {
  const navigate = useNavigate();
  const onCardClicked = () => {
    if (link === "") return;
    navigate(link);
  };

  return (
    <Card
      color="primary"
      sx={{
        width: {
          xs: "100%",
          sm: "322px",
          md: "100%",
          xl: "100%",
        },
        maxWidth: 510,
        height: { xs: 130, md: 110 },
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
        onClick={onCardClicked}
      >
        {title.toLowerCase().includes("error") ? (
          <img alt="error" className="icon-warning" src={errorIcon} />
        ) : (
          <img alt="exito" className="icon-warning" src={succesIcon} />
        )}
        <Box sx={{ width: "100%" }}>
          <div className="content-flex-row-between">
            <Typography typography="modeColor" variant="h6">
              {title}
            </Typography>
            <Typography typography="modeColor" variant="subtitle1">
              {date}
            </Typography>
          </div>
          <Typography typography="modeColor" variant="subtitle1">
            {body}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}

NotificationCard.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  body: PropTypes.string,
  link: PropTypes.string,
  date: PropTypes.string,
  isOpened: PropTypes.bool,
};

NotificationCard.defaultProps = {
  body: "",
  link: "",
  icon: "",
  date: "dd/mm/yyyy",
  isOpened: false,
};

export default NotificationCard;
