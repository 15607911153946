import React, { useState, useEffect, useRef } from "react";
import { Box, Button, Card, Divider, Hidden, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { useLocation, useNavigate } from "react-router-dom";
// Components
import ClickCapture from "../ClickCapture";
import PlaceSelector from "../PlaceSelector";
import SwitchButton from "../SwitchButton/SwitchButton";
// Routes
import { getRoutes } from "./routes";
// Context
import { useAuth } from "../../context/AuthContext";
import { useElectronic } from "../../context/ElectronicContext";
import { useNotifications } from "../../context/NotificationsContext";
// SCSS
import { StyledMenu } from "./styleMenu";
import "./Sidebar.scss";
// ICON

import profileIcon from "../../assets/icons/profile.svg";
import logoutIcon from "../../assets/icons/log out.svg";
import arrowIcon from "../../assets/icons/Arrows Menu.svg";
import homeIcon from "../../assets/icons/Home.svg";
import MenuIcon from "./components/MenuIcon";
import returnArrow from "../../assets/icons/return_icon.svg";
import arrowOpenIcon from "../../assets/icons/arrowOpen.svg";

function Sidebar({ isSideBarOpen, setIsSideBarOpen, changeTheme }) {
  const [menuActive, setMenuActive] = useState(false);
  const [, setActiveRoutes] = useState({});
  const [selectedOption, setSelectedOption] = useState();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const prevRoutesRef = useRef(null);
  const {
    state: authState,
    handleLogout,
    permissions: userPermissions,
  } = useAuth();
  const { LineDetailPlace, setLineDetailPlace, setAccountSelected } =
    useElectronic();
  const { setNotifications, refetchNotifications } = useNotifications();

  const permissions = ["Main"];
  const routes = getRoutes("Main");

  const { isSuperAdminOrHaveAccess } = userPermissions;

  const availableRoutes = routes.filter(route =>
    Array.isArray(route.permissions)
      ? route.permissions?.some(perm => permissions?.some(x => perm === x))
      : true,
  );
  const [optionSeleted, setOptionSelected] = useState(availableRoutes[0]);
  useEffect(() => {
    const firstNotisLoad = async () => {
      setNotifications(await refetchNotifications());
    };
    firstNotisLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineDetailPlace.id]);

  useEffect(() => {
    setMenuActive(false);
  }, [location.pathname]);

  const handleToggleSubMenu = state => {
    if (isSideBarOpen === false && !state) setIsSideBarOpen(true);
    if (state === false) return setIsSubMenuOpen(false);
    return setIsSubMenuOpen(!isSubMenuOpen);
  };

  const toggleActiveRoutes = id => {
    if (id === 2) {
      handleToggleSubMenu();
    } else {
      setIsSubMenuOpen(false);
    }
    setSelectedOption(id);
    const route = availableRoutes[id];

    if (route.to) {
      navigate(route.to);
      setOptionSelected(availableRoutes[id]);

      setMenuActive(false);
      if (window.innerWidth < 900) {
        setIsSideBarOpen(false);
      }
    } else {
      setActiveRoutes(availableRoutes[id]);
      setMenuActive(true);
    }
  };

  const handleNavigateBack = async () => {
    await navigate(-1);
  };

  // close sidebar menu when ESC key is pressed
  useEffect(() => {
    const handleKeyDown = e => {
      if (menuActive && e.keyCode === 27) {
        setMenuActive(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  });

  useEffect(() => {
    prevRoutesRef.current = availableRoutes;
  }, [availableRoutes]);

  useEffect(() => {
    const currentRoutes = prevRoutesRef.current;

    if (currentRoutes) {
      const currentPath = window.location.pathname.split("/")[1];
      const submenu = currentRoutes[2]?.links || [];
      const selectRoute = currentRoutes.find(
        route => route.name === currentPath,
      );
      const subRoutes = submenu.find(link => link.to === `/${currentPath}`);

      if (window.location.pathname === "/") {
        setOptionSelected({ label: "Inicio", icon: homeIcon, to: "/" });
      } else if (subRoutes) {
        setOptionSelected({
          label: subRoutes.name,
          icon: currentRoutes[2]?.icon,
          to: subRoutes.to,
        });
      } else if (selectRoute) {
        setOptionSelected(selectRoute);
      }
    }
  }, [navigate]);

  return (
    <nav className="navigation-bar">
      <div className="navbar-container">
        <ClickCapture
          className="navbar-container__left-icon"
          onOutsideClick={() => setMenuActive(false)}
        >
          <StyledMenu className="sidebar-menu" open={isSideBarOpen}>
            <div className="sidebar-options">
              <div className="sidebar-header">
                <div
                  className="content-flex-row-between invert-order-reverse"
                  style={{ paddingLeft: "1rem" }}
                >
                  <button
                    className="button-Content-Icon"
                    type="button"
                    onClick={async e => {
                      e.stopPropagation();
                      if (isSideBarOpen) {
                        setMenuActive(false);
                        handleToggleSubMenu(false);
                      }
                      setIsSideBarOpen(prev => !prev);
                      !isSideBarOpen &&
                        setNotifications(await refetchNotifications());
                    }}
                  >
                    <img
                      alt="Pagina principal"
                      className="icon"
                      src={isSideBarOpen ? arrowIcon : arrowOpenIcon}
                    />
                  </button>
                  <Hidden smUp>
                    <SwitchButton onMenu onChangeTheme={changeTheme} />
                  </Hidden>
                </div>
                <Hidden smUp>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "1rem",
                      flexDirection: "column",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                  >
                    <PlaceSelector
                      style={{ width: "60%", display: "flex", gap: "1rem" }}
                    />
                  </Box>
                </Hidden>
                <div
                  className={`sidebar-menu-item nohover ${isSideBarOpen ? "invert-order" : "invert-order-reverse"} ${selectedOption === "home" ? "select-Item-Option" : ""}`}
                  role="button"
                  tabIndex={0}
                  onClick={() => {
                    setSelectedOption("home");
                    navigate("/");
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter" || e.key === " ") {
                      setSelectedOption("home");
                    }
                  }}
                >
                  <img alt="Pagina principal" className="icon" src={homeIcon} />
                  <Typography
                    typography="defaultLight"
                    variant="fixedSizeSubtitle1"
                    width="100%"
                  >
                    Home
                  </Typography>
                </div>
                <div
                  className={`sidebar-menu-item ${isSideBarOpen ? "invert-order" : "invert-order-reverse"}`}
                >
                  {isSideBarOpen ? (
                    <div className="div_icon">
                      {authState?.user?.displayName
                        ? authState.user.displayName.charAt(0)
                        : ""}
                    </div>
                  ) : (
                    <img
                      alt="Pagina principal"
                      className="icon"
                      src={profileIcon}
                    />
                  )}
                  <div className="">
                    <Typography
                      typography="defaultLight"
                      variant="fixedSizeSubtitle1"
                      width="100%"
                    >
                      Hola, {authState?.user?.displayName || ""}
                    </Typography>
                    <Typography
                      typography="defaultLight"
                      variant="subtitle2"
                      width="100%"
                    >
                      {authState?.user?.email || ""}
                    </Typography>
                  </div>
                </div>
              </div>
              <hr className="line" />
              {availableRoutes.map(
                (r, i) =>
                  isSuperAdminOrHaveAccess(
                    r.to ?? "/electronic-bills",
                    LineDetailPlace,
                  ) && (
                    <MenuIcon
                      handleToggleSubMenu={handleToggleSubMenu}
                      index={i}
                      isSideBarOpen={isSideBarOpen}
                      isSubMenuOpen={isSubMenuOpen}
                      route={r}
                      selectedOption={selectedOption}
                      toggleActiveRoutes={() => toggleActiveRoutes(i)}
                    />
                  ),
              )}
              <div
                className={`sidebar-menu-item margin-Button ${isSideBarOpen ? "invert-order" : "invert-order-reverse"}`}
                role="button"
                tabIndex="-1"
                onClick={() => {
                  handleLogout();
                  setLineDetailPlace({ id: 0 });
                  setAccountSelected({ id: 0 });
                }}
                onKeyDown={() => null}
              >
                <img alt="cerrar sesiòn" className="icon" src={logoutIcon} />{" "}
                <Typography
                  typography="defaultLight"
                  variant="fixedSizeSubtitle1"
                  width="100%"
                >
                  Cerrar sesión
                </Typography>
              </div>
            </div>
          </StyledMenu>
        </ClickCapture>
        {/* Navbar */}
        <Card
          color="baseColor"
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: { xs: "0rem", sm: "1rem", md: "1.875rem" },
          }}
        >
          <Box
            sx={{
              left: "90px",
              top: "30px",
              display: "flex",
              alignItems: "center",
              gap: { xs: "0.1rem", sm: "0.5rem" },
              fontWeight: 600,
            }}
          >
            <Button
              className="button-Content-Icon"
              sx={{
                display: { xs: "flex", sm: "none" },
                padding: "0px",
                justifyContent: "start",
              }}
              type="button"
              onClick={async e => {
                e.stopPropagation();
                if (isSideBarOpen) {
                  setMenuActive(false);
                  handleToggleSubMenu(false);
                }
                setIsSideBarOpen(prev => !prev);
                !isSideBarOpen &&
                  setNotifications(await refetchNotifications());
              }}
            >
              <img
                alt="Pagina principal"
                src={arrowOpenIcon}
                style={{ width: "31px", height: "31px" }}
              />
            </Button>
            <Button
              className="button-Content-Icon icon-color"
              sx={{
                paddingRight: "1rem",
              }}
              type="button"
              onClick={handleNavigateBack}
            >
              <img
                alt="icono regresar"
                className="icon img-return-Navbar"
                src={returnArrow}
              />
            </Button>
            <img
              alt="icono opción"
              className="img-option-Navbar icon-color"
              src={optionSeleted.icon}
            />
            <Typography
              fontWeight="600px"
              sx={{ marginTop: { xs: "5px", sm: "10px" } }}
              typography="modeColor"
              variant="h5"
              width="100%"
            >
              {optionSeleted.label}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              flexDirection: "row",
              justifyContent: "center",
              paddingY: "0.5rem",
              alignItems: "center",
            }}
          >
            <Hidden smDown>
              <PlaceSelector
                style={{ height: "40px", display: "flex", gap: "1rem" }}
              />
              <SwitchButton onChangeTheme={changeTheme} />
            </Hidden>
          </Box>
        </Card>
      </div>
      <Divider color="tertiaryOnly" sx={{ width: "100%" }} />
    </nav>
  );
}

Sidebar.propTypes = {
  isSideBarOpen: PropTypes.bool,
  setIsSideBarOpen: PropTypes.func,
  changeTheme: PropTypes.func,
};

Sidebar.defaultProps = {
  isSideBarOpen: false,
  setIsSideBarOpen: () => null,
  changeTheme: () => null,
};

export default Sidebar;
