/* eslint-disable no-param-reassign */
import { formatDateString } from "./helpers";
import { formatNumber } from "./ReportUtils";

// eslint-disable-next-line import/prefer-default-export

export const billIncomesListHeaders = () => [
  { label: "Fecha", key: "date" },
  { label: "# Factura", key: "electronicBillId" },
  { label: "Estado", key: "state" },
  { label: "Condición", key: "condition" },
  { label: "Moneda", key: "coin" },
  { label: "Total antes impuestos", key: "totalBeforetax" },
  { label: "Exento", key: "exempt" },
  { label: "IVA 1%", key: "iva1" },
  { label: "IVA 2%", key: "iva2" },
  { label: "IVA 4%", key: "iva4" },
  { label: "IVA 8%", key: "iva8" },
  { label: "IVA 13%", key: "iva13" },
  { label: "Otro Impuestos", key: "anotherTax" },
  { label: "Total IVA 1%", key: "totalIva1" },
  { label: "Total IVA 2%", key: "totalIva2" },
  { label: "Total IVA 4%", key: "totalIva4" },
  { label: "Total IVA 8%", key: "totalIva8" },
  { label: "Total IVA 13%", key: "totalIva13" },
  { label: "Total Otros Impuestos", key: "totalIvaOthers" },
  { label: "Total", key: "total" },
];

export const d104Headers = () => [
  { label: "IVA 1% Servicios", key: "col1" },
  { label: "", key: "col2" },
  { label: "IVA 2% Servicios", key: "col3" },
  { label: "", key: "col4" },
  { label: "IVA 4% Servicios", key: "col5" },
  { label: "", key: "col6" },
  { label: "IVA 8% Servicios", key: "col7" },
  { label: "", key: "col8" },
  { label: "IVA 13% Servicios", key: "col9" },
  { label: "", key: "col10" },
  { label: "Exento Servicios", key: "col11" },
  { label: "", key: "col12" },
  { label: "", key: "col13" },
  { label: "Total Servicios", key: "col14" },
];
const getIVA = (list, filter, tax) => {
  const result = list.find(index => index.id === filter);
  if (tax) {
    return result.Amount;
  }
  return result.Total;
};

export const getD104Data = (setDataCSV, services, merch, totals) => {
  const reportData = [];
  const header2 = {
    col1: "IVA 1% Mercancías",
    col2: "",
    col3: "IVA 2% Mercancías",
    col4: "",
    col5: "IVA 4% Mercancías",
    col6: "",
    col7: "IVA 8% Mercancías",
    col8: "",
    col9: "IVA 13% Mercancías",
    col10: "",
    col11: "Exento Mercancías",
    col14: "Total Mercancías",
  };
  const header3 = {
    col1: "Total IVA 1%",
    col2: "",
    col3: "Total IVA 2%",
    col4: "",
    col5: "Total IVA 4%",
    col6: "",
    col7: "Total IVA 8%",
    col8: "",
    col9: "Total IVA 13%",
    col10: "",
    col11: "Total Exento",
    col12: "Impuestos Totales",
    col13: "Subtotal",
    col14: "Total Gravado",
  };
  if (services.length > 0 && merch.length > 0 && totals?.Taxes?.length > 0) {
    reportData.push({
      col1: `${formatNumber(getIVA(services, "3", false).toFixed(2))}`,
      col2: formatNumber(getIVA(services, "3", true).toFixed(2)),
      col3: `${formatNumber(getIVA(services, "4", false).toFixed(2))}`,
      col4: formatNumber(getIVA(services, "4", true).toFixed(2)),
      col5: `${formatNumber(getIVA(services, "5", false).toFixed(2))}`,
      col6: formatNumber(getIVA(services, "5", true).toFixed(2)),
      col7: `${formatNumber(getIVA(services, "8", false).toFixed(2))}`,
      col8: formatNumber(getIVA(services, "8", true).toFixed(2)),
      col9: `${formatNumber(getIVA(services, "1", false).toFixed(2))}`,
      col10: formatNumber(getIVA(services, "1", true).toFixed(2)),
      col11: formatNumber(totals.TotalServicesExempt.toFixed(2)),
      col14: formatNumber(totals.TotalServicesTaxed.toFixed(2)),
    });
    reportData.push({}, header2, {
      col1: `${formatNumber(getIVA(merch, "3", false).toFixed(2))}`,
      col2: formatNumber(getIVA(merch, "3", true).toFixed(2)),
      col3: `${formatNumber(getIVA(merch, "4", false).toFixed(2))}`,
      col4: formatNumber(getIVA(merch, "4", true).toFixed(2)),
      col5: `${formatNumber(getIVA(merch, "5", false).toFixed(2))}`,
      col6: formatNumber(getIVA(merch, "5", true).toFixed(2)),
      col7: `${formatNumber(getIVA(merch, "8", false).toFixed(2))}`,
      col8: formatNumber(getIVA(merch, "8", true).toFixed(2)),
      col9: `${formatNumber(getIVA(merch, "1", false).toFixed(2))}`,
      col10: formatNumber(getIVA(merch, "1", true).toFixed(2)),
      col11: formatNumber(totals.TotalMerchExempt.toFixed(2)),
      col14: formatNumber(totals.TotalMerchTaxed.toFixed(2)),
    });
    reportData.push({}, header3, {
      col1: `${formatNumber(getIVA(totals?.Taxes, "3", false).toFixed(2))}`,
      col2: formatNumber(getIVA(totals?.Taxes, "3", true).toFixed(2)),
      col3: `${formatNumber(getIVA(totals?.Taxes, "4", false).toFixed(2))}`,
      col4: formatNumber(getIVA(totals?.Taxes, "4", true).toFixed(2)),
      col5: `${formatNumber(getIVA(totals?.Taxes, "5", false).toFixed(2))}`,
      col6: formatNumber(getIVA(totals?.Taxes, "5", true).toFixed(2)),
      col7: `${formatNumber(getIVA(totals?.Taxes, "8", false).toFixed(2))}`,
      col8: formatNumber(getIVA(totals?.Taxes, "8", true).toFixed(2)),
      col9: `${formatNumber(getIVA(totals?.Taxes, "1", false).toFixed(2))}`,
      col10: formatNumber(getIVA(totals?.Taxes, "1", true).toFixed(2)),
      col11: formatNumber(totals.TotalExempt.toFixed(2)),
      col12: formatNumber(totals.TotalTaxes.toFixed(2)),
      col13: formatNumber(totals.TotalNetSell.toFixed(2)),
      col14: formatNumber(totals.TotalGrav.toFixed(2)),
    });
  }
  setDataCSV(reportData);
};

export const getDateReport = date => {
  const dates = { newdate: null, year: null, month: null, day: null };
  if (date !== null && date !== undefined) {
    dates.newdate = new Date(date);
    dates.year = dates.newdate.getFullYear();
    dates.month = (1 + dates.newdate.getMonth()).toString().padStart(2, "0");
    dates.day = dates.newdate.getDate().toString().padStart(2, "0");
  } else {
    return "Fecha no disponible";
  }
  return `${dates.day}-${dates.month}-${dates.year}`;
};

export const summation = (accumulator, curr) => accumulator + curr;
export const responses = [
  { name: "Accepted", value: "Aceptado" },
  { name: "Rejected", value: "Rechazado" },
  { name: "Partially accepted", value: "Aceptado parcialmente" },
];

export const getSummaryState = () => ({
  totalIva: 0,
  totalIvaOther: 0,
  subTotal: 0,
  total: 0,
  allSubTotal: 0,
  allIva: 0,
  allTotal: 0,
  taxes: [
    { name: "iva0", value: 0 },
    { name: "iva1", value: 0 },
    { name: "iva2", value: 0 },
    { name: "iva4", value: 0 },
    { name: "iva8", value: 0 },
    { name: "iva13", value: 0 },
    { name: "ivaOther", value: 0 },
  ],
});

export const getSummaryData = (
  summary,
  bill,
  totalSummaryBills,
  getSellCondition,

  getCoins,
  useCoinDetailsHooks,
) => {
  const { currentCoin, currentSymbolCoin, toExchangeRate } =
    useCoinDetailsHooks;
  if (!bill.FreeShipping) {
    summary.taxes[5].value += bill.TotalDelivery || 0;
  }
  summary.subTotal = summary.taxes.map(x => x.value).reduce(summation);
  if (!bill.FreeShipping) {
    summary.subTotal += bill.TotalDelivery || 0;
  }

  summary.taxes.forEach(index => {
    index.value = toExchangeRate(index.value);
  });
  summary.subTotal = toExchangeRate(summary.subTotal);

  summary.totalIva =
    summary.taxes[1].value * 0.01 +
    summary.taxes[2].value * 0.02 +
    summary.taxes[3].value * 0.04 +
    summary.taxes[4].value * 0.08 +
    summary.taxes[5].value * 0.13 +
    summary.totalIvaOther;

  summary.total = summary.subTotal + summary.totalIva;
  totalSummaryBills.allIva += summary.totalIva;
  totalSummaryBills.allSubTotal += summary.subTotal;
  totalSummaryBills.allTotal += summary.total;

  const sellCondition = getSellCondition?.sellConditions.find(
    sell => sell.id === bill.FK_SellCondition,
  )?.Name_SellCondition;
  return {
    date: getDateReport(bill.OrderDate_Bill),
    electronicBillId: `#${bill.EB.Key}`,
    state: responses.find(x => x.name === bill.EB.Response)?.value,
    condition: sellCondition,
    coin: `${
      getCoins?.coins?.filter(index => index.id === currentCoin)[0].Name_Coin
    }(${currentSymbolCoin}) `,
    totalBeforetax: summary.subTotal.toFixed(4),
    exempt: summary.taxes[0].value.toFixed(4),
    iva1: summary.taxes[1].value.toFixed(4),
    iva2: summary.taxes[2].value.toFixed(4),
    iva4: summary.taxes[3].value.toFixed(4),
    iva8: summary.taxes[4].value.toFixed(4),
    iva13: summary.taxes[5].value.toFixed(4),
    anotherTax: summary.taxes[6].value.toFixed(4),
    totalIva1: (summary.taxes[1].value * 0.01).toFixed(4),
    totalIva2: (summary.taxes[2].value * 0.02).toFixed(4),
    totalIva4: (summary.taxes[3].value * 0.04).toFixed(4),
    totalIva8: (summary.taxes[4].value * 0.08).toFixed(4),
    totalIva13: (summary.taxes[5].value * 0.13).toFixed(4),
    totalIvaOthers: summary.taxes[6].value.toFixed(4),
    total: summary.total.toFixed(4),
  };
};
export const generateElectronicBillsCSVData = electronicBills => {
  const electronicBillHeaders = [
    { label: "ID Empresa", key: "FK_Place" },
    { label: "Tipo Documento Recibido", key: "receivedDocumentType" },
    { label: "Consecutivo de Aceptación", key: "acceptanceConsecutive" },
    { label: "Fecha Emisión Aceptación", key: "acceptanceEmmitedDate" },
    { label: "Consecutivo Documento", key: "internConsecutive" },
    { label: "Identificación Emisor", key: "issuerLegalId" },
    { label: "Nombre Emisor", key: "issuerName" },
    { label: "Actividad Económica Emisor", key: "issuerEconomicActivity" },
    { label: "Fecha Emisión Documento", key: "documentEmittedDate" },
    { label: "Detalles", key: "details" },
    { label: "Estado Hacienda", key: "haciendaState" },
    { label: "Mensaje Hacienda", key: "haciendaMessage" },
    { label: "Total Impuesto Acreditar", key: "totalTaxCredit" },
    { label: "Total Gravado", key: "totalTaxed" },
    { label: "Total Exento", key: "totalExempt" },
    { label: "Total Exonerado", key: "totalExonerated" },
    { label: "Total Descuento", key: "totalDiscount" },
    { label: "Total Neto", key: "totalNet" },
    { label: "Total IVA", key: "totalIVA" },
    { label: "Código Moneda", key: "coinCode" },
    { label: "Tipo Cambio", key: "exchangeRate" },
    { label: "Total", key: "totalBill" },
  ];

  const electronicBillData =
    electronicBills.length > 0 &&
    electronicBills.map(electronicBill => {
      const [acceptanceDateFormatedDate, acceptanceDateFormatedTime] =
        formatDateString(
          electronicBill?.AcceptanceDate ?? electronicBill?.EmitedDay,
        );

      const [emitedDayFormatedDate, emitedDayFormatedTime] = formatDateString(
        electronicBill?.EmitedDay,
      );

      return {
        FK_Place: electronicBill?.Place?.id,
        receivedDocumentType:
          electronicBill?.ElectronicBillDocumentType?.Description,
        acceptanceConsecutive: `#${electronicBill?.InternConsecutive}`,
        acceptanceEmmitedDate: `${acceptanceDateFormatedDate} ${acceptanceDateFormatedTime}`,
        internConsecutive: `#${electronicBill?.InternConsecutive}`,
        issuerLegalId:
          electronicBill?.Place?.Company?.User?.Person?.DocumentId_Person,
        issuerName: electronicBill?.Place?.Company?.User?.Person?.Name_Person,
        issuerEconomicActivity: "0000-0000-0000",
        documentEmittedDate: `${emitedDayFormatedDate} ${emitedDayFormatedTime}`,
        details:
          electronicBill?.ElectronicBillDetail.length > 0
            ? electronicBill?.ElectronicBillDetail.map(
                electronicBillDetail =>
                  `Product Id: ${electronicBillDetail?.ProductFeature?.Product?.id}; Product Name: ${electronicBillDetail?.ProductFeature?.Product?.Name_Product}; Product Price: ${electronicBillDetail?.Price}`,
              )
                .toString()
                .replaceAll(`"`, `'`)
            : "",
        haciendaState: "**Estado de Hacienda**",
        haciendaMessage: "**Mensaje de Hacienda**",
        totalTaxCredit: "",
        totalTaxed: electronicBill?.TotalTaxed,
        totalExempt: electronicBill?.TotalExempt,
        totalExonerated: electronicBill?.TotalExonerated,
        totalDiscount: electronicBill?.TotalDiscount,
        totalNet: electronicBill?.TotalNetSell,
        totalIVA: electronicBill?.TotalTaxes,
        coinCode: electronicBill?.Coin.Code_Coin,
        exchangeRate: electronicBill?.Exchange,
        totalBill: electronicBill?.TotalBill,
      };
    });

  return {
    headers: electronicBillHeaders || [],
    data: electronicBillData || [],
  };
};
