import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
// External components
import { Box, Button, CardContent, Card, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// GraphQL
import { useApolloClient } from "@apollo/client";
import { client_EB_api } from "../../graphql/client";
import {
  GET_MANY_PDFS,
  GET_EB_BY_ID,
  RESEND_EMAIL,
  GET_CREDIT_NOTES,
} from "../../graphql";
// Components
import { Table } from "../../components/DataTable/components";
import { CustomLoading, PersonSection, SendBillScreen } from "../../components";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useModal } from "../../context";
// Enums
import DEFAULT_COLUMNS from "./Enums/Columns";
import { ElectronicBillErrors } from "../../Enums/Errors";
import {
  BILL_TYPES_URL_PARAMS,
  ELECTRONIC_BILLS_DOCUMENT_TYPES,
} from "../../Enums/ElectronicBill";
// Utils
import { downloadBase64File } from "../../utils/helpers";
// SCSS
import "./EBViewPage.scss";
import { normalizeEBillDetails } from "./EBviewHelper";
import { customToast } from "../../utils";

function EBViewPage() {
  const [electronicBillForReview, setElectronicBillForReview] = useState();
  const { id, viewType, billType } = useParams();
  const {
    useCoinDetail,
    LineDetailPlace,
    resetElectronicBill,
    resetExportationBasicInfo,
  } = useElectronic();
  const { getElectronicBillForReview, formatBillDetailToTable } =
    useElectronicBill();
  const navigate = useNavigate();
  const { toExchangeRate, currentSymbolCoin, resetDefaultCoin } = useCoinDetail;
  const { setModalOpen } = useModal();

  const checkBillType = useCallback(
    billTypeEnumType => billType === billTypeEnumType,
    [billType],
  );
  // const isDebitNote = () => checkBillType(BILL_TYPES_URL_PARAMS.DEBIT_NOTE);
  const isCreditNote = () => checkBillType(BILL_TYPES_URL_PARAMS.CREDIT_NOTE);
  const isBuyElectronicBill = () =>
    checkBillType(BILL_TYPES_URL_PARAMS.BUY_BILL);
  const isExportationElectronicBill = () =>
    checkBillType(BILL_TYPES_URL_PARAMS.EXPORTATION_BILL);
  const getCreditNote = async bill_id => {
    const resp = await client_EB_api.query({
      query: GET_CREDIT_NOTES,
      variables: {
        whereParams: {
          comparisons: [["CreditNote_view.id", "=", bill_id]],
        },
      },
    });

    if (!resp?.data?.getCreditNotesNew) {
      return null;
    }
    const temp = {
      ...resp.data.getCreditNotesNew[0],
      details: resp.data.getCreditNotesNew[0].CreditNoteDetails,
    };
    delete temp.CreditNoteDetails;
    return temp;
  };

  const selectGetBillFunction = useCallback(() => {
    if (checkBillType(BILL_TYPES_URL_PARAMS.DEBIT_NOTE)) {
      return async e =>
        normalizeEBillDetails(await getElectronicBillForReview(e));
    }
    if (checkBillType(BILL_TYPES_URL_PARAMS.CREDIT_NOTE)) {
      return async e => getCreditNote(e);
    }
    if (checkBillType(BILL_TYPES_URL_PARAMS.BUY_BILL)) {
      return async e =>
        normalizeEBillDetails(await getElectronicBillForReview(e));
    }
    return async e =>
      normalizeEBillDetails(await getElectronicBillForReview(e));
  }, [checkBillType, getElectronicBillForReview]);

  const fetchElectronicBillForReview = useCallback(async () => {
    if (!id) return;
    const getEB = selectGetBillFunction();
    const resp = await getEB(id);
    if (!resp) return;
    setElectronicBillForReview(resp);
  }, [id, selectGetBillFunction]);

  useEffect(() => {
    if (electronicBillForReview) return;
    fetchElectronicBillForReview();
  }, [electronicBillForReview, fetchElectronicBillForReview]);
  const client = useApolloClient();

  const getElectronicBillById = async idEB => {
    const electronicBill = await client.query({
      query: GET_EB_BY_ID,
      variables: {
        id: idEB,
      },
      fetchPolicy: "no-cache",
    });
    return electronicBill;
  };

  const handleSendEmails = async receivers => {
    let idElectronicBill = 0;
    const features = [];

    const electronicBill = await getElectronicBillById(id);

    let creditNote = null;
    idElectronicBill = electronicBill?.data?.EBill?.Bill?.id;

    if (
      electronicBill?.data?.EBill?.ElectronicBillDocumentType?.id ===
      `${ELECTRONIC_BILLS_DOCUMENT_TYPES.CREDIT_NOTE}`
    ) {
      creditNote = await getElectronicBillById(
        electronicBill?.data?.EBill?.ReferencedDocument?.id,
      );
      idElectronicBill = creditNote?.data?.EBill?.Bill?.id;
      electronicBill?.data?.EBill?.ElectronicBillDetail.map(detail =>
        creditNote?.data?.EBill?.ElectronicBillDetail.map(
          creditDetail =>
            detail.ProductFeature.id === creditDetail.ProductFeature.id &&
            features.push(detail.ProductFeature.id),
        ),
      );
    }

    await client_EB_api.mutate({
      mutation: RESEND_EMAIL,
      variables: {
        id: idElectronicBill,
        idElectronicBillFeatures: features,
        to: receivers,
      },
    });
    customToast.success("Email enviado con éxito!");
  };

  const openSendEmailModal = () => {
    setModalOpen(
      true,
      <SendBillScreen
        receptorRequired
        billingEmail={receptor?.Email}
        handleCloseModal={() => setModalOpen(false)}
        handleSend={handleSendEmails}
        place={LineDetailPlace}
      />,
    );
  };

  const receptor =
    electronicBillForReview?.Bill?.User?.UserBillingProfile ||
    electronicBillForReview?.ReferencedDocument?.Bill?.User?.UserBillingProfile;

  const handleDownload = async () => {
    const { data } = await client_EB_api.query({
      query: GET_MANY_PDFS,
      variables: {
        ids: electronicBillForReview?.id,
        place_id: LineDetailPlace?.id,
      },
    });
    if (data.downloadManyPDF[0].payload) {
      const { payload, params, type } = data.downloadManyPDF[0];
      downloadBase64File(payload, `${params[0]}.${type}`);
      return;
    }
    if (!data.downloadManyPDF[0].error) {
      return;
    }
    const { error } = data.downloadManyPDF[0];
    if (error === ElectronicBillErrors.NOT_FOUND) {
      customToast.error("El archivo no se encontró");
      return;
    }
    customToast.error("Error desconocido");
  };

  const handleNewElectronicBill = () => {
    resetElectronicBill();
    resetDefaultCoin();
    navigate("/electronic-bills");
  };

  const handleNewBuyElectronicBill = () => {
    resetElectronicBill();
    resetDefaultCoin();
    navigate("/buy-electronic-bills");
  };

  const handleNewExportationElectronicBill = () => {
    resetElectronicBill();
    resetDefaultCoin();
    resetExportationBasicInfo();
    navigate("/exportation-bill");
  };

  const productsFormattedToTable = electronicBillForReview?.details?.map(
    (productFormatted, index) =>
      formatBillDetailToTable(
        {
          ...productFormatted,
          index: index + 1,
        },
        currentSymbolCoin,
      ),
  );
  const totalAmounts = useMemo(() => {
    const amounts = checkBillType(BILL_TYPES_URL_PARAMS.CREDIT_NOTE)
      ? electronicBillForReview
      : electronicBillForReview?.Bill;

    return {
      ...amounts,
      TotalExempt:
        (amounts?.TotalGoodsExempt || 0) + (amounts?.TotalServicesExempt || 0),
      TotalExonerated:
        (amounts?.TotalGoodsExonerated || 0) +
        (amounts?.TotalServicesExonerated || 0),
      TotalTaxed: amounts?.TotalTaxed,
      TotalNetSell: (amounts?.SubTotal_Bill || 0) - (amounts?.Discount || 0),
      TotalSell: amounts?.TotalBill,
    };
  }, [checkBillType, electronicBillForReview]);

  return electronicBillForReview ? (
    <Box
      sx={{
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
      }}
    >
      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
        variant="outlined"
      >
        <Typography fontWeight="bold" typography="modeColor" variant="h6">
          Resumen de factura electrónica
        </Typography>
        <CardContent
          sx={{
            padding: 0,
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <CardContent
            sx={{
              padding: 0,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div className="content-flex-row-start">
              <strong>Clave:</strong>
              <p>{`  ${electronicBillForReview.Key}`}</p>
            </div>
            <div className="content-flex-row-start">
              <strong>Consecutivo:</strong>
              <p>{`  ${electronicBillForReview?.InternConsecutive || "--"}`}</p>
            </div>
            <div className="content-flex-row-start">
              <strong>Condición de venta:</strong>
              <p>{`${
                electronicBillForReview?.SellConditionMH?.Name_SellCondition ||
                "--"
              }`}</p>
            </div>
            <div className="content-flex-row-start">
              <strong>Tipo de documento:</strong>
              <p>
                {electronicBillForReview.ElectronicBillDocumentType
                  ?.Description || ""}
              </p>
            </div>
            <div className="content-flex-row-start">
              <p>
                <strong>Moneda:</strong>
                {electronicBillForReview.Coin.Name_Coin}
              </p>
            </div>
          </CardContent>
          <CardContent
            sx={{
              padding: 0,
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <div className="content-flex-row-start">
              <strong>Plazo crédito: --- </strong>
              <p>
                Fecha{!electronicBillForReview.EmitedDay && " (Tentativa)"}:{" "}
                {electronicBillForReview.EmitedDay
                  ? new Date(
                      electronicBillForReview.EmitedDay,
                    ).toLocaleDateString()
                  : new Date().toLocaleDateString().concat(" Verificando...")}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Medio de pago:</strong>
              <p>
                {` ${electronicBillForReview.PaymentMethodMH?.Name_PaymentMethod}`}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Tipo de cambio:</strong>
              <p>{(1 / electronicBillForReview.Coin.Buy_Coin).toFixed(2)}</p>
            </div>
          </CardContent>
        </CardContent>
      </Card>

      <div className="content-grid-column">
        <Card
          color="primary"
          sx={{
            paddingY: "1rem",
            paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Typography fontWeight="bold" typography="modeColor" variant="h6">
            Datos emisor
          </Typography>
          <PersonSection
            person={
              electronicBillForReview?.Place.Company.User.UserBillingProfile
            }
          />
        </Card>

        {receptor && (
          <Card
            color="primary"
            sx={{
              paddingY: "1rem",
              paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            <Typography fontWeight="bold" typography="modeColor" variant="h6">
              Datos Receptor
            </Typography>
            <PersonSection
              isExportationBill={isExportationElectronicBill()}
              person={receptor}
            />
          </Card>
        )}
      </div>

      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontWeight="bold" typography="modeColor" variant="h6">
          Detalles
        </Typography>
        <Table columns={DEFAULT_COLUMNS} data={productsFormattedToTable} />
      </Card>

      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography fontWeight="bold" typography="modeColor" variant="h6">
          Totales
        </Typography>

        <div className="content-grid-column">
          <CardContent
            sx={{
              padding: 0,
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="content-flex-row-start">
              <strong>Descuento total: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.Discount || 0).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total exento: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalExempt || 0).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total exonerado: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalExonerated || 0).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total exento en productos: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalGoodsExempt || 0).toFixed(
                    2,
                  )}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total exonerado en productos: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(
                    totalAmounts?.TotalGoodsExonerated || 0,
                  ).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total de productos con impuesto: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalGoodsTaxed || 0).toFixed(2)}
              </p>
            </div>
          </CardContent>
          <CardContent
            sx={{
              padding: 0,
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div className="content-flex-row-start">
              <strong>Total de la venta: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalSell || 0).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total de servicios exempto: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(
                    totalAmounts?.TotalServicesExempt || 0,
                  ).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total de servicio exonerado: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(
                    totalAmounts?.TotalServicesExonerated || 0,
                  ).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total impuestos de servicio: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalServicesTaxed || 0).toFixed(
                    2,
                  )}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total de impuestos: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalTaxed || 0).toFixed(2)}
              </p>
            </div>
            <div className="content-flex-row-start">
              <strong>Total de venta neta: </strong>
              <p>
                {currentSymbolCoin +
                  toExchangeRate(totalAmounts?.TotalNetSell || 0).toFixed(2)}
              </p>
            </div>
          </CardContent>
        </div>
      </Card>

      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          justifyContent: "end",
        }}
      >
        <strong> Total de la factura: </strong>
        <p>
          {currentSymbolCoin +
            toExchangeRate(
              electronicBillForReview?.TotalBill || totalAmounts?.TotalBill,
            ).toFixed(2) || 0}
        </p>
      </Card>

      <div className="content-flex-end">
        <Button
          color="primary"
          size="large"
          variant="outlined"
          onClick={() => handleDownload(electronicBillForReview)}
        >
          Descargar factura
        </Button>
        {isCreditNote() && (
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => navigate(`/electronic-bills/${id}/DebitNote`)}
          >
            Crear nota de débito
          </Button>
        )}
        {viewType === "created" && isExportationElectronicBill && (
          <Button
            className="bill__print-button"
            color="secondary"
            onClick={handleNewExportationElectronicBill}
          >
            Realizar otra factura
          </Button>
        )}
        {viewType === "created" && isBuyElectronicBill() && (
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() => handleNewBuyElectronicBill()}
          >
            Realizar otra factura
          </Button>
        )}
        {viewType === "created" &&
          !isCreditNote() &&
          !isBuyElectronicBill() &&
          !isExportationElectronicBill() && (
            <Button
              color="primary"
              size="large"
              variant="contained"
              onClick={() => handleNewElectronicBill()}
            >
              Realizar otra factura
            </Button>
          )}
        {id && viewType === "view" && (
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={() =>
              openSendEmailModal(
                id,
                setModalOpen,
                receptor?.Email,
                LineDetailPlace,
                getElectronicBillById,
              )
            }
          >
            <FontAwesomeIcon icon="envelope" />
            Enviar correo
          </Button>
        )}
      </div>
    </Box>
  ) : (
    <CustomLoading
      centered
      vCentered
      errorTexts={["Lo sentimos", "Ha ocurrido un error validando los datos"]}
      loadingState={!electronicBillForReview ? "loading" : "warning"}
      loadingTexts={["Recuperando información", "Por favor, espera..."]}
      warningTexts={["Algo inesperado ha ocurrido"]}
    />
  );
}

EBViewPage.propTypes = {};

EBViewPage.defaultProps = {};
export default EBViewPage;
