/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_GROUPS_BY_PLACE = gql`
  query getGroupsByPlace($FK_Place: ID!) {
    groups: getGroupByIdPlace(FK_Place: $FK_Place) {
      id
      Name_ClientGroup
      Description_ClientGroup
      Place {
        id
        Name_Place
      }
    }
  }
`;
