import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  Routes,
  Route,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
// External components
import { Box, Tabs, Tab } from "@mui/material";
// Components
import { toast } from "react-toastify";
import ExpensesManagement from "../ExpensesManagement";
import DocumentsAcceptance from "../DocumentsAcceptance";
import ExpensesUpload from "../ExpensesUpload";
// Context
import { useAuth } from "../../context";
// SCSS
import "../../pages/IncomePage/IncomePage.scss";
import "../../styles/index.scss";
import "../../pages/ProfilePage/ProfilePage.scss";
import { useElectronic } from "../../context/ElectronicContext";
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import arrowRight from "../../assets/icons/arrowRight.svg";

function MenuExpenses() {
  const [toggleState, setToggleState] = useState(1);
  const { LineDetailPlace } = useElectronic();
  const location = useLocation();
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const canRead = haveActionPermission("Read", "/expenses", LineDetailPlace);
  const canCreate = haveActionPermission(
    "Create",
    "/expenses",
    LineDetailPlace,
  );
  const tabsRef = useRef(null);
  const [viewButton, setViewButton] = useState();
  const [dimensions, setDimensions] = useState({
    scrollWidth: 0,
    clientWidth: 0,
  });

  const scroll = direction => {
    if (direction === "left") setViewButton(2);
    if (direction === "right") setViewButton(3);
  };

  const checkOverflow = () => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth } = tabsRef.current;
      setDimensions({ scrollWidth, clientWidth });
    }
  };

  useEffect(() => {
    if (!canRead) {
      toast.warning(noAccessMessage("Leer", "Egresos"));
      navigate(`/`);
    }
    navigate(`/expenses/upload-expenses`);
    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const routes = [
      { route: "/expenses/upload-expenses", value: 1 },
      { route: "/expenses/documents-acceptance", value: 2 },
      { route: "/expenses/documents-management", value: 3 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 1,
    );
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (dimensions.scrollWidth < 495) {
      setViewButton(2);
    } else {
      setViewButton(0);
    }
  }, [dimensions]);

  return (
    <React.Fragment>
      <div ref={tabsRef} className="content-flex-row-between">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "1rem",
            justifyContent: viewButton !== 2 ? "space-between" : "start",
          }}
        >
          <button
            style={{
              border: "none",
              background:
                "linear-gradient(to right, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",

              cursor: "pointer",
              display:
                dimensions.scrollWidth > 495 || viewButton === 2
                  ? "none"
                  : "flex",
              position: "absolute",
              transform: "translateX(-50%)",
              top: "105px",
              zIndex: "1",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
              right: "300px",
            }}
            type="button"
            onClick={() => scroll("left")}
          >
            <img
              alt="izquierda"
              src={arrowLeft}
              style={{ width: "18px", height: "18px" }}
            />
          </button>

          <Tabs
            sx={{
              "height": { xs: "35px", sm: "50px" },

              "& .MuiTabs-flexContainer": {
                gap: "1rem",
              },
              "margin": 0,
              "width": "100%",
            }}
          >
            {canCreate && (
              <Tab
                as={Link}
                className={`${toggleState === 1 ? "active" : "tabs"}`}
                color="option"
                label="Insertar egresos"
                sx={{
                  height: { xs: "35px", sm: "50px" },
                  paddingX: 1,

                  maxHeight: "auto",
                  minHeight: "auto",
                  display:
                    viewButton !== 2 && dimensions.scrollWidth < 495
                      ? "none"
                      : "flex",
                  justifyContent: "center",
                  borderRadius: " 5px",
                }}
                to="/expenses/upload-expenses"
              />
            )}
            <Tab
              as={Link}
              className={`${toggleState === 2 ? "active" : "tabs"}`}
              color="option"
              label="Aceptación documentos"
              sx={{
                height: { xs: "35px", sm: "50px" },
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                borderRadius: " 5px",
                justifyContent: "center",
                display:
                  viewButton !== 2 && dimensions.scrollWidth < 495
                    ? "none"
                    : "flex",
              }}
              to="/expenses/documents-acceptance"
            />
            <Tab
              as={Link}
              className={`${toggleState === 3 ? "active" : "tabs"}`}
              color="option"
              label="Administración de egresos"
              sx={{
                height: { xs: "35px", sm: "50px" },
                padding: 1,
                maxHeight: "auto",
                minHeight: "auto",
                justifyContent: "center",
                display: "flex",
                borderRadius: " 5px",
              }}
              to="/expenses/documents-management"
            />
          </Tabs>
        </div>
        <button
          style={{
            border: "none",
            background:
              "linear-gradient(to left, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",
            cursor: "pointer",
            display:
              dimensions.scrollWidth > 495 || viewButton === 3
                ? "none"
                : "flex",
            position: "absolute",
            right: "5px",
            top: "105px",
            zIndex: "10",
            alignItems: "center",
            justifyContent: "center",
            padding: "15px",
            boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
          }}
          tabIndex={0}
          type="button"
          onClick={() => scroll("right")}
        >
          <img
            alt="derecha"
            src={arrowRight}
            style={{ width: "18px", height: "18px" }}
          />
        </button>
      </div>

      <Box className="content-flex-column">
        <Routes>
          <Route
            element={
              canCreate ? (
                <ExpensesUpload />
              ) : (
                <Navigate to="../documents-acceptance" />
              )
            }
            path="upload-expenses"
          />
          <Route
            element={<DocumentsAcceptance />}
            path="documents-acceptance"
          />
          <Route element={<ExpensesManagement />} path="documents-management" />
        </Routes>
      </Box>
    </React.Fragment>
  );
}

export default MenuExpenses;
