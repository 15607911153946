import React, { useCallback, useState } from "react";
import { useMutation } from "@apollo/client";
import { useParams, useNavigate } from "react-router-dom";
import { RegisterReceiver } from "../../components";
import {
  client,
  CREATE_NEW_CLIENT,
  GET_ALL_CLIENTS_BY_GROUP,
  REGISTER_NEW_CLIENTXGROUP,
  TAVUEL_USER_BY_BILLING_PROFILE,
} from "../../graphql";
import { useElectronic } from "../../context/ElectronicContext";
import { useAuth } from "../../context";
import { customToast } from "../../utils";

function ModifyClientPage() {
  const { clientId } = useParams();
  const { LineDetailPlace } = useElectronic();
  const [registerGroup] = useMutation(REGISTER_NEW_CLIENTXGROUP);
  const [registerClient] = useMutation(CREATE_NEW_CLIENT);
  const { state: authState } = useAuth();
  const navigate = useNavigate();

  const [registerReceiverByBillingProfile] = useMutation(
    TAVUEL_USER_BY_BILLING_PROFILE,
  );

  const addNewClient = useCallback(
    async profile => {
      try {
        const { data } = await registerReceiverByBillingProfile({
          variables: {
            FK_Place: LineDetailPlace?.id,
            profile,
          },
        });
        if (data) {
          const responseResterGroup = await registerGroup({
            variables: {
              data: {
                FK_User: data?.BillingProfile?.id,
                FK_ClientGroup: clientId,
              },
            },
            refetchQueries: [
              {
                query: GET_ALL_CLIENTS_BY_GROUP,
                variables: {
                  FK_ClientGroup: clientId,
                },
              },
            ],
          });
          const responseRegisterClient = await registerClient({
            variables: {
              data: {
                Created_By: authState.user.TavuelUser.id,
                FK_User: data?.BillingProfile?.id,
                FK_Place: LineDetailPlace?.id,
              },
            },
            refetchQueries: [
              {
                query: GET_ALL_CLIENTS_BY_GROUP,
                variables: {
                  FK_ClientGroup: clientId,
                },
              },
            ],
          });
          if (responseRegisterClient && responseResterGroup) {
            customToast.success("¡Cliente agregado al grupo con éxito!");
          }
          navigate(-1);
        }
      } catch (err) {
        customToast.error("No se pudo agregar el nuevo cliente.");
      }
    },
    [
      LineDetailPlace?.id,
      authState.user.TavuelUser.id,
      clientId,
      navigate,
      registerClient,
      registerGroup,
      registerReceiverByBillingProfile,
    ],
  );

  const [selectedClient, setselectedClient] = useState(
    JSON.parse(localStorage.getItem("clientSelected")),
  );

  const handleOnClosed = () => {
    localStorage.removeItem("clientSelected");
    navigate(-1);
  };

  const onClientModified = () => {
    client.refetchQueries({
      include: [GET_ALL_CLIENTS_BY_GROUP],
    });
  };

  return selectedClient && !clientId ? (
    <RegisterReceiver
      FK_User={selectedClient.BillingProfile.User.id}
      handleChangeAddress={() => null}
      handleOnClosed={handleOnClosed}
      initialReceiver={selectedClient.BillingProfile}
      setBillingProfile={value =>
        setselectedClient(prev => ({ ...prev, BillingProfile: value }))
      }
      title="Editar información de cliente"
      onFormSaved={onClientModified}
    />
  ) : (
    <RegisterReceiver
      newClient
      FK_User={0}
      handleChangeAddress={() => null}
      handleCreateNewClient={addNewClient}
      handleOnClosed={handleOnClosed}
      initialReceiver={null}
      setBillingProfile={null}
      title="Perfil de facturación"
    />
  );
}

export default ModifyClientPage;
