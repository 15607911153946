const formatCurrency = (symbolCoin, amount) => {
  if (amount) {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "CRC",
    })
      .format(amount)
      .replace("CRC", symbolCoin);
  }
  return "₡ 0.00";
};

export default formatCurrency;
