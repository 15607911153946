import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import InputForm from "../../InputForm/InputForm";
import styleSelectPhoneNumber from "./styles";

function PhoneInput({
  className,
  label,
  placeholder,
  onChange,
  disabled,
  countries,
  phone,
  code,
  codeKey,
  phoneKey,
}) {
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState(null);

  useEffect(() => {
    countries &&
      setCountryList(
        countries.map(({ Name_Country, PhoneCode_Country, ...rest }) => ({
          label: `+(${PhoneCode_Country}) ${Name_Country}`,
          value: PhoneCode_Country,
          PhoneCode_Country,
          Name_Country,
          ...rest,
        })),
      );
  }, [countries]);

  useEffect(() => {
    if (countryList && code) {
      setCountry(
        countryList.find(({ PhoneCode_Country }) => PhoneCode_Country === code),
      );
    }
  }, [countryList, setCountry, code]);

  const formatOptionLabel = (
    { value, label: xLabel, Iso_Country },
    { context },
  ) => (
    <div className="phone-input-icon">
      <img
        alt={country?.Name_Country}
        className="phone-input-flag"
        loading="lazy"
        src={`https://flagcdn.com/32x24/${Iso_Country.toLowerCase()}.png`}
      />
      {context === "value" ? `+${value}` : xLabel}
    </div>
  );

  const countrySelected = (c, { name }) => {
    setCountry(c);
    onChange(name, c.PhoneCode_Country);
  };

  return (
    <div className="input container-phone-number  without-padding">
      <Select
        className="first-element input principal-input without-padding withoutborder"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        formatOptionLabel={formatOptionLabel}
        name={codeKey}
        options={countryList}
        placeholder={placeholder}
        styles={styleSelectPhoneNumber}
        theme={theme => ({
          ...theme,
          background: "transparent",
          colors: {
            ...theme.colors,
            primary: "transparent",
            neutral20: "transparent",
            neutral30: "transparent",
          },
        })}
        value={country}
        onChange={countrySelected}
      />
      <div className="second-element">
        <InputForm
          classNameInput="withoutborder"
          color="primary"
          disabled={disabled}
          id="number"
          name={phoneKey}
          placeholder={placeholder ?? label}
          size="small"
          type="number"
          value={phone}
          onChange={e => onChange(e.target.name, e.target.value)}
        />
      </div>
    </div>
  );
}

PhoneInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  phone: PropTypes.string,
  code: PropTypes.string,
  codeKey: PropTypes.string,
  phoneKey: PropTypes.string,
  disabled: PropTypes.bool,
  countries: PropTypes.array,
};

PhoneInput.defaultProps = {
  onChange: e => e,
  label: "",
  className: "",
  placeholder: null,
  disabled: false,
  countries: [],
  code: "",
  phone: "",
  codeKey: "",
  phoneKey: "",
};

export default PhoneInput;
