import React from "react";
import PropTypes from "prop-types";
// External components
import { Select } from "rbx";
// SCSS
import "./MonthSelect.scss";

function MonthSelect({ months, value, name, onChange }) {
  const stateMonths = [
    { Id: 1, Mes: "Enero" },
    { Id: 2, Mes: "Febrero" },
    { Id: 3, Mes: "Marzo" },
    { Id: 4, Mes: "Abril" },
    { Id: 5, Mes: "Mayo" },
    { Id: 6, Mes: "Junio" },
    { Id: 7, Mes: "Julio" },
    { Id: 8, Mes: "Agosto" },
    { Id: 9, Mes: "Setiembre" },
    { Id: 10, Mes: "Octubre" },
    { Id: 11, Mes: "Noviembre" },
    { Id: 12, Mes: "Deciembre" },
  ];

  return (
    <Select.Container>
      <Select
        className="selector-month"
        name={name}
        value={value}
        onChange={e => onChange(e.target.name, e.target.value)}
      >
        {months > 0 &&
          stateMonths
            ?.filter(item => item.Id < months)
            .map(item => (
              <Select.Option key={item.Id} value={item.Id}>
                {item.Mes}
              </Select.Option>
            ))}
      </Select>
    </Select.Container>
  );
}

MonthSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  months: PropTypes.number.isRequired,
};

export default MonthSelect;
