import { gql } from "@apollo/client";

export const LOGIN_MUTATION = gql`
  mutation login($input: AuthInput!) {
    login(input: $input) {
      user {
        userid
        email
      }
      token
    }
  }
`;

export const FORGOT_PASSWORD_MUTATION = gql`
  mutation forgotPassword($email: String, $uid: ID!) {
    forgotPassword(email: $email, uid: $uid)
  }
`;

export const COMPLETE_PASSWORD_RESET_MUTATION = gql`
  mutation completePasswordReset($input: CompletePasswordInput!) {
    completePasswordReset(input: $input)
  }
`;

export const FIREBASE_AUTH = gql`
  mutation firebaseAuth($user: GoogleAuthInput) {
    firebaseUser: firebaseAuth(user: $user) {
      id
      UID
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
      Phone_Number
      TavuelUser {
        id
        BillingProfile
        UserBillingProfile {
          id
          Default
          Name
          IDForeign
          MH_PersonType {
            id
            Name_PersonType
            Code
          }
          MH_Province {
            id
            Name
            id_MH
          }
          MH_Canton {
            id
            Name
            id_MH
          }
          MH_District {
            id
            Name
            id_MH
          }
          MH_Neighborhood {
            id
            Name
            id_MH
          }
          OtherSigns
          OtherSignsForeign
          ID_Number
          ComercialName
          Email
          PhoneCodCountry
          PhoneNumber
        }
        DefaultCoin {
          id
          Name_Coin
          Symbol_Coin
          Sell_Coin
          Buy_Coin
          Code_Coin
        }
        Person {
          id
          Name_Person
          Lastname_Person
          DocumentId_Person
          BirthDate_Person
          Email
          Phone
          Country {
            id
            Name_Country
          }
          Contacts {
            id
            Data_Contact
            ContactType {
              id
            }
          }
        }
      }
    }
  }
`;

export const REGISTER_PERSON = gql`
  mutation registerPersonAdmin($user: GoogleAuthInput) {
    registeredPersonAdmin: registerPersonAdmin(user: $user) {
      id
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      UID
      Phone_Number
      Provider_Id
      TavuelUser {
        id
        NickName_User
        BillingProfile
        UserBillingProfile {
          id
          Default
          Name
          IDForeign
          MH_PersonType {
            id
            Name_PersonType
            Code
          }
          ID_Number
          ComercialName
          Email
          PhoneCodCountry
          PhoneNumber
        }
        Person {
          id
          Name_Person
          Lastname_Person
          Email
          Phone
          ProfileImage
          Country {
            id
            Name_Country
          }
          Contacts {
            id
            Data_Contact
            ContactType {
              id
            }
          }
        }
      }
    }
  }
`;

export const REGISTER_USER = gql`
  mutation registerUser($user: GoogleAuthInput, $Origin: String) {
    registeredUser: registerUser(user: $user, Origin: $Origin) {
      id
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;
export const REGISTER_USER_ADMIN = gql`
  mutation registerUserAdmin($user: GoogleAuthInput) {
    registeredUser: registerUserAdmin(user: $user) {
      id
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;
export const REGISTER_USER_AND_SEND_EMAIL = gql`
  mutation registerUserAndSendEmail($user: GoogleAuthInput, $FK_Role: ID) {
    registeredUser: registerUserAndSendEmail(user: $user, FK_Role: $FK_Role) {
      id
      Token
      Email
      Name
      Given_Name
      AcceptTerms
      ShowGuide
      Family_Name
      Picture
      Locale
      Verified_Email
      Refresh_Token
      Created_At
      Updated_At
    }
  }
`;

export const REGISTER_USER_TO_PLACE_WITH_EXISTS_ACCOUNT = gql`
  mutation registerUserToPlaceWithExistsAccount($FK_Role: ID, $FK_User: ID) {
    registerUserToPlaceWithExistsAccount(FK_Role: $FK_Role, FK_User: $FK_User)
  }
`;

export const UPDATE_USER_FULL_INFORMATION = gql`
  mutation updateUserFullInformation($user: GoogleAuthInput!) {
    updatedUser: updateUserFullInformation(user: $user) {
      UID
      id
      Name
      Given_Name
      Family_Name
      Email
      Phone_Number
      User {
        Person {
          BirthDate_Person
        }
      }
    }
  }
`;

export const UPDATE_USER_PROFILE_PICTURE = gql`
  mutation updateUserProfilePicture($Route_File: String, $FirebaseId: String) {
    updatedUserProfilePicture: updateUserProfilePicture(
      Route_File: $Route_File
      FirebaseId: $FirebaseId
    )
  }
`;
