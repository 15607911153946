import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import PropTypes from "prop-types";
// GraphQL
import { client_EB_api } from "../graphql/client";
import {
  CREATE_NOTIFICATION,
  GET_NOTIFICATIONS_BY_PLACE_AND_USER,
  SAVE_NOTIFICATION_USER,
} from "../graphql";
// Context
import { useElectronic } from "./ElectronicContext";
import { useAuth } from "./AuthContext";

export const NotificationsContext = createContext([]);

export function NotificationsProvider({ children }) {
  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { LineDetailPlace } = useElectronic();
  const { state: auth } = useAuth();

  const createNotification = useCallback(
    async (title, body, icon, clickAction) => {
      await client_EB_api.mutate({
        mutation: CREATE_NOTIFICATION,
        variables: {
          place: LineDetailPlace.id,
          data: JSON.stringify({ title, body, icon, clickAction }),
          user: auth.user.TavuelUser.id,
          module: 11,
        },
      });
    },
    [LineDetailPlace.id, auth.user.TavuelUser.id],
  );

  const saveNotificationsUser = useCallback(async notisUser => {
    try {
      notisUser.forEach(async (element, i) => {
        await client_EB_api.mutate({
          mutation: SAVE_NOTIFICATION_USER,
          variables: {
            ...notisUser[i],
          },
        });
      });
      return "Notifications save finished";
    } catch (error) {
      return null;
    }
  }, []);

  const refetchNotifications = useCallback(async () => {
    const { data } = await client_EB_api.query({
      query: GET_NOTIFICATIONS_BY_PLACE_AND_USER,
      variables: {
        place: LineDetailPlace.id,
        user: auth.user.TavuelUser.id,
        module: 11,
      },
      fetchPolicy: "no-cache",
    });
    return data.notifications;
  }, [LineDetailPlace.id, auth.user.TavuelUser.id]);

  const getNotificationsToOpen = useCallback(
    notis => notis.filter(noti => !noti.Notification_User[0].Readed_At),
    [],
  );

  const openNotifications = useCallback(
    async notis => {
      const notificationsUser = getNotificationsToOpen(notis).reduce(
        (acc, noti) => [
          ...acc,
          ...noti.Notification_User.map(notiUser => ({
            ...notiUser,
            Readed_At: new Date().toISOString().slice(0, 19).replace("T", " "),
          })),
        ],
        [],
      );
      const resp = await saveNotificationsUser(notificationsUser);
      return resp;
    },
    [getNotificationsToOpen, saveNotificationsUser],
  );

  const closedNotifications = useMemo(
    () =>
      notifications.filter(noti => !noti.Notification_User[0].Readed_At).length,
    [notifications],
  );

  const valueToReturn = useMemo(
    () => ({
      notifications,
      setNotifications,
      getNotificationsToOpen,
      refetchNotifications,
      createNotification,
      openNotifications,
      saveNotificationsUser,
      isLoading,
      setIsLoading,
      closedNotifications,
    }),
    [
      notifications,
      setNotifications,
      getNotificationsToOpen,
      refetchNotifications,
      createNotification,
      openNotifications,
      saveNotificationsUser,
      isLoading,
      setIsLoading,
      closedNotifications,
    ],
  );

  return (
    <NotificationsContext.Provider value={valueToReturn}>
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useNotifications = () => useContext(NotificationsContext);

export default NotificationsProvider;
