import React from "react";
import { Box, Card, Typography } from "@mui/material";
import PropTypes from "prop-types";
import "./InventoryFullPageCard.scss";

export default function InventoryFullPageCard({
  placeName,
  placeDescription,
  placeImage,
  placeCode,
}) {
  return placeCode ? (
    <Card
      color="primary"
      sx={{
        width: "20.813rem",
        height: "9.18rem",
        paddingY: "1rem",
        paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "start",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <img
          alt="Logo"
          src={placeImage}
          style={{ width: "45px", height: "45px" }}
        />
        <Typography noWrap fontWeight={600} typography="modeColor" variant="h6">
          {placeName}
        </Typography>
      </Box>
      <Box className="">
        <Typography noWrap typography="modeColor" variant="subtitle1">
          <b>Descripción: </b>
          {placeDescription}
        </Typography>
        <Typography noWrap typography="modeColor" variant="subtitle1">
          <b>Código: </b>
          {placeCode}
        </Typography>
      </Box>
    </Card>
  ) : (
    <Card
      color="primary"
      sx={{
        width: "20.813rem",
        height: "9.18rem",
        paddingY: "1rem",
        paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
        display: "flex",
        flexDirection: "row",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <img
        alt="Logo"
        src={placeImage}
        style={{ width: "45px", height: "45px" }}
      />
      <Box className="">
        <Typography
          noWrap
          fontWeight={500}
          sx={{ fontSize: 20, maxWidth: "100%" }}
          typography="modeColor"
        >
          {placeName}
        </Typography>
        <Typography noWrap sx={{ maxWidth: "100%" }} typography="modeColor">
          {placeDescription}
        </Typography>
      </Box>
    </Card>
  );
}

InventoryFullPageCard.propTypes = {
  placeName: PropTypes.string,
  placeDescription: PropTypes.string,
  placeImage: PropTypes.any,
  placeCode: PropTypes.string,
};

InventoryFullPageCard.defaultProps = {
  placeName: "",
  placeDescription: "",
  placeImage: undefined,
  placeCode: "",
};
