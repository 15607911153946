import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
// Components
import { Box, List, ListItem, Typography } from "@mui/material";
import ProfileBillWrapper from "../ProfileBillWrapper";
import { InventoryFullPageCard, InventoryModalCard } from "./components";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { validateRoute } from "../../utils/helpers";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// SCSS
import "../../styles/index.scss";
import "./Inventories.scss";
import SearchInput from "../Products/components/SearchInput";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};

function getSearchInputClass(isModalMode) {
  if (!isModalMode) return "searchInput";
  return `modal-search-input${localStorage.theme === "dark" ? "-dark" : ""}`;
}

function Inventories({
  isModalMode,
  setModalClose,
  onClickFunction,
  bill,
  color,
  title,
}) {
  const [billing, setBilling] = useState(INITIAL_STATE);
  const [searchQuery, setSearchQuery] = useState("");

  const { LineDetailPlace, setLineDetailPlace, setLineDetail, resetReceiver } =
    useElectronic();
  const { state: authState } = useAuth();
  const { setModalOpen } = useModal();
  const isSecurityResetUrl = window.location?.pathname.includes(
    "/security/resetPassword/",
  );

  const navigate = useNavigate();
  const user = authState.user.TavuelUser;

  const { GetPLacesByUserIdAndModuleId } = usePlaces();

  const [loading, dataPlaces, error] = GetPLacesByUserIdAndModuleId(user?.id);

  const setBillingProfile = BillingProfile =>
    setBilling(prv => ({ ...prv, BillingProfile }));

  useEffect(() => {
    if (
      authState.user &&
      authState.user?.TavuelUser?.BillingProfile === null &&
      !isSecurityResetUrl
    ) {
      createElectronicBillProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  const createElectronicBillProfile = () => {
    setModalOpen(
      true,
      <ProfileBillWrapper
        initialReceiver={billing?.BillingProfile}
        setBillingProfile={setBillingProfile}
        setModalOpen={setModalOpen}
      />,
    );
  };

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectPlace = id => {
    if (onClickFunction) return onClickFunction(id, bill?.id);
    return selectPlace(id);
  };

  const selectPlace = id => {
    let placeSelected;
    let isSamePlace = false;

    if (id !== LineDetailPlace.id) {
      placeSelected = dataPlaces?.places.find(placeItem => placeItem.id === id);
    } else {
      placeSelected = LineDetailPlace.id;
      isSamePlace = true;
    }

    if (placeSelected) {
      if (!isSamePlace) {
        setLineDetailPlace(placeSelected);
        setLineDetail([]);
        resetReceiver();
        setModalClose(false);
      }
      if (
        isStringIncludedIn(window.location?.pathname, [
          "/store",
          "/inventories",
        ])
      )
        navigate(-1);
    } else if (placeSelected.length > 0 && LineDetailPlace.id === 0) {
      setLineDetailPlace(placeSelected[0]);
      setLineDetail([]);
      resetReceiver();
    }
  };

  const isStringIncludedIn = (lineToSearchIn, linesToSearch) => {
    for (let i = 0; i < linesToSearch.length; i += 1) {
      if (lineToSearchIn.includes(linesToSearch[i])) return true;
    }
    return false;
  };

  const filterPlace = places =>
    places?.filter(inventory =>
      inventory.Name_Place.trim()
        .split(" ")
        .toLocaleString()
        .replaceAll(",", "")
        .toLocaleUpperCase()
        .includes(
          searchQuery
            .trim()
            .split(" ")
            .toLocaleString()
            .replaceAll(",", "")
            .toLocaleUpperCase(),
        ),
    );

  return (
    <Box
      className={`content-flex-column   ${isModalMode ? "modal-content-size" : ""}`}
    >
      <Typography
        align="center"
        fontWeight={600}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        {title}
      </Typography>

      <Box sx={{ maxWidth: "31rem" }}>
        <SearchInput
          className={getSearchInputClass(isModalMode)}
          label="Buscar..."
          value={searchQuery}
          onChange={({ target: { value } }) => setSearchQuery(value)}
        />
      </Box>

      {isModalMode && (
        <List
          sx={{
            padding: 0,
            overflowY: "auto",
            maxHeight: "calc(600px - (88px + 28px))",
          }}
        >
          {filterPlace(dataPlaces?.places)?.map(placeItem => (
            <ListItem
              sx={{ paddingX: 0, paddingTop: 2 }}
              onClick={() => handleSelectPlace(placeItem.id)}
            >
              <InventoryModalCard
                color={color}
                placeDescription={placeItem.Description_Place}
                placeImage={validateRoute(placeItem)}
                placeName={placeItem.Name_Place}
              />
            </ListItem>
          ))}
        </List>
      )}
      {!isModalMode && (
        <div className="content-flex-cards">
          {filterPlace(dataPlaces?.places)?.map(placeItem => (
            <Box
              key={placeItem.id}
              item
              onClick={() => handleSelectPlace(placeItem.id)}
            >
              <InventoryFullPageCard
                placeCode={placeItem.Code}
                placeDescription={placeItem.Description_Place}
                placeImage={validateRoute(placeItem)}
                placeName={placeItem.Name_Place}
              />
            </Box>
          ))}
        </div>
      )}
    </Box>
  );
}

Inventories.propTypes = {
  isModalMode: PropTypes.bool,
  setModalClose: PropTypes.func,
  onClickFunction: PropTypes.func,
  bill: PropTypes.object,
  color: PropTypes.string,
  title: PropTypes.string,
};

Inventories.defaultProps = {
  isModalMode: false,
  setModalClose: e => e,
  onClickFunction: null,
  bill: {},
  color: "primaryInventory",
  title: "",
};

export default Inventories;
