import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// External components
import { Typography, Box, CircularProgress } from "@mui/material";
// Util
import { EBTypeToTypesEnum } from "../../utils/electronicBill";
// components
import EBResumeCard from "../EBResumeCard";
// SCSS
import "./DocumentSearch.scss";

function DocumentSearch({ electronicBills, isLoading }) {
  const navigate = useNavigate();
  return (
    <Box>
      <div style={{ display: electronicBills ? "" : "none" }}>
        {electronicBills && electronicBills?.Ebills?.length === 0 && (
          <Typography
            align="left"
            typography="modeColor"
            variant="subtitle1"
            width="100%"
          >
            No se han encontrado registros
          </Typography>
        )}
        <Box className="content-flex-cards">
          {electronicBills?.Ebills?.map(item => (
            <EBResumeCard
              key={item.id}
              actions={[
                {
                  isIcon: false,
                  icon: "eye",
                  text: "ver documento",
                  onClick: () =>
                    navigate(
                      `/ElectronicBillView/${item.id}/view/${EBTypeToTypesEnum(
                        item.ElectronicBillDocumentType.Description,
                      )}`,
                    ),
                },
              ]}
              item={item}
            />
          ))}
        </Box>
      </div>
      {isLoading && (
        <CircularProgress disableShrink sx={{ position: "absolute" }} />
      )}
    </Box>
  );
}

DocumentSearch.propTypes = {
  electronicBills: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool.isRequired,
};

DocumentSearch.defaultProps = {
  electronicBills: null,
};

export default DocumentSearch;
