import PropTypes from "prop-types";
import { Card, CircularProgress, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./ScheduledBill.scss";
import { BILL_CONFIGURATION_FREQUENCY_TEXT } from "../../Enums/BillConfiguration";

function ScheduledBill({
  BillConfiguration,
  FailedBillsConfiguration,
  SuccessBillsConfiguration,
  CanceledBillsConfiguration,
  loading,
  pathname,
}) {
  const navigate = useNavigate();
  const formatDate = bill => {
    const date = new Date(bill);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  return loading === false ? (
    <div className="content-flex-cards">
      {pathname === "/ScheduledBill/pendings" &&
        BillConfiguration?.getPendingScheduledBills?.map(bill => (
          <Card
            color="primary"
            role="button"
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: "0.3rem",
              overflow: "visible",
              width: { xs: "100%", sm: "333px" },
            }}
            tabIndex={0}
            onClick={() => navigate(`/electronic-bills/${bill?.id}/Draft`)}
            onKeyDown={null}
          >
            <Typography typography="modeColor" variant="body1" width="100%">
              <b>Cliente: </b>
              {bill?.User?.Person?.Name_Person}
            </Typography>
            <Typography typography="modeColor" variant="body1" width="100%">
              <b>Fecha de envío: </b>
              {formatDate(bill?.BillConfiguration?.date)}
            </Typography>
            <Typography typography="modeColor" variant="body1" width="100%">
              <b>Periocidad: </b>
              {
                BILL_CONFIGURATION_FREQUENCY_TEXT[
                  bill?.BillConfiguration?.Frequency
                ]
              }
            </Typography>
          </Card>
        ))}

      {pathname === "/ScheduledBill/complete" &&
        SuccessBillsConfiguration?.getBillsConfigurationEvents?.map(
          successBillsConfiguration => (
            <Card
              key={successBillsConfiguration.id}
              color="primary"
              role="button"
              sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.3rem",
                overflow: "visible",
                width: { xs: "100%", sm: "333px" },
                height: "112px",
              }}
              tabIndex={0}
              onClick={() =>
                navigate(
                  `/electronic-bills/${successBillsConfiguration?.Bill?.id}/Draft`,
                )
              }
              onKeyDown={null}
            >
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Cliente: </b>
                {successBillsConfiguration?.Bill?.User?.Person?.Name_Person}
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Enviada: </b>
                {formatDate(successBillsConfiguration?.Execution_Time)}
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Periocidad: </b>
                {
                  BILL_CONFIGURATION_FREQUENCY_TEXT[
                    successBillsConfiguration?.BillConfiguration?.Frequency
                  ]
                }
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Tiempo de ejecución del proceso:</b>
                {successBillsConfiguration?.Time_Execute_Process}
              </Typography>
            </Card>
          ),
        )}

      {pathname === "/ScheduledBill/failed" &&
        FailedBillsConfiguration?.getBillsConfigurationEvents?.map(
          failedBillsConfiguration => (
            <Card
              key={failedBillsConfiguration.id}
              color="primary"
              role="button"
              sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.3rem",
                overflow: "visible",
                width: { xs: "100%", sm: "333px" },
                height: "112px",
              }}
              tabIndex={0}
              onClick={() =>
                navigate(
                  `/electronic-bills/${failedBillsConfiguration?.Bill?.id}/Draft`,
                )
              }
              onKeyDown={null}
            >
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Cliente: </b>
                {failedBillsConfiguration?.Bill?.User?.Person?.Name_Person}
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Fecha de envío fallido: </b>
                {formatDate(failedBillsConfiguration?.Execution_Time)}
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Periocidad: </b>
                {
                  BILL_CONFIGURATION_FREQUENCY_TEXT[
                    failedBillsConfiguration?.BillConfiguration?.Frequency
                  ]
                }
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Tiempo de ejecución del proceso: </b>
                {failedBillsConfiguration?.Time_Execute_Process}
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Mensaje de error: </b>
                {failedBillsConfiguration?.Error_Message}
              </Typography>
            </Card>
          ),
        )}

      {pathname === "/ScheduledBill/canceled" &&
        CanceledBillsConfiguration?.getCanceledScheduledBills?.map(
          canceledBillConfiguration => (
            <Card
              key={canceledBillConfiguration.id}
              color="primary"
              role="button"
              sx={{
                padding: "1rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: "0.3rem",
                overflow: "visible",
                width: { xs: "100%", sm: "333px" },
                height: "112px",
              }}
              tabIndex={0}
              onClick={() =>
                navigate(
                  `/electronic-bills/${canceledBillConfiguration?.Bill?.id}/Draft`,
                )
              }
              onKeyDown={null}
            >
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Cliente: </b>
                {canceledBillConfiguration?.Bill?.User?.Person?.Name_Person}
              </Typography>
              <Typography typography="modeColor" variant="body1" width="100%">
                <b>Fecha programada: </b>
                {formatDate(canceledBillConfiguration?.date)}
              </Typography>
            </Card>
          ),
        )}
    </div>
  ) : (
    <CircularProgress />
  );
}

ScheduledBill.propTypes = {
  BillConfiguration: PropTypes.object,
  FailedBillsConfiguration: PropTypes.object,
  CanceledBillsConfiguration: PropTypes.object,
  SuccessBillsConfiguration: PropTypes.object,
  loading: PropTypes.bool,
  pathname: PropTypes.string,
};
ScheduledBill.defaultProps = {
  BillConfiguration: {},
  CanceledBillsConfiguration: {},
  FailedBillsConfiguration: {},
  SuccessBillsConfiguration: {},
  loading: false,
  pathname: "",
};

export default ScheduledBill;
