import EMAIL_PROVIDERS from "../Enums/EmailToken";
import getAzureCode from "./AzureApi";
import getCodeGmail from "./GmailApi";
import getCodeZoho from "./ZohoApi";

export const emailLoginFunction = {
  [EMAIL_PROVIDERS.Gmail]: getCodeGmail,
  [EMAIL_PROVIDERS.Zoho]: getCodeZoho,
  [EMAIL_PROVIDERS.Outlook]: getAzureCode,
};

export const ZohoConnector = {
  email: "Zoho",
  type: EMAIL_PROVIDERS.Zoho,
};
export const EmailConnector = {
  email: "Gmail",
  type: EMAIL_PROVIDERS.Gmail,
};
export const OutlookConnector = {
  email: "Outlook",
  type: EMAIL_PROVIDERS.Outlook,
};
export const EmailConnectors = [
  OutlookConnector,
  EmailConnector,
  ZohoConnector,
];
