/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_COMPANIES = gql`
  query getCompiesByUser($id: ID!) {
    companies: getAllCompanyByUser(id: $id) {
      id
      Name_Company
      Description_Company
      Files {
        id
        Route_File
      }
    }
  }
`;
export const GET_COMPANY = gql`
  query getCompany($id: ID!) {
    getCompany(id: $id) {
      id
      Name_Company
      Description_Company
      Files {
        id
        Route_File
      }
    }
  }
`;
