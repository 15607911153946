/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_REFERENCE_TYPE = gql`
  query getReferenceCode($id: ID!) {
    referenceTypes: getReferenceCode(id: $id) {
      Document_Type
      id
    }
  }
`;
export const GET_ALL_REFERENCE_TYPE = gql`
  query getAllReferenceCode {
    allReferenceType: getAllReferenceCode {
      id
      Document_Type
      id_MH
    }
  }
`;
