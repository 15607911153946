import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { toast } from "react-toastify";
import { Box, Button, TablePagination, Typography } from "@mui/material";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_PRODUCTS_BY_PLACE_AND_CRITERIA } from "../../graphql";
// Components
import Inventories from "../Inventories";
import { CardProduct, NewOrEditProduct } from "./components";
import Loading from "../Loading";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./Products.scss";
import SearchInput from "./components/SearchInput";

function Products() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const { permissions } = useAuth();
  const navigate = useNavigate();

  const { haveActionPermission, noAccessMessage } = permissions;

  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  const canRead = haveActionPermission("Read", "/products", LineDetailPlace);
  const canCreate = haveActionPermission(
    "Create",
    "/products",
    LineDetailPlace,
  );
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Productos"));
    navigate("/");
  }

  const [
    getProductsByPlaceAndCriteria,
    { data: productsByPlaceAndCriteria, loading },
  ] = useLazyQuery(GET_PRODUCTS_BY_PLACE_AND_CRITERIA);

  useEffect(() => {
    getProductsByPlaceAndCriteria({
      variables: {
        id: LineDetailPlace.id,
        criteria: search,
      },
    });
  }, [LineDetailPlace.id, getProductsByPlaceAndCriteria, search]);

  const getDates = async value => {
    getProductsByPlaceAndCriteria({
      variables: {
        id: LineDetailPlace.id,
        criteria: value,
      },
    });
  };

  const handleChange = (name, value) => {
    setSearch(value);
    value !== "" && getDates(value);
  };

  const handleNewProduct = () => {
    setModalOpen(
      true,
      <NewOrEditProduct
        product={null}
        setCloseModal={setModalOpen}
        title="Nuevo Producto/Servicio"
      />,
    );
  };

  const refetch = {
    id: LineDetailPlace.id,
    criteria: search,
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const products = productsByPlaceAndCriteria?.products ?? [];

  const paginatedProducts = products.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage,
  );

  return (
    <div>
      {LineDetailPlace.id === 0 && <Inventories />}
      {LineDetailPlace.id !== 0 && (
        <div className="content-product">
          <div className="headerButton">
            <Box sx={{ maxWidth: "31rem" }}>
              <SearchInput
                className="searchInput"
                label="Buscar Producto/Servicio"
                value={search}
                onChange={({ target: { value, name } }) =>
                  handleChange(name, value)
                }
              />
            </Box>
            {canCreate && (
              <Button
                className="adjust-width-button"
                color="secondary"
                type="button"
                variant="contained"
                onClick={handleNewProduct}
              >
                Agregar
              </Button>
            )}
          </div>
          {search === "" && (
            <Typography
              align="left"
              typography="modeColor"
              variant="subtitle1"
              width="100%"
            >
              Resultado de búsqueda
            </Typography>
          )}
          {loading ? (
            <Loading />
          ) : (
            <Box
              sx={{
                display: "grid",
                gap: "1rem",
                gridTemplateColumns: {
                  xs: "repeat(1, 1fr)",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(3, 1fr)",
                  xl: "repeat(4, 1fr)",
                },
              }}
            >
              {search !== "" && !loading && products.length === 0 ? (
                <Typography
                  align="left"
                  typography="modeColor"
                  variant="subtitle1"
                  width="100%"
                >
                  No se encuentran productos!
                </Typography>
              ) : (
                paginatedProducts.map((element, index) => (
                  <CardProduct
                    key={element.id || index}
                    product={element}
                    refetch={refetch}
                  />
                ))
              )}
            </Box>
          )}
          {paginatedProducts.length > 0 && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              mt={2}
            >
              <TablePagination
                component="div"
                count={products.length}
                labelDisplayedRows={({ from, to, count }) =>
                  `${from}-${to} de ${count}`
                }
                labelRowsPerPage="Productos por página"
                page={page}
                rowsPerPage={rowsPerPage}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </div>
      )}
    </div>
  );
}

export default Products;
