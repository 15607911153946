import React from "react";
import PropTypes from "prop-types";
// External components
import { Button, Card, Image } from "rbx";
// Graphql
import { useMutation } from "@apollo/client";
import { REGISTER_NEW_PLACE_USER } from "../../graphql";
// Context
import { useAuth } from "../../context";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "../../styles/index.scss";
import "./UserToPlace.scss";
import "../Inventories/Inventories.scss";

function UserToPlace({ place, onClose }) {
  const { state: authState } = useAuth();

  // mutation
  const [registerPlaceUser] = useMutation(REGISTER_NEW_PLACE_USER);

  const validateRoute = placeItem => {
    const route = getUrl(placeItem);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  const getUrl = companyItem => {
    if (place) {
      const route = companyItem.Files?.find(item => item.Route_File !== null);
      return route?.Route_File || "";
    }
    return "";
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSend = async e => {
    e.preventDefault();
    const param = {
      FK_User: authState?.user?.TavuelUser?.id,
      FK_Admin: place?.Company?.User?.id,
      FK_Place: place?.id,
      FK_Role: null,
      Status: "P",
    };
    try {
      const { data } = await registerPlaceUser({
        variables: {
          place: param,
        },
      });

      if (data?.place) {
        toast.success("¡Solicitud enviada con éxito!");
        onClose();
      } else {
        toast.error("Ocurrió un error al enviar la solicitud");
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  return (
    <div>
      <h1>
        Ya existe un lugar registrado con ese nombre. ¿Desea unirse al lugar?
      </h1>
      <div key={place?.id} className=" principal-container place-card">
        <Card key={place?.id} className=" card-content">
          <div className="title-product ">
            <h2>
              {place?.Name_Place}, {place?.Company?.Name_Company}
            </h2>
          </div>

          <div className="flex">
            <div className="content-right">
              <div className="product-img ">
                <Image.Container size="32px sq.">
                  <Image src={validateRoute(place)} />
                </Image.Container>
              </div>
            </div>

            <div className="flex middle ">
              <p className="detail-product ellipsis three-lines">
                {place?.Description_Place}
              </p>
            </div>
          </div>
        </Card>
      </div>
      <div className="profile-page-button">
        <Button color="secondary" onClick={handleCancel}>
          Cancelar
        </Button>
        <Button color="primary" onClick={handleSend}>
          Enviar solicitud
        </Button>
      </div>
    </div>
  );
}
UserToPlace.propTypes = {
  place: PropTypes.object,
  onClose: PropTypes.func.isRequired,
};
UserToPlace.defaultProps = {
  place: {},
};
export default UserToPlace;
