import { gql } from "@apollo/client";

export const EDIT_PRODUCT_FEATURE = gql`
  mutation updateFeatureAndRelations(
    $Product_Price: FeaturePrice
    $InventoryDetail: InventoryDetailUpdate
    $Product: ProductUpdate
    $FeatureId: ID!
    $InventoryId: ID!
    $file: FileInput
    $FeatureCode: ProductFeatureCodeInput
  ) {
    productFeature: updateFeatureAndRelations(
      Product_Price: $Product_Price
      InventoryDetail: $InventoryDetail
      Product: $Product
      FeatureId: $FeatureId
      InventoryId: $InventoryId
      file: $file
      FeatureCode: $FeatureCode
    ) {
      id
    }
  }
`;

export const DELETE_PRODUCT_FEATURE = gql`
  mutation deleteFeatureAndRelations($id: ID!) {
    deleteFeatureAndRelations(id: $id)
  }
`;
