import React, { useState, useEffect } from "react";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useLocation,
} from "react-router-dom";
// External components
import { Tab, Container } from "rbx";
// Pages
import ManageRolesPage from "../ManageRolesPage";
import ManageUsersPage from "../ManageUsersPage";

function ControlUserRolePage() {
  const [toggleState, setToggleState] = useState(1);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const routes = [
      { route: "/control-user-role/manager-roles", value: 1 },
      { route: "/control-user-role/manager-users", value: 2 },
    ];
    setToggleState(
      routes.find(index => index.route === location.pathname)?.value || 0,
    );
    if (location.pathname === "/control-user-role") {
      navigate("/control-user-role/manager-roles");
    }
  }, [navigate, location.pathname]);

  return (
    <React.Fragment>
      <div className="mb-5">
        <Tab.Group>
          <Tab
            as={Link}
            className={toggleState === 1 ? "active" : "tabs"}
            to="/control-user-role/manager-roles"
          >
            Administración de roles
          </Tab>
          <Tab
            as={Link}
            className={toggleState === 2 ? "active" : "tabs"}
            to="/control-user-role/manager-users"
          >
            Administración de usuarios
          </Tab>
        </Tab.Group>
      </div>
      <Container fluid>
        <Routes>
          <Route
            element={<ManageRolesPage />}
            path="/control-user-role/manager-roles"
          />
          <Route
            element={<ManageUsersPage />}
            path="/control-user-role/manager-users"
          />
        </Routes>
      </Container>
    </React.Fragment>
  );
}

export default ControlUserRolePage;
