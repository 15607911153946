import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control, Label } from "rbx";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_GROUPS_BY_PLACE } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";

function ClientGroupSelect({
  value,
  name,
  onChange,
  label,
  disabled,
  className,
  FK_Place,
}) {
  const { data: dataClientsGroups } = useQuery(GET_ALL_GROUPS_BY_PLACE, {
    variables: {
      FK_Place,
    },
  });

  return (
    <div>
      {dataClientsGroups?.groups?.length > 0 && (
        <Control className={className}>
          <Label>{label}</Label>
          <Select.Container fullwidth>
            <Select
              className="selector "
              disabled={disabled}
              name={name}
              value={value}
              onChange={e => onChange(e.target.name, e.target.value)}
            >
              <Select.Option key={0} value={0}>
                Sin seleccionar
              </Select.Option>
              {dataClientsGroups?.groups?.map(group => (
                <Select.Option key={group.id} value={group.id}>
                  {group.Name_ClientGroup}
                </Select.Option>
              ))}
            </Select>
          </Select.Container>
        </Control>
      )}
      {dataClientsGroups?.groups?.length === 0 && (
        <Label>No hay grupos de clientes registrados para este lugar</Label>
      )}
    </div>
  );
}

ClientGroupSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  FK_Place: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

ClientGroupSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default ClientGroupSelect;
