export const BILL_CONFIGURATION_FREQUENCY = {
  DAILY: 1,
  WEEKLY: 2,
  MONTHLY: 3,
};

export const BILL_CONFIGURATION_FREQUENCY_TEXT = {
  [BILL_CONFIGURATION_FREQUENCY.DAILY]: "Diario",
  [BILL_CONFIGURATION_FREQUENCY.WEEKLY]: "Semanal",
  [BILL_CONFIGURATION_FREQUENCY.MONTHLY]: "Mensual",
};

export const RESULT_BILL_CONFIGURATION_EVENTS = {
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
};
