import React, { useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
// Context
import { useQuery } from "@apollo/client";
import { useAuth } from "../../context/AuthContext";
// SCSS
import "./createUser.scss";
import InputForm from "../InputForm/InputForm";
import { getdate } from "../../utils/helpers";
import { getAllCountries } from "../../graphql";
import { customToast } from "../../utils";

function CreateUser() {
  const { data: getCountryData } = useQuery(getAllCountries);
  const [isLoading, setIsLoading] = useState(false);
  const [state, setstate] = useState({
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: getdate(),
    Phone_Number: "+506",
    PhoneCodCountry: "506",
    FK_Coin: 1,
    FK_Country: "52",
  });

  const [countPhoneValid, setCountPhoneValid] = useState(8);

  const { handleRegisterPerson, handleLogout } = useAuth();

  const handleChange = (name, value) => {
    const valueFormat = value.replace(/[^a-zA-Z ]/g, "");

    setstate(prev => ({ ...prev, [name]: valueFormat }));

    if (name === "BirthDate_Person") {
      const birthDate = new Date(value);
      const today = new Date();
      const age = today.getFullYear() - birthDate.getFullYear();

      const isOver18 =
        age > 18 ||
        (age === 18 &&
          today.getMonth() >= birthDate.getMonth() &&
          today.getDate() >= birthDate.getDate());

      if (isOver18) {
        setstate(prev => ({ ...prev, [name]: value }));
      } else {
        setstate(prev => ({ ...prev, [name]: getdate() }));
        customToast.warning("Debes ser mayor de 18 años.");
      }
    }
  };

  const handleValidations = () => {
    const isValidPhoneNumber = matchIsValidTel(state.Phone_Number, {
      onlyCountries: [],
      excludedCountries: [],
      continents: [],
    });

    if (!isValidPhoneNumber) {
      setIsLoading(false);
      return customToast.warning(
        "El número ingresado no es válido con el país",
      );
    }

    if (
      !state.Given_Name ||
      !state.Family_Name ||
      !state.Phone_Number ||
      !state.BirthDate_Person
    ) {
      setIsLoading(false);
      return customToast.warning(
        "Se deben de completar todos los datos solicitados",
      );
    }
    return setIsLoading(true);
  };
  const handleSave = () => {
    handleValidations();
    handleRegisterPerson(state);
    // if (data) {
    //   handleLoginGoogle();
    // }
  };

  const handleChangePhoneNumber = (value, info) => {
    const isValid = matchIsValidTel(value, {
      onlyCountries: [],
      excludedCountries: [],
      continents: [],
    });

    if (isValid) {
      setCountPhoneValid(info.nationalNumber.length);
    }

    const countryMatch = getCountryData?.countries.find(
      country => country.Iso_Country === info.countryCode,
    );

    if (info.nationalNumber.length <= countPhoneValid) {
      setstate(prev => ({
        ...prev,
        Phone_Number: value,
        PhoneCodCountry: info.countryCallingCode,
        FK_Country: countryMatch?.id,
      }));
    }
  };

  return (
    <div className="background-page">
      <div className="main-register">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              background: "#030710",
              padding: "0.2rem",
              borderRadius: " 5px",
            }}
          >
            <Button
              disabled
              sx={{
                "width": "100%",
                "cursor": "auto",
                "&.Mui-disabled": {
                  backgroundColor: "rgba(57, 110, 156, 1)",
                },
              }}
              type="button"
              variant="contained"
            >
              <Typography
                typography="defaultLight"
                variant="fixedSizeSubtitle1"
              >
                Regístrate
              </Typography>
            </Button>
          </Box>
          <InputForm
            key="name"
            color="predetermined"
            id="name"
            label="Nombre"
            name="Given_Name"
            size="small"
            type="text"
            value={state.Given_Name}
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <InputForm
            key="Family_Name"
            color="predetermined"
            id="Family_Name"
            label="Apellido"
            name="Family_Name"
            size="small"
            type="text"
            value={state.Family_Name}
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <MuiTelInput
            key="Phone_Number"
            MenuProps={{
              sx: {
                "& .MuiTelInput-Flag": {
                  width: "24px",
                  height: "16px",
                },
              },
            }}
            color="predetermined"
            inputProps={{ autoComplete: "new-password" }}
            name="Phone_Number"
            size="small"
            sx={{
              "& .MuiTelInput-Flag": {
                width: "24px",
                height: "16px",
              },
            }}
            value={state.Phone_Number}
            variant="filled"
            onChange={handleChangePhoneNumber}
          />
          <InputForm
            key="BirthDate_Person"
            color="predetermined"
            id="BirthDate_Person"
            label="Fecha de nacimiento"
            name="BirthDate_Person"
            size="small"
            type="date"
            value={
              state.BirthDate_Person === 0 ? getdate() : state.BirthDate_Person
            }
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <Button
            className="btn-animate"
            size="medium"
            sx={{ justifyContent: "center" }}
            variant="contained"
            onClick={handleSave}
          >
            Guardar
          </Button>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography align="center" variant="subtitle1">
              ¿Ya tienes una cuenta?
            </Typography>
            <Button
              outlined
              rounded
              className="landing-page-button-secondary"
              type="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                handleLogout();
              }}
            >
              Iniciar sesión
            </Button>
          </Box>
        </Box>
      </div>
      {isLoading && (
        <CircularProgress
          disableShrink
          size={100}
          sx={{ position: "absolute" }}
        />
      )}
    </div>
  );
}

export default CreateUser;
