import React, { useCallback, useEffect, useState } from "react";
import { Button, Control, Field, Input, Title } from "rbx";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuth } from "../../context";
import { decrypt } from "../../utils";
import "./ResetPasswordPage.scss";
import "../LandingPage/LandingPage.scss";

function ResetPasswordPage() {
  const queryParams = window.location?.pathname.split("token=");
  const token = queryParams[1];
  const tokenD = JSON.parse(decrypt(token));

  const [loginWithToken, setLoginWithToken] = useState(true);
  const [state, setState] = useState({ email: tokenD.userEmail, password: "" });

  const { handleVerifyPassword, handleLoginWithCustomToken } = useAuth();
  const navigate = useNavigate();

  const handleChange = (name, value) => {
    setState(prev => ({ ...prev, [name]: value }));
  };
  const handleLoginWithToken = useCallback(
    async () => {
      try {
        const userCredential = await handleLoginWithCustomToken(tokenD.token);
        if (userCredential) {
          navigate(`/security/resetPassword/token=${token}`);
        } else {
          setLoginWithToken(false);
        }
      } catch (err) {
        toast.error("Error al iniciar sesión!");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleLoginWithCustomToken],
  );

  useEffect(() => {
    loginWithToken && handleLoginWithToken();
  }, [handleLoginWithToken, loginWithToken, tokenD]);

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const userCredential = await handleVerifyPassword(
        state.email,
        state.password,
      );
      if (userCredential) {
        navigate(`/security/resetPassword/token=${token}`);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <div className="principal-container landing-page">
      <form
        className="principal-container landing-page-container"
        onSubmit={handleSubmit}
      >
        <div>
          <Title className="landing-page-title">Ingrese su contraseña</Title>
          <div className="landing-page-inputs">
            <Field>
              <Control className="emailLable">Correo: {state.email}</Control>
            </Field>
            <Field>
              <Control>
                <Input
                  name="password"
                  placeholder="Contraseña"
                  size="large"
                  type="password"
                  onChange={e => handleChange(e.target.name, e.target.value)}
                />
              </Control>
            </Field>
          </div>
          <br />
          <div className="landing-page-buttons">
            <Button
              rounded
              className="landing-page-button login-buttons"
              size="large"
              type="submit"
            >
              &nbsp;&nbsp;&nbsp;&nbsp; INICIAR&nbsp;&nbsp;&nbsp;&nbsp;
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

ResetPasswordPage.propTypes = {};

export default ResetPasswordPage;
