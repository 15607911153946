import React, { useState } from "react";
// External components
import { Container, Input, Title, Button, Table } from "rbx";
// SCSS
import "./sendingBill.scss";

function SendingBill() {
  const [emails, setEmails] = useState([]);
  const [currentEmail, setCurrentemail] = useState("");

  const handleSubmit = e => {
    e.preventDefault();
    if (currentEmail !== "") {
      setEmails([currentEmail, ...emails]);
      setCurrentemail("");
    }
  };

  const DeleteEmail = email => {
    const newEmails = emails.filter(e => e !== email);
    setEmails(newEmails);
  };

  return (
    <Container fluid>
      <div>
        <Title className="text-center">Enviar Factura</Title>
      </div>
      <Container fluid className="mt-2 d-flex justify-content-center">
        <Title subtitle className="w-40" size={5}>
          Email
        </Title>
      </Container>
      <form className=" d-flex justify-content-center" onSubmit={handleSubmit}>
        <Input
          className="w-30"
          placeholder="email@email.com"
          value={currentEmail}
          onChange={e => {
            setCurrentemail(e.target.value);
          }}
        />
        <Button className="ml-1 p-1 " color="primary" type="submit">
          <svg
            fill="none"
            height="20"
            viewBox="0 0 24 20"
            width="24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.30762 0.833984C1.19762 0.833984 0.307617 1.72398 0.307617 2.83398V14.834C0.307617 15.934 1.20762 16.834 2.30762 16.834H13.3976C13.3376 16.504 13.3076 16.174 13.3076 15.834C13.3076 12.524 15.9976 9.83398 19.3076 9.83398C19.6476 9.83398 19.9776 9.86398 20.3076 9.92398V2.83398C20.3076 1.72398 19.4076 0.833984 18.3076 0.833984H2.30762ZM2.30762 2.83398L10.3076 7.83398L18.3076 2.83398V4.83398L10.3076 9.83398L2.30762 4.83398V2.83398ZM18.3076 11.834V14.834H15.3076V16.834H18.3076V19.834H20.3076V16.834H23.3076V14.834H20.3076V11.834H18.3076Z"
              fill="white"
            />
          </svg>
        </Button>
      </form>
      <Container fluid>
        <Table className="w-40">
          <Table.Body>
            {emails.map(email => (
              <Table.Row key={email}>
                <Table.Cell>
                  <svg
                    fill="none"
                    height="21"
                    viewBox="0 0 21 21"
                    width="21"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => {
                      DeleteEmail(email);
                    }}
                  >
                    <path
                      d="M10.7627 0.101074C16.2927 0.101074 20.7627 4.57107 20.7627 10.1011C20.7627 15.6311 16.2927 20.1011 10.7627 20.1011C5.2327 20.1011 0.762695 15.6311 0.762695 10.1011C0.762695 4.57107 5.2327 0.101074 10.7627 0.101074ZM15.7627 5.10107H13.2627L12.2627 4.10107H9.2627L8.2627 5.10107H5.7627V7.10107H15.7627V5.10107ZM7.7627 16.1011H13.7627C14.0279 16.1011 14.2823 15.9957 14.4698 15.8082C14.6573 15.6206 14.7627 15.3663 14.7627 15.1011V8.10107H6.7627V15.1011C6.7627 15.3663 6.86805 15.6206 7.05559 15.8082C7.24312 15.9957 7.49748 16.1011 7.7627 16.1011Z"
                      fill="black"
                    />
                  </svg>
                </Table.Cell>
                <Table.Cell>{email}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Container>
      <Container fluid className="d-flex justify-content-center mt-2">
        <Button className="fw-bolder" color="primary">
          <span className="p-1 fw-bolder">Enviar</span>
          <svg
            fill="none"
            height="20"
            viewBox="0 0 21 20"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.5879 13.0898H15.5879V10.0898L20.5879 14.5898L15.5879 19.0898V16.0898H11.5879V13.0898ZM18.5879 0.0898438H2.58789C2.05746 0.0898438 1.54875 0.300557 1.17368 0.67563C0.798604 1.0507 0.587891 1.55941 0.587891 2.08984V14.0898C0.587891 14.6203 0.798604 15.129 1.17368 15.5041C1.54875 15.8791 2.05746 16.0898 2.58789 16.0898H9.93789C9.70453 15.4489 9.58605 14.7719 9.58789 14.0898C9.58931 12.8015 10.0054 11.5478 10.7746 10.5142C11.5438 9.48068 12.6253 8.72218 13.859 8.35094C15.0927 7.9797 16.4132 8.01545 17.625 8.45288C18.8369 8.89031 19.8757 9.7062 20.5879 10.7798V2.08984C20.5879 1.55941 20.3772 1.0507 20.0021 0.67563C19.627 0.300557 19.1183 0.0898438 18.5879 0.0898438ZM18.5879 4.08984L10.5879 9.08984L2.58789 4.08984V2.08984L10.5879 7.08984L18.5879 2.08984V4.08984Z"
              fill="white"
            />
          </svg>
        </Button>
      </Container>
    </Container>
  );
}

export default SendingBill;
