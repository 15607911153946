const getBill = (
  electronicBill,
  flowType,
  user_id,
  coins,
  platformExchnage,
) => {
  let detailLines = electronicBill.DetalleServicio.LineaDetalle;
  if (!Array.isArray(electronicBill.DetalleServicio.LineaDetalle)) {
    detailLines = [electronicBill.DetalleServicio.LineaDetalle];
  }
  const exchageRate = parseFloat(
    electronicBill?.ResumenFactura?.CodigoTipoMoneda?.TipoCambio?._text || 1,
  );

  const billCoin =
    electronicBill.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda?._text ??
    "CRC";

  const bill_coin = coins.find(coin => coin.Code_Coin === billCoin);

  const getAmountAsDolar = amountAsString =>
    parseFloat(amountAsString || "0") * bill_coin.Sell_Coin;
  const factura = {
    // agrega los datos de la factura al objeto factura
    FK_Place: 1,
    FK_Bill: 1,
    FK_User: parseInt(user_id, 10),
    Key: electronicBill.Clave?._text,
    ActivityCode: electronicBill.CodigoActividad?._text,
    SellCondition: electronicBill.CondicionVenta?._text,
    EmitedDay: new Date(electronicBill.FechaEmision?._text),
    BillFlowType: flowType,
    PaymentMethod: electronicBill.MedioPago?._text,
    InternConsecutive: electronicBill.NumeroConsecutivo?._text,
    DocumentType: "01",
    CoinCode: billCoin,
    Exchange: exchageRate,
    ...(bill_coin.Code_Coin === "CRC" && {
      PlatformExchange: platformExchnage,
    }),

    TotalBill: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalComprobante?._text,
    ),
    TotalDiscount: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalDescuentos?._text,
    ),
    TotalExempt: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalExento?._text,
    ),
    TotalExonerated: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalExonerado?._text,
    ),
    TotalTaxed: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalGravado?._text,
    ),
    TotalTaxes: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalImpuesto?._text,
    ),
    TotalGoodsExonerated: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalMercExonerada?._text,
    ),
    TotalGoodsExempt: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalMercanciasExentas?._text,
    ),
    TotalGoodsTaxed: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalMercanciasGravadas?._text,
    ),
    TotalServicesExonerated: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalServExonerado?._text,
    ),
    TotalServicesExempt: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalServExentos?._text,
    ),
    TotalServicesTaxed: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalServGravados?._text,
    ),
    TotalSell: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalVenta?._text,
    ),
    TotalNetSell: getAmountAsDolar(
      electronicBill.ResumenFactura.TotalVentaNeta?._text,
    ),
    Emmitter: {
      Name: electronicBill.Emisor.Nombre?._text,
      Description: electronicBill.Emisor.NombreComercial?._text,
      DocumentID: electronicBill.Emisor.Identificacion.Numero?._text,
      PersonType: electronicBill.Emisor.Identificacion.Tipo?._text,
      Email: electronicBill.Emisor.CorreoElectronico?._text,
      PhoneNumber: electronicBill?.Emisor?.Telefono?.NumTelefono?._text || null,
      PhoneCodCountry:
        electronicBill?.Emisor?.Telefono?.CodigoPais?._text || "0",
      Province: parseInt(electronicBill.Emisor.Ubicacion.Provincia?._text, 10),
      Canton: parseInt(electronicBill.Emisor.Ubicacion.Canton?._text, 10),
      District: parseInt(electronicBill.Emisor.Ubicacion.Distrito?._text, 10),
      Address: electronicBill.Emisor.Ubicacion.OtrasSenas?._text,
    },
    ElectronicBillDetail: detailLines.map(lineDetail => ({
      Name: lineDetail.Detalle?._text,
      Price: getAmountAsDolar(lineDetail.PrecioUnitario?._text),
      CabysCode: lineDetail.Codigo?._text,
      BarCode: lineDetail?.CodigoComercial?.Codigo?._text,
      Quantity: parseFloat(lineDetail.Cantidad?._text),
      MeasurementUnit: lineDetail.UnidadMedida?._text,
      Tariff: lineDetail.Impuesto?.CodigoTarifa?._text,
    })),
  };
  return factura;
};

export default getBill;
