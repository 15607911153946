import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { Box, Button, Column, Container, Field } from "rbx";
import { toast } from "react-toastify";
// Components
import { Input } from "../../components/Common";
import {
  Divider,
  ClientCard,
  PaymentDataCard,
  OptionCard,
  ResumeCard,
} from "../../components";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Hook
import useExportationBill from "../../hooks/useExportationBill";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
// Scss
import "./ExportationBillPage.scss";

function ExportationBillPage(props) {
  const {
    receiver,
    useCoinDetail,
    exportationBasicInfo,
    setExportationBasicInfo,
    lineDetail,
    setLineDetail,
    resetReceiver,
    isBilling,
    setIsBilling,
    resetExportationBasicInfo,
  } = useElectronic();
  const { handleCreatDraftFEE, handleCreateFEE } = useExportationBill();
  const navigate = useNavigate();

  const { resetDefaultCoin } = useCoinDetail;

  const handleOnChangeBasicInfo = (name, value) => {
    setExportationBasicInfo(prev => ({ ...prev, [name]: value }));
  };

  const validateExportationElectronicBill = () => {
    const isArancelNeed = lineDetail
      .map(line => !["Sp", "Os"].includes(line.unit_Measure.Symbol))
      .includes(true);
    if (
      lineDetail.length === 0 ||
      (isArancelNeed && exportationBasicInfo?.arrancel.length === 0)
    )
      return false;
    return true;
  };

  const createDraftExportationElectronicBill = async () => {
    const savedDraftBill = await handleCreatDraftFEE();

    if (!savedDraftBill?.data?.createDraftFEE) {
      toast.error('Ocurrió un error al generar la factura."');
    }

    return savedDraftBill;
  };

  const createExportationElectronicBill = async () => {
    const responseDraft = await createDraftExportationElectronicBill();
    if (!responseDraft?.data?.createDraftFEE?.id) return;
    const responseFEE = await handleCreateFEE(
      responseDraft?.data?.createDraftFEE?.id,
    );

    if (
      !responseFEE?.data?.createExportationBill ||
      responseFEE?.data?.createExportationBill?.error
    ) {
      toast.error(
        "Ocurrió un error al guardar la factura electrónica de exportación",
      );
      return;
    }
    toast.success(
      "Se ha guardado exitosamente la factura electrónica de exportación",
    );
    navigate(
      `/ElectronicBillView/${responseFEE.data.createExportationBill.id}/created`,
    );
  };

  useEffect(() => {
    if (!isBilling) {
      resetReceiver();
      resetDefaultCoin();
      resetExportationBasicInfo();
      setLineDetail([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => {
      if (isBilling) setIsBilling(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isBilling],
  );

  return (
    <div>
      <Box>
        <h2>Información Básica</h2>

        <Column.Group>
          <Column>
            <Input
              defaultValue={exportationBasicInfo?.expirationDate}
              label="Fecha de Vencimiento:"
              name="expirationDate"
              type="date"
              onChange={e =>
                handleOnChangeBasicInfo(e.target.name, e.target.value)
              }
            />
          </Column>
        </Column.Group>
        <Column.Group>
          <Column>
            <Field>
              <Input
                defaultValue={exportationBasicInfo?.purchaseOrder}
                label="Orden de compra"
                name="purchaseOrder"
                placeholder="Orden de compra"
                onChange={e =>
                  handleOnChangeBasicInfo(e.target.name, e.target.value)
                }
              />
            </Field>
          </Column>
          <Column>
            <Field>
              <Input
                defaultValue={exportationBasicInfo?.arrancel}
                label="Partida Arancelaria ( obligatorio en caso de venta de productos)"
                name="arrancel"
                placeholder="Partida Arancelaria"
                onChange={e =>
                  handleOnChangeBasicInfo(e.target.name, e.target.value)
                }
              />
            </Field>
          </Column>
        </Column.Group>

        <Divider type="shadow" />
        <OptionCard
          mainComponent
          coinDetails={[useCoinDetail]}
          icon="shopping-basket"
          title="Añadir productos"
        />
        <ResumeCard
          type={ElectronicBillTypes.FacturaElectronicaDeExportacion}
        />
        <ClientCard
          optional={
            receiver.Receiver_Id === ""
              ? "Click para buscar (opcional)"
              : ` ${receiver?.Receiver_Name}, 
              ${receiver?.Receiver_Id}`
          }
          optional2={
            receiver?.Receiver_Email === "" &&
            receiver?.Receiver_PhoneNumber === ""
              ? ""
              : ` ${receiver?.Receiver_Email}, 
            ${receiver?.Receiver_PhoneNumber}`
          }
          type={ElectronicBillTypes.FacturaElectronicaDeExportacion}
        />
        <PaymentDataCard
          type={ElectronicBillTypes.FacturaElectronicaDeExportacion}
        />
        <Divider type="transparent" />
        <Container className="actions-container">
          <Button
            className="actions-button"
            color="light"
            size="large"
            onClick={() => createDraftExportationElectronicBill()}
          >
            Guardar en borrador
          </Button>
          <Button
            className="actions-button"
            color="primary"
            disabled={!validateExportationElectronicBill()}
            size="large"
            onClick={createExportationElectronicBill}
          >
            Generar factura electrónica de exportación
          </Button>
        </Container>
      </Box>
    </div>
  );
}

ExportationBillPage.propTypes = {};

export default ExportationBillPage;
