import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Container, Input, Table } from "rbx";
import BooleanInput from "../../../../BooleanInput";
import "../../../../../styles/index.scss";
import "./Taxes.scss";
import { customToast as toast } from "../../../../../utils";
import { removeFirstCharIfZero } from "../../../../../utils/helpers";

function Taxes({
  product,
  setModalOpen,
  list,
  setDataTable,
  taxes,
  coinDetails,
}) {
  const [otherTaxes, setOtherTaxes] = useState([]);
  const [totals, setTotals] = useState(0.0);

  const { currentSymbolCoin, toExchangeRate } = coinDetails;
  // useEffects
  useEffect(() => {
    // list of taxes
    if (otherTaxes.length === 0) {
      if (product.taxes.length !== 0) {
        setTotals(product.totalTaxes);
        product.taxes.forEach(item => {
          setOtherTaxes(prev => [
            ...prev,
            {
              isSelected: item.isSelected,
              percent: item.percent,
              id: item.id,
              name: item.name,
              amount: item.amount,
            },
          ]);
        });
      } else {
        taxes.taxes.forEach(item => {
          if (
            item.MH_Code !== "01" &&
            item.MH_Code !== "07" &&
            item.MH_Code !== "08"
          ) {
            setOtherTaxes(prev => [
              ...prev,
              {
                isSelected: false,
                percent: "0",
                amount: 0,
                id: item.id,
                name: item.Description_Tax,
              },
            ]);
          }
        });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handles
  const handleChange = (name, value, id) => {
    const buffer = removeFirstCharIfZero(value);
    setOtherTaxes(
      otherTaxes.map(tax => (tax.id === id ? { ...tax, [name]: buffer } : tax)),
    );
  };

  const handleCalculateTax = (name, value) => {
    let amount = 0;

    if (name === "percent") {
      amount = (product.unit_price * 1000 * value) / 100;
    }
    if (name === "amount") {
      amount = (value / (product.unit_price * 100)) * 10;
    }
    return amount;
  };

  useEffect(() => {
    const calculateTotals = () => {
      // calculates totals for each tax on product
      let totalsPercent = 0;

      const filterTaxes = otherTaxes.filter(
        item => item.percent !== 0 && item.amount !== 0,
      );

      filterTaxes.forEach(item => {
        totalsPercent += parseFloat(item.percent);
      });
      setTotals((product.unit_price * totalsPercent) / 100);
    };
    calculateTotals();
  }, [otherTaxes, product.unit_price]);

  const handleKeyBlur = (e, name, value, id) => {
    // caculate total of selected tax

    if (name === "percent") {
      const total = handleCalculateTax(name, value);
      setOtherTaxes(
        otherTaxes.map(tax =>
          tax.id === id
            ? {
                ...tax,
                amount: parseFloat(total).toFixed(1),
              }
            : tax,
        ),
      );
    }
    if (name === "amount") {
      const total = handleCalculateTax(name, value);
      setOtherTaxes(
        otherTaxes.map(tax =>
          tax.id === id
            ? {
                ...tax,
                percent: parseFloat(total).toFixed(3),
              }
            : tax,
        ),
      );
    }
  };
  const handleKeyPressed = (e, name, value, id) => {
    // caculate total of selected tax
    const keyfloat = window.Event ? e.which : e.keyCode;

    if (keyfloat === 13) {
      // enter key
      if (name === "percent") {
        const total = handleCalculateTax(name, parseFloat(value), id);
        setOtherTaxes(
          otherTaxes.map(tax =>
            tax.id === id
              ? {
                  ...tax,
                  amount: parseFloat(total).toFixed(1),
                }
              : tax,
          ),
        );
      }

      if (name === "amount") {
        const total = handleCalculateTax(name, parseFloat(value), id);
        setOtherTaxes(
          otherTaxes.map(tax =>
            tax.id === id
              ? {
                  ...tax,
                  percent: parseFloat(total).toFixed(3),
                }
              : tax,
          ),
        );
      }
    }
  };

  const handleSelect = id => {
    setOtherTaxes(
      otherTaxes.map(tax => {
        if (tax.id === id && tax.isSelected === true) {
          return { ...tax, isSelected: !tax.isSelected, percent: 0, amount: 0 };
        }
        if (tax.id === id && tax.isSelected === false) {
          return { ...tax, isSelected: !tax.isSelected };
        }
        return tax;
      }),
    );
  };

  const handleSave = () => {
    // handle to confirm list of taxes
    const filterTaxes = otherTaxes.filter(item => item.isSelected === true);

    if (filterTaxes?.length === 0) {
      setTotals(0.0);
      setDataTable(
        list.map(item =>
          product.id === item.id
            ? {
                ...item,
                taxes: otherTaxes,
                totalTaxes: 0.0,
              }
            : item,
        ),
      );
    }
    setDataTable(
      list.map(item =>
        product.id === item.id
          ? {
              ...item,
              taxes: otherTaxes,
              totalTaxes: totals,
            }
          : item,
      ),
    );
    setModalOpen(false, "");
    toast.success("¡Cambios registrados con éxito!", { toastId: "taxes" });
  };

  return (
    <Container fluid>
      <div>
        <h3 className="primary-title is-primary"> IVA</h3>
        <div className=" tax-container">
          <h4> Tipo IVA:</h4>
          <Input disabled value={product?.tax?.Description_Tax} />
          <h4> Tarifa:</h4>
          <Input disabled value={product?.Tariff?.Name} />
        </div>
        <h3 className="primary-title is-primary">Otros Impuestos</h3>
        <div className=" tax-container">
          <Table bordered fullwidth hoverable narrow striped>
            <Table.Head>
              <Table.Row>
                <Table.Heading>
                  <abbr> </abbr>
                </Table.Heading>
                <Table.Heading>
                  <abbr className="head-text">Tipo</abbr>
                </Table.Heading>
                <Table.Heading>
                  <abbr className="head-text">Porcentaje</abbr>
                </Table.Heading>
                <Table.Heading>
                  <abbr className="head-text">Monto {currentSymbolCoin}</abbr>
                </Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {otherTaxes?.map(tax => (
                <Table.Row key={tax.id}>
                  <Table.Heading>
                    <BooleanInput
                      value={tax.isSelected}
                      onChange={() => handleSelect(tax.id)}
                    />{" "}
                  </Table.Heading>
                  <Table.Cell>
                    <h4>{tax.name}</h4>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      disabled={!tax.isSelected}
                      name="percent"
                      type="number"
                      value={tax?.percent}
                      onBlur={e =>
                        handleKeyBlur(e, e.target.name, e.target.value, tax.id)
                      }
                      onChange={e =>
                        handleChange(e.target.name, e.target.value, tax.id)
                      }
                      onKeyDown={e =>
                        handleKeyPressed(
                          e,
                          e.target.name,
                          e.target.value,
                          tax.id,
                        )
                      }
                      onKeyPress={e =>
                        handleKeyPressed(
                          e,
                          e.target.name,
                          e.target.value,
                          tax.id,
                        )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      disabled={!tax.isSelected}
                      name="amount"
                      type="number"
                      value={tax?.amount}
                      onBlur={e =>
                        handleKeyBlur(e, e.target.name, e.target.value, tax.id)
                      }
                      onChange={e =>
                        handleChange(e.target.name, e.target.value, tax.id)
                      }
                      onKeyDown={e =>
                        handleKeyPressed(
                          e,
                          e.target.name,
                          e.target.value,
                          tax.id,
                        )
                      }
                      onKeyPress={e =>
                        handleKeyPressed(
                          e,
                          e.target.name,
                          e.target.value,
                          tax.id,
                        )
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
          <h3>
            Total: {currentSymbolCoin}
            {toExchangeRate(totals).toFixed(3)}
          </h3>
        </div>
        <div className="container-button">
          <Button
            rounded
            className="button-styles"
            color="primary"
            type="Button"
            onClick={handleSave}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </Container>
  );
}

Taxes.propTypes = {
  product: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setDataTable: PropTypes.func.isRequired,
  taxes: PropTypes.object.isRequired,
  coinDetails: PropTypes.func.isRequired,
};

export default Taxes;
