import PropTypes from "prop-types";
// Components
import { Card, Typography } from "@mui/material";
import BooleanInput from "../../../BooleanInput";
// Utils
import { formatCurrency } from "../../../../utils";
import { formatDateString } from "../../../../utils/helpers";

const EXPENSE_CARD_SELECTION_STATUS = {
  IS_SELECTED: true,
  IS_NOT_SELECTED: false,
};

function ExpenseCard({ item, handleSelect }) {
  const [emitedDayFormatedDate] = formatDateString(item?.EmitedDay);

  const [acceptanceDateFormatedDate] = formatDateString(
    item?.AcceptanceDate ?? item?.EmitedDay,
  );

  return (
    <Card
      key={item?.id}
      color="primary"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "1rem",
        width: { xs: "100%", sm: "365px" },
        padding: "1rem",
      }}
    >
      <div className="content-flex-end">
        <BooleanInput
          name={item?.id}
          value={item?.selected}
          onChange={(name, value) =>
            handleSelect(
              name,
              value
                ? EXPENSE_CARD_SELECTION_STATUS.IS_SELECTED
                : EXPENSE_CARD_SELECTION_STATUS.IS_NOT_SELECTED,
            )
          }
        />
      </div>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold">
          {" "}
          Consecutivo de documento:{" "}
        </span>
        <Typography align="start" typography="modeColor" variant="subtitle1">
          {item?.InternConsecutive}
        </Typography>
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold">
          Consecutivo de aceptación:{" "}
        </span>
        {item?.AcceptanceDocument?.InternConsecutive
          ? item?.AcceptanceDocument?.InternConsecutive
          : 0}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold">Emisor: </span>
        {item?.Place?.Name_Place}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Cédula: </span>
        {item?.Place?.Company?.User?.Person?.DocumentId_Person}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Fecha de emisión: </span>
        {emitedDayFormatedDate}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Fecha de aceptación: </span>
        {acceptanceDateFormatedDate ?? emitedDayFormatedDate}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Moneda: </span>
        {item?.Coin?.Name_Coin}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Total: </span>
        {formatCurrency(
          parseFloat(item.TotalBill, 10),
          item?.Coin?.Code_Coin ? item?.Coin?.Code_Coin : "CRC",
        )}
      </Typography>
    </Card>
  );
}

ExpenseCard.propTypes = {
  item: PropTypes.object.isRequired,
  handleSelect: PropTypes.func.isRequired,
};

export default ExpenseCard;
