import React, { useState, createContext, useContext, useMemo } from "react";
import PropTypes from "prop-types";

import { Modal, Backdrop, Box } from "@mui/material";
import { customToast as toast } from "../utils";
import "./ModalContext.scss";
import { style, styleBackdrop } from "./variables";

const initialState = {
  isModalOpen: false,
  modalContent: "",
};

export const ModalContext = createContext(initialState);

export function ModalConsumer({ theme }) {
  const handleModalClick = event => {
    event.stopPropagation();
  };

  return (
    <ModalContext.Consumer>
      {({ isModalOpen, modalContent, setModalOpen, msg, showMessage }) => (
        <Backdrop
          color="transparent"
          open={isModalOpen}
          sx={styleBackdrop}
          onClick={() => setModalOpen(false)}
        >
          <Modal
            open={isModalOpen}
            onClick={handleModalClick}
            onClose={() => {
              showMessage && toast.error(msg);
              setModalOpen(false);
            }}
          >
            <Box
              bgcolor={
                theme === "light"
                  ? "rgba(246, 249, 254, 0.8)"
                  : "rgba(9, 25, 40, 0.65)"
              }
              color={theme === "light" ? "black" : "white"}
              sx={style}
            >
              <div
                className={`${theme === "light" ? "light_modal" : "dark_modal"}`}
              >
                {modalContent}
              </div>
            </Box>
          </Modal>
        </Backdrop>
      )}
    </ModalContext.Consumer>
  );
}

ModalConsumer.propTypes = {};

export function ModalProvider({ children, theme }) {
  const [state, setState] = useState(initialState);

  const setModalOpen = (
    isModalOpen,
    modalContent,
    msg = "",
    showMessage = false,
  ) => {
    setState({ isModalOpen, modalContent, msg, showMessage });
  };

  const valueToReturn = useMemo(() => ({ ...state, setModalOpen }), [state]);

  return (
    <ModalContext.Provider value={valueToReturn}>
      {children}
      <ModalConsumer theme={theme} />
    </ModalContext.Provider>
  );
}

ModalProvider.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string,
};

ModalProvider.defaultProps = {
  children: null,
  theme: "",
};

ModalConsumer.propTypes = {
  theme: PropTypes.string,
};

ModalConsumer.defaultProps = {
  theme: "",
};

export const useModal = () => useContext(ModalContext);

export default ModalContext;
