import React, { useCallback, useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { Box, Card, Typography } from "@mui/material";
// Graphql
import { useMutation } from "@apollo/client";
import {
  DELETE_PRODUCT_FEATURE,
  GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
} from "../../../../graphql";
// Components
import NewOrEditProduct from "../NewOrEditProduct";
import ConfirmationModal from "../../../ConfirmationModal";
// Context
import { useAuth, useModal } from "../../../../context";
// Utils
import { customToast } from "../../../../utils";
import { validateRoute } from "../../../../utils/helpers";
// SCSS
import "./CardProduct.scss";
import deleteIcon from "../../../../assets/icons/delete_icon.svg";
import editIcon from "../../../../assets/icons/edit_icon.svg";
import { useElectronic } from "../../../../context/ElectronicContext";

function CardProduct({ product, refetch }) {
  const [deleteProduct] = useMutation(DELETE_PRODUCT_FEATURE);
  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  const { permissions } = useAuth();

  const { haveActionPermission } = permissions;

  const canEdit = haveActionPermission("Edit", "/products", LineDetailPlace);
  const canDelete = haveActionPermission(
    "Delete",
    "/products",
    LineDetailPlace,
  );

  const getUrl = pro => {
    const route = pro.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateInitialRoute = pro => {
    const route = getUrl(pro);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    return route;
  };

  const handleEdit = () => {
    setModalOpen(
      true,
      <NewOrEditProduct
        product={product}
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Editar Producto/Servicio"
      />,
    );
  };

  const deletedProduct = useCallback(async () => {
    try {
      const { data } = await deleteProduct({
        variables: {
          id: product?.id,
        },
        refetchQueries: [
          {
            query: GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
            variables: refetch,
          },
        ],
      });
      if (data) {
        customToast.success("¡Producto eliminado con éxito!");
      } else {
        customToast.error("Error al eliminar el producto");
      }
    } catch (err) {
      customToast.error("Error al eliminar el producto");
    }
  }, [deleteProduct, product, refetch]);

  const hanldeDelete = e => {
    e.preventDefault();
    deletedProduct();
    setModalOpen(false);
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const handleConfirmDelete = e => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea borrar este producto?"
        header="¿Desea borrar este producto?"
        textConfirm="Eliminar"
        onCancel={cancelDelete}
        onConfirm={() => hanldeDelete(e)}
      />,
    );
  };

  useEffect(() => {
    function getDefaultImage(e) {
      e.target.src = validateRoute(product);
    }
    const img1 = document.getElementById("file");
    img1.onerror = getDefaultImage;
  }, [product]);

  return (
    <Card
      color="primary"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        minHeight: " 270px",
        borderRadius: "5px",
        padding: "20px 15px 20px 15px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          height: { sm: "15rem", md: "100%" },
        }}
      >
        <div className="card-Product">
          <img
            alt="Producto"
            className="card-image"
            id="file"
            src={validateInitialRoute(product?.Files)}
          />
          <Typography typography="modeColor" variant="body1">
            {product.Product?.Name_Product}
          </Typography>
        </div>
        <Typography typography="modeColor" variant="body1" width="100%">
          <strong>Código:</strong> {product.Codes[0]?.FeatureCode}
        </Typography>
        <Typography typography="modeColor" variant="body1" width="100%">
          <strong>Descripción:</strong> {product.Product?.Description_Product}
        </Typography>
        <Typography typography="modeColor" variant="body1" width="100%">
          <strong>Código cabys:</strong> {product.ProductPrice?.Cabys?.Code}
        </Typography>
      </Box>
      <div className="content-buttons">
        {canDelete && (
          <button
            className="button-modifications"
            type="button"
            onClick={handleConfirmDelete}
          >
            <img
              alt="eliminar"
              className="icon-button icon-color"
              src={deleteIcon}
            />
          </button>
        )}
        {canEdit && (
          <button
            className="button-modifications"
            type="button"
            onClick={handleEdit}
          >
            <img
              alt="editar"
              className="icon-button icon-color"
              src={editIcon}
            />
          </button>
        )}
      </div>
    </Card>
  );
}

CardProduct.propTypes = {
  product: PropTypes.object.isRequired,
  refetch: PropTypes.object,
};
CardProduct.defaultProps = {
  refetch: {},
};

export default CardProduct;
