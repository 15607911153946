import React, { useState } from "react";
import PropTypes from "prop-types";
import "firebase/storage";
// Components
import { Typography } from "@mui/material";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "../../pages/ProfilePage/ProfilePage.scss";
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";

function ClientGroupRegister({
  setModalOpen,
  placeId,
  groupId,
  registerGroup,
  updateGroup,
  nameGroup,
  descriptionGroup,
}) {
  // states
  const [informationGroup, setInformationGroup] = useState({
    Name_ClientGroup: nameGroup,
    Description_ClientGroup: descriptionGroup,
    FK_Place: placeId,
    id: groupId,
  });

  const handleChange = (name, value) => {
    setInformationGroup(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (informationGroup?.id !== 0) {
      if (
        informationGroup.Name_ClientGroup !== "" &&
        informationGroup.Description_ClientGroup !== "" &&
        informationGroup?.FK_Place &&
        informationGroup?.id
      ) {
        updateGroup(informationGroup);
      } else {
        toast.error(
          "Debe completar toda la información solicitada para realizar los cambios.",
        );
      }
    } else if (
      informationGroup.Name_ClientGroup !== "" &&
      informationGroup.Description_ClientGroup !== "" &&
      informationGroup?.FK_Place
    ) {
      registerGroup({
        Name_ClientGroup: informationGroup.Name_ClientGroup,
        Description_ClientGroup: informationGroup.Description_ClientGroup,
        FK_Place: informationGroup?.FK_Place,
      });
    } else {
      toast.error(
        "Debe completar toda la información solicitada para el registro.",
      );
    }
  };

  const handleCancel = () => {
    setModalOpen(false, "");
  };

  return (
    <form className="container-modal" onSubmit={handleSubmit}>
      <div className="content-second-modal">
        {groupId === 0 ? (
          <Typography
            align="center"
            fontWeight={600}
            typography="modeColor"
            variant="h6"
          >
            Registrar nuevo grupo
          </Typography>
        ) : (
          <Typography
            align="center"
            fontWeight={600}
            typography="modeColor"
            variant="h6"
          >
            Editar grupo
          </Typography>
        )}
        <InputForm
          color="primary"
          id="Name_ClientGroup"
          name="Name_ClientGroup"
          placeholder="Nombre"
          size="small"
          type="text"
          value={informationGroup.Name_ClientGroup}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          color="primary"
          id="Description_ClientGroup"
          name="Description_ClientGroup"
          placeholder="Descripción"
          size="small"
          type="text"
          value={informationGroup.Description_ClientGroup}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <FormWithButtons onCancel={handleCancel} onSubmit={handleSubmit} />
      </div>
    </form>
  );
}

ClientGroupRegister.propTypes = {
  setModalOpen: PropTypes.func,
  placeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  registerGroup: PropTypes.func,
  updateGroup: PropTypes.func,
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  nameGroup: PropTypes.string,
  descriptionGroup: PropTypes.string,
};
ClientGroupRegister.defaultProps = {
  setModalOpen: () => null,
  registerGroup: () => null,
  updateGroup: () => null,
  placeId: "",
  groupId: 0,
  nameGroup: "",
  descriptionGroup: "",
};

export default ClientGroupRegister;
