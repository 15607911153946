/* eslint-disable react/jsx-props-no-spreading */
import { useApolloClient } from "@apollo/client";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Box, Card, FormControl } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AsyncSelect from "react-select/async";
import { GET_CLIENTS_BY_CRITERIA } from "../../../../graphql";
import "react-spring-bottom-sheet/dist/style.css";
import "../../../ProductSelect/ProductSelect.scss";
import "./SelectProfile.scss";
import { useElectronic } from "../../../../context/ElectronicContext";
import styleAsynSelect from "../../../CabysCodeSelect/styles";

function CustomOption(value) {
  const { data } = value;
  const option = data;

  // functions
  const selectIcon = TypeId => {
    // star icon
    if (TypeId === "1") {
      return "user";
    }
    return "building";
  };

  return (
    <Box
      {...value}
      sx={{ display: "flex", width: "100%", background: "transparent" }}
    >
      {option && (
        <Card
          key={option.ID_Number}
          sx={{ display: "flex", width: "100%", background: "transparent" }}
        >
          <div className="flex">
            <FontAwesomeIcon
              icon={selectIcon(option.MH_PersonType?.id)}
              size="lg"
            />

            <div className="column">
              <h3>{option.Name},</h3>
              <h3>{option.ID_Number}</h3>
            </div>
          </div>
        </Card>
      )}
    </Box>
  );
}

function SelectProfile({
  clientList,
  handleSaveNewClient,
  deleteFavoriteClient,
}) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const theme = localStorage.getItem("theme");
  const { LineDetailPlace, setReceiver, setAddress, setClientGroup } =
    useElectronic();
  const [defaultOptions, setDefaultOptions] = useState([]);

  // handles
  const handleChange = newValue => {
    // select client
    const billingProfile = {
      id: newValue.id,
      Receiver_Id: newValue.ID_Number,
      Receiver_Name: newValue.Name,
      Receiver_Email: newValue.Email,
      Receiver_PhoneCode: newValue.PhoneCodCountry,
      Receiver_PhoneNumber: newValue.PhoneNumber,
      Receiver_PersonType: newValue.MH_PersonType,
      Receiver_UserId: newValue.idUser,
    };
    // Add address
    const address = {
      Province: newValue.MH_Neighborhood.District.Canton.Province,
      Canton: newValue.MH_Neighborhood.District.Canton,
      District: newValue.MH_Neighborhood.District,
      Neighborhood: newValue.MH_Neighborhood,
      Other: newValue.OtherSigns,
    };
    setAddress(address);
    setReceiver(billingProfile);
    setClientGroup({ id: 0 });
    navigate(-1);
  };

  const loadOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_CRITERIA,
      variables: {
        criteria: inputValue,
        FK_Place: LineDetailPlace?.id,
      },
    });

    const profiles = data?.clients;

    const result = Array.isArray(profiles)
      ? profiles.map(BillingProfile => ({
          id: BillingProfile.id,
          Name: BillingProfile?.Name,
          idUser: BillingProfile?.User?.id,
          ID_Number: BillingProfile?.ID_Number,
          Email: BillingProfile?.Email,
          MH_PersonType: BillingProfile?.MH_PersonType,
          MH_Neighborhood: BillingProfile?.MH_Neighborhood,
          PhoneNumber: BillingProfile?.PhoneNumber,
          PhoneCodCountry: BillingProfile?.PhoneCodCountry,
          value: BillingProfile.id,
          label: "Buscar...",
        }))
      : [];

    setDefaultOptions(result);
    return result;
  };

  return (
    <FormControl fullWidth style={{ maxWidth: "30rem" }}>
      <AsyncSelect
        cacheOptions
        className="input principal-input without-padding"
        classNamePrefix={`${theme}`}
        components={{ Option: CustomOption }}
        defaultOptions={defaultOptions}
        loadOptions={loadOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar..."
        styles={styleAsynSelect}
        onChange={handleChange}
      />
    </FormControl>
  );
}
SelectProfile.defaultProps = {
  clientList: [],
};
SelectProfile.propTypes = {
  clientList: PropTypes.array,
  handleSaveNewClient: PropTypes.func.isRequired,
  deleteFavoriteClient: PropTypes.func.isRequired,
};

export default SelectProfile;
