import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
// Components
import { Box } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// Context
import { useAuth } from "../context";
import { ModalProvider } from "../context/ModalContext";
import { NotificationsProvider } from "../context/NotificationsContext";
import { useElectronic } from "../context/ElectronicContext";
// Helper Route
import { InventoryRequiredRoute } from "../routes";
// Components
import {
  Sidebar,
  CreateUser,
  ClientsMaintenance,
  SendingBill,
  ResetForgotPassword,
} from "../components";
// Pages
import LandingPage from "./LandingPage";
import HomePage from "./HomePage";
import ExternalLoginPage from "./ExternalLoginPage";
import ControlUserRolPage from "./ControlUserRolPage";
import IncomePage from "./IncomePage";
import ExpensesPage from "./ExpensesPage";
import ReportsPage from "./ReportsPage";
import ClientsPage from "./ClientsPage";
import ProfilePage from "./ProfilePage";
import ElectronicBillsPage from "./ElectronicBillsPage";
import ProformaPage from "./ProformaPage";
import ClientsGroupPage from "./ClientsGroupsPage";
import PlacesPage from "./PlacesPage";
import BuyElectronicBillsPage from "./BuyElectronicBillsPage";
import SecurityPage from "./Security/SecurityPage";
import ResetPasswordPage from "./ResetPasswordPage/ResetPasswordPage";
import ProductsPage from "./ProductsPage";
import EBViewPage from "./EBViewPage";
import ExportationBillPage from "./ExportationBillPage";
import DocumentSearchPage from "./DocumentSearchPage";
import CreditDebitNotesPage from "./CreditDebitNotesPage";
import CreditNotes from "./CreditNotes";
import NotificationsPage from "./NotificationsPage";
import ScheduledBillSearchPage from "./ScheduledBillSearchPage";
import ThemeCustom from "../components/ThemeProvider/ThemeCustom";
import ErrorPage from "./ErrorPage/ErrorPage";

function Pages(props) {
  const {
    state: authState,
    handleLogout,
    permissions,
    isSideBarOpen,
    setIsSideBarOpen,
    isLoading,
    refetchPermissionsByUserAndPlace,
  } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { LineDetailPlace, setLineDetailPlace, setAccountSelected } =
    useElectronic();

  const { isSuperAdminOrHaveAccess } = permissions;

  const handleFullLogOut = () => {
    setLineDetailPlace({ id: 0 });
    setAccountSelected({ id: 0 });
    handleLogout();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    debounce: 500,
    onIdle: handleFullLogOut,
  });

  const [theme, setTheme] = useState(
    () => localStorage.getItem("theme") || "light",
  );
  const toggleTheme = () => {
    const newTheme = theme === "light" ? "dark" : "light";
    setTheme(newTheme);
    localStorage.setItem("theme", newTheme);
  };

  useEffect(() => {
    const isForgotPage = location.pathname.includes("/forgot");
    if (isForgotPage) return;
    if (!isLoading && !authState.isLoggedIn && !isForgotPage) navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState.isLoggedIn, isLoading, navigate]);

  useEffect(() => {
    if (!LineDetailPlace?.id || !authState?.user?.TavuelUser?.id) return;
    refetchPermissionsByUserAndPlace?.({
      FK_User: authState?.user?.TavuelUser?.id,
      FK_Place: LineDetailPlace?.id,
    });
  }, [LineDetailPlace, authState, refetchPermissionsByUserAndPlace, location]);

  return authState.isLoggedIn ? (
    <ThemeCustom setTheme={setTheme} theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {authState.user.TavuelUser ? (
          <ModalProvider theme={theme}>
            <NotificationsProvider>
              <main
                className={`main-container ${isSideBarOpen ? "open" : ""}`}
                role="presentation"
                onClick={() =>
                  window.innerWidth < 900 ? setIsSideBarOpen(false) : null
                }
                onKeyDown={() =>
                  window.innerWidth < 900 ? setIsSideBarOpen(false) : null
                }
              >
                <Sidebar
                  changeTheme={toggleTheme}
                  isSideBarOpen={isSideBarOpen}
                  setIsSideBarOpen={setIsSideBarOpen}
                />
                <div
                  className={`
                  main-page-container
                  ${isSideBarOpen && "open"}
                `}
                >
                  <Box sx={{ marginTop: "2rem" }}>
                    <Routes>
                      {isSuperAdminOrHaveAccess(
                        "/reports",
                        LineDetailPlace,
                      ) && (
                        <Route element={<ReportsPage />} path="/reports/*" />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/incomes",
                        LineDetailPlace,
                      ) && <Route element={<IncomePage />} path="/incomes" />}
                      {isSuperAdminOrHaveAccess("/places", LineDetailPlace) && (
                        <Route element={<PlacesPage />} path="/places/*" />
                      )}
                      <Route element={<ProfilePage />} path="/profile" />
                      {isSuperAdminOrHaveAccess(
                        "/notifications",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={<NotificationsPage />}
                          path="/notifications"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/security",
                        LineDetailPlace,
                      ) && (
                        <Route element={<SecurityPage />} path="/security/*" />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/clients-maintenance",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={<ClientsMaintenance />}
                          path="/clients-maintenance"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={<CreditNotes />}
                          path="/CreditDebitNote/:id/CreditNotes"
                        />
                      )}
                      <Route element={<SendingBill />} path="/send-bill" />
                      <Route
                        element={<ControlUserRolPage />}
                        path="/control-user-role"
                      />
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={<EBViewPage />}
                          path="/ElectronicBillView/:id/:viewType/:billType"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/clients",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute element={ClientsPage} />
                          }
                          path="/clients"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/expenses",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute element={ExpensesPage} />
                          }
                          path="/expenses/*"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/products",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute element={ProductsPage} />
                          }
                          path="/products"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/clients",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={ClientsGroupPage}
                            />
                          }
                          path="/clients-groups/*"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={ElectronicBillsPage}
                            />
                          }
                          path="/electronic-bills/*"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={BuyElectronicBillsPage}
                            />
                          }
                          path="/buy-electronic-bills/*"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={ExportationBillPage}
                            />
                          }
                          path="/exportation-bill"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={CreditDebitNotesPage}
                            />
                          }
                          path="/CreditDebitNote"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute element={ProformaPage} />
                          }
                          path="/Proforma"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={DocumentSearchPage}
                            />
                          }
                          path="/DocumentSearch/*"
                        />
                      )}
                      {isSuperAdminOrHaveAccess(
                        "/electronic-bills",
                        LineDetailPlace,
                      ) && (
                        <Route
                          element={
                            <InventoryRequiredRoute
                              element={ScheduledBillSearchPage}
                            />
                          }
                          path="/ScheduledBill/*"
                        />
                      )}
                      <Route element={<HomePage />} path="/" />
                      <Route element={<ErrorPage />} path="*" />
                    </Routes>
                  </Box>
                </div>
              </main>
            </NotificationsProvider>
          </ModalProvider>
        ) : (
          <CreateUser />
        )}
      </LocalizationProvider>
    </ThemeCustom>
  ) : (
    <ThemeCustom setTheme={setTheme} theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div>
          <Routes>
            <Route
              element={<ResetPasswordPage />}
              path="/resetPassword/:token"
            />
            <Route
              element={<ResetForgotPassword />}
              path="/forgot-password/:token"
            />
            <Route
              element={<ExternalLoginPage />}
              path="/external-login/:token/:uid/:id"
            />
            <Route element={<LandingPage />} path="/" />
            <Route element={<ErrorPage />} path="*" />
          </Routes>
        </div>
      </LocalizationProvider>
    </ThemeCustom>
  );
}

Pages.propTypes = {};

export default Pages;
