/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const getAllCountries = gql`
  query getAllCountries {
    countries: getAllCountries {
      id
      Name_Country
      PhoneCode_Country
      Iso_Country
    }
  }
`;

export const filterCountries = gql`
  query filterCountries(
    $searchInput: String
    $id: ID
    $isoCountry: String
    $FK_Country: ID
  ) {
    countries: filterCountries(
      searchInput: $searchInput
      id: $id
      isoCountry: $isoCountry
      FK_Country: $FK_Country
    ) {
      id
      Name_Country
      PhoneCode_Country
      Iso_Country
    }
  }
`;
