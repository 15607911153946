import Discounts from "../components/PaymentComponet/detail/LineDetail/Discounts";
import Taxes from "../components/PaymentComponet/detail/LineDetail/Taxes";

export const getDetails = product => {
  let details = null;
  product.details?.forEach(item => {
    if (item?.Value_ProductFeatureDetail !== null) {
      details = !details
        ? item.Value_ProductFeatureDetail
        : `${details} / ${item.Value_ProductFeatureDetail}`;
    }
  });
  return details;
};

export const hadleAddDiscounts = (
  product,
  setModalOpen,
  useCoinDetail,
  list,
  setDataTable,
) => {
  setModalOpen(
    true,
    <Discounts
      coinDetails={useCoinDetail}
      list={list}
      product={product}
      setDataTable={setDataTable}
      setModalOpen={setModalOpen}
    />,
  );
};

export const hadleAddTaxes = (
  product,
  setModalOpen,
  useCoinDetail,
  list,
  setDataTable,
  getTaxesData,
) => {
  setModalOpen(
    true,
    <Taxes
      coinDetails={useCoinDetail}
      list={list}
      product={product}
      setDataTable={setDataTable}
      setModalOpen={setModalOpen}
      taxes={getTaxesData}
    />,
  );
};

export const handleDecrement = ({
  id,
  quantity,
  setDataTable,
  list,
  selectables,
  updateQuantity,
  electronicBill,
  removeOption,
}) => {
  if (quantity > 1) {
    setDataTable(
      list.map(item => {
        if (id === item?.id) {
          item?.detail_id &&
            !selectables &&
            updateQuantity({
              variables: {
                FK_ProductFeature: item?.id,
                FK_Bill: electronicBill?.id,
                Quantity_BillDetail: -1,
              },
            });
          return {
            ...item,
            quantity: item.quantity - 1,
            total: (item.unit_price * (item.quantity - 1)).toFixed(3),
          };
        }
        return item;
      }),
    );
  }
  if (removeOption && quantity === 1) {
    setDataTable(
      list.map(item => {
        if (id === item?.id) {
          item?.detail_id &&
            !selectables &&
            updateQuantity({
              variables: {
                FK_ProductFeature: item?.id,
                FK_Bill: electronicBill?.id,
                Quantity_BillDetail: -1,
              },
            });
          return {
            ...item,
            quantity: 0,
          };
        }
        return item;
      }),
    );
  }
};

export const handleProductList = (id, name, setDataTable, list, value) => {
  setDataTable(
    list.map(item => {
      if (id === item?.id) {
        return {
          ...item,
          [name]: value,
        };
      }
      return item;
    }),
  );
};

export const handleIncrement = (
  id,
  setDataTable,
  list,
  selectables,
  updateQuantity,
  electronicBill,
) => {
  setDataTable(
    list.map(item => {
      if (
        id === item.id &&
        item.quantity <
          (item.maxQuantity !== undefined
            ? item.maxQuantity
            : Number.MAX_SAFE_INTEGER)
      ) {
        item.detail_id &&
          !selectables &&
          updateQuantity({
            variables: {
              FK_ProductFeature: item?.id,
              FK_Bill: electronicBill?.id,
              Quantity_BillDetail: 1,
            },
          });
        return {
          ...item,
          quantity: item.quantity + 1,
          total: (item.unit_price * (item.quantity + 1)).toFixed(3),
        };
      }
      return item;
    }),
  );
};

export const handleDeleteProduct = (
  product,
  setDataTable,
  list,
  selectables,
  deleteDetail,
) => {
  product.detail_id &&
    !selectables &&
    deleteDetail({ variables: { id: product?.detail_id } });
  setDataTable(list.filter(item => product?.id !== item?.id));
};

export const handleCheck = (product_ID, value, setDataTable, list) => {
  setDataTable(
    list.map(item =>
      item.id === product_ID && item.valid ? { ...item, checked: value } : item,
    ),
  );
};
