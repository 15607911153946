import React from "react";
import { ListItemButton, Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import plusIcon from "../../../assets/icons/plus.svg";
import NestedMenu from "./NestedMenu";

function MenuIcon({
  route,
  isSideBarOpen,
  toggleActiveRoutes,
  index,
  selectedOption,
  isSubMenuOpen,
}) {
  return (
    <React.Fragment>
      <ListItemButton
        key={route.name}
        className={`sidebar-menu-item ${isSideBarOpen ? "invert-order" : "invert-order-reverse"} ${selectedOption === index ? "select-Item-Option" : ""} ${index === 2 ? " menu-option-color  " : ""} `}
        style={{
          background: selectedOption === index && "rgba(57, 110, 156, 1)",
        }}
        tabIndex="0"
        onClick={() => toggleActiveRoutes(index)}
        onKeyDown={() => toggleActiveRoutes(index)}
      >
        <img alt={route.label} className="icon" src={route.icon} />
        <Typography
          typography="defaultLight"
          variant="fixedSizeSubtitle1"
          width="100%"
        >
          {route.label}
        </Typography>
        {route.links && (
          <Box sx={{ marginX: "1.30rem" }}>
            <img alt="agregar" className="plus" src={plusIcon} />
          </Box>
        )}
      </ListItemButton>
      {index === 6 && <hr className="line" />}
      {isSubMenuOpen && route.links && (
        <Box sx={{ bgcolor: "rgba(68, 129, 182, 0.5)", marginX: "1.30rem" }}>
          <NestedMenu links={route.links} />{" "}
        </Box>
      )}
    </React.Fragment>
  );
}

MenuIcon.propTypes = {
  route: PropTypes.object.isRequired,
  isSideBarOpen: PropTypes.bool.isRequired,
  toggleActiveRoutes: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  links: PropTypes.array,
  selectedOption: PropTypes.string.isRequired,
  isSubMenuOpen: PropTypes.string.isRequired,
};
MenuIcon.defaultProps = {
  links: [],
};

export default MenuIcon;
