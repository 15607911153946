const ChipStylesDark = {
  MuiChip: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          color: "rgba(255, 255, 255, 1)",
          border: "1px solid rgba(255, 255, 255, 1)",
          backgroundColor: "transparent",
        },
      },
    ],
  },
};

const ChipStylesLight = {
  MuiChip: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          border: "1px solid rgba(3, 7, 16, 1)",
          backgroundColor: "rgba(18, 59, 100, 1)",
        },
      },
    ],
  },
};

export { ChipStylesDark, ChipStylesLight };
