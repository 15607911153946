import { gql } from "@apollo/client";
/* eslint-disable import/prefer-default-export */
export const GET_DEBIT_NOTES = gql`
  query GetDebitNotes($WhereParams: WhereParams) {
    debitNotesData: getDebitNotes(WhereParams: $WhereParams) {
      id
      FK_ElectronicBillDocumentType
      ElectronicBillDocumentType {
        Description
      }
      FK_User
      User {
        Person {
          Name_Person
          Lastname_Person
          id
        }
      }
      FK_Coin
      Coin {
        Buy_Coin
        Code_Coin
        Name_Coin
        Sell_Coin
        Symbol_Coin
        id
      }
      Place {
        id
        Name_Place
      }
    }
  }
`;
