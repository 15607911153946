/* eslint-disable react/jsx-props-no-spreading */
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";
import styleSelectPhoneNumber from "../Common/PhoneInput/styles";

// eslint-disable-next-line react/prop-types
function Control({ children, getIcon, selected, ...props }) {
  return (
    <components.Control {...props}>
      {getIcon && (
        <img
          alt="Icon"
          className="control-icon"
          // eslint-disable-next-line react/prop-types
          src={getIcon(selected?.label?.toLowerCase() || "crc")}
        />
      )}
      {children}
    </components.Control>
  );
}

// eslint-disable-next-line react/prop-types
function Option({ children, data, getIcon, ...props }) {
  return (
    <components.Option {...props} data>
      <Box sx={{ display: "flex", cursor: "pointer" }}>
        {getIcon && (
          <img
            alt="Icon"
            className="phone-input-flag"
            loading="lazy"
            // eslint-disable-next-line react/prop-types
            src={getIcon(data.label?.toLowerCase() || "crc")}
          />
        )}
        <Typography typography="modeButton" variant="body2" width="100%">
          {children}
        </Typography>
      </Box>
    </components.Option>
  );
}
function SelectComponent({ getIcon, options, selected, onChange }) {
  const control = props => (
    <Control {...props} getIcon={getIcon} selected={selected} />
  );
  const option = props => <Option {...props} getIcon={getIcon} />;

  return (
    <Box sx={{ width: "100px" }}>
      <ReactSelect
        className="input without-padding "
        components={{
          DropdownIndicator: null,
          Control: control,
          Option: option,
        }}
        defaultValue={selected}
        options={options}
        styles={styleSelectPhoneNumber}
        theme={theme => ({
          ...theme,
          background: "transparent",
          colors: {
            ...theme.colors,
            primary: "transparent",
            neutral20: "transparent",
            neutral30: "transparent",
          },
        })}
        onChange={onChange}
      />
    </Box>
  );
}

SelectComponent.propTypes = {
  getIcon: PropTypes.func,
  options: PropTypes.array,
  selected: PropTypes.object,
  onChange: PropTypes.func,
};

SelectComponent.defaultProps = {
  getIcon: null,
  options: [],
  selected: null,
  onChange: null,
};

export default SelectComponent;
