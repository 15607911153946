/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_PLACES_BY_USER = gql`
  query getPlaces($FK_User: ID!, $FK_Module: ID!) {
    places: getPlacesByUserAndModule(FK_User: $FK_User, FK_Module: $FK_Module) {
      id
      Name_Place
      Description_Place
      Code
      Files {
        id
        Route_File
      }
      Company {
        id
        Name_Company
        Description_Company
        Files {
          id
          Route_File
        }
      }
      State_Place
      Gmail
      Gmail_App
    }
  }
`;
export const GET_PLACE_BY_ID = gql`
  query getPlace($id: String) {
    place: getPlace(id: $id) {
      id
      Name_Place
      Description_Place
      Code
      Files {
        id
        Route_File
      }
      Company {
        id
        Name_Company
        Description_Company
        Files {
          id
          Route_File
        }
      }
      State_Place
      Gmail
      Gmail_App
    }
  }
`;
export const GET_PLACE_BY_NAME = gql`
  query placeByName($name: String) {
    place: getPlaceByName(name: $name) {
      id
      Name_Place
      Description_Place
      Code
      Files {
        id
        Route_File
      }
      Company {
        id
        Name_Company
        User {
          id
        }
      }
    }
  }
`;
export const GET_PLACE_BY_USER_ID = gql`
  query getPlaceByUserId($FK_User: ID) {
    place: placeByUserId(FK_User: $FK_User) {
      id
    }
  }
`;
