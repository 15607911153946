/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_ACTIVITIES = gql`
  query getActivities {
    activities: getAllActivities {
      id
      id_MH
      Name
    }
  }
`;
export const GET_ACTIVITIES_BY_CRITERIA = gql`
  query getByCriteria($criteria: String) {
    activities: findActivitiesByCriteria(criteria: $criteria) {
      id
      id_MH
      Name
    }
  }
`;
export const GET_ACTIVITIES_BY_ID_MH = gql`
  query getById_MH($id_MH: String!) {
    activity: findActivitiesById_MH(id_MH: $id_MH) {
      id
      id_MH
      Name
    }
  }
`;
