/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const REGISTER_NEW_CLIENT_GROUP = gql`
  mutation createClientGroup($data: ClientGroupInput) {
    clientGroup: createClientGroup(data: $data) {
      id
      Name_ClientGroup
      Description_ClientGroup
      Place {
        id
        Name_Place
      }
      Clients {
        id
        ClientGroup {
          id
        }
      }
    }
  }
`;

export const UPDATE_CLIENT_GROUP = gql`
  mutation updateClientGroup($data: ClientGroupInput) {
    clientGroup: updateClientGroup(data: $data) {
      id
      Name_ClientGroup
      Description_ClientGroup
      Place {
        id
        Name_Place
      }
      Clients {
        id
        ClientGroup {
          id
        }
      }
    }
  }
`;

export const DELETE_CLIENT_GROUP = gql`
  mutation deleteClientGroup($id: ID!) {
    clientGroup: deleteClientGroup(id: $id)
  }
`;
