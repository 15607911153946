import React from "react";
import PropTypes from "prop-types";
// SCSS
import "./loading.scss";
import { CircularProgress } from "@mui/material";

function Loading({ size }) {
  return <CircularProgress disableShrink />;
}
Loading.propTypes = {
  size: PropTypes.string,
};
Loading.defaultProps = {
  size: "",
};

export default Loading;
