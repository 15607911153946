import React from "react";
import PropTypes from "prop-types";
// component
import { Typography } from "@mui/material";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
// SCSS
import "./ConfirmationModal.scss";

function ConfirmationModal({ header, body, onCancel, onConfirm, textConfirm }) {
  return (
    <div className="container-modal modal-content-size">
      <Typography
        align="center"
        fontWeight={600}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        {header}
      </Typography>
      <Typography
        align="center"
        typography="modeColor"
        variant="subtitle1"
        width="100%"
      >
        {body}
      </Typography>
      <FormWithButtons
        submitText={textConfirm || "Confirmar"}
        onCancel={onCancel}
        onSubmit={onConfirm}
      />
    </div>
  );
}

ConfirmationModal.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  textConfirm: PropTypes.string,
};

ConfirmationModal.defaultProps = {
  textConfirm: "",
};

export default ConfirmationModal;
