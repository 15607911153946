import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import PropTypes from "prop-types";
import "./CustomCropper.scss";

function CustomCropper({
  upImg,
  crop,
  onChange,
  onComplete,
  onLoad,
  previewCanvasRef,
  style,
  imgRef,
}) {
  return (
    <div
      style={{
        width: "100%",
        maxHeight: "15rem",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <ReactCrop
          aspect={1}
          crop={crop}
          style={{
            display: "flex",
            maxwWidth: "7rem",
            maxHeight: "7rem",
            justifyContent: "center",
            alignItems: "center",
          }}
          onChange={onChange}
          onComplete={onComplete}
          onImageLoaded={onLoad}
        >
          <img
            ref={imgRef}
            alt="imagen"
            src={upImg}
            style={{ display: "block", margin: "auto" }}
          />
        </ReactCrop>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          marginTop: "1rem",
        }}
      >
        <canvas ref={previewCanvasRef} style={style} />
      </div>
    </div>
  );
}

CustomCropper.propTypes = {
  upImg: PropTypes.string.isRequired,
  crop: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  onLoad: PropTypes.string.isRequired,
  previewCanvasRef: PropTypes.string.isRequired,
  style: PropTypes.object,
  imgRef: PropTypes.string.isRequired,
};
CustomCropper.defaultProps = { style: {} };

export default CustomCropper;
