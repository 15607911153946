import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Box, Card, Typography } from "@mui/material";
// Utils
import { calculateTaxes, formatNumber } from "../../utils/ReportUtils";
import { useElectronic } from "../../context/ElectronicContext";

function ExpansionPanel({ bill }) {
  const [isActive, setIsActive] = useState(false);
  const { useCoinDetail } = useElectronic();
  const { toExchangeRate } = useCoinDetail;

  const calculateTotal = (Quantity, price, percent) => {
    const total = Quantity * price + calculateTaxes(Quantity * price, percent);
    return total;
  };

  return (
    <Card
      color={!isActive ? "borderbottom" : "borderbottomActive"}
      sx={{ height: !isActive ? "48px" : "100%", minHeight: "48px" }}
    >
      <Card
        key={bill.InternConsecutive}
        sx={{
          display: "flex",
          gap: "1rem",
          justifyContent: "space-between",
          paddingX: "1rem",
          background: "transparent",
          boxShadow: "none",
          alignItems: "center",
          height: !isActive ? "100%" : "auto",
          minHeight: "48px",
        }}
        tabIndex="0"
        onClick={() => setIsActive(!isActive)}
        onKeyDown={() => setIsActive(!isActive)}
      >
        <Typography
          style={{ cursor: "pointer" }}
          typography="modeColor"
          variant="body2"
        >
          Líneas de detalle
        </Typography>
        <Typography
          fontWeight={600}
          style={{ cursor: "pointer" }}
          typography="modeColor"
          variant="h6"
        >
          {isActive ? "-" : "+"}
        </Typography>
      </Card>
      {isActive && (
        <Card
          color="optionCardSelect"
          sx={{
            margin: "1rem",
            padding: "0.2rem",
            gap: "1rem",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            height: "7rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            {bill.ElectronicBillDetail.Length > 0 ? (
              bill.ElectronicBillDetail.map(detail => (
                <Card key={detail.id} sx={{ padding: "0.2rem" }}>
                  <Typography
                    fontWeight={600}
                    typography="modeColor"
                    variant="body2"
                  >
                    {detail?.ProductFeature?.Product?.Name_Product}
                  </Typography>
                  <Typography typography="modeColor" variant="body2">
                    <b>Código: </b>
                    {detail?.ProductFeature?.Codes[0]?.FeatureCode},
                  </Typography>
                  <Typography typography="modeColor" variant="body2">
                    <b>
                      {detail.Quantity}(
                      {
                        detail?.ProductFeature?.Product?.InventoryDetail
                          ?.UnitOfMeasurement?.Symbol
                      }
                      {" x "}
                    </b>
                    {formatNumber(toExchangeRate(detail?.Price?.toFixed(2)))}
                  </Typography>
                  <Typography typography="modeColor" variant="body2">
                    <b>
                      {formatNumber(
                        toExchangeRate(
                          calculateTaxes(
                            detail.Quantity * (detail?.Price || 0),
                            detail?.ProductFeature?.ProductPrice?.Tariff
                              ?.Percent,
                          ).toFixed(2),
                        ),
                      )}{" "}
                    </b>
                    {detail?.ProductFeature?.ProductPrice?.Tariff?.Percent}
                    %)
                  </Typography>
                  <Typography typography="modeColor" variant="body2">
                    <b> Total: </b>
                    {formatNumber(
                      toExchangeRate(
                        calculateTotal(
                          detail.Quantity,
                          detail?.Price,
                          detail?.ProductFeature?.ProductPrice?.Tariff?.Percent,
                        ).toFixed(2),
                      ),
                    )}
                  </Typography>
                </Card>
              ))
            ) : (
              <Typography
                fontWeight={600}
                style={{ cursor: "pointer" }}
                typography="modeColor"
                variant="body1"
              >
                No existen detalles
              </Typography>
            )}
          </Box>
        </Card>
      )}
    </Card>
  );
}
ExpansionPanel.propTypes = {
  bill: PropTypes.object.isRequired,
};

export default ExpansionPanel;
