/* eslint new-cap: ["error", { "newIsCap": false }] */
import React from "react";
// External components
import { Box, Card, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// External library
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
// Graphql
import { useApolloClient } from "@apollo/client";
import { GET_CLIENTS_BY_PLACE } from "../../graphql";
// Context
import { useAuth } from "../../context";
// utils
import { customToast as toast, downloadCSV } from "../../utils";
// SCSS
import "../Inventories/Inventories.scss";
import "./ClientListReport.scss";
import usePlaces from "../../hooks/usePlaces";

function ClientListReport() {
  const client = useApolloClient();
  const { state } = useAuth();
  const user = state.user.TavuelUser;
  const { GetPLacesByUserIdAndModuleId: getPLaces } = usePlaces();
  const [loading, placesData, error] = getPLaces(user?.id);
  const generatePDF = (clients, namePlace) => {
    const doc = new jsPDF();
    const tableRows = [];
    doc.setFillColor(245, 245, 245);
    doc.rect(
      0,
      0,
      doc.internal.pageSize.width,
      doc.internal.pageSize.height,
      "F",
    );

    doc.setFont("helvetica");
    doc.setFontSize(12);
    doc.text("Conta-Fast", 8, 15);
    doc.text("Lista de clientes", 8, 20);
    doc.text(`${namePlace}`, 8, 25);
    doc.text(`${format(new Date(), "yyyy-MM-dd")}`, 180, 15);

    clients.forEach(index => {
      const ticketData = [
        index?.BillingProfile?.MH_PersonType?.Name_PersonType,
        index?.BillingProfile?.ID_Number,
        index?.BillingProfile?.Name,
        index?.BillingProfile?.Email,
        index?.BillingProfile?.PhoneNumber,
        `${
          index?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Province
            ?.Name
        },${index?.BillingProfile?.MH_Neighborhood?.District?.Canton?.Name}, ${
          index?.BillingProfile?.MH_Neighborhood?.District?.Name
        },${index?.BillingProfile?.MH_Neighborhood?.Name}, ${
          index?.BillingProfile?.OtherSigns !== null
            ? index?.BillingProfile?.OtherSigns
            : ""
        }`,
      ];
      tableRows.push(ticketData);
    });

    doc.autoTable({
      columns: [
        "Tipo de identificación",
        "Identificación",
        "Nombre",
        "Correo electrónico",
        "Teléfono",
        "Dirección",
      ],

      body: tableRows,
      rowPageBreak: "avoid",
      startY: 30,
      columnStyles: {
        0: {
          fillColor: [245, 245, 245],
        },
        1: {
          fillColor: [245, 245, 245],
        },
        2: {
          fillColor: [245, 245, 245],
        },
        3: {
          fillColor: [245, 245, 245],
        },
        4: {
          fillColor: [245, 245, 245],
        },
        5: {
          fillColor: [245, 245, 245],
        },
      },
      theme: "grid",
      headStyles: {
        fillColor: [245, 245, 245],
        textColor: [3, 7, 16],
        lineColor: [3, 7, 16],
        lineWidth: 0.01,
        cellPadding: {
          top: 4,
          right: 1,
          bottom: 4,
          left: 1,
        },
        valign: "middle",
        halign: "center",
      },
      styles: {
        valign: "middle",
        lineColor: [3, 7, 16],
      },
      bodyStyles: {
        cellPadding: {
          top: 4,
          right: 1,
          bottom: 4,
          left: 1,
        },
      },
      tableLineColor: [3, 7, 16],
      tableLineWidth: 0.1,
    });

    doc.save(`clientes_${namePlace}.pdf`);

    toast.success("Reporte generado con éxito");
  };

  const handleGenerateReport = async (place, typeDocument) => {
    const { data } = await client.query({
      query: GET_CLIENTS_BY_PLACE,
      variables: {
        FK_Place: place?.id,
      },
      fetchPolicy: "no-cache",
    });

    if (data?.client?.length > 0) {
      // gerate report

      const filteredArr = data?.client.reduce((acc, current) => {
        const x = acc.find(
          item =>
            item.BillingProfile?.ID_Number ===
            current.BillingProfile?.ID_Number,
        );
        if (!x) {
          return acc.concat([current]);
        }
        return acc;
      }, []);

      if (typeDocument === 1) {
        generatePDF(filteredArr, place?.Name_Place);
      } else {
        const columns = [
          {
            Header: "MH_PersonType",
            id: "MH_PersonType",
            accessor: "index.BillingProfile.MH_PersonType.Name_PersonType",
          },
          {
            Header: "ID_Number",
            id: "ID_Number",
            accessor: "index.BillingProfile.ID_Number",
          },
          {
            Header: "Name",
            id: "Name",
            accessor: "index.BillingProfile.Name",
          },
          {
            Header: "Email",
            id: "Email",
            accessor: "index.BillingProfile.Email",
          },
          {
            Header: "PhoneNumber",
            id: "PhoneNumber",
            accessor: "index.BillingProfile.PhoneNumber",
          },
          {
            Header: "Province",
            id: "Province",
            accessor:
              "index.BillingProfile.MH_Neighborhood.District.Canton.Province.Name",
          },
          {
            Header: "Canton",
            id: "Canton",
            accessor:
              "index.BillingProfile.MH_Neighborhood.District.Canton.Name",
          },
          {
            Header: "District",
            id: "District",
            accessor: "index.BillingProfile.MH_Neighborhood.District.Name",
          },
          {
            Header: "MH_Neighborhood",
            id: "MH_Neighborhood",
            accessor: "index.BillingProfile.MH_Neighborhood.Name",
          },
          {
            Header: "OtherSigns",
            id: "OtherSigns",
            accessor: "index.BillingProfile.OtherSigns",
          },
        ];
        downloadCSV(data?.client, `clientes_${place?.Name_Place}`, columns);
      }
    } else {
      toast.success(`No hay clientes registrados para ${place?.Name_Place} `);
    }
  };

  const getUrl = placeItem => {
    const route = placeItem.Files?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const validateRoute = placeItem => {
    const route = getUrl(placeItem);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  return (
    <Box className="content-flex-cards">
      {placesData?.places?.map(placeItem => (
        <Card
          color="primary"
          sx={{
            height: "122x",
            width: "333px",
            minWidth: "333px",
            display: "flex",
            flexDirection: "column",
            paddingY: "1rem",
            paddingX: { xs: "0.5rem", sm: "1.5rem" },
            gap: "0.1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <img
              alt="Logo de lugar"
              src={validateRoute(placeItem)}
              style={{ width: "45px", height: "45px" }}
            />
            <Typography
              fontWeight={600}
              typography="modeColor"
              variant="subtitle1"
            >
              {placeItem.Name_Place}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "end",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "0.2rem",
                alignItems: "center",
              }}
            >
              <FontAwesomeIcon
                icon="download"
                size="lg"
                style={{ marginRight: "0.4rem" }}
              />
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleGenerateReport(placeItem, 2)}
              >
                CSV
              </Button>
            </Box>
            <Button
              color="primary"
              variant="outlined"
              onClick={() => handleGenerateReport(placeItem, 1)}
            >
              PDF
            </Button>
          </Box>
        </Card>
      ))}
    </Box>
  );
}

export default ClientListReport;
