import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
// External components
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Box } from "@mui/material";
import { Delete, Notification } from "rbx";

// Components
import SendBillScreen from "../SendBillScreen";
import Paginated from "../Paginated";
import Loading from "../Loading";
// Context
import { useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Hook
import useElectronicBill from "../../hooks/useElectronicBill";
// Graphql
import { client_EB_api } from "../../graphql/client";
import {
  GET_ELECTRONICS_BILLS_NEW,
  GET_MANY_XML,
  SEND_MANY_XML,
} from "../../graphql";
// Enums
import { ElectronicBillErrors } from "../../Enums/Errors";
import DEFAULT_COLUMNS from "./Enums/Columns";
// Utils
import { downloadBase64File } from "../../utils/helpers";
// SCSS
import "./EmmitedDocumentReport.scss";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import { customToast } from "../../utils";

function EmmitedDocumentReport() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedRows, setSelectedRows] = useState([]);
  const [xmlErrors, setxmlErrors] = useState({});
  const [tableData, setTableData] = useState([]);

  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();
  const { getBillByElectronicBill } = useElectronicBill();

  const handleSelectRows = selected_Rows => {
    if (selected_Rows.length !== 1) {
      setSelectedRows(selected_Rows);
      return;
    }
    setSelectedRows(prev =>
      selected_Rows.reduce(
        (acc, act) => {
          const elementToRemoveIndex = acc.indexOf(act);
          if (elementToRemoveIndex < 0) {
            acc.push(act);
            return acc;
          }
          acc.splice(elementToRemoveIndex, 1);
          return acc;
        },
        [...prev],
      ),
    );
  };

  const [getData, { data, loading }] = useLazyQuery(GET_ELECTRONICS_BILLS_NEW, {
    variables: {
      getElectronicBillsInput: {
        where: {
          equals: {
            FK_OwnerPlace: LineDetailPlace.id,
            FK_Place: LineDetailPlace.id,
          },
          inMonth: selectedDate,
        },
        orderBy: [
          {
            columnName: "EmitedDay",
            order: "DESC",
          },
        ],
      },
    },
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    getData();
    setSelectedRows([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  useEffect(() => {
    setTableData(
      data?.getElectronicBillsNew?.ElectronicBills?.map(dataRow => ({
        ...dataRow,
        selectedInTable: false,
      })),
    );
  }, [data]);

  const handleDownloadEmails = async () => {
    const resp = await client_EB_api.query({
      query: GET_MANY_XML,
      variables: {
        keys: selectedRows,
      },
    });
    const respPayload = resp.data.downloadManyXML;
    const groupedResult = respPayload.reduce(
      (acc, item) => {
        if (item.error) {
          acc.errors[item.error] = acc.errors[item.error]
            ? [...acc.errors[item.error], item.searchedParam.substr(-12)]
            : [item.searchedParam.substr(-12)];
          return acc;
        }
        acc.validXML.push(...item.params);
        return acc;
      },
      { errors: {}, validXML: [] },
    );

    setxmlErrors(groupedResult.errors);
    if (respPayload[0].params.length > 0) {
      downloadBase64File(respPayload[0].payload, "xmls.zip");
    }
    if (groupedResult.validXML.length > 0) {
      customToast.success(`Se Encontraron Documentos`);
    }
  };

  const handleSendEmails = async emails => {
    const resp = await client_EB_api.query({
      query: SEND_MANY_XML,
      variables: {
        keys: selectedRows,
        ...(emails.length > 0 && { emails, place_id: LineDetailPlace.id }),
      },
    });
    if (resp) {
      customToast.success(`Documentos enviados al correo(s).`);
    } else {
      customToast.error(`Documentos no enviados.`);
    }
  };

  const handleRemoveError = attribute_name => {
    setxmlErrors(prev => {
      const temp = { ...prev };
      delete temp[attribute_name];
      return temp;
    });
  };

  const castElectronicBillsForTable = electronicBills =>
    electronicBills.map(electronicBill => ({
      ...electronicBill,
      Bill: getBillByElectronicBill(electronicBill),
    }));

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "1rem",
          justifyContent: "space-between",
          height: "100%",
          alignItems: { xs: "end", sm: "start" },
        }}
      >
        <DatePicker
          color="primary"
          label="Mes y año de emisión"
          size="small"
          sx={{
            "& .MuiFormLabel-root": {
              transform: " translate(14px, 5px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              padding: "24px 14px 10px 14px ",
            },
            "maxHeight": "48px",
            "width": { xs: "100%", sm: "322px" },
          }}
          value={selectedDate}
          variant="filled"
          views={["month", "year"]}
          onChange={newDate => {
            setSelectedDate(newDate);
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "0.4rem",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <FormWithButtons
            buttonsAlignment="end"
            cancelText="Descargar"
            displayCancel={!(selectedRows?.length > 0)}
            displaySubmit={!(selectedRows?.length > 0)}
            submitText="   Enviar Facturas"
            onCancel={() =>
              setModalOpen(
                true,
                <SendBillScreen
                  handleCloseModal={() => setModalOpen(false)}
                  handleSend={handleSendEmails}
                  place={LineDetailPlace}
                />,
              )
            }
            onSubmit={() => handleDownloadEmails()}
          />
        </Box>
      </Box>
      {xmlErrors[ElectronicBillErrors.NOT_FOUND] && (
        <Notification color="danger">
          <Delete
            as="button"
            onClick={() => handleRemoveError(ElectronicBillErrors.NOT_FOUND)}
          />
          <p>
            No se lograron encontrar los siguientes XML:
            <b>
              {" "}
              {xmlErrors[ElectronicBillErrors.NOT_FOUND]
                .map(item => `...${item}`)
                .toString()
                .replaceAll(",", ", ")}
            </b>
          </p>
        </Notification>
      )}

      {xmlErrors[ElectronicBillErrors.FILE_CORRUPTED] && (
        <Notification color="danger">
          <Delete
            as="button"
            onClick={() =>
              handleRemoveError(ElectronicBillErrors.FILE_CORRUPTED)
            }
          />
          <p>
            Los siguientes XML presentan errores y no se pudieron obtener:
            <b>
              {" "}
              ...
              {xmlErrors[ElectronicBillErrors.NOT_FOUND]
                .map(item => `...${item}`)
                .toString()
                .replaceAll(",", ", ")}
            </b>
          </p>
        </Notification>
      )}
      {loading && <Loading />}
      {tableData?.length > 0 && !loading && (
        <Paginated
          selectables
          columns={DEFAULT_COLUMNS}
          data={castElectronicBillsForTable(
            data.getElectronicBillsNew?.ElectronicBills,
          )}
          maxWidth={1000}
          selectField="Key"
          selectedRows={selectedRows}
          setSelectedRows={handleSelectRows}
        />
      )}
    </Box>
  );
}

export default EmmitedDocumentReport;
