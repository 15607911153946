/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_COINS = gql`
  query getAllCoins {
    coins: getAllCoins {
      id
      Name_Coin
      Symbol_Coin
      Sell_Coin
      Buy_Coin
      Code_Coin
    }
  }
`;
