import React, { useState } from "react";
// External components
import { Box, Button, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// GraphQL
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  GET_EB_SUBMODULE_PERMISSION,
  UDPATE_EB_SUBMODULE_PERMISSION,
} from "../../graphql";
// Context
import { useAuth } from "../../context";
// Enums
import { ElectronicBillAcceptanceStates } from "../../Enums/ElectronicBill";
import { ESUBMODULEROUTES } from "../../Enums/Submodules";
import LandingPage from "../../pages/LandingPage";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
  formatData,
} from "../../utils/helpers";
import { useElectronic } from "../../context/ElectronicContext";
import ElectronicBillExpenseCard from "../ElectronicBillExpenseCard/ElectronicBillExpenseCard";
import SelectForm from "../SelectForm/SelectForm";
import "./DocumentAcceptance.scss";
import { customToast } from "../../utils";

function DocumentsAcceptance() {
  const [selectedDate, setSelectedDate] = useState();
  const [currentElectronicBills, setCurrentElectronicBills] = useState([]);
  const [initialElectronicBills, setInitialElectronicBills] = useState([]);
  const [globalMultiselectState, setGlobalMultiselectState] = useState(
    ElectronicBillAcceptanceStates.ACCEPTED,
  );

  const { permissions, state: authState } = useAuth();
  const { LineDetailPlace } = useElectronic();

  const [updateElectronicBill] = useMutation(UDPATE_EB_SUBMODULE_PERMISSION);
  const { haveActionPermission, noAccessMessage } = permissions;

  const canEdit = haveActionPermission("Edit", "/expenses", LineDetailPlace);

  const [
    getElectronicBillsByDate,
    {
      data: responseGetElectronicBillsByDate,
      refetch: refetchGetElectronicBillsByDate,
      loading,
    },
  ] = useLazyQuery(GET_EB_SUBMODULE_PERMISSION, {
    onCompleted: response => {
      setInitialElectronicBills(
        response?.getEBSubmodulePermission?.ElectronicBills,
      );
      setCurrentElectronicBills(
        response?.getEBSubmodulePermission?.ElectronicBills,
      );
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const handleChangeSelect = (name, value, id) => {
    if (!canEdit) {
      customToast.error(noAccessMessage("Editar", "Egresos"));
      return;
    }
    setCurrentElectronicBills(
      responseGetElectronicBillsByDate?.getEBSubmodulePermission?.ElectronicBills.map(
        electronicBill =>
          electronicBill.id === id
            ? { ...electronicBill, FK_AcceptanceState: parseInt(value, 10) }
            : electronicBill,
      ),
    );
  };

  const handleSearch = async () => {
    if (selectedDate.$y > new Date().getFullYear()) {
      customToast.error("El año debe ser igual o anterior al actual en curso");
    }
    if (authState.user.TavuelUser.id) {
      handleGetElectronicBillsByDate();
    }
  };

  const handleSaveEB = async ebAttributes => {
    if (!canEdit) {
      customToast.error(noAccessMessage("Editar", "Egresos"));
      return;
    }
    const { id, FK_AcceptanceState } = currentElectronicBills.find(
      item => item.id === ebAttributes.id,
    );

    const { data } = await updateElectronicBill({
      variables: {
        data: {
          ebData: {
            Id: parseInt(id, 10),
            FK_AcceptanceState:
              FK_AcceptanceState ?? ElectronicBillAcceptanceStates.ACCEPTED,
          },
          submodulePermissionData: {
            placeId: LineDetailPlace.id,
            submoduleRoute: ESUBMODULEROUTES.EXPENSES,
          },
        },
      },
    });

    if (data.updateEBSubmodulePermission) {
      customToast.success("Factura actualizada correctamente");
      refetchGetElectronicBillsByDate();
    } else customToast.error("Hubo un error al intentar actualizar la factura");
  };
  const handleSaveAllEBAcceptanceState = async () => {
    const electronicBillsAbleToChange =
      initialElectronicBills.length > 0
        ? initialElectronicBills.filter(
            initialElectronicBill =>
              initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.NO_STATUS ||
              initialElectronicBill.FK_AcceptanceState ===
                ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED,
          )
        : [];

    if (
      electronicBillsAbleToChange.some(
        electronicBill =>
          electronicBill.FK_AcceptanceState ===
          ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED,
      ) &&
      parseInt(globalMultiselectState, 10) ===
        ElectronicBillAcceptanceStates.REJECTED
    ) {
      customToast.error(
        "¡No se pueden rechazar facturas parcialmente aceptadas!",
      );
      return;
    }

    const updateElectronicBillsPromises = electronicBillsAbleToChange.map(
      initialElectronicBill =>
        updateElectronicBill({
          variables: {
            data: {
              ebData: {
                Id: parseInt(initialElectronicBill.id, 10),
                FK_AcceptanceState: globalMultiselectState,
              },
              submodulePermissionData: {
                placeId: LineDetailPlace.id,
                submoduleRoute: ESUBMODULEROUTES.EXPENSES,
              },
            },
          },
        }),
    );

    const responsePromises = await Promise.all(updateElectronicBillsPromises);

    if (
      responsePromises.every(
        responseItem => responseItem?.data?.updateEBSubmodulePermission,
      )
    ) {
      customToast.success("Facturas actualizadas correctamente");
      refetchGetElectronicBillsByDate();
    } else
      customToast.error("Hubo un error al intentar actualizar las facturas");
  };

  const handleGetElectronicBillsByDate = async () => {
    if (LineDetailPlace.id === 0) {
      customToast.error("Debe seleccionar un lugar antes de buscar facturas.");
      return;
    }

    await getElectronicBillsByDate({
      variables: {
        data: {
          searchData: {
            where: {
              equals: {
                FK_OwnerPlace: LineDetailPlace.id,
                BillFlowType: "1",
              },
              inMonth: selectedDate,
            },
          },
          submodulePermissionData: {
            placeId: LineDetailPlace.id,
            submoduleRoute: ESUBMODULEROUTES.EXPENSES,
          },
        },
      },
    });
  };

  const getMultiselectItemStyles = multiSelectItem =>
    multiSelectItem.initialElectronicBillAcceptanceState ===
      ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED &&
    multiSelectItem.Id === ElectronicBillAcceptanceStates.REJECTED
      ? { display: "none" }
      : { display: "block" };

  const multiSelectData = [
    {
      Id: ElectronicBillAcceptanceStates.ACCEPTED,
      Name: "Aceptado",
      Styles: multiSelectItem => getMultiselectItemStyles(multiSelectItem),
    },
    {
      Id: ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED,
      Name: "Aceptado parcial",
      Styles: multiSelectItem => getMultiselectItemStyles(multiSelectItem),
    },
    {
      Id: ElectronicBillAcceptanceStates.REJECTED,
      Name: "Rechazado",
      Styles: multiSelectItem => getMultiselectItemStyles(multiSelectItem),
    },
  ];

  const currentElectronicBillsSortedAscendant = sortArrayItemsByDate(
    currentElectronicBills,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "1rem",
          alignItems: { xs: "start", md: "center" },
          justifyContent: { xs: "start", md: "space-between" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", md: "auto" },
            gap: "0.5rem",
          }}
        >
          <Typography typography="modeColor" variant="body1" width="100%">
            Buscar por mes y año
          </Typography>

          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <DatePicker
              color="primary"
              label="Mes y año de emisión"
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  transform: " translate(14px, 5px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  padding: "24px 14px 10px 14px ",
                },
              }}
              value={selectedDate}
              variant="filled"
              views={["month", "year"]}
              onChange={newDate => {
                setSelectedDate(newDate);
              }}
            />
            <Button
              color="secondary"
              disabled={!selectedDate}
              variant="contained"
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: { xs: "100%", sm: "auto", md: "100%" },
            gap: "0.5rem",
          }}
        >
          <Typography typography="modeColor" variant="body1">
            Actualizar estado de aceptación de las facturas que no tienen
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: "1rem",
            }}
          >
            <SelectForm
              color="secondary"
              data={formatData(multiSelectData, "Name")}
              label="Tipo de documentos"
              name="is_accepted"
              size="small"
              value={globalMultiselectState}
              variant="filled"
              onChange={event => {
                setGlobalMultiselectState(event.target.value);
              }}
            />
            <Button
              fullWidth
              color="primary"
              size="small"
              variant="contained"
              onClick={handleSaveAllEBAcceptanceState}
            >
              Guardar todas las Facturas electrónicas
            </Button>
          </Box>
        </Box>
      </Box>

      <div className="content-flex-cards">
        {Array.isArray(currentElectronicBillsSortedAscendant) &&
        currentElectronicBillsSortedAscendant.length > 0 ? (
          currentElectronicBillsSortedAscendant?.map(currentElectronicBill => {
            const initialElectronicBill = initialElectronicBills.find(
              initialEB => initialEB.id === currentElectronicBill.id,
            );

            return (
              <ElectronicBillExpenseCard
                actions={{
                  handleSaveEB,
                  handleChange: handleChangeSelect,
                }}
                currentElectronicBill={currentElectronicBill}
                initialElectronicBill={initialElectronicBill}
                multiSelectData={multiSelectData}
              />
            );
          })
        ) : (
          <Typography typography="modeColor" variant="body1" width="100%">
            ¡No se encontraron documentos!
          </Typography>
        )}
        {loading && <LandingPage />}
      </div>
    </React.Fragment>
  );
}

export default DocumentsAcceptance;
