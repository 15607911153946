import React from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import arrowOpen from "../../assets/icons/arrow_open_icon.svg";

function SelectInput({
  disabled,
  value,
  name,
  onChange,
  data,
  placeholder,
  className,
}) {
  const theme = localStorage.getItem("theme");
  return (
    <FormControl fullWidth className="container-button">
      <Select
        displayEmpty
        className={`${className} without-padding"`}
        disabled={disabled}
        name={name}
        value={value}
        onChange={onChange}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {data?.map(
          option =>
            option.id && (
              <MenuItem
                key={option.id}
                className="menuSelect"
                style={{ color: theme === "light" ? "#000" : "#ffff" }}
                value={option.id}
              >
                {option?.Description_Tax || option?.Name}
              </MenuItem>
            ),
        )}
      </Select>
      <div className="icon-select-input">
        <img alt="abrir" className="img" sizes={0.5} src={arrowOpen} />
      </div>
    </FormControl>
  );
}
SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  data: PropTypes.array,
  placeholder: PropTypes.string,
  className: PropTypes.string,
};

SelectInput.defaultProps = {
  disabled: false,
  data: [],
  placeholder: "",
  className: "second-input",
};

export default SelectInput;
