import React, { useCallback } from "react";
import PropTypes from "prop-types";
// External components
import { Button, Container, Typography, Box } from "@mui/material";
// Components
import Inventories from "../../../Inventories";
// Hook
import useZoho from "../../../../hooks/useEmail";
// Context
import { useModal } from "../../../../context";
// Utils
import getImageEmail from "../../../../utils/GetImageEmail";
import { customToast as toast } from "../../../../utils";
// SCSS
import "./CardAccount.scss";

function CardAccount({ account, isSelected, handleChange, AccountManagement }) {
  const { updatePlaceTokenEmail } = useZoho();

  const { setModalOpen } = useModal();

  const updateAccount = useCallback(async () => {
    const selectPlace = async (FK_Place, bill) => {
      setModalOpen(false);
      const response = await updatePlaceTokenEmail(account?.id, FK_Place);
      if (!response?.data?.email) {
        toast.error("Error al actualizar la cuenta");
        return;
      }
      toast.success("¡Cuenta actualizada!");
      setModalOpen(true, <AccountManagement openModal={setModalOpen} />);
    };
    try {
      setModalOpen(
        true,
        <Inventories
          setModalClose={setModalOpen}
          onClickFunction={selectPlace}
        />,
        "No se seleccionó un nuevo lugar para poder realizar el cambio del lugar.",
        true,
      );
    } catch (err) {
      toast.error(err);
    }
  }, [account?.id, setModalOpen, updatePlaceTokenEmail]);

  const getCssImage = () => {
    const namesCss = {
      1: "img-zoho",
      2: "img-gmail",
      3: "img-outlook",
    };
    return namesCss[account?.type];
  };

  return (
    <Container>
      <Button
        color="option"
        sx={{
          width: "100%",
          display: "grid",
          justifyContent: "start",
          gridTemplateColumns: "repeat(3, 1fr)",
          gap: "1rem",
          height: { xs: "50px", md: "60px" },
        }}
        type="button"
        variant="contained"
        onClick={() => !isSelected && handleChange(account)}
      >
        <img
          alt="icon"
          className={getCssImage()}
          src={getImageEmail(account?.type)}
        />
        <Box
          sx={{
            gridColumn: "span 2",
          }}
        >
          <Typography
            align="left"
            typography="modeColor"
            variant="subtitle1"
            width="100%"
          >
            {account?.email}
          </Typography>
        </Box>
      </Button>
      <Container
        sx={{ display: account?.place ? "block" : "none" }}
        onClick={() => updateAccount()}
      >
        <img alt="imagenLogo" src={account?.place?.Logo} />
        <p>{account?.place?.Name_Place}</p>
      </Container>
    </Container>
  );
}

CardAccount.propTypes = {
  account: PropTypes.object.isRequired,
  isSelected: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  AccountManagement: PropTypes.func.isRequired,
};

CardAccount.defaultProps = {
  isSelected: false,
};

export default CardAccount;
