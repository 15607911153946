/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const REGISTER_NEW_PLACE_USER = gql`
  mutation createPlaceU($place: PlaceUsersInput) {
    place: createPlaceUsers(place: $place) {
      id
      Status
      Created_At
      Updated_At
      FK_User {
        id
        NickName_User
      }
      FK_Admin {
        id
        NickName_User
      }
      FK_Place {
        id
        Name_Place
      }
      FK_Role {
        id
      }
    }
  }
`;
