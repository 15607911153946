import React, { useEffect, useRef, useState } from "react";
import { Tabs, Tab, Box } from "@mui/material";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import ScheduledBillSearch from "../../components/ScheduledBillSearch/ScheduledBillSearch";
// IMG
import arrowLeft from "../../assets/icons/arrowLeft.svg";
import arrowRight from "../../assets/icons/arrowRight.svg";

function ScheduledBillSearchPage() {
  const navigate = useNavigate();
  const [tabState, setTabState] = useState(1);
  const tabsRef = useRef(null);
  const [viewButton, setViewButton] = useState();
  const [dimensions, setDimensions] = useState({
    scrollWidth: 0,
    clientWidth: 0,
  });

  const routes = [
    { route: "pendings", value: 1, displayName: "Pendientes" },
    { route: "complete", value: 2, displayName: "Completas" },
    { route: "failed", value: 3, displayName: "Fallidas" },
    { route: "canceled", value: 4, displayName: "Canceladas" },
  ];

  const checkOverflow = () => {
    if (tabsRef.current) {
      const { scrollWidth, clientWidth } = tabsRef.current;
      setDimensions({ scrollWidth, clientWidth });
    }
  };

  const scroll = direction => {
    if (direction === "left") setViewButton(2);
    if (direction === "right") setViewButton(3);
  };

  useEffect(() => {
    navigate("pendings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    navigate("pendings");

    checkOverflow();
    window.addEventListener("resize", checkOverflow);
    return () => window.removeEventListener("resize", checkOverflow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dimensions.scrollWidth < 495) {
      setViewButton(2);
    } else {
      setViewButton(0);
    }
  }, [dimensions]);

  return (
    <Box className="content-flex-column">
      <div ref={tabsRef} className="content-flex-row-between">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            gap: "1rem",
            justifyContent: viewButton !== 2 ? "space-between" : "start",
          }}
        >
          <button
            style={{
              border: "none",
              background:
                "linear-gradient(to right, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",

              cursor: "pointer",
              display:
                dimensions.scrollWidth > 495 || viewButton === 2
                  ? "none"
                  : "flex",
              position: "absolute",
              transform: "translateX(-50%)",
              top: "105px",
              zIndex: "1",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
              right: "300px",
            }}
            type="button"
            onClick={() => scroll("left")}
          >
            <img
              alt="izquierda"
              src={arrowLeft}
              style={{ width: "18px", height: "18px" }}
            />
          </button>
          <Tabs
            sx={{
              "height": { xs: "35px", sm: "50px" },

              "& .MuiTabs-flexContainer": {
                gap: "1rem",
              },
              "margin": 0,
              "width": "100%",
            }}
          >
            {routes.map(routeItem => (
              <Tab
                key={routeItem.value}
                as={Link}
                className={tabState === routeItem.value ? "active" : "tabs"}
                color="option"
                label={routeItem.displayName}
                sx={{
                  height: { xs: "35px", sm: "50px" },
                  padding: 1,
                  maxHeight: "auto",
                  minHeight: "auto",
                  display:
                    viewButton !== 2 && dimensions.scrollWidth < 495
                      ? "none"
                      : "flex",
                  justifyContent: "center",
                  borderRadius: " 5px",
                }}
                to={routeItem.route}
                onClick={() => setTabState(routeItem.value)}
              />
            ))}
          </Tabs>
          <button
            style={{
              border: "none",
              background:
                "linear-gradient(to left, rgba(0, 0, 0, 10), rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0) 100%)",
              cursor: "pointer",
              display:
                dimensions.scrollWidth > 495 || viewButton === 3
                  ? "none"
                  : "flex",
              position: "absolute",
              right: "5px",
              top: "105px",
              zIndex: "10",
              alignItems: "center",
              justifyContent: "center",
              padding: "15px",
              boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
            }}
            tabIndex={0}
            type="button"
            onClick={() => scroll("right")}
          >
            <img
              alt="derecha"
              src={arrowRight}
              style={{ width: "18px", height: "18px" }}
            />
          </button>
        </div>
      </div>
      <Routes>
        <Route element={<ScheduledBillSearch />} path="pendings" />
        <Route element={<ScheduledBillSearch />} path="complete" />
        <Route element={<ScheduledBillSearch />} path="failed" />
        <Route element={<ScheduledBillSearch />} path="canceled" />
      </Routes>
    </Box>
  );
}

export default ScheduledBillSearchPage;
