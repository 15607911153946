/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_D104_CONFIGURATION = gql`
  mutation createD104Configuration(
    $d104ConfigurationInput: D104ConfigurationInput
  ) {
    createD104Configuration(d104ConfigurationInput: $d104ConfigurationInput) {
      id
      FK_User
      Automatic_Process
      Execution_Date
      Hour
      User_Id
      Password
      Created_At
      Updated_At
    }
  }
`;
