import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import "./D104Configuration.scss";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { Checkbox } from "../BooleanInput/components";
import { VALIDATE_CREDENTIALS_D104_CONFIGURATION } from "../../graphql/atv/atv.querys";
import { CREATE_D104_CONFIGURATION } from "../../graphql/d104Configuration/d104Configuration.mutations";
import { CREATE_TIV } from "../../graphql/tiv/tiv.mutations";
import { useAuth } from "../../context";
import { client_EB_api } from "../../graphql/client";
import { customToast } from "../../utils";
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";

function D104Configuration() {
  dayjs.extend(utc);
  const { state: auth } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const inputRefs = useRef([]);

  const INITIAL_STATE = {
    AutomaticProcess: false,
    ExecutionDate: 14,
    Hour: dayjs(),
    User_Id: "",
    Password: "",
    TIV: {
      A: "",
      B: "",
      C: "",
      D: "",
      E: "",
      F: "",
      G: "",
      H: "",
      I: "",
      J: "",
      K: "",
      L: "",
      M: "",
      N: "",
      O: "",
    },
  };
  const [D104configuration, setD104configuration] = useState(INITIAL_STATE);
  const cleanFields = () => setD104configuration(INITIAL_STATE);

  const handleChangeD104configuration = data => {
    setD104configuration(prev => ({ ...prev, ...data }));
  };
  const handleChange = (name, value) => {
    setD104configuration(prev => ({ ...prev, [name]: value }));
  };

  const handleChangeTIV = (name, value, index) => {
    if (value.length <= 2) {
      setD104configuration(prev => ({
        ...prev,
        TIV: { ...prev.TIV, [name]: value },
      }));

      if (value.length === 2 && index < 14) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const validateCredentials = async () => {
    const { data } = await client_EB_api.query({
      query: VALIDATE_CREDENTIALS_D104_CONFIGURATION,
      variables: {
        user: D104configuration.User_Id,
        password: D104configuration.Password,
        tiv: D104configuration.TIV,
      },
    });
    return data.validateCredentialsD104Configuration;
  };

  const saveD104Configuration = async () => {
    try {
      await client_EB_api.mutate({
        mutation: CREATE_D104_CONFIGURATION,
        variables: {
          d104ConfigurationInput: {
            Automatic_Process: D104configuration.AutomaticProcess,
            Execution_Date: D104configuration.ExecutionDate,
            FK_User: auth.user.TavuelUser.id,
            Hour: D104configuration.Hour,
            Password: D104configuration.Password,
            User_Id: D104configuration.User_Id,
          },
        },
      });
      await client_EB_api.mutate({
        mutation: CREATE_TIV,
        variables: {
          tivInput: {
            FK_User: auth.user.TavuelUser.id,
            A: D104configuration.TIV.A,
            B: D104configuration.TIV.B,
            C: D104configuration.TIV.C,
            D: D104configuration.TIV.D,
            E: D104configuration.TIV.E,
            F: D104configuration.TIV.F,
            G: D104configuration.TIV.G,
            H: D104configuration.TIV.H,
            I: D104configuration.TIV.I,
            J: D104configuration.TIV.J,
            K: D104configuration.TIV.K,
            L: D104configuration.TIV.L,
            M: D104configuration.TIV.M,
            N: D104configuration.TIV.N,
            O: D104configuration.TIV.O,
          },
        },
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const handleAddConfiguration = async () => {
    if (
      D104configuration.User === "" ||
      D104configuration.Password === "" ||
      D104configuration.TIV.A === "" ||
      D104configuration.TIV.B === "" ||
      D104configuration.TIV.C === "" ||
      D104configuration.TIV.D === "" ||
      D104configuration.TIV.E === "" ||
      D104configuration.TIV.F === "" ||
      D104configuration.TIV.G === "" ||
      D104configuration.TIV.H === "" ||
      D104configuration.TIV.I === "" ||
      D104configuration.TIV.J === "" ||
      D104configuration.TIV.K === "" ||
      D104configuration.TIV.L === "" ||
      D104configuration.TIV.M === "" ||
      D104configuration.TIV.N === "" ||
      D104configuration.TIV.O === ""
    ) {
      customToast.error("Debe completar todos los datos solicitados.");
    } else {
      const result = await validateCredentials();
      if (result) {
        const isCreated = await saveD104Configuration();
        if (isCreated)
          customToast.success("¡Configuración guardada con éxito!");
      } else {
        cleanFields();
        customToast.error(
          "Por favor revisar sus credenciales del Ministerio de Hacienda",
        );
      }
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
        <Button color="light" size="medium" variant="contained">
          Ver reportes subidos
        </Button>
        <Button color="light" size="medium" variant="contained">
          Ver reportes pendientes
        </Button>
      </Box>
      <Card
        color="optionCardReport"
        sx={{
          paddingY: { xs: "1rem", sm: "1.25rem" },
          paddingX: { xs: "1rem", sm: "3rem" },
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Typography typography="modeColor" variant="subtitle1" width="100%">
          La fecha máxima para presentar la declaración D-104 es el 15 de cada
          mes por lo que la fecha máxima de ejecución va a ser el 14 de cada mes
        </Typography>
        {/* PROGRAMAR D104 */}
        <Box
          sx={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: {
              xs: "repeat(1, 1fr)",
              md: "repeat(3, 1fr)",
            },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Checkbox
              checked={!!D104configuration?.AutomaticProcess}
              defaultValue={false}
              name="D104configuration"
              onChange={value => {
                setD104configuration(prev => ({
                  ...prev,
                  AutomaticProcess: !prev.AutomaticProcess,
                }));
              }}
            />
            <Typography typography="modeColor" variant="subtitle1" width="100%">
              Proceso automático
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography typography="modeColor" variant="subtitle1" width="100%">
              Fecha de ejecución
            </Typography>

            <InputForm
              key="ExecutionDate"
              readOnly
              color="secondary"
              id="ExecutionDate"
              name="ExecutionDate"
              placeholder="0"
              size="small"
              sx={{ maxWidth: { sx: "100%", md: "136px" } }}
              type="number"
              value={D104configuration.ExecutionDate}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <TimePicker
              color="secondary"
              desktopModeMediaQuery="@media (min-width: 0px)"
              format="h:mm A"
              label="Hora"
              name="Hour"
              openClockOnFocus={false}
              size="small"
              sx={{
                "maxWidth": { sx: "100%", md: "180px" },
                "& .MuiFormLabel-root": {
                  transform: " translate(14px, 5px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  padding: "24px 14px 10px 14px ",
                },
              }}
              value={dayjs(D104configuration.Hour).utc()}
              variant="filled"
              onChange={newTime => {
                handleChangeD104configuration({
                  Hour: newTime.utc().format("YYYY-MM-DDTHH:mm:ss.SSS[Z]"),
                });
              }}
            />
          </Box>
        </Box>
      </Card>
      {/* CREDENCIALES */}
      <Card
        color="optionCardReport"
        sx={{
          paddingY: { xs: "1rem", sm: "1.25rem" },
          paddingX: { xs: "1rem", sm: "3rem" },
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Credenciales Ministerio de Hacienda
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "1rem",
          }}
        >
          <InputForm
            key="User_Id"
            readOnly
            color="secondary"
            id="User_Id"
            label="Usuario"
            name="User_Id"
            size="small"
            value={D104configuration.User_Id}
            onChange={e => handleChange(e.target.name, e.target.value)}
          />

          <InputForm
            key="Password"
            readOnly
            color="secondary"
            id="Password"
            label="Contraseña"
            name="Password"
            showPassword={showPassword}
            size="small"
            type={showPassword ? "text" : "password"}
            typeInput="password"
            value={D104configuration.Password}
            onChange={e => handleChange(e.target.name, e.target.value)}
            onClickPassword={() => setShowPassword(!showPassword)}
          />
        </Box>
      </Card>
      {/* TIV */}
      <Card
        color="optionCardReport"
        sx={{
          paddingY: { xs: "1rem", sm: "1.25rem" },
          paddingX: { xs: "1rem", sm: "3rem" },
          gap: "1rem",
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
        }}
      >
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          TIV
        </Typography>
        <Box
          color="optionCardReport"
          sx={{
            display: "grid",
            gap: 2,
            gridTemplateColumns: "repeat(auto-fill, minmax(70px, 1fr))",
          }}
        >
          {Object.keys(D104configuration?.TIV).map((tiv, index) => (
            <Box
              key={tiv}
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                alignItems: "center",
              }}
            >
              <Typography
                align="center"
                fontWeight={600}
                typography="modeColor"
                variant="subtitle1"
                width="100%"
              >
                {tiv}
              </Typography>
              <TextField
                key={tiv}
                required
                autoComplete="off"
                color="tertiaryOnly"
                id={tiv}
                inputRef={el => {
                  inputRefs.current[index] = el;
                }}
                maxLength="2"
                name={tiv}
                sx={{
                  "flex": "1 1 2.5rem",

                  "minWidth": "2.5rem",
                  "width": "2.5rem",
                  "height": "2.5rem",
                  "& .MuiOutlinedInput-root": {
                    "padding": "0px",
                    "& fieldset": {
                      padding: "0px",
                      border: "none",
                    },
                    "&:hover fieldset": {
                      border: "none",
                    },
                    "&.Mui-focused fieldset": {
                      border: "none",
                    },
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "0.2rem",
                    textAlign: "center",
                  },
                }}
                value={D104configuration?.TIV[tiv]}
                variant="outlined"
                onChange={e =>
                  handleChangeTIV(
                    e.target.name,
                    e.target.value.toUpperCase(),
                    index,
                  )
                }
              />
            </Box>
          ))}
        </Box>
      </Card>
      <FormWithButtons
        buttonsAlignment="end"
        cancelText="Descartar"
        submitText="Guardar"
        onCancel={() => cleanFields()}
        onSubmit={() => handleAddConfiguration()}
      />
    </Box>
  );
}
export default D104Configuration;
