const TabsStylesDark = {
  MuiTab: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          "textTransform": "capitalize",
          "color": "#ffff",
          "borderBottom": "1px solid rgba(57, 110, 156, 1)",
          "borderRadius": "2px 2px 0px 0px",
          "&.active": {
            "color": "#ffff",
            " background-color": " rgba(57, 110, 156, 1)",
            "borderBottom": "none",
          },
          "&.active, &:hover": {
            "color": "#ffff",
            " background-color": " rgba(57, 110, 156, 1)",
            "borderBottom": "none",
          },
        },
      },
      {
        props: props => props.color === "secondary",
        style: {
          "textTransform": "capitalize",
          "color": "#ffff",
          "borderBottom": "1px solid rgba(57, 110, 156, 1)",
          "borderRadius": "2px 2px 0px 0px",
          "&.active": {
            "color": "#000",
            " background": "rgba(116, 178, 231, 1)",
            "borderBottom": "none",
          },
          "&.active, &:hover": {
            color: "#000",
            background: "rgba(116, 178, 231, 1)",
            borderBottom: "none",
          },
        },
      },
      {
        props: props => props.color === "option",
        style: {
          "textTransform": "capitalize",
          "background": "#F5F5F5",
          "max-height": "48px",
          "margin": "0px",
          "color": "#091928",
          "&.active": {
            color: "#E3E3E3",
            background: "rgba(57, 110, 156, 1)",
          },
          "&.active, &:hover": {
            color: "#E3E3E3",
            background: "rgba(57, 110, 156, 1)",
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        "textTransform": "none",
        "fontSize": "16px",
        "@media (max-width:600px)": {
          fontSize: "14px",
        },
      },
    },
  },
};

const TabsStylesLight = {
  MuiTab: {
    variants: [
      {
        props: props => props.color === "primary",
        style: {
          "textTransform": "capitalize",
          "color": "rgba(38, 101, 155, 1)",
          "borderBottom": "1px solid rgba(57, 110, 156, 1)",
          "borderRadius": "2px 2px 0px 0px",
          "&.active": {
            "color": "#ffff",
            " background-color": " rgba(57, 110, 156, 1)",
            "borderBottom": "none",
            "margin": "0px",
          },
          "&.active, &:hover": {
            "color": "#ffff",
            " background-color": " rgba(57, 110, 156, 1)",
            "borderBottom": "none",
            "margin": "0px",
          },
        },
      },
      {
        props: props => props.color === "secondary",
        style: {
          "textTransform": "capitalize",
          "color": "rgba(3, 7, 16, 1)",
          "borderBottom": "1px solid rgba(57, 110, 156, 1)",
          "borderRadius": "2px 2px 0px 0px",
          "&.active": {
            "color": "rgba(9, 25, 40, 1)",
            " background": "rgba(38, 101, 155, 1)",
            "borderBottom": "none",
            "margin": "0px",
          },
          "&.active, &:hover": {
            "color": "rgba(9, 25, 40, 1)",
            " background": "rgba(38, 101, 155, 1)",
            "borderBottom": "none",
            "margin": "0px",
          },
        },
      },
      {
        props: props => props.color === "option",
        style: {
          "textTransform": "capitalize",
          "max-height": "48px",
          "background": "#D2D2D2",
          "margin": "0px",
          "& .MuiInputLabel-root": {
            color: "#E3E3E3",
          },
          "&.active": {
            color: "#E3E3E3",
            background: "rgba(57, 110, 156, 1)",
          },
          "&.active, &:hover": {
            color: "#E3E3E3",
            background: "rgba(57, 110, 156, 1)",
          },
        },
      },
    ],
    styleOverrides: {
      root: {
        "textTransform": "none",
        "fontSize": "16px",
        "@media (max-width:600px)": {
          fontSize: "14px",
        },
      },
    },
  },
};

export { TabsStylesDark, TabsStylesLight };
