import React from "react";
import { Box } from "@mui/material";
import PropTypes from "prop-types";
import IvaReportSubsection from "../IvaReportSubsection/IvaReportSubsection";

function IvaReportSubsections(props) {
  const { summarizedData, totals, symbolCoin } = props;
  const { services, merch } = summarizedData;

  const servicesTotals = {
    TotalDataTaxed: totals.TotalServicesTaxed,
    TotalDataExempt: totals.TotalServicesExempt,
  };

  const merchTotals = {
    TotalDataTaxed: totals.TotalMerchTaxed,
    TotalDataExempt: totals.TotalMerchExempt,
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <IvaReportSubsection data={services} totals={servicesTotals} />
      <IvaReportSubsection
        data={merch}
        title={`Mercancías: ${symbolCoin}`}
        totals={merchTotals}
      />
      <IvaReportSubsection
        isTotalsSection
        data={services}
        title={`Totales: ${symbolCoin}`}
        totals={totals}
      />
    </Box>
  );
}

IvaReportSubsections.propTypes = {
  summarizedData: PropTypes.object.isRequired,
  totals: PropTypes.number.isRequired,
  symbolCoin: PropTypes.any,
};

IvaReportSubsections.defaultProps = {
  symbolCoin: "",
};

export default IvaReportSubsections;
