import { createTheme } from "@mui/material/styles";
import {
  inputStylesLight,
  ButtonStylesLight,
  inputStylesDark,
  ButtonStylesDark,
  CardStylesDark,
  CardStylesLight,
  SelectStylesDark,
  SelectStylesLight,
  TableStylesLight,
  TableStylesDark,
  TabsStylesDark,
  TabsStylesLight,
  ChipStylesDark,
  ChipStylesLight,
} from "./index";

const letter = {
  fontFamily: "Montserrat, Arial, sans-serif",
  h4: {
    "fontSize": 34,
    "@media (max-width:600px)": {
      fontSize: 20,
    },
  },
  h5: {
    "fontSize": 24,
    "@media (max-width:600px)": {
      fontSize: 20,
    },
  },
  h6: {
    "fontSize": 20,
    "@media (max-width:600px)": {
      fontSize: 16,
    },
  },
  fixedSizeSubtitle1: {
    fontSize: 16,
  },
  fixedSizeSubtitle3: {
    fontSize: 12,
  },

  subtitle1: {
    "fontSize": 16,
    "@media (max-width:600px)": {
      fontSize: 14,
    },
  },
};

const lightTheme = createTheme({
  typography: {
    modeColor: {
      color: "#000",
    },
    modeButton: {
      color: "rgba(18, 59, 100, 1)",
    },
    modeButtonLight: {
      color: "rgba(102, 255, 249, 1)",
    },
    modeButtonsecondLight: {
      color: "#26659B",
    },
    defaultLight: {
      color: "#ffff",
    },
    defaultDark: {
      color: "rgba(0, 0, 0, 1)",
    },
    ...letter,
  },
  palette: {
    mode: "light",
    baseColor: {
      main: "rgba(246, 249, 254, 1)",
    },
    predetermined: {
      main: "rgba(56, 133, 191, 1)",
    },
    light: {
      main: "rgba(210, 210, 210, 1))",
    },
    background: {
      default: "rgba(246, 249, 254, 1)",
    },
    option: {
      main: "#F6F9FE",
    },
    arrowButton: {
      main: "#F6F9FE",
    },
    tertiaryOnly: {
      main: "rgba(18, 59, 100, 1)",
    },
    whiteOnly: {
      main: " rgba(210, 210, 210, 1))",
    },
  },
  components: {
    ...inputStylesLight,
    ...ButtonStylesLight,
    ...CardStylesLight,
    ...SelectStylesLight,
    ...TableStylesLight,
    ...TabsStylesLight,
    ...ChipStylesLight,
  },
});

const darkTheme = createTheme({
  typography: {
    modeColor: {
      color: "#ffff",
    },

    modeButton: {
      color: "rgba(191, 246, 244, 1)",
    },
    modeButtonLight: {
      color: "rgba(102, 255, 249, 1)",
    },
    modeButtonsecondLight: {
      color: "rgba(102, 255, 249, 1)",
    },
    defaultLight: {
      color: "#ffff",
    },
    defaultDark: {
      color: "rgba(0, 0, 0, 1)",
    },
    ...letter,
  },
  palette: {
    mode: "dark",
    baseColor: {
      main: "rgba(3, 7, 16, 1)",
    },
    predetermined: {
      main: "rgba(56, 133, 191, 1)",
    },
    light: {
      main: " rgba(210, 210, 210, 1))",
    },
    option: {
      main: "#091928",
    },
    background: {
      default: "rgba(3, 7, 16, 1)",
    },
    arrowButton: {
      main: "#F6F9FE",
    },
    tertiaryOnly: {
      main: "rgba(18, 59, 100, 1)",
    },
    whiteOnly: {
      main: "rgba(255, 255, 255, 1)",
    },
  },
  components: {
    ...inputStylesDark,
    ...ButtonStylesDark,
    ...CardStylesDark,
    ...SelectStylesDark,
    ...TableStylesDark,
    ...TabsStylesDark,
    ...ChipStylesDark,
  },
});

export { lightTheme, darkTheme };
