import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { Box, Button, Typography } from "@mui/material";
// Utils
import { customToast } from "../../utils";
// Styles
import "./ResetForgotPassword.scss";
import { useAuth } from "../../context";
import InputForm from "../InputForm/InputForm";
// Assets
import backArrow from "../../assets/icons/left_arrow.svg";

function ResetForgotPassword() {
  const [statepassword, setstatePassword] = useState({
    Password: "",
    PasswordConfirm: "",
  });
  const [showPassword, setShowPassword] = useState({
    Password: false,
    PasswordConfirm: false,
  });
  const queryParams = window.location?.pathname.split("token=");
  const token = queryParams[1];

  const navigate = useNavigate();

  const { changePassword, handleLoginWithCustomToken, handleLogout } =
    useAuth();

  const handleClick = async () => {
    // check token and change password
    if (!statepassword.Password || !statepassword.PasswordConfirm) {
      const texto = !statepassword.Password
        ? "Por favor ingrese la contraseña"
        : "Por favor ingrese la contraseña de confirmación";
      return customToast.warning(texto);
    }
    if (statepassword.Password.length < 8) {
      return customToast.warning("La contraseña debe ser de mínimo 8 dígitos");
    }
    if (statepassword.Password !== statepassword.PasswordConfirm) {
      return customToast.warning("Las contraseñas deben de coincidir");
    }
    const user = await handleLoginWithCustomToken(token);

    if (user) {
      // change password
      const passwordUpdated = await changePassword(statepassword.Password);
      if (passwordUpdated) {
        return true;
      }
      // if password was not updated
      navigate("/");
      return handleLogout();
    }
    return customToast.error("Ha ocurrido un error");
  };

  const handleOnChange = (name, value) => {
    setstatePassword(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div className="principal-container  background-page">
      <Box className="reset-password">
        <div
          className="content-return"
          role="button"
          tabIndex={0}
          type="button"
          onClick={() => navigate("/")}
          onKeyDown={() => {}}
        >
          <img
            alt="Regresar"
            className="content-return-img"
            sizes="1"
            src={backArrow}
          />
        </div>
        <Typography
          fontWeight={600}
          typography="defaultLight"
          variant="h5"
          width="100%"
        >
          Restablecer contraseña
        </Typography>
        <InputForm
          id="PasswordReset"
          label="Contraseña"
          name="Password"
          showPassword={showPassword.Password}
          size="small"
          type={showPassword.Password ? "text" : "password"}
          typeInput="password"
          value={statepassword.Password}
          onChange={e => handleOnChange(e.target.name, e.target.value)}
          onClickPassword={() =>
            setShowPassword(prev => ({
              ...prev,
              Password: !showPassword.Password,
            }))
          }
        />
        <InputForm
          id="PasswordConfirmReset"
          label="Confirmar Contraseña"
          name="PasswordConfirm"
          showPassword={showPassword.PasswordConfirm}
          size="small"
          type={showPassword.PasswordConfirm ? "text" : "password"}
          typeInput="password"
          value={statepassword.PasswordConfirm}
          onChange={e => handleOnChange(e.target.name, e.target.value)}
          onClickPassword={() =>
            setShowPassword(prev => ({
              ...prev,
              PasswordConfirm: !showPassword.PasswordConfirm,
            }))
          }
        />
        <Typography typography="defaultLight" variant="subtitle2" width="100%">
          Debe contener mínimo 8 caracteres
        </Typography>
        <Button
          color="primary"
          size="large"
          variant="contained"
          onClick={handleClick}
        >
          Restablecer
        </Button>
      </Box>
    </div>
  );
}

export default ResetForgotPassword;
