import React from "react";
import { Routes, Route } from "react-router-dom";
import { ClientsGroupsXPlace, ClientsXGroup } from "../../components";
import ClientsGroupsPage from "./ClientsGroupsPage";
import ModifyClientPage from "../ModifyClientPage/ModifyClientPage";

function ClientsGroupRouter(props) {
  return (
    <Routes>
      <Route
        element={<ClientsXGroup />}
        path="groups/:id/:name/clients/:id/:name/:placeId"
      />
      <Route element={<ClientsGroupsXPlace />} path="groups/:id/:name" />
      <Route
        element={<ModifyClientPage />}
        path="groups/:id/:name/clients/:id/:name/:placeId/billing-profile/:clientId?"
      />
      <Route element={<ClientsGroupsPage />} path="" />
    </Routes>
  );
}

export default ClientsGroupRouter;
