import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
// GraphQL
import { useQuery } from "@apollo/client";
import { GET_INVENTORIES_BY_PLACE } from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { customToast } from "../../utils";
// SCSS
import "./InventoriesSelect.scss";
import InputForm from "../InputForm/InputForm";

function InventoriesSelect({
  value,
  name,
  onChange,
  disabled,
  style,
  handleAddInventory,
  hanldeEditInventory,
}) {
  const [addNew, setAddNew] = useState(false);
  const [edit, setEdit] = useState(false);
  const [description, setDescription] = useState("");
  const [repeatedDescription, setRepeatedDescription] = useState(false);

  const { LineDetailPlace } = useElectronic();

  const { data: inventories } = useQuery(GET_INVENTORIES_BY_PLACE, {
    variables: {
      query: {
        FK_Place: LineDetailPlace?.id,
      },
    },
  });

  useEffect(() => {
    if (value === 0 && inventories?.inventories?.length > 0) {
      onChange(name, inventories?.inventories[0].id);
    }
  }, [inventories, name, onChange, value]);

  const handleChangeDescription = e => {
    setDescription(e);
    const repeatedName = inventories.inventories.find(i => i.Description === e);
    if (repeatedName) {
      setRepeatedDescription(true);
    } else {
      setRepeatedDescription(false);
    }
  };

  const handleSave = e => {
    e.preventDefault();
    if (description === "") {
      customToast.error("Por favor, ingrese el nombre del inventario.");
    } else if (edit) {
      hanldeEditInventory(description);
      setEdit(false);
    } else {
      handleAddInventory(description);
      setAddNew(false);
    }
  };

  const findInventory = id => {
    const result = inventories?.inventories?.find(index => index.id === id);

    return result?.Description;
  };

  return (
    <div className={`${addNew || edit ? "box-select" : ""} `} style={style}>
      <div className="box-option">
        <Select
          className="second-input select-width"
          disabled={disabled}
          name={name}
          value={value}
          onChange={e => {
            onChange(e.target.name, e.target.value);
            setAddNew(false);
            setEdit(false);
          }}
        >
          {inventories?.inventories?.map(index => (
            <MenuItem key={index.id} value={index.id}>
              {" "}
              {index.Description}
            </MenuItem>
          ))}
        </Select>
        <div className="inventories-buttons">
          <Button
            className=" edit-width"
            color="primary"
            sx={{ size: "14px", padding: "0px" }}
            type="button"
            variant="outlined"
            onClick={e => {
              setEdit(true);
              setAddNew(false);
              setDescription(findInventory(value));
              handleChangeDescription(findInventory(value));
            }}
          >
            Editar
          </Button>
          <Button
            className="add-width"
            color="secondary"
            sx={{ size: "14px", paddingY: "1px", paddingX: "0.7rem" }}
            type="button"
            variant="contained"
            onClick={e => {
              setAddNew(true);
              setEdit(false);
              setDescription("");
              setRepeatedDescription(false);
            }}
          >
            Agregar Nuevo
          </Button>
        </div>
      </div>

      {(addNew || edit) && (
        <FormControl fullWidth id="inventories">
          <div className="contain-edit">
            {repeatedDescription && (
              <p style={{ color: "red" }}>
                Este nombre ya fue utilizado en otro inventario
              </p>
            )}
            <InputForm
              id="Name_Product"
              name="Name_Product"
              placeholder="Nombre del inventario"
              size="small"
              type="text"
              value={description}
              onChange={e => handleChangeDescription(e.target.value)}
            />
            <button
              className="second-Button second-Button-blue main-modify-product-button-width"
              type="button"
              onClick={handleSave}
            >
              Guardar
            </button>
          </div>
        </FormControl>
      )}
    </div>
  );
}

InventoriesSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  handleAddInventory: PropTypes.func.isRequired,
  hanldeEditInventory: PropTypes.func.isRequired,
};

InventoriesSelect.defaultProps = {
  disabled: false,
  style: {},
};

export default InventoriesSelect;
