/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_NEW_CLIENT = gql`
  mutation getClient($data: BillingClientInput!) {
    client: createBillingClient(data: $data) {
      id
      BillingProfile {
        id
        Name
        ID_Number
        Email
        PhoneNumber
        PhoneCodCountry
        MH_PersonType {
          id
          Name_PersonType
        }
        User {
          id
        }
        MH_Neighborhood {
          id
          Name
          District {
            id
            Name
            Canton {
              id
              Name
              Province {
                id
                Name
              }
            }
          }
        }
      }
      Created_By {
        id
      }
    }
  }
`;
export const DELETE_BILLING_CLIENT = gql`
  mutation deleteBillingClient($FK_User: ID!, $FK_Place: ID!) {
    deleteBillingClient(FK_User: $FK_User, FK_Place: $FK_Place)
  }
`;

export const DELETE_MULTIPLE_BILLING_CLIENT = gql`
  mutation deleteBillingClient($ids: [ID]!) {
    deleteMultipleBillingClientById(ids: $ids)
  }
`;

export const UPDATE_CLIENT = gql`
  mutation updateClient(
    $id: ID!
    $billingProfile: BillingProfileInput!
    $FK_Place: ID
  ) {
    client: updateBillingProfile(
      id: $id
      billingProfile: $billingProfile
      FK_Place: $FK_Place
    ) {
      ... on Error {
        error
      }
      ... on BillingProfile {
        id
        Name
        ID_Number
        Email
        PhoneNumber
        PhoneCodCountry
        MH_PersonType {
          id
          Name_PersonType
        }
        User {
          id
        }
        OtherSigns
        MH_Neighborhood {
          id
          Name
          District {
            id
            Name
            Canton {
              id
              Name
              Province {
                id
                Name
              }
            }
          }
        }
      }
    }
  }
`;
