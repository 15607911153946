import React from "react";
import { useNavigate } from "react-router-dom";
// External components
import { Card, Image, Title } from "rbx";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// Context
import { useAuth } from "../../context";
// SCSS
import "../../components/Inventories/Inventories.scss";

function ClientsGroupsPage() {
  const { state } = useAuth();
  const navigate = useNavigate();
  const user = state.user.TavuelUser;

  const { GetPLacesByUserIdAndModuleId } = usePlaces();

  const [loading, dataPlaces, error] = GetPLacesByUserIdAndModuleId(user?.id);

  const getUrl = placeItem => {
    const route = placeItem.Files?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectPlace = place => {
    navigate(`/clients-groups/groups/${place?.id}/${place?.Name_Place}`);
  };

  const validateRoute = placeItem => {
    const route = getUrl(placeItem);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  return (
    <div>
      <div>
        <Title className="title-center"> Seleccione un lugar</Title>
      </div>

      <div className="grid">
        {Array.isArray(dataPlaces?.places) &&
          dataPlaces?.places.length > 0 &&
          dataPlaces?.places?.map(placeItem => (
            <div
              key={placeItem.id}
              className="hover-table-options principal-container"
              role="button"
              tabIndex={0}
              onClick={() => handleSelectPlace(placeItem)}
              onKeyDown={() => null}
            >
              <Card key={placeItem.id} className=" card-content">
                <div className="title-product">
                  <h2>{placeItem.Name_Place}</h2>
                </div>

                <div className="flex">
                  <div className="content-right">
                    <div className="product-img ">
                      <Image.Container size="32px sq.">
                        <Image src={validateRoute(placeItem)} />
                      </Image.Container>
                    </div>
                  </div>

                  <div className="flex middle ">
                    <p className="detail-product ellipsis three-lines">
                      {placeItem.Description_Place}{" "}
                    </p>
                  </div>
                </div>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
}

ClientsGroupsPage.propTypes = {};

ClientsGroupsPage.defaultProps = {};

export default ClientsGroupsPage;
