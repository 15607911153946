import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Container,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import { customToast as toast } from "../../../../../utils";
import "../../../../../styles/index.scss";
import SelectForm from "../../../../SelectForm/SelectForm";
import { formatData } from "../../../../../utils/helpers";
import InputForm from "../../../../InputForm/InputForm";
import deleteIcon from "../../../../../assets/icons/delete_icon.svg";

function Discounts({ product, setModalOpen, list, setDataTable, coinDetails }) {
  const [key, setKey] = useState(1);
  const [cont, setCont] = useState(false);
  const [discount, setDiscount] = useState({
    Type: "1",
    Amount: "",
  });
  const [discountsList, setDiscountsList] = useState([]);

  const { currentSymbolCoin, toExchangeRate } = coinDetails;
  const totalPrice = toExchangeRate(product.total);
  const theme = localStorage.getItem("theme");
  const colorLine = theme === "light" ? "#030710" : "#fff";
  useEffect(() => {
    product?.discountAmount?.forEach(item => {
      if (item !== 0) {
        setDiscountsList(discounts => [
          ...discounts,
          {
            Percent: ((item / totalPrice) * 100).toFixed(1),
            Amount: item,
            key,
          },
        ]);
        setKey(k => key + 1);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const types = [
    { Name: "Porcentaje", Id: "1" },
    { Name: "Monto", Id: "2" },
  ];

  const handleDelete = line => {
    const filterList = discountsList.filter(item => item !== line);
    setDiscountsList(filterList.map((item, i) => ({ ...item, key: i + 1 })));
    setKey(filterList.length + 1);
    if (cont === true) {
      setCont(false);
    }
  };
  const clear = () => {
    setDiscount({
      Type: "1",
      Amount: "",
    });
  };
  const handleChange = (name, value) => {
    setDiscount(prev => ({ ...prev, [name]: value }));
  };
  const handleSave = () => {
    const amountsDiscount = discountsList.map(item => item.Percent);
    const amounts = discountsList.map(item => item.Amount);
    setDataTable(
      list.map(item =>
        product.id === item.id
          ? {
              ...item,
              discount: amountsDiscount,
              discountAmount: amounts,
            }
          : item,
      ),
    );
    if (amountsDiscount.length === 0) {
      setDataTable(
        list.map(item =>
          product.id === item.id
            ? {
                ...item,
                discountAmount: [0],
                discount: [0],
              }
            : item,
        ),
      );
    }
    setModalOpen(false, "");
    toast.success("¡Descuentos registrados con éxito!", {
      toastId: "discount",
    });
  };
  const handleAdd = () => {
    let totalsAmount = 0;
    discountsList.forEach(item => {
      totalsAmount += parseFloat(item.Percent, 10);
    });

    if (discount.Type === "" || discount.Amount === "") {
      toast.error("Debe completar todos los datos requeridos");
    } else if (discount.Amount === 0) {
      toast.error("El valor proporcionado debe ser mayor a 0");
    } else if (
      discount.Type === "1" &&
      (discount.Amount === 0 || discount.Amount > 100)
    ) {
      toast.error("El valor del porcentaje debe ser entre 0 y 100");
    } else {
      if (discount.Type === "1") {
        // Percent
        const amount = (totalPrice * discount.Amount) / 100;
        if (parseFloat(discount.Amount, 10) + totalsAmount > 100) {
          toast.error(
            "La suma de los montos de los descuentos exceden el precio unitario.",
          );
        } else {
          setDiscountsList(discounts => [
            ...discounts,
            { Percent: discount.Amount, Amount: amount.toFixed(3), key },
          ]);
        }
      } else {
        // Amount
        const percent = (parseFloat(discount.Amount) * 100) / totalPrice;
        if (percent > 100) {
          toast.error(
            "El monto no puede exceder el precio unitario del producto",
          );
        } else {
          const amount = (totalPrice * percent) / 100;

          if (percent + totalsAmount > 100) {
            toast.error(
              "La suma de los montos de los descuentos exceden el precio unitario.",
            );
          } else {
            setDiscountsList(discounts => [
              ...discounts,
              { Amount: amount.toFixed(3), Percent: percent.toFixed(1), key },
            ]);
          }
        }
      }

      setKey(key + 1);
      clear();
      validateTotalDiscounts();
    }
  };

  const validateTotalDiscounts = () => {
    if (discountsList.length === 5) {
      setCont(true);
    }
  };
  return (
    <Container
      sx={{
        padding: { xs: "1rem", md: "3rem" },
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        width: {
          xs: "90%",
          sm: "488px",
        },
        height: "487px",
        backdropFilter: "blur(15px)",
      }}
    >
      <h3> Descuentos</h3>
      <SelectForm
        color="secondary"
        data={formatData(types, "Name")}
        label="Seleccione:"
        name="Type"
        size="small"
        value={discount?.Type}
        onChange={handleChange}
      />
      <InputForm
        color="secondary"
        id="Id"
        label="Valor"
        name="Amount"
        size="small"
        type="number"
        value={discount?.Amount}
        variant="filled"
        onChange={e => handleChange(e.target.name, e.target.value)}
      />
      <div className="content-flex-end">
        <Button
          color="secondary"
          disabled={cont}
          size="medium"
          sx={{ width: "50%" }}
          type="Button"
          variant="contained"
          onClick={handleAdd}
        >
          Agregar Descuento
        </Button>
      </div>

      <div className="data-table-container">
        <TableContainer
          sx={{
            overflow: "auto",
            height: "124px",
            border: ` 2px solid  ${colorLine}`,
            paddingY: "0.2rem",
            paddingX: "1rem",
            borderRadius: "4px",
          }}
        >
          <Table>
            <TableHead>
              <TableRow
                align="center"
                sx={{
                  borderTop: "none",
                  borderBottom: `2px solid  ${colorLine}`,
                  borderRight: "none",
                  background: "transparent",
                  height: "1rem",
                  paddingTop: 0,
                  paddingBottom: "0.1rem",
                }}
              >
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  #
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Porcentaje
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Monto
                </TableCell>
                <TableCell
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  Eliminar
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {discountsList?.map(line => (
                <TableRow
                  key={line.key}
                  align="center"
                  sx={{
                    borderTop: "none",
                    borderBottom: `2px solid  ${colorLine}`,
                    borderRight: "none",
                    background: "transparent",
                    height: "1rem",
                    paddingTop: 0,
                    paddingBottom: "0.1rem",
                  }}
                >
                  <TableCell component="th" scope="row">
                    {line.key}
                  </TableCell>
                  <TableCell>{line?.Percent}%</TableCell>
                  <TableCell>
                    {currentSymbolCoin} {parseFloat(line?.Amount).toFixed(3)}
                  </TableCell>
                  <TableCell>
                    <button
                      className="button-modifications"
                      type="button"
                      onClick={() => handleDelete(line)}
                    >
                      <img
                        alt="eliminar"
                        className=" icon-color"
                        src={deleteIcon}
                      />
                    </button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="content-flex-end">
        <Button
          color="primary"
          sx={{ width: "30%" }}
          type="Button"
          variant="contained"
          onClick={handleSave}
        >
          Confirmar
        </Button>
      </div>
    </Container>
  );
}

Discounts.propTypes = {
  product: PropTypes.object.isRequired,
  list: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  setDataTable: PropTypes.func.isRequired,
  coinDetails: PropTypes.func.isRequired,
};

export default Discounts;
