/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const CREATE_MANY_ELECTRONIC_BILLS = gql`
  mutation createManyElectronicBills(
    $electronicBills: [ElectronicBillContInput]
    $OwnerPlace: ID
  ) {
    createManyElectronicBills(
      electronicBills: $electronicBills
      OwnerPlace: $OwnerPlace
    ) {
      ... on Error {
        key
        error
      }
      ... on ElectronicBillCont {
        Key
      }
    }
  }
`;

export const ELECTRONIC_BILLS_DELETE = gql`
  mutation deleteElectronicBill($id: ID!) {
    deleteElectronicBill(id: $id)
  }
`;

export const DELETE_EB_SUBMODULE_PERMISSION = gql`
  mutation deleteEBSubmodulePermission(
    $data: DeleteEBSubmodulePermissionInput!
  ) {
    deleteEBSubmodulePermission(data: $data)
  }
`;

export const CREATE_CREDIT_NOTE = gql`
  mutation createCreditNote($docEB: DocEBInput) {
    createCreditNote(docEB: $docEB) {
      ... on EBResponse {
        id
        clave
      }
      ... on EBHaciendaError {
        error
      }
    }
  }
`;

export const CREATE_DEBIT_NOTE = gql`
  mutation createDebitNote($docEB: DocEBInput) {
    createDebitNote(docEB: $docEB) {
      ... on EBResponse {
        id
        clave
      }
      ... on EBHaciendaError {
        error
      }
    }
  }
`;

export const CREATE_EB_FROM_BILL = gql`
  mutation createElectronicBill($id: Int) {
    bill: createElectronicBill(id: $id) {
      ... on EBResponse {
        id
        clave
      }
      ... on EBHaciendaError {
        error
      }
    }
  }
`;

export const UPDATE_EB = gql`
  mutation updateElectronicBill($ebAttributes: ElectronicBillContInput) {
    updateElectronicBill(ebAttributes: $ebAttributes)
  }
`;

export const UDPATE_EB_SUBMODULE_PERMISSION = gql`
  mutation updateEBSubmodulePermission(
    $data: UpdateEBSubmodulePermissionInput
  ) {
    updateEBSubmodulePermission(data: $data)
  }
`;

export const CREATE_FEC = gql`
  mutation createFECBill($electronicBillContInput: ElectronicBillContInput) {
    createFECBill(electronicBillContInput: $electronicBillContInput) {
      ... on EBResponse {
        id
        clave
      }
      ... on EBHaciendaError {
        error
      }
    }
  }
`;

export const CREATE_FEE = gql`
  mutation createExportationBill($fecDataInput: FECDataInput) {
    createExportationBill(fecDataInput: $fecDataInput) {
      ... on EBResponse {
        id
        clave
      }
      ... on EBHaciendaError {
        error
      }
    }
  }
`;
