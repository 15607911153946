import React, { useEffect, useState } from "react";
// External components
import { Card, Select, Tab, Label } from "rbx";
// Graphql
import { useLazyQuery, useQuery } from "@apollo/client";
import {
  GET_ROLES_BY_PLACE,
  GET_SUBMODULES_BY_PLACE_AND_MODULE_AND_ROLE,
} from "../../graphql/role/role.queries";
// Components
import {
  PermissionByModuleAndRole,
  SubModuleSelect,
  ProfileBillWrapper,
} from "../../components";
// Context
import { useElectronic } from "../../context/ElectronicContext";
import { useAuth } from "../../context/AuthContext";
import { useModal } from "../../context";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// SCSS
import "./ManageRolesPage.scss";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};
function ManageRolesPage() {
  const [selectedRole, setSelectedRole] = useState({ checked: null });
  const [selectSubmodule, setSelectSubmodule] = useState(0);
  const [billing, setBilling] = useState(INITIAL_STATE);

  const { state: authState, idPlaces } = useAuth();
  const user = authState.user.TavuelUser;

  const { GetPLacesByUserIdAndModuleId } = usePlaces();

  const [, placesData] = GetPLacesByUserIdAndModuleId(user?.id);

  const { data: rolesByPlaceData } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: idPlaces,
    },
  });

  const [getSubmodule, { data: submoduleData }] = useLazyQuery(
    GET_SUBMODULES_BY_PLACE_AND_MODULE_AND_ROLE,
  );

  const getDates = async idRole => {
    getSubmodule({
      variables: {
        FK_Place: idPlaces,
        FK_Module: 11,
        FK_Role: idRole,
      },
    });
  };

  const onChange = e => {
    setSelectSubmodule(e);
  };

  const handleSelect = idRole => {
    getDates(idRole);
    setSelectedRole({ checked: idRole });
  };

  const { LineDetailPlace, setLineDetailPlace, setLineDetail } =
    useElectronic();

  const handleSelectPlace = id => {
    const placeSelected = placesData?.places.find(
      placeItem => placeItem.id === id,
    );

    if (placeSelected) {
      setLineDetailPlace(placeSelected);
      setLineDetail([]);
    } else if (placeSelected?.length > 0 && LineDetailPlace?.id === 0) {
      setLineDetailPlace(placeSelected[0]);
      setLineDetail([]);
    }
  };

  useEffect(() => {
    if (placesData !== null) {
      handleSelectPlace(idPlaces);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placesData]);

  useEffect(() => {
    if (authState?.user?.TavuelUser?.BillingProfile === null) {
      createElectronicBillProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setBillingProfile = BillingProfile =>
    setBilling(prv => ({ ...prv, BillingProfile }));

  const { setModalOpen } = useModal();

  const createElectronicBillProfile = () => {
    setModalOpen(
      true,
      <ProfileBillWrapper
        initialReceiver={billing?.BillingProfile}
        setBillingProfile={setBillingProfile}
        setModalOpen={setModalOpen}
      />,
    );
  };

  return (
    <div className="gridd">
      <div className="roles">
        <Tab.Group kind="boxed">
          <Tab active>
            <h1>
              <span>Roles</span>
            </h1>
          </Tab>
          <Tab>
            <h1>
              <span>+</span>
            </h1>
          </Tab>
        </Tab.Group>
        <div className="submodulos">
          {rolesByPlaceData?.getRolesByPlace?.map(role => (
            <Card
              key={role.id}
              className="submodulos card-role"
              onClick={() => handleSelect(role.id)}
            >
              <p>{role.Name_Role}</p>
            </Card>
          ))}
        </div>
      </div>
      <div className="permissions">
        {submoduleData != null && (
          <div>
            <Label>SubModule</Label>
            <Select.Container fullwidth>
              <SubModuleSelect
                className="submodulos"
                submoduleData={submoduleData}
                onChange={onChange}
              />
            </Select.Container>
          </div>
        )}
        {submoduleData != null && (
          <PermissionByModuleAndRole
            selectSubmodule={selectSubmodule}
            selectedRole={selectedRole}
          />
        )}
      </div>
    </div>
  );
}

export default ManageRolesPage;
