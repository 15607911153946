/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_INVENTORY_BY_PLACE = gql`
  mutation createInventoryOnFirstCellar($FK_Place: ID!, $Description: String) {
    inventory: createInventoryOnFirstCellar(
      FK_Place: $FK_Place
      Description: $Description
    ) {
      id
      Description
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation updateInventory($inventory: InventoryUpdate) {
    inventory: updateInventory(inventory: $inventory) {
      id
      Description
    }
  }
`;
