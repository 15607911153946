import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
// External components
import {
  Box,
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
// GraphQL
import { client_EB_api } from "../../graphql/client";
import { RESEND_EMAIL } from "../../graphql";
// Hooks
import useCoinDetails from "../../hooks/useCoinDetails";
// SCSS
import "./DraftBill.scss";
import { get24HoursTime } from "../../utils/helpers";

import deleteIcon from "../../assets/icons/delete_icon.svg";
import editIcon from "../../assets/icons/edit_icon.svg";
import SendIcon from "../../assets/icons/arrow_left_outline.svg";
import { customToast } from "../../utils";

function DraftBill({ bill, onDelete }) {
  const navigate = useNavigate();
  const { toLocalExchangeRate } = useCoinDetails();
  const theme = useTheme();

  const isDarkMode = theme.palette.mode !== "dark";

  const handleDelete = () => {
    onDelete(bill);
  };

  const handleEdit = () => {
    navigate(`/electronic-bills/${bill.id}/Draft`);
  };

  const handleSend = async () => {
    if (!bill || !bill.User || !bill.User.Person || !bill.User.Person.Email) {
      customToast.error("Error al buscar el email!");
      return;
    }

    const { data } = await client_EB_api.mutate({
      mutation: RESEND_EMAIL,
      variables: {
        id: bill.id,
        to: bill.User.Person.Email,
      },
    });

    if (data.response.length === 0 || data.response[0] === false) {
      customToast.error("Error al enviar el email!");
      return;
    }

    customToast.success("Email enviado con éxito!");
  };

  return (
    <Card
      color="primary"
      sx={{
        position: "relative",
        padding: 3,
        paddingBottom: 8,
        height: { xs: "300px", md: "250px" },
      }}
    >
      <Grid container spacing={2}>
        {bill.User?.Person?.Name_Person && (
          <React.Fragment>
            <Grid item xs={6}>
              <Typography fontSize={14} fontWeight={600}>
                Cliente:
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography fontSize={14}>
                {bill.User.Person.Name_Person}
              </Typography>
            </Grid>
          </React.Fragment>
        )}
        {bill.User?.Person?.Name_Person && false}
        <Grid item xs={6}>
          <Typography fontSize={14} fontWeight={600}>
            Fecha:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={14}>
            {new Date(bill.Updated_At).toLocaleDateString()}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize={14} fontWeight={600}>
            Hora:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          {/* to do: fix the time format */}
          <Typography fontSize={14}>
            {get24HoursTime(new Date(bill.Updated_At))}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize={14} fontWeight={600}>
            Total:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={14}>
            {`${bill?.Coin?.Symbol_Coin} ${toLocalExchangeRate(
              bill.Total_Bill,
              bill.Coin,
            )}`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Typography fontSize={14} fontWeight={600}>
            No. Productos:
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography fontSize={14}>{bill.Detail?.length}</Typography>
        </Grid>
      </Grid>
      <Stack bottom={16} direction="row" position="absolute" right={16}>
        <Button
          sx={{ minWidth: 0, width: 45, height: 40, padding: 0 }}
          onClick={handleDelete}
        >
          <Box
            alt="Eliminar"
            className="search-icon"
            component="img"
            height={20}
            src={deleteIcon}
            sx={{ filter: `invert(${isDarkMode ? 1 : 0})` }}
            width={20}
          />
        </Button>
        <Button
          sx={{ minWidth: 0, width: 45, height: 40, padding: 0 }}
          onClick={handleEdit}
        >
          <Box
            alt="Editar"
            className="search-icon"
            component="img"
            height={20}
            src={editIcon}
            sx={{ filter: `invert(${isDarkMode ? 1 : 0})` }}
            width={20}
          />
        </Button>
        <Button
          sx={{ minWidth: 0, width: 45, height: 40, padding: 0 }}
          onClick={handleSend}
        >
          <Box
            alt="Enviar"
            className="search-icon"
            component="img"
            height={20}
            src={SendIcon}
            sx={{ filter: `invert(${isDarkMode ? 1 : 0})` }}
            width={20}
          />
        </Button>
      </Stack>
    </Card>
  );
}
DraftBill.propTypes = {
  bill: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DraftBill;
