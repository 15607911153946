import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Input, Typography, Button, Card } from "@mui/material";

// Graphql
import { client_EB_api } from "../../../../graphql/client";
import { UPDATE_PIN } from "../../../../graphql";
// Utils
import { encrypt, customToast as toast } from "../../../../utils/index";
import InputForm from "../../../InputForm/InputForm";
import SimpleGrid from "../../../SimpleGrid";

function P12Upload({ setModalOpen, MH_User, onChange }) {
  const [showPin, setShowPin] = useState(false);
  const [p12, setP12] = useState(null);
  const [p12Pin, setP12Pin] = useState("");

  const uploadP12 = async e => {
    e.preventDefault();
    const fileData = new FormData();
    fileData.append("w", "fileUploader");
    fileData.append("r", "subir_certif");
    fileData.append("sessionKey", MH_User.Session.sessionKey);
    fileData.append("fileToUpload", p12);
    fileData.append("iam", MH_User.userName);
    const data = await fetch(process.env.REACT_APP_FRONT_P12_UPLOAD, {
      method: "POST",
      body: fileData,
    });
    const { resp } = await data.json();
    if (resp.idFile) {
      saveUserData(resp.idFile);
      cleanP12Values();
    } else {
      toast.error("Error al intentar cargar el P12");
    }
  };

  const saveUserData = async id => {
    const pin = encrypt(p12Pin);
    try {
      const {
        data: { updatePin },
      } = await client_EB_api.mutate({
        mutation: UPDATE_PIN,
        variables: { id, pin },
      });
      onChange(updatePin);
      toast.success("P12 cargado con éxito");
    } catch (e) {
      toast.error("Error al actualizar el pin del P12");
    }
  };

  const cleanP12Values = () => {
    setP12(null);
    setP12Pin("");
  };

  const openFileSelector = () => {
    cleanP12Values();
    document.querySelector("#fileSelectorP12").click();
  };

  const onFileSelectorChanged = e => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target.type === "file") {
      setP12(e?.target.files[0]);
    }
  };

  const handleOnChangePassword = e => {
    if (e.target.value?.length <= 4) setP12Pin(e.target.value);
  };

  return (
    <SimpleGrid>
      <Card
        color="file"
        sx={{ paddingX: "1rem", height: "48px" }}
        onClick={() => openFileSelector()}
      >
        <Input
          accept=".p12"
          className="input-file"
          id="fileSelectorP12"
          sx={{ display: "none" }}
          type="file"
          onChange={e => onFileSelectorChanged(e)}
        />
        <Typography
          fontWeight="bold"
          textAlign="start"
          typography="modeColor"
          variant="subtitle1"
        >
          {MH_User?.FileEB?.name}
        </Typography>
        <Typography
          textAlign="start"
          typography="modeColor"
          variant="subtitle2"
        >
          {new Date((MH_User?.FileEB?.timestamp || 0) * 1000).toLocaleString()}
        </Typography>
      </Card>
      {p12 && (
        <InputForm
          color="primary"
          id="passwordP12"
          label="Pin para P12"
          name="password"
          showPassword={showPin}
          size="small"
          type={showPin ? "text" : "password"}
          typeInput="password"
          value={p12Pin}
          onChange={handleOnChangePassword}
          onClickPassword={() => setShowPin(!showPin)}
        />
      )}

      <Button
        color="secondary"
        disabled={p12Pin === ""}
        sx={{
          height: 48,
          maxWidth: "113px",
        }}
        variant="contained"
        onClick={uploadP12}
      >
        Subir P12
      </Button>
    </SimpleGrid>
  );
}

P12Upload.propTypes = {
  setModalOpen: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  MH_User: PropTypes.any.isRequired,
};

P12Upload.defaultProps = {
  setModalOpen: state => null,
};

export default P12Upload;
