import React from "react";
// Components
import { BuyElectronicBill } from "../../components";
// SCSS
import "./BuyElectronicBillsPage.scss";

function BuyElectronicBillsPage() {
  return <BuyElectronicBill />;
}

export default BuyElectronicBillsPage;
