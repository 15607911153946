/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { useTable, useSortBy, useExpanded } from "react-table";
import {
  Checkbox,
  Table as TableMui,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// SCSS
import "../../DataTable.scss";
/**
 * colums: [{type="number|any"}]
 */
function Table({
  columns,
  data,
  onRowClick,
  initialSortBy,
  onChangeSort,
  renderRowSubComponent,
  isSubRow,
  hiddenColumns,
  selectedRows,
  setSelectedRows,
  selectable,
  selectField,
}) {
  const tableState = {
    pageIndex: 0,
    sortBy: initialSortBy,
    hiddenColumns,
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: { ...tableState },
    },
    useSortBy,
    useExpanded,
  );

  const renderTableHeaderSort = column => {
    if (column.isSorted) {
      return column.isSortedDesc ? " ▼" : " ▲";
    }
    return "";
  };

  useEffect(() => {
    onChangeSort(sortBy);
  }, [onChangeSort, sortBy]);

  return (
    <div
      className="data-table-container"
      style={{ width: isSubRow ? "110%" : "100%", overflowX: "auto" }}
    >
      <div className="table-container">
        <TableContainer
          sx={{
            background: "transparent",
          }}
        >
          <TableMui {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup, i) => (
                <TableRow
                  key={`head-row-${i}`}
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {selectable && (
                    <TableCell id="select-all-bills">
                      <Checkbox
                        checked={data.every(row =>
                          selectedRows.includes(row[selectField]),
                        )}
                        onChange={() =>
                          setSelectedRows(
                            selectedRows.length === 0
                              ? data.map(val => val[selectField])
                              : [],
                          )
                        }
                      />
                    </TableCell>
                  )}
                  {headerGroup.headers.map((column, j) => (
                    <TableCell
                      key={`heading-${j}`}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      sx={{
                        borderTop: "none",
                        borderBottom: "none",
                        borderLeft:
                          j === 0 ? "none" : "2px solid rgba(23, 56, 89, 1)",
                        borderRight: "none",
                        background: "transparent",
                        height: "1rem",
                        paddingTop: 0,
                        paddingBottom: "0.1rem",
                      }}
                    >
                      <Typography
                        typography="modeColor"
                        variant="subtitle1"
                        xs={{ display: "flex", justifyContent: "center" }}
                      >
                        {column.render("Header")}
                      </Typography>
                      <span>{renderTableHeaderSort(column)}</span>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={`body-row-${i}`}>
                    <TableRow
                      {...row.getRowProps()}
                      style={
                        row.isExpanded
                          ? { backgroundColor: "rgba(36, 168, 100, 30%)" }
                          : null
                      }
                      onClick={() => onRowClick(row.original)}
                    >
                      {selectable && (
                        <TableCell>
                          <Checkbox
                            checked={selectedRows.includes(
                              row.original[selectField],
                            )}
                            onChange={() =>
                              setSelectedRows([row.original[selectField]])
                            }
                          />
                        </TableCell>
                      )}
                      {row.cells.map((cell, j) => (
                        <TableCell
                          key={`body-cell-${j}`}
                          data-title={cell?.column?.Header || ""}
                          style={
                            typeof cell.column?.maxWidth === "string"
                              ? { width: cell.column.maxWidth }
                              : { maxWidth: cell.column?.maxWidth || 400 }
                          }
                          {...cell.getCellProps()}
                          sx={{
                            borderTop: "none",
                            borderBottom: "none",
                            borderLeft:
                              j === 0
                                ? "none"
                                : "2px solid rgba(23, 56, 89, 1)",
                            borderRight: "none",
                            background: "transparent",
                            height: "2rem",
                            paddingTop: "0.1rem",
                            paddingBottom: "0.1rem",
                          }}
                        >
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {cell.render("Cell")}
                          </p>
                        </TableCell>
                      ))}
                    </TableRow>

                    {row.isExpanded ? (
                      <TableRow {...row.getRowProps()}>
                        {renderRowSubComponent({ row })}
                      </TableRow>
                    ) : null}
                  </React.Fragment>
                );
              })}
            </TableBody>
          </TableMui>
        </TableContainer>
      </div>
    </div>
  );
}

Table.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  onRowClick: PropTypes.func,
  initialSortBy: PropTypes.array,
  onChangeSort: PropTypes.func,
  renderRowSubComponent: PropTypes.func,
  isSubRow: PropTypes.bool,
  hiddenColumns: PropTypes.array,
  selectedRows: PropTypes.arrayOf(PropTypes.bool),
  setSelectedRows: PropTypes.func,
  selectable: PropTypes.bool,
  selectField: PropTypes.string,
};

Table.defaultProps = {
  columns: [],
  data: [],
  initialSortBy: [],
  onRowClick: () => null,
  onChangeSort: () => null,
  renderRowSubComponent: () => null,
  isSubRow: false,
  hiddenColumns: [],
  selectedRows: [],
  setSelectedRows: () => null,
  selectable: false,
  selectField: "",
};
export default Table;
