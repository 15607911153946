import { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Box, Typography } from "@mui/material";
// Context
import { useModal } from "../../../../context";
// SCSS
import "./UserSearch.scss";
import InputForm from "../../../InputForm/InputForm";
import FormWithButtons from "../../../FormWithButttons/FormWithButtons";

function UserSearch({ handleSearch }) {
  const [search, SetSearch] = useState("");

  const { setModalOpen } = useModal();

  const handleCancel = () => setModalOpen(false);

  return (
    <Box className="container-modal">
      <Box
        className="modal-content-padding"
        sx={{ width: { xs: "310px", sm: "580px" } }}
      >
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Agregar usuario existente por correo electrónico
        </Typography>
        <InputForm
          color="primary"
          id="search"
          label="Email"
          name="search"
          size="small"
          type="text"
          value={search}
          variant="filled"
          onChange={e => SetSearch(e.target.value)}
        />
        <FormWithButtons
          buttonsAlignment="center"
          cancelText="Cancelar"
          submitText=" Agregar"
          onCancel={handleCancel}
          onSubmit={() => handleSearch(search)}
        />
      </Box>
    </Box>
  );
}

UserSearch.propTypes = {
  handleSearch: PropTypes.func,
};

UserSearch.defaultProps = {
  handleSearch: e => e,
};

export default UserSearch;
