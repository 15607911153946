export const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export const styleBackdrop = {
  backgroundColor: "transparent",
  bgcolor: "transparent",
};
