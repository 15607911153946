import React, { useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { Box, Card, Typography } from "@mui/material";
// Component
import PersonSection from "../PersonSection";
import { Table } from "../DataTable/components";
// Enums
import DEFAULT_COLUMNS from "./Enums/Columns";
// Hooks
import useMHConfigData from "../../hooks/useMHConfigData";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import formatCurrency from "../../utils/formatCurrency";
// Initial States
import { billUploadDetailEmptyState } from "../../InitialStates/BillUploadDetail";

function BillUploadDetail({ electronicDocument }) {
  const { data: emiterData, getMHData: getMHEmisorData } = useMHConfigData();
  const { data: receptorData, getMHData: getMHReceptorData } =
    useMHConfigData();
  const {
    useCoinDetail: { getCoinsData },
    getAllPaymentMethods,
    getAllSellCondition,
  } = useElectronic();

  const selectedCoin =
    getCoinsData?.coins?.find(
      X =>
        X?.Code_Coin ===
        electronicDocument?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda
          ._text,
    ) ?? billUploadDetailEmptyState.Coin;

  const sellConditionSelected = getAllSellCondition?.sellConditions?.find(
    sellCondition =>
      sellCondition.id_MH === electronicDocument?.CondicionVenta._text,
  );

  const paymentMethodSelected = getAllPaymentMethods?.paymentMethod?.find(
    paymentMethod =>
      paymentMethod.id_MH === electronicDocument?.MedioPago._text,
  );

  const parsePersonToBillingProfile = (person, ubicationData) => ({
    Name: person?.Nombre?._text,
    ID_Number: person?.Identificacion?.Numero?._text,
    Email: person?.CorreoElectronico?._text || "",
    ComercialName: person?.NombreComercial?._text || "",
    PhoneNumber: `+${person?.Telefono?.CodigoPais._text}-${person?.Telefono?.NumTelefono?._text}`,
    MH_Province: { Name: ubicationData?.getMHPresentationData?.Province?.Name },
    MH_District: { Name: ubicationData?.getMHPresentationData?.District?.Name },
    MH_Canton: { Name: ubicationData?.getMHPresentationData?.Canton?.Name },
    MH_Neighborhood: { Name: "" },
    OtherSigns: person?.Ubicacion?.OtrasSenas?._text || "",
  });

  const getProductsData = DetalleServicio =>
    DetalleServicio?.LineaDetalle?.map(
      (
        {
          NumeroLinea,
          Cantidad,
          UnidadMedida,
          Detalle,
          PrecioUnitario,
          MontoTotal,
          MontoTotalLinea,
        },
        index,
      ) => ({
        Linea: `${NumeroLinea?._text}`,
        Name: `${Detalle?._text}`,
        Quantity: `${Cantidad?._text}`,
        PrecioUnitario: formatCurrency(
          selectedCoin?.Symbol_Coin,
          parseFloat(PrecioUnitario?._text),
        ),
        Monto: formatCurrency(
          selectedCoin?.Symbol_Coin,
          parseFloat(MontoTotalLinea?._text),
        ),
        Descuento: formatCurrency(selectedCoin?.Symbol_Coin, 0),
      }),
    );

  const getBillResume = ResumenFactura => ({
    TotalDiscount: ResumenFactura.TotalDescuentos?._text,
    TotalExempt: ResumenFactura.TotalExento?._text,
    TotalExonerado: ResumenFactura.TotalExonerado?._text,
    TotalGoodsExempt: ResumenFactura.TotalMercanciasExentas?._text,
    TotalGoodsExonerated: ResumenFactura.TotalMercExonerada?._text,
    TotalGoodsTaxed: ResumenFactura.TotalMercanciasGravadas?._text,
    TotalSell: ResumenFactura.TotalVenta?._text,
    TotalServicesExempt: ResumenFactura.TotalServExentos?._text,
    TotalServicesExonerated: ResumenFactura.TotalServExonerado?._text,
    TotalServicesTaxed: ResumenFactura.TotalServGravados?._text,
    TotalTaxed: ResumenFactura.TotalGravado?._text,
    TotalNetSell: ResumenFactura.TotalVentaNeta?._text,
    TotalBill: ResumenFactura.TotalComprobante?._text,
    TotalTaxes: ResumenFactura.TotalImpuesto?._text,
  });

  const bill = getBillResume(electronicDocument.ResumenFactura);

  useEffect(() => {
    if (!electronicDocument) return;
    getMHEmisorData(
      "",
      electronicDocument?.Emisor?.Ubicacion?.Canton._text,
      electronicDocument?.Emisor?.Ubicacion?.Distrito._text,
      electronicDocument?.Emisor?.Ubicacion?.Provincia._text,
    );
    getMHReceptorData(
      "",
      electronicDocument?.Receptor?.Ubicacion?.Canton._text,
      electronicDocument?.Receptor?.Ubicacion?.Distrito._text,
      electronicDocument?.Receptor?.Ubicacion?.Provincia._text,
    );
  }, [getMHEmisorData, electronicDocument, getMHReceptorData]);

  return (
    <Box
      className="container-modal"
      sx={{
        padding: 2,
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
        height: "500px",
        overflowY: "auto",
        width: { xs: "400px", md: "100%" },
      }}
    >
      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "visible",
        }}
      >
        <Typography typography="modeColor" variant="h6">
          <span className="has-text-weight-bold">
            Resumen de factura electrónica
          </span>
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Clave: </span>
          {`  ${electronicDocument.Clave._text}`}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Consecutivo:</span>
          {electronicDocument.NumeroConsecutivo._text}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Condición de venta:</span>
          {`${sellConditionSelected?.Name_SellCondition || "--"} `}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Tipo de documento:</span>
          {billUploadDetailEmptyState.DocumentType}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Moneda:</span>
          {selectedCoin?.Code_Coin || "no encontrada"}
        </Typography>

        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Plazo crédito: ---</span>
          Fecha{" "}
          {new Date(
            electronicDocument.FechaEmision._text.toString(),
          ).toLocaleDateString() || "---"}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Medio de pago:</span>
          {` ${paymentMethodSelected?.Name_PaymentMethod || "no encontrada"}`}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Tipo de cambio:</span>{" "}
          {electronicDocument?.ResumenFactura?.CodigoTipoMoneda?.TipoCambio
            ._text ?? billUploadDetailEmptyState.Exchange}
        </Typography>
      </Card>
      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "visible",
        }}
      >
        <Typography typography="modeColor" variant="h6">
          <span className="has-text-weight-bold">Datos Emisor</span>
        </Typography>
        <PersonSection
          person={parsePersonToBillingProfile(
            electronicDocument.Emisor,
            emiterData,
          )}
        />
      </Card>
      {electronicDocument.Receptor && (
        <Card
          color="primary"
          sx={{
            paddingY: "1rem",
            paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            overflow: "visible",
          }}
        >
          <Typography typography="modeColor" variant="h6">
            <span className="has-text-weight-bold"> Datos Receptor</span>
          </Typography>
          <PersonSection
            person={parsePersonToBillingProfile(
              electronicDocument.Receptor,
              receptorData,
            )}
          />
        </Card>
      )}
      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "visible",
        }}
      >
        <Typography typography="modeColor" variant="h6">
          <span className="has-text-weight-bold">Detalles</span>
        </Typography>
        <Table
          columns={DEFAULT_COLUMNS}
          data={getProductsData(electronicDocument.DetalleServicio)}
        />
      </Card>

      <Card
        color="primary"
        sx={{
          paddingY: "1rem",
          paddingX: { xs: "1rem", sm: "1rem", md: "2.5rem" },
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          overflow: "visible",
        }}
      >
        <Typography typography="modeColor" variant="h6">
          <span className="has-text-weight-bold"> Totales</span>
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total Descuento:</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalDiscount),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total Exento:</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalExempt),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total Exoneraado:</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalExonerated),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">
            Total de Productos Exento:
          </span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalGoodsExempt),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">
            Total de Productos Exonerado :
          </span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalGoodsExonerated),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">
            Total de Productos Gravado:
          </span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalGoodsTaxed),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total de la Venta:</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalSell),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">
            Total de Servicios Exento:{" "}
          </span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalServicesExempt),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">
            Total de Servicio Exonerado:
          </span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalServicesExonerated),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">
            Total de Servicio Gravado:
          </span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalServicesTaxed),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total de Impuestos :</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalTaxes),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total de Venta Neta:</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalNetSell),
          )}
        </Typography>
        <Typography typography="modeColor" variant="sutitle1">
          <span className="has-text-weight-bold">Total de la Factura:</span>{" "}
          {formatCurrency(
            selectedCoin?.Symbol_Coin,
            parseFloat(bill.TotalBill),
          )}
        </Typography>
      </Card>
    </Box>
  );
}

BillUploadDetail.propTypes = {
  electronicDocument: PropTypes.object.isRequired,
};
export default BillUploadDetail;
