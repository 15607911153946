import React, { useEffect } from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control } from "rbx";

function SubModuleSelect({ submoduleData, onChange, className }) {
  useEffect(() => {
    onChange(submoduleData?.getSubmodulesByPlaceAndModuleAndRole[0]?.id);
  });

  return (
    <Control expanded>
      <Select.Container fullwidth>
        <Select className={className} onChange={e => onChange(e.target.value)}>
          {submoduleData?.getSubmodulesByPlaceAndModuleAndRole?.map(
            submodule => (
              <Select.Option key={submodule.id} value={submodule.id}>
                {submodule.Name_Submodule}
              </Select.Option>
            ),
          )}
        </Select>
      </Select.Container>
    </Control>
  );
}

SubModuleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  submoduleData: PropTypes.object.isRequired,
};

SubModuleSelect.defaultProps = {
  className: "",
};

export default SubModuleSelect;
