const DEFAULT_COLUMNS = [
  {
    Header: "Linea",
    id: "key ",
    type: "number",
    accessor: "Line",
  },
  {
    Header: "Nombre",
    id: "Response",
    type: "Text",
    accessor: "Name",
  },
  {
    Header: "Precio Unitario",
    type: "number",
    accessor: "UnitPrice",
  },
  {
    Header: "Cantidad",
    type: "number",
    accessor: "Quantity",
  },
  {
    Header: "Total",
    type: "number",
    accessor: "Amount",
  },
];

export default DEFAULT_COLUMNS;
