/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_UNIT_OF_MEASURE = gql`
  query listAllUnitOfMeasurement {
    unitsOfMeasurement: listAllUnitOfMeasurement {
      id
      Name_UnitOfMeasurement
      Symbol
    }
  }
`;
