import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Button, Card, Typography } from "@mui/material";
// Graphql
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_ALL_GROUPS_BY_PLACE,
  REGISTER_NEW_CLIENT_GROUP,
  UPDATE_CLIENT_GROUP,
  DELETE_CLIENT_GROUP,
} from "../../graphql";
import ClientGroupRegister from "../ClientGroupRegister";
// Utils
import { customToast as toast } from "../../utils";
import { ESUBMODULEROUTES } from "../../Enums/Submodules";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// SCSS
import "./ClientsGroupsXPlace.scss";
import "../ProductCard/ProductCard.scss";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import clientIcon from "../../assets/icons/client_icon.svg";
import deleteIcon from "../../assets/icons/delete_icon.svg";
import editIcon from "../../assets/icons/edit_icon.svg";

function ClientsGroupsXPlace() {
  const { LineDetailPlace } = useElectronic();
  const navigate = useNavigate();
  const { setModalOpen } = useModal();

  const {
    loading,
    data: dataClientsGroups,
    error,
  } = useQuery(GET_ALL_GROUPS_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });
  const [registerGroup] = useMutation(REGISTER_NEW_CLIENT_GROUP);
  const [updateGroup] = useMutation(UPDATE_CLIENT_GROUP);
  const [deleteGroup] = useMutation(DELETE_CLIENT_GROUP);
  const { setModalOpen: setDeleteConfirmationModal } = useModal();
  const { permissions } = useAuth();

  const { haveActionPermission, noAccessMessage } = permissions;
  const canRead = haveActionPermission("Read", "/clients", LineDetailPlace);
  const canCreate = haveActionPermission("Create", "/clients", LineDetailPlace);
  const canEdit = haveActionPermission("Edit", "/clients", LineDetailPlace);
  const canDelete = haveActionPermission("Delete", "/clients", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Clientes"));
    navigate("/");
  }
  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  const handleSelectGroup = group => {
    navigate(
      `/clients-groups/groups/${LineDetailPlace.id}/${LineDetailPlace.Name_Place}/clients/${group?.id}/${group?.Name_ClientGroup}/${LineDetailPlace.id}`,
    );
  };
  const register = useCallback(
    async newGroup => {
      try {
        setModalOpen(false);
        const { data } = await registerGroup({
          variables: {
            data: newGroup,
          },
          refetchQueries: [
            {
              query: GET_ALL_GROUPS_BY_PLACE,
              variables: {
                FK_Place: LineDetailPlace.id,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Grupo registrado con éxito!");
        }
      } catch (err) {
        toast.error("Error al registrar el grupo");
      }
    },
    [registerGroup, setModalOpen, LineDetailPlace.id],
  );
  const update = useCallback(
    async newGroup => {
      const newGroupData = {
        submodulePermissionData: {
          placeId: LineDetailPlace.id,
          submoduleRoute: ESUBMODULEROUTES.CLIENTS,
        },
        ...newGroup,
      };
      try {
        setModalOpen(false);
        const { data } = await updateGroup({
          variables: {
            data: newGroupData,
          },
          refetchQueries: [
            {
              query: GET_ALL_GROUPS_BY_PLACE,
              variables: {
                FK_Place: LineDetailPlace.id,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Grupo editado con éxito!");
        }
      } catch (err) {
        toast.error("Error al editar el grupo");
      }
    },
    [setModalOpen, updateGroup, LineDetailPlace.id],
  );
  const handleAddGroup = () => {
    setModalOpen(
      true,
      <ClientGroupRegister
        placeId={LineDetailPlace.id}
        registerGroup={register}
        setModalOpen={setModalOpen}
      />,
    );
  };

  const handleEditGroup = (id, name_group, description_group) => {
    setModalOpen(
      true,
      <ClientGroupRegister
        descriptionGroup={description_group}
        groupId={id}
        nameGroup={name_group}
        placeId={LineDetailPlace.id}
        setModalOpen={setModalOpen}
        updateGroup={update}
      />,
    );
  };

  const handleDeleteGroup = async id => {
    setDeleteConfirmationModal(
      true,
      <ConfirmationModal
        body="¿Desea eliminar este usuario de tu grupo?"
        header="¿Eliminar usuario?"
        onCancel={() => setDeleteConfirmationModal(false)}
        onConfirm={async () => {
          try {
            const { data } = await deleteGroup({
              variables: {
                id,
              },
              refetchQueries: [
                {
                  query: GET_ALL_GROUPS_BY_PLACE,
                  variables: {
                    FK_Place: LineDetailPlace.id,
                  },
                },
              ],
            });
            if (data) {
              toast.success("¡Grupo eliminado con éxito!");
            }
          } catch (err) {
            toast.error("Error al eliminar el grupo");
          } finally {
            setDeleteConfirmationModal(false);
          }
        }}
      />,
    );
  };

  return (
    <div className="content-flex-column">
      {canCreate && (
        <div className="groups-header">
          <Button
            className="adjust-width-button"
            color="primary"
            type="button"
            variant="contained"
            onClick={handleAddGroup}
          >
            Nuevo grupo
          </Button>
        </div>
      )}
      <div className="content-flex-cards">
        {Array.isArray(dataClientsGroups?.groups) &&
          dataClientsGroups?.groups?.length > 0 &&
          dataClientsGroups?.groups?.map(groupItem => (
            <Card
              color="primary"
              sx={{
                width: "20.813rem",
                height: "6.938rem",
                paddingY: "1rem",
                paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <div
                  className="information-container"
                  role="button"
                  tabIndex={0}
                  onClick={() => handleSelectGroup(groupItem)}
                  onKeyDown={() => null}
                >
                  <img
                    alt="icono cliente"
                    className="icon-color icon-client"
                    src={clientIcon}
                  />
                  <Typography
                    align="center"
                    fontWeight={600}
                    typography="modeColor"
                    variant="fixedSizeSubtitle1"
                  >
                    {groupItem.Name_ClientGroup}
                  </Typography>
                </div>
                <div className="content-flex-align-end">
                  {canEdit && (
                    <div>
                      <button
                        className="button-modifications"
                        type="button"
                        onClick={() =>
                          handleEditGroup(
                            groupItem.id,
                            groupItem.Name_ClientGroup,
                            groupItem.Description_ClientGroup,
                          )
                        }
                      >
                        <img
                          alt="editar"
                          className="icon-button icon-color"
                          src={editIcon}
                        />
                      </button>
                      <Tooltip id="ButtonEdit" type="info">
                        <span>Edita la información del grupo</span>
                      </Tooltip>
                    </div>
                  )}
                  {canDelete && (
                    <div>
                      <button
                        className="button-modifications"
                        type="button"
                        onClick={() => handleDeleteGroup(groupItem.id)}
                      >
                        <img
                          alt="eliminar"
                          className="icon-button icon-color"
                          src={deleteIcon}
                        />
                      </button>
                      <Tooltip id="ButtonDelete" type="info">
                        <span>Elimina el grupo</span>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>
            </Card>
          ))}
      </div>
      {dataClientsGroups?.groups?.length === 0 && (
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="fixedSizeSubtitle1"
        >
          Aún no hay grupos registrados para {LineDetailPlace.Name_Place}.
        </Typography>
      )}
    </div>
  );
}

ClientsGroupsXPlace.propTypes = {};

ClientsGroupsXPlace.defaultProps = {};

export default ClientsGroupsXPlace;
