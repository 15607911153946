/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const UPDATE_CONFIGURATION_BILL = gql`
  mutation updateConfigurationBill($billConfiguration: BillConfigurationInput) {
    updateConfigurationBill(billConfiguration: $billConfiguration) {
      id
    }
  }
`;
