import { useApolloClient, useMutation } from "@apollo/client";
import { useCallback } from "react";
import { useElectronic } from "../context/ElectronicContext";
import { TAX_CODES } from "../Enums/ElectronicBill";
import SELL_CONDITION from "../Enums/SellCondition";
import {
  CREATE_EB_FROM_BILL,
  GET_EB_BY_ID,
  CREATE_MANY_ELECTRONIC_BILLS,
  ELECTRONIC_BILLS_EXIST,
  GET_EB_FOR_REVIEW,
  GET_BILL_BY_ID,
  SAVE_TEMP_BILL,
} from "../graphql";
import { client_EB_api } from "../graphql/client";
import {
  billConfigurationEmptyState,
  defaultTariff,
  defaultTax,
} from "../InitialStates/ElectronicContext";
import { formatElectronicBillDetailsFormattedWithMaxQuantity } from "../utils/electronicBill";
import getBill from "../utils/formatBill";
import { removeTypeName } from "../utils/helpers";
import { getSubTotal, getTaxedAmount, getTotal } from "../utils/Operations";

const useElectronicBill = () => {
  const [createTempBill] = useMutation(SAVE_TEMP_BILL);
  const client = useApolloClient();

  const {
    lineDetail,
    LineDetailPlace,
    receiver,
    electronicBill,
    setElectronicBill,
    setLineDetail,
    setLineDetailPlace,
    setReceiver,
    useCoinDetail,
    scheduledBill,
    setScheduledBill,
  } = useElectronic();

  const {
    handleCoinChange,
    getCoinsData,
    toDolar,
    toLocalExchangeRate,
    toExchangeRate,
  } = useCoinDetail;

  const getElectronicBill = async id => {
    const {
      data: { EBill },
    } = await client.query({
      query: GET_EB_BY_ID,
      variables: {
        id,
      },
      fetchPolicy: "no-cache",
    });
    return EBill;
  };
  const getElectronicBillForReview = useCallback(
    async id => {
      const {
        data: { bill },
      } = await client.query({
        query: GET_EB_FOR_REVIEW,
        variables: {
          id,
        },
        fetchPolicy: "no-cache",
      });
      return bill;
    },
    [client],
  );

  const getDraftBill = async id => {
    const {
      data: { bill },
    } = await client.query({
      query: GET_BILL_BY_ID,
      variables: {
        id,
      },
    });
    return bill;
  };

  const getBillByElectronicBill = _electronicBill =>
    _electronicBill?.Bill ??
    _electronicBill?.ReferencedDocument?.Bill ??
    _electronicBill?.ReferencedDocument?.ReferencedDocument?.Bill;

  const getLineDetailsAsDraftDetails = lineDetails =>
    lineDetail.map(detail => {
      const subTotal = getSubTotal(detail.unit_price, 0) * detail.quantity;

      const taxedAmount =
        getTaxedAmount({
          taxCode: detail?.tax?.MH_Code ?? TAX_CODES.IVA,
          subTotal,
          porcentajeTarifa: detail?.Tariff?.Percent,
          baseTax: 0,
          factor: 0,
        }) * detail.quantity;

      const exemptAmount = subTotal - taxedAmount;
      let totalDiscounts = 0;
      detail?.discountAmount?.forEach(index => {
        totalDiscounts += parseFloat(index);
      });

      return {
        unitPrice: detail.unit_price,
        subTotal,
        totalPrice: getTotal(subTotal, taxedAmount),
        discount: toDolar(parseFloat(totalDiscounts)),
        baseTax: toDolar(detail?.BaseTax) || 0,
        factor: detail?.Factor || 0,
        exempt: exemptAmount,
        taxed: taxedAmount,
        tariff: removeTypeName(detail?.Tariff ?? defaultTariff),
        tax: removeTypeName(detail?.tax ?? defaultTax),
        MeasurementUnit: detail.unit_Measure.id,
        FK_ProductFeature: detail.id,
        quantity: detail.quantity,
        Total_BillDetail: toDolar(getTotal(subTotal, taxedAmount)),
      };
    });

  const saveTempralBill = async () => {
    const Coin = removeTypeName(
      await getCoinsData.coins.find(
        element => element.id === useCoinDetail.currentCoin,
      ),
    );

    const {
      data: { bill },
    } = await createTempBill({
      variables: {
        bill_new: {
          FK_Place: Number(LineDetailPlace.id),
          FK_User: receiver.user.id,
          Coin,
          FK_SellCondition: electronicBill.Sell_Condition,
          FK_PaymentMethod: electronicBill.Payment_Method,
          Payment_Detail: electronicBill.OtherPaymentMethod,
          SellCondition_Detail:
            electronicBill.Sell_Condition === SELL_CONDITION.CREDITO.ID
              ? electronicBill.CreditPaymentCondition
              : electronicBill.OtherSellCondition,

          FK_BillType: electronicBill.BillType,
        },
        detail_new: getLineDetailsAsDraftDetails(),
        detail: lineDetail.map(detail => ({
          FK_ProductFeature: detail.value,
          Quantity_BillDetail: detail.quantity,
          Total_BillDetail: detail.total,
          SubTotal_BillDetail: detail.unit_price,
          BaseTax: toDolar(parseFloat(detail?.BaseTax).toFixed(4)) || 0,
          Factor: parseFloat(detail?.Factor || 0).toFixed(4),
        })),
        config_new: {
          date: `${scheduledBill.Year}-${scheduledBill.Month}-${scheduledBill.Day} 00:00:00`,
          Day: scheduledBill.Day,
          Month: scheduledBill.Month,
          Year: scheduledBill.Year,
          Minute: scheduledBill.Minute,
          Hour: scheduledBill.Hours,
          Frequency: scheduledBill.Frequency,
          Active: scheduledBill.Active,
          Next_Execution: `${scheduledBill.Year}-${scheduledBill.Month}-${scheduledBill.Day} ${scheduledBill.Hours}:${scheduledBill.Minute}:00`,
        },
      },
    });
    return bill;
  };
  const saveEBillFormBill = async id => {
    const {
      data: { bill },
    } = await client_EB_api.mutate({
      mutation: CREATE_EB_FROM_BILL,
      variables: {
        id: parseInt(id, 10),
      },
    });
    return bill;
  };

  const saveElectronicBillsXML = async (bills, OwnerPlaceId) => {
    const { data } = await client.mutate({
      mutation: CREATE_MANY_ELECTRONIC_BILLS,
      variables: {
        electronicBills: bills,
        OwnerPlace: OwnerPlaceId,
      },
    });
    const { createManyElectronicBills } = data;
    return createManyElectronicBills;
  };

  const validateIfElectronicBillExist = async newBills => {
    const { data } = await client.query({
      query: ELECTRONIC_BILLS_EXIST,
      variables: {
        electronicBills: newBills,
      },
    });
    const { electronicBillExist } = data;
    return electronicBillExist;
  };

  const handleElectronicBillsXML = async (
    tavuelUser,
    electronicBills,
    OwnerPlaceId,
    flowType,
  ) => {
    const { id } = tavuelUser;
    const platformExchnage = toLocalExchangeRate(1, getCoinsData?.coins[0]);
    const selectedElectronicBills = electronicBills
      .filter(invoice => invoice.selected)
      .map(invoice =>
        getBill(invoice, flowType, id, getCoinsData.coins, platformExchnage),
      );

    const response = {
      response: null,
      message: [],
      success: null,
    };
    if (selectedElectronicBills.length === 0) {
      return {
        response: "error",
        message: "No se ha seleccionado alguna factura",
      };
    }

    const responseBill = await saveElectronicBillsXML(
      selectedElectronicBills,
      OwnerPlaceId,
    );

    if (responseBill) {
      const { billDenid, billAccepted } = responseBill.reduce(
        (acc, bill) => {
          if (bill.error) {
            acc.billDenid.push(bill);
            return acc;
          }
          acc.billAccepted.push(bill);
          return acc;
        },
        { billDenid: [], billAccepted: [] },
      );
      if (billDenid.length > 0) {
        response.response = "errors";
        response.message.push(...billDenid.map(({ key }) => key));
      }
      if (billAccepted.length > 0) {
        response.success = "Facturas guardadas con éxito";
      }
      return response;
    }

    return { response: "error", message: "Ha ocurrido un error" };
  };

  const loadElectronicBill = async (id, isCreditNoteView) => {
    const data = await getElectronicBill(id);
    if (!data) return;

    const formattedElectronicBill =
      formatElectronicBillDetailsFormattedWithMaxQuantity(data);

    const products = formattedElectronicBill?.ElectronicBillDetail?.map(
      index => {
        const feature = formattedElectronicBill?.Bill?.Detail?.find(
          detail => detail?.ProductFeature?.id === index?.ProductFeature?.id,
        );
        if (feature)
          return {
            ...index,
            Quantity_BillDetail: feature?.Quantity_BillDetail,
            Total_BillDetail: feature?.Total_BillDetail,
          };
        return null;
      },
    ).filter(index => index?.id);

    const result = Array.isArray(products)
      ? products.map(item => {
          const {
            ProductFeature: {
              Details,
              Product,
              ProductPrice,
              Files,
              id: productFeature_id,
            },
          } = item;

          return {
            detail_id: item.id,
            id: productFeature_id,
            description: Product?.Description_Product,
            cabys: ProductPrice?.Cabys,
            Tariff: ProductPrice?.Tariff,
            unit_price: ProductPrice?.Price_ProductPrice?.toFixed(3),
            quantity: item?.Quantity_BillDetail,
            discountAmount: [
              toExchangeRate(item?.discount_BillDetail?.toFixed(3)),
            ],
            tax: ProductPrice?.Tax,
            taxes: [],
            totalTaxes: 0,
            total: item?.Total_BillDetail?.toFixed(3),
            unit_Measure: "",
            product_Code: "",
            url: Files,
            details: Details,
            value: item.id,
            label: Product?.Description_Product,
            checked: false,
            valid: true,
            maxQuantity: item.maxQuantity,
          };
        })
      : [];
    const place = data?.Place;

    setLineDetailPlace({
      id: place?.id,
      Name_Place: place?.Name_Place,
      Company: { id: place?.Company.id },
      Code: place?.Code || "000",
    });

    data?.Receiver?.UserBillingProfile &&
      setReceiver({
        id: data?.Receiver?.id,
        Receiver_Id: data?.Receiver?.UserBillingProfile.ID_Number,
        Receiver_Email: data?.Receiver?.UserBillingProfile.Email,
        Receiver_Name: data?.Receiver?.UserBillingProfile.Name,
        Receiver_PhoneNumber: data?.Receiver?.UserBillingProfile.PhoneNumber,
      });
    setLineDetail(result);
    setElectronicBill({
      id: data?.id,
      Sell_Condition: data?.FK_SellCondition,
      Coin: data?.FK_Coin,
      Payment_Method: data?.FK_PaymentMethod,
      OtherPaymentMethod: data?.Payment_Detail,
      OtherSellCondition: data?.SellCondition_Detail,
    });
    handleCoinChange(data?.Coin?.id);
  };

  const loadDraftBill = async id => {
    const data = await getDraftBill(id);
    if (!data) return;

    const products = data?.Detail;
    const result = Array.isArray(products)
      ? products.map(item => {
          const {
            ProductFeature: {
              Details,
              Product,
              ProductPrice,
              Files,
              id: productFeature_id,
            },
          } = item;

          return {
            detail_id: item.id,
            id: productFeature_id,
            description: Product?.Description_Product,
            cabys: ProductPrice?.Cabys,
            Tariff: ProductPrice?.Tariff,
            unit_price: ProductPrice?.Price_ProductPrice?.toFixed(3),
            quantity: item?.Quantity_BillDetail,
            BaseTax: item?.BaseTax,
            Factor: item?.Factor,
            discountAmount: [
              toExchangeRate(item?.discount_BillDetail.toFixed(3)),
            ],
            tax: ProductPrice?.Tax,
            taxes: [],
            totalTaxes: 0,
            total: item.Total_BillDetail.toFixed(3),
            Taxed: item.Taxed,
            unit_Measure: "",
            product_Code: "",
            url: Files,
            details: Details,
            value: item.id,
            label: Product?.Description_Product,
          };
        })
      : [];
    const place =
      data.Detail[0].ProductFeature.InventoryDetail.Inventory.Cellar.Place;
    setLineDetailPlace({
      id: place?.id,
      Name_Place: place?.Name_Place,
      Company: { id: place?.Company.id },
      Code: place?.Code || "000",
    });
    data?.User?.id &&
      setReceiver({
        id: data?.User?.id,
        Receiver_Id: data?.User?.UserBillingProfile.ID_Number,
        Receiver_Email: data?.User?.UserBillingProfile.Email,
        Receiver_Name: data?.User?.UserBillingProfile.Name,
        Receiver_PhoneNumber: data?.User?.UserBillingProfile.PhoneNumber,
      });
    setLineDetail(result);
    setElectronicBill({
      id: data?.id,
      Sell_Condition: data?.FK_SellCondition,
      Coin: data?.FK_Coin,
      Payment_Method: data?.FK_PaymentMethod,
      OtherPaymentMethod: data?.Payment_Detail,
      OtherSellCondition: data?.SellCondition_Detail,
      CreditPaymentCondition: data?.SellCondition_Detail,
    });
    handleCoinChange(data?.FK_Coin);
    if (data.BillConfiguration !== null) {
      const infoDate = new Date(data?.BillConfiguration?.date);
      setScheduledBill({
        date: infoDate,
        Day: infoDate.getDate(),
        Month: infoDate.getMonth(),
        Year: infoDate.getFullYear(),
        Hour: `${data?.BillConfiguration?.Hour}${":"}${data?.BillConfiguration?.Minute}`,
        Minute: data.BillConfiguration.Minute,
        Hours: data.BillConfiguration.Hour,
        Frequency: data?.BillConfiguration?.Frequency,
        Active: data?.BillConfiguration?.Active,
      });
    } else {
      setScheduledBill(billConfigurationEmptyState);
    }
  };

  const formatBillDetailToTable = (billDetail, symbolCoin) => ({
    Line: billDetail.index,
    UnitPrice:
      symbolCoin + toExchangeRate(billDetail.UnitPrice || 0).toFixed(2),
    Quantity: billDetail?.Quantity,
    Name: `${billDetail?.ProductFeature?.Product?.Name_Product} ${billDetail?.ProductFeature?.Product?.Description_Product}`,
    Amount: symbolCoin + toExchangeRate(billDetail.Total_BillDetail).toFixed(2),
    Discount: billDetail.discount_BillDetail,
    productFeatureId: billDetail?.ProductFeature?.id || 0,
  });
  const getBillDetailsByElectronicBill = (_electronicBill, isDebitNote) =>
    _electronicBill?.Bill?.Detail ??
    _electronicBill?.ReferencedDocument?.Bill?.Detail ??
    _electronicBill?.ReferencedDocument?.ReferencedDocument?.Bill?.Detail ??
    [];

  const formatProductsWithCorrectPriceAndQuantity = (
    electronicBillDetails,
    billDetails,
  ) =>
    electronicBillDetails.map(electronicBillDetail => {
      const foundBillItem = billDetails.find(
        billDetail =>
          billDetail?.ProductFeature?.id ===
          electronicBillDetail?.ProductFeature?.id,
      );

      return {
        ...foundBillItem,
        Quantity_BillDetail: electronicBillDetail?.Quantity ?? 0,
      };
    });

  return {
    saveTempralBill,
    saveEBillFormBill,
    getElectronicBill,
    loadElectronicBill,
    saveElectronicBillsXML,
    validateIfElectronicBillExist,
    handleElectronicBillsXML,
    getElectronicBillForReview,
    LineDetailPlace,
    getDraftBill,
    loadDraftBill,
    formatBillDetailToTable,
    getBillDetailsByElectronicBill,
    formatProductsWithCorrectPriceAndQuantity,
    getBillByElectronicBill,
  };
};

export default useElectronicBill;
