import React from "react";
import PropTypes from "prop-types";
// External components
import { Card, Typography } from "@mui/material";
// Graphql
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_COINS,
  GET_ALL_PAYMENT_METHODS,
  GET_ALL_SELL_CONDITIONS,
  UPDATE_BILL_PAYMENT_METHOD,
  UPDATE_BILL_SELL_CONDITION,
  UPDATE_TEMP_BILL_FK,
} from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import { ElectronicBillTypes } from "../../Enums/ElectronicBill";
// SCSS
import "../OptionCard/OptionCard.scss";
import InputForm from "../InputForm/InputForm";
import SelectForm from "../SelectForm/SelectForm";
import { formatData } from "../../utils/helpers";

function PaymentDataCard({ mainComponent, type }) {
  const [updateBillField] = useMutation(UPDATE_TEMP_BILL_FK);
  const [updateBillPaymentMethod] = useMutation(UPDATE_BILL_PAYMENT_METHOD);
  const [updateBillSellCondition] = useMutation(UPDATE_BILL_SELL_CONDITION);
  const { data: getPaymentMethodData } = useQuery(GET_ALL_PAYMENT_METHODS);
  const { data: getSellConditionData } = useQuery(GET_ALL_SELL_CONDITIONS);
  const { data: getCoinsData } = useQuery(GET_ALL_COINS);

  const { electronicBill, handleChangeElectronicBill, useCoinDetail } =
    useElectronic();
  const { currentCoin, handleCoinChange } = useCoinDetail;

  const disabled = type === ElectronicBillTypes.CreditNote;

  const updateValues = (name, value) => {
    if (!electronicBill.id) return;
    if (name === "Coin") {
      updateBillField({
        variables: {
          id: electronicBill.id,
          value,
          field: "FK_Coin",
        },
      });
      return;
    }
    if (name === "Payment_Method" || name === "OtherPaymentMethod") {
      handlePaymentChange(name, value);
      return;
    }
    if (
      name === "Sell_Condition" ||
      name === "OtherSellCondition" ||
      name === "CreditPaymentCondition"
    ) {
      handleSellConditionChange(name, value);
    }
  };

  const handlePaymentChange = (name, value) => {
    handleChangeElectronicBill(name, value);
    if (name === "Payment_Method") {
      if (value === "6") {
        updateBillPaymentMethod({
          variables: {
            id: electronicBill.id,
            FK_PaymentMethod: value,
            Payment_Detail: electronicBill.OtherPaymentMethod,
          },
        });
        return;
      }

      updateBillPaymentMethod({
        variables: {
          id: electronicBill.id,
          FK_PaymentMethod: value,
          Payment_Detail: null,
        },
      });
      return;
    }

    updateBillPaymentMethod({
      variables: {
        id: electronicBill.id,
        FK_PaymentMethod: electronicBill.Payment_Method,
        Payment_Detail: electronicBill[name],
      },
    });
  };

  const handleSellConditionChange = (name, value) => {
    handleChangeElectronicBill(name, value);
    if (name === "Sell_Condition") {
      if (value === "2" || value === "10") {
        updateBillSellCondition({
          variables: {
            id: electronicBill.id,
            FK_SellCondition: value,
            SellCondition_Detail:
              value === "2"
                ? electronicBill.CreditPaymentCondition
                : electronicBill.OtherSellCondition,
          },
        });
        return;
      }
      updateBillSellCondition({
        variables: {
          id: electronicBill.id,
          FK_SellCondition: value,
          SellCondition_Detail: null,
        },
      });
    } else {
      updateBillSellCondition({
        variables: {
          id: electronicBill.id,
          FK_SellCondition: electronicBill.Sell_Condition,
          SellCondition_Detail: electronicBill[name],
        },
      });
    }
  };

  return (
    <Card
      color="primary"
      sx={{
        minHeight: "184px",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "1rem",
      }}
    >
      {!mainComponent && (
        <div className="card-secondary-content-title flex">
          <div className="card-total-width">
            <Typography
              fontWeight={600}
              typography="modeColor"
              variant="h6"
              width="100%"
            >
              Datos de pago
            </Typography>
          </div>
        </div>
      )}
      {mainComponent && (
        <Typography
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Datos de pago
        </Typography>
      )}
      <div className="content-select flex ">
        {getPaymentMethodData?.paymentMethod && (
          <SelectForm
            color="secondary"
            data={formatData(
              getPaymentMethodData?.paymentMethod,
              "Name_PaymentMethod",
            )}
            disabled={disabled}
            label="Método de pago"
            labelId="demo-simple-select-filled-label"
            name="Payment_Method"
            size="small"
            value={electronicBill.Payment_Method}
            onChange={e => {
              handleChangeElectronicBill(e.target.name, e.target.value);
              updateValues(e.target.name, e.target.value);
            }}
          />
        )}
      </div>
      <div className="flex ">
        {electronicBill?.Payment_Method === "6" && (
          <InputForm
            color="secondary"
            disabled={disabled}
            label="Especifique el método de pago"
            name="OtherPaymentMethod"
            size="small"
            type="text"
            value={electronicBill.OtherPaymentMethod}
            onBlur={({ target: { name, value } }) => updateValues(name, value)}
            onChange={e =>
              handleChangeElectronicBill(e.target.name, e.target.value)
            }
          />
        )}
      </div>
      <div className="content-select flex ">
        <SelectForm
          color="secondary"
          data={formatData(getCoinsData?.coins, "Name_Coin")}
          disabled={disabled}
          label="Tipo de moneda"
          name="Coin"
          size="small"
          value={currentCoin}
          onChange={({ target: { name, value } }) => {
            handleChangeElectronicBill(name, value);
            updateValues(name, value);
            handleCoinChange(value);
          }}
        />
      </div>
      <div className="content-select flex ">
        {getSellConditionData?.sellConditions && (
          <SelectForm
            color="secondary"
            data={formatData(
              getSellConditionData?.sellConditions || [],
              "Name_SellCondition",
            )}
            disabled={disabled}
            label="Condición de venta"
            labelId="demo-simple-select-filled-label"
            name="Sell_Condition"
            size="small"
            value={electronicBill.Sell_Condition}
            onChange={e => {
              handleChangeElectronicBill(e.target.name, e.target.value);
              updateValues(e.target.name, e.target.value);
            }}
          />
        )}
      </div>
      <div className="flex ">
        {electronicBill?.Sell_Condition === "10" && (
          <InputForm
            disabled={disabled}
            label="Especifique la condición de venta"
            name="OtherSellCondition"
            size="small"
            type="text"
            value={electronicBill.OtherSellCondition}
            onBlur={({ target: { name, value } }) => updateValues(name, value)}
            onChange={e =>
              handleChangeElectronicBill(e.target.name, e.target.value)
            }
          />
        )}
        {electronicBill?.Sell_Condition === "2" && (
          <InputForm
            disabled={disabled}
            label="Indique la cantidad de días del plazo del crédito"
            name="CreditPaymentCondition"
            size="small"
            type="number"
            value={electronicBill.CreditPaymentCondition}
            onBlur={({ target: { name, value } }) => updateValues(name, value)}
            onChange={e =>
              handleChangeElectronicBill(e.target.name, e.target.value)
            }
          />
        )}
      </div>
    </Card>
  );
}

PaymentDataCard.propTypes = {
  mainComponent: PropTypes.bool,
  type: PropTypes.string.isRequired,
};
PaymentDataCard.defaultProps = {
  mainComponent: false,
};
export default PaymentDataCard;
