/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_INVENTORIES_BY_PLACE = gql`
  query getByPlace($query: InventoriesByPlaceInput) {
    inventories: getInventoriesByPlace(query: $query) {
      id
      Description
    }
  }
`;
