// export { default } from './BuyElectronicBill';

import React from "react";
import { Routes, Route } from "react-router-dom";
import Inventories from "../Inventories";
import ClientReceiver from "../PaymentComponet/Receiver/ClientReceiver";
import BuyElectronicBill from "./BuyElectronicBill";

function BuyElectronicBillRoute() {
  return (
    <Routes>
      <Route element={<Inventories />} path="store" />
      <Route element={<ClientReceiver />} path="client" />
      <Route element={<BuyElectronicBill />} path="" />
    </Routes>
  );
}

export default BuyElectronicBillRoute;
