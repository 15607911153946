import React from "react";
import PropTypes from "prop-types";
// External components
import { Button, Card, Typography } from "@mui/material";
// SCSS
import "./SummaryExpenses.scss";
// ICON
import deleteIcon from "../../../../assets/icons/delete_icon.svg";

function SummaryExpenses({
  amount,
  expense,
  discount,
  setInfoSummary,
  id,
  iva,
  symbolCoin,
  quantity,
  measurmentUnit,
  total,
  subTotal,
}) {
  const handleDelete = () => {
    setInfoSummary(prev => prev.filter(i => i.id !== id));
  };

  return (
    <Card
      color="tertiaryAcento"
      sx={{
        maxHeight: "194x",
        width: "333px",
        minWidth: "333px",
        display: "flex",
        flexDirection: "column",
        padding: "1rem",
        gap: "0.1rem",
      }}
      variant="outlined"
    >
      <div className="content-grid-fixed-column">
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="body1"
        >
          <b>Precio unitario</b>
          {symbolCoin}
          {parseFloat(amount).toFixed(3)}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="body1"
        >
          <b>Cantidad</b>
          {`${quantity} ${measurmentUnit.Symbol}`}
        </Typography>
      </div>
      <div className="content-grid-fixed-column">
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="body1"
          width="100%"
        >
          <b>Descuento Total</b>
          {parseFloat(discount).toFixed(3)}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="body1"
          width="100%"
        >
          <b>IVA</b>
          {symbolCoin}
          {iva}
        </Typography>
      </div>

      <div className="content-grid-fixed-column">
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="body1"
          width="100%"
        >
          <b>SubTotal</b>
          {symbolCoin}
          {parseFloat(subTotal).toFixed(3)}
        </Typography>
        <Typography
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
          typography="modeColor"
          variant="body1"
          width="100%"
        >
          <b>Total</b>
          {symbolCoin}
          {total.toFixed(3)}
        </Typography>
      </div>

      <div className="content-flex-end">
        <Button onClick={handleDelete}>
          <img
            alt="eliminar"
            className="icon-button icon-color"
            src={deleteIcon}
          />
        </Button>
      </div>
    </Card>
  );
}

SummaryExpenses.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  discount: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  expense: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  iva: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  symbolCoin: PropTypes.string.isRequired,
  setInfoSummary: PropTypes.func,
  quantity: PropTypes.number.isRequired,
  measurmentUnit: PropTypes.object.isRequired,
  total: PropTypes.number.isRequired,
  subTotal: PropTypes.number.isRequired,
};

SummaryExpenses.defaultProps = {
  setInfoSummary: e => e,
};

export default SummaryExpenses;
