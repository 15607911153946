/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const CREATE_PERMISSION_SUBMODULE_ROL = gql`
  mutation handleRolePermissionSubmodule(
    $rolePermissionSubmodule: RolePermissionSubmoduleInput
  ) {
    handleRolePermissionSubmodule(
      rolePermissionSubmodule: $rolePermissionSubmodule
    )
  }
`;

export const CREATE_ROL_WITH_SUBMODULE = gql`
  mutation createRoleWithSubmodules(
    $role: RoleInput
    $submodules: [SubmoduleWithAccessInput]
  ) {
    role: createRoleWithSubmodules(role: $role, submodules: $submodules)
  }
`;

export const UPDATE_ROL_WITH_SUBMODULE = gql`
  mutation updateRoleWithSubmodules(
    $role: RoleInput
    $submodules: [SubmoduleWithAccessInput]
  ) {
    role: updateRoleWithSubmodules(role: $role, submodules: $submodules)
  }
`;
