import React from "react";
import "./InputForm.scss";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TextField, FormControl } from "@mui/material";

function InputForm({
  type,
  name,
  id,
  onChange,
  onClick,
  label,
  size,
  autoComplete,
  required,
  value,
  onClickPassword,
  showPassword,
  typeInput,
  classNameInput,
  placeholder,
  color,
  multiline,
  maxRows,
  rows,
  defaultValue,
  disabled,
  sx,
  variant,
  isError,
}) {
  return (
    <FormControl fullWidth className="container-button">
      <TextField
        autoComplete={autoComplete}
        className={` ${classNameInput}`}
        color={color}
        defaultValue={defaultValue}
        disabled={disabled}
        error={isError}
        id={id}
        label={label}
        maxRows={maxRows}
        multiline={multiline}
        name={name}
        placeholder={placeholder}
        required={required}
        size={size}
        sx={{ borderRadius: 1, ...sx }}
        type={type}
        value={value}
        variant={variant}
        onChange={onChange}
        onClick={onClick}
      />
      <div className="input-container">
        {typeInput === "password" &&
          (showPassword ? (
            <div className="icon">
              <FontAwesomeIcon icon="eye" onClick={onClickPassword} />
            </div>
          ) : (
            <div className="icon">
              <FontAwesomeIcon icon="eye-slash" onClick={onClickPassword} />
            </div>
          ))}
      </div>
    </FormControl>
  );
}

InputForm.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onClickPassword: PropTypes.func,
  showPassword: PropTypes.bool,
  typeInput: PropTypes.string,
  classNameInput: PropTypes.string,
  placeholder: PropTypes.string,
  color: PropTypes.oneOf(["predetermined", "primary"]),
  multiline: PropTypes.bool,
  maxRows: PropTypes.number,
  rows: PropTypes.number,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.any,
  variant: PropTypes.string,
  isError: PropTypes.bool,
};

InputForm.defaultProps = {
  onClickPassword: () => {},
  showPassword: false,
  typeInput: "",
  classNameInput: "",
  placeholder: "",
  color: "predetermined",
  multiline: false,
  maxRows: 0,
  rows: 0,
  defaultValue: "",
  value: "",
  type: "",
  onClick: undefined,
  autoComplete: "",
  required: false,
  disabled: false,
  sx: undefined,
  variant: "filled",
  isError: false,
};
export default InputForm;
