/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_USER_DOCUMENT_ID = gql`
  query getProfile($DocumentId_Person: String) {
    userByDocumentId_Person: billingProfileByDocumentId_Person(
      DocumentId_Person: $DocumentId_Person
    ) {
      id
      MH_PersonType {
        id
      }
      ID_Number
      Name
      Email
      PhoneCodCountry
      PhoneNumber
      MH_Neighborhood {
        id
        Name
        District {
          id
          Name
          Canton {
            id
            Name
            Province {
              id
              Name
            }
          }
        }
      }
      OtherSigns
    }
  }
`;

export const ME_QUERY = gql`
  query ME_QUERY {
    me {
      id
      NickName_User
      Person {
        id
        Name_Person
        Lastname_Person
        BirthDate_Person
        Email
        Phone
        Country {
          id
          Name_Country
          PhoneCode_Country
        }
        Contacts {
          id
          Data_Contact
          ContactType {
            id
          }
        }
      }
      FirebaseUser {
        id
        Picture
        Email
        Phone_Number
      }
      DefaultCoin {
        id
        Name_Coin
        Symbol_Coin
        Sell_Coin
        Buy_Coin
        Code_Coin
      }
    }
  }
`;

export const GET_USER_BY_EMAIL = gql`
  query getUserByEmail($criteria: String) {
    user: getUserByEmail(criteria: $criteria) {
      id
      Email
      Name
      Picture
      User {
        id
      }
    }
  }
`;

export const GET_USERS_BY_PLACE = gql`
  query getUsersByPlace($FK_Place: ID!) {
    user: getUsersByPlace(FK_Place: $FK_Place) {
      id
      FirebaseUser {
        id
        Email
        Name
        Picture
      }
      Roles {
        id
        Name_Role
        Place {
          id
        }
      }
      UserRoles {
        id
        FK_Role
      }
    }
  }
`;
