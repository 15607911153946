import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// External components
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import { Box, Typography } from "@mui/material";
import { Button } from "rbx";
// Components
import BooleanInput from "../BooleanInput";
import InputForm from "../InputForm/InputForm";
import { customToast as toast } from "../../utils";
// scss
import "./Register.scss";
import { getFormRegister, validator } from "../../utils/helpers";

function Register({
  setLoginSection,
  handleRegisterUser,
  loginSection,
  getCountryData,
  setIsLoadingRegister,
}) {
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [formDisabled, setFormDisabled] = useState(true);
  const [form, setForm] = useState(getFormRegister);
  const onChangeInput = e => {
    const { name, value } = e.target;
    const errors = validator(
      name,
      value,
      name === "PasswordConfirm" ? form.Password.value : "",
    );
    form[name].value = e.target.value;
    form[name].isTouch = true;
    form[name].onError = errors.length > 0;
    form[name].errors = errors;
    setForm({ ...form });
  };
  const save = () => {
    setIsLoadingRegister(true);
    try {
      const data = {
        Given_Name: form.Given_Name.value,
        Email: form.Email.value,
        Password: form.Password.value,
        Family_Name: form.Family_Name.value,
        BirthDate_Person: form.BirthDate_Person.value,
        Phone_Number: form.Phone_Number.value,
        FK_Coin: form.FK_Coin.value,
        FK_Country: form.FK_Country.value,
        AcceptTerms: form.AcceptTerms.value,
      };
      handleRegisterUser(data);
      setIsLoadingRegister(false);
    } catch {
      setIsLoadingRegister(false);
      toast.error("Error inesperado al registrar usuario");
    }
  };
  const handleChangePhoneNumber = (value, info) => {
    try {
      const isValid = matchIsValidTel(value, {
        onlyCountryies: [],
        excludedCountryies: [],
        continents: [],
      });
      form.Phone_Number.value = value;
      form.Phone_Number.isTouch = true;
      form.Phone_Number.onError = !isValid;
      form.Phone_Number.errors = [];

      const countryMatch = getCountryData?.countries.find(
        country => country.Iso_Country === info.countryCode,
      );

      if (info.nationalNumber.length <= 8) {
        form.Phone_Number.value = value;
        form.FK_Country.value = countryMatch?.id;
      }
      setForm({ ...form });
    } catch {
      toast.error("Error inesperado al registrar usuario");
    }
  };
  useEffect(() => {
    const isInvalid = Object.values(form).some(field => field.onError);
    setFormDisabled(isInvalid);
  }, [form]);
  return (
    <div className="main-register">
      <div className="landing-page-inputs">
        <div className="button__startAuth">
          <Button
            className="landing-page-button-light  button__width__Full"
            type="button"
          >
            <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
              Regístrate
            </Typography>
          </Button>
          <Button
            className="landing-page-button-dark button__width__Full"
            type="button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setLoginSection(!loginSection);
            }}
          >
            <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
              Iniciar sesión
            </Typography>
          </Button>
        </div>
        <InputForm
          key="EmailRegister"
          required
          autoComplete="off"
          id="email"
          isError={form.Email.onError}
          label="Correo Electrónico"
          name="Email"
          size="small"
          type="test"
          value={form.Email.value}
          onChange={e => onChangeInput(e)}
        />
        <InputForm
          key="Given_NameRegister"
          autoComplete="off"
          isError={form.Given_Name.onError}
          label="Nombre del encargado(a)"
          name="Given_Name"
          size="small"
          type="text"
          value={form.Given_Name.value}
          onChange={e => onChangeInput(e)}
        />
        <InputForm
          key="Family_NameRegister"
          autoComplete="off"
          label="Apellidos del encargado(a)"
          name="Family_Name"
          size="small"
          type="text"
          value={form.Family_Name.value}
          onChange={e => onChangeInput(e)}
        />
        <MuiTelInput
          key="Phone_Number"
          MenuProps={{
            sx: {
              "& .MuiTelInput-Flag": {
                width: "24px",
                height: "16px",
              },
            },
          }}
          color="predetermined"
          inputProps={{ autoComplete: "new-password" }}
          name="Phone_Number"
          size="small"
          sx={{
            "& .MuiTelInput-Flag": {
              width: "24px",
              height: "16px",
            },
          }}
          value={form.Phone_Number.value}
          variant="filled"
          onChange={handleChangePhoneNumber}
        />
        <InputForm
          key="BirthDate_PersonRegister"
          label="Fecha de nacimiento"
          name="BirthDate_Person"
          size="small"
          type="date"
          value={form.BirthDate_Person.value}
          onChange={e => onChangeInput(e)}
        />
        <InputForm
          key="PasswordRegister"
          autoComplete="new-password"
          isError={form.Password.onError}
          label="Contraseña (Mínimo 8 caracteres)"
          name="Password"
          showPassword={showPassword.password.value}
          size="small"
          type={showPassword.password ? "text" : "password"}
          typeInput="password"
          value={form.Password.value}
          onChange={e => onChangeInput(e)}
          onClickPassword={() =>
            setShowPassword(prev => ({
              ...prev,
              password: !showPassword.password,
            }))
          }
        />
        <InputForm
          autoComplete="off"
          isError={form.PasswordConfirm.onError}
          label="Confirme la contraseña"
          name="PasswordConfirm"
          showPassword={showPassword.confirmPassword}
          size="small"
          type={showPassword.confirmPassword ? "text" : "password"}
          typeInput="password"
          value={form.PasswordConfirm.value}
          onChange={e => onChangeInput(e)}
          onClickPassword={() =>
            setShowPassword(prev => ({ ...prev, confirmPassword: true }))
          }
        />
        <Box
          sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}
        >
          <BooleanInput
            className="ckbox"
            name="AcceptTerms"
            value={form.AcceptTerms.value}
            onChange={(name, value) => {
              form.AcceptTerms.value = value;
              form.AcceptTerms.isTouch = true;
              form.AcceptTerms.onError = !value;
              setForm({ ...form });
            }}
          />
          <Typography typography="defaultLight" variant="fixedSizeSubtitle3">
            Aceptar Términos
          </Typography>
        </Box>
      </div>
      <div className="landing-page-buttons">
        <Button
          className="landing-page-button-light button__width__Full"
          disabled={formDisabled}
          type="button"
          onClick={save}
        >
          <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
            Continuar
          </Typography>
        </Button>
      </div>
    </div>
  );
}

Register.propTypes = {
  setLoginSection: PropTypes.func.isRequired,
  loginSection: PropTypes.bool.isRequired,
  handleRegisterUser: PropTypes.func.isRequired,
  getCountryData: PropTypes.array.isRequired,
  setIsLoadingRegister: PropTypes.func.isRequired,
};

export default Register;
