import React, { useState, useCallback, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { Button } from "rbx";
import { v4 as uuid } from "uuid";
// Components
import Loading from "../Loading";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "react-image-crop/dist/ReactCrop.css";
import "./Cropper.scss";
import CustomCropper from "../CustomCropper/CustomCropper";

function Cropper({ onClose, setUpImg, upImg, onSaveImage }) {
  const [saved, setSaved] = useState(false);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 100,
    height: 100,
    aspect: 1,
  });
  const [completedCrop, setCompletedCrop] = useState(null);

  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);

  const handleSave = () => {
    setSaved(true);
    saveBlob(previewCanvasRef.current, completedCrop);
  };
  const saveBlob = (canvas, cropped) => {
    const storage = getStorage();
    // extrae el blob del canvas
    if (!cropped || !canvas) {
      return;
    }

    canvas.toBlob(async blob => {
      const firebaseId = uuid();
      let storageRef;
      let finalFireBaseId;
      if (process.env.NODE_ENV === "production") {
        finalFireBaseId = `people/dev/${firebaseId}`;
        storageRef = ref(storage, finalFireBaseId);
      } else {
        finalFireBaseId = `people/${firebaseId}`;
        storageRef = ref(storage, finalFireBaseId);
      }

      await uploadBytes(storageRef, blob).then(
        snapshot => {
          toast.success("¡Imagen guardada con éxito!");
        },
        error => {
          toast.error(error.message);
        },
      );
      const url = await getDownloadURL(storageRef);
      if (!url) {
        onClose();
        return toast.error("Link de imagen no válido");
      }
      onSaveImage(url, firebaseId);
      return onClose();
    });
  };

  const onLoad = useCallback(img => {
    imgRef.current = img;
  }, []);

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return;
    }
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop1 = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop1.width * pixelRatio * scaleX;
    canvas.height = crop1.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop1.x * scaleX,
      crop1.y * scaleY,
      crop1.width * scaleX,
      crop1.height * scaleY,
      0,
      0,
      crop1.width * scaleX,
      crop1.height * scaleY,
    );
  }, [completedCrop]);

  return (
    <div
      style={{
        width: "100%",
        height: "500px",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        gap: "2rem",
      }}
    >
      <CustomCropper
        crop={crop}
        imgRef={imgRef}
        previewCanvasRef={previewCanvasRef}
        style={{
          width: Math.round(completedCrop?.width ?? 0),
          height: Math.round(completedCrop?.height ?? 0),
        }}
        upImg={upImg}
        onChange={c => setCrop(c)}
        onComplete={c => setCompletedCrop(c)}
        onLoad={onLoad}
      />
      {saved && <Loading size="small" />}
      {!saved && (
        <div className="content-flex-row-between">
          <Button
            color="primary"
            disabled={!completedCrop?.width || !completedCrop?.height}
            type="button"
            variant="contained"
            onClick={handleSave}
          >
            Recortar y Guardar
          </Button>
          <Button
            className="CropperPhoto-button"
            color="primary"
            variant="outlined"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </div>
      )}
    </div>
  );
}
Cropper.propTypes = {
  onClose: PropTypes.func,
  setUpImg: PropTypes.func.isRequired,
  upImg: PropTypes.string.isRequired,
  onSaveImage: PropTypes.func.isRequired,
};
Cropper.defaultProps = {
  onClose: () => null,
};
export default Cropper;
