import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
// External components
import { Typography } from "@mui/material";
// Components
import Inventories from "../Inventories";
// Context
import { useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Utils
import { validateRoute } from "../../utils/helpers";
// SCSS
import "./PlaceSelector.scss";

function PlaceSelector({ style }) {
  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  const messageModal = () => {
    setModalOpen(
      true,
      <div className="container-modal place-selector__modal-container">
        <Inventories isModalMode setModalClose={setModalOpen} />
      </div>,
    );
  };

  return (
    <Button
      color="primary"
      sx={style}
      tabIndex="-1"
      type="button"
      variant="contained"
      onClick={() => messageModal()}
      onKeyDown={() => messageModal()}
    >
      {LineDetailPlace.id !== 0 && (
        <img
          alt="icono logo"
          className="place-selector__image"
          src={validateRoute(LineDetailPlace)}
          style={{
            borderRadius: "100px",
            width: "28px",
            height: "28px",
          }}
        />
      )}
      <Typography typography="defaultLight" variant="subtitle1" width="100%">
        {LineDetailPlace?.Name_Place || "Sin Seleccionar"}
      </Typography>
    </Button>
  );
}

PlaceSelector.propTypes = {
  style: PropTypes.object,
};
PlaceSelector.defaultProps = {
  style: {},
};
export default PlaceSelector;
