import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Title, Table, Button, Field, Control } from "rbx";
// Component
import SelectClientGroup from "../../../ClientGroupSelect/ClientGroupSelect";

function AddClientsToGroup({
  selectedList,
  setModalOpen,
  addClientsToGroup,
  placeName,
  FK_Place,
}) {
  const [seletedGroup, setSelectedGroup] = useState({ group: 0 });
  const handleAddClientsToGroup = () => {
    addClientsToGroup(seletedGroup?.group);
    setModalOpen(false);
  };

  const handleChange = (name, value) => {
    setSelectedGroup(prev => ({ ...prev, [name]: value }));
  };

  const validateSelected = () => {
    if (selectedList?.length > 0) {
      return false;
    }
    return true;
  };

  return (
    <div>
      <Title className="title-center">Grupos de {placeName}</Title>
      <Field>
        <Control>
          <SelectClientGroup
            FK_Place={FK_Place}
            label="Grupos: "
            name="group"
            value={seletedGroup?.group}
            onChange={handleChange}
          />
        </Control>
      </Field>
      {selectedList?.length > 0 && (
        <div>
          <Table bordered fullwidth hoverable narrow striped>
            <Table.Head>
              <Table.Row>
                <Table.Heading>
                  <abbr>Número de identificación</abbr>
                </Table.Heading>
                <Table.Heading>
                  <abbr>Nombre</abbr>
                </Table.Heading>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {selectedList?.map(line => (
                <Table.Row key={line?.id}>
                  <Table.Heading>{line?.ID_Number} </Table.Heading>
                  <Table.Cell>{line?.Name}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      )}

      <div className="container-header">
        <Button
          color="secondary"
          type="button"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          disabled={validateSelected()}
          type="button"
          onClick={() => handleAddClientsToGroup()}
        >
          Agregar clientes
        </Button>
      </div>
    </div>
  );
}

AddClientsToGroup.propTypes = {
  selectedList: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  addClientsToGroup: PropTypes.func.isRequired,
  placeName: PropTypes.string.isRequired,
  FK_Place: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
};

export default AddClientsToGroup;
