import React from "react";
import { Box } from "@mui/material";
import MenuExpenses from "../../components/MenuExpenses";
// Components

function ReportsPage() {
  return (
    <Box sx={{ display: "flex", gap: "1rem", flexDirection: "column" }}>
      <MenuExpenses />
    </Box>
  );
}
export default ReportsPage;
