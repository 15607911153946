import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useElectronic } from "../../context/ElectronicContext";
import SelectInput from "../SelectInput/SelectInput";

function TaxSelect({ disabled, value, name, onChange }) {
  const { taxesData } = useElectronic();

  useEffect(() => {
    if (value !== undefined || !taxesData?.taxes) return;
    onChange(name, taxesData.taxes[0].id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxesData, value]);
  return (
    <SelectInput
      data={taxesData?.taxes}
      disabled={disabled}
      name={name}
      value={value}
      onChange={e => onChange(e.target.name, e.target.value)}
    />
  );
}
TaxSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

TaxSelect.defaultProps = {
  disabled: false,
};

export default TaxSelect;
