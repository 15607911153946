import PropTypes from "prop-types";
import { Typography, Box } from "@mui/material";
import { MHUser, P12 as P12Upload } from "./components";

function MHData({ setModalOpen, billingProfile, setFileEB, setMHUser }) {
  return (
    billingProfile && (
      <Box sx={{ marginX: { xs: "1rem", ms: "20%", md: "2rem", xl: "10rem" } }}>
        <Typography
          fontWeight="bold"
          sx={{ marginY: "1rem" }}
          textAlign="center"
          variant="h6"
        >
          P12 y Credenciales Ministerio Hacienda
        </Typography>
        <MHUser billingProfile={billingProfile} onChange={setMHUser} />
        <P12Upload
          MH_User={billingProfile?.MH_User}
          setModalOpen={setModalOpen}
          onChange={setFileEB}
        />
      </Box>
    )
  );
}

MHData.propTypes = {
  setModalOpen: PropTypes.func,
  setFileEB: PropTypes.func,
  setMHUser: PropTypes.func,
  billingProfile: PropTypes.any,
};

MHData.defaultProps = {
  setModalOpen: state => null,
  setFileEB: state => null,
  setMHUser: state => null,
  billingProfile: null,
};

export default MHData;
