import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// External components
import { Box, Button, Typography } from "@mui/material";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_CREDIT_NOTES } from "../../graphql";
// Components
import { CreditDebitCard } from "../../components";
import LandingPage from "../LandingPage";
// Helpers
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";

function CreditNotes() {
  const navigate = useNavigate();
  const [activeNote, setActiveNote] = useState(false);
  const { id } = useParams();

  const { data: creditNotesData, loading: isLoading } = useQuery(
    GET_CREDIT_NOTES,
    {
      variables: {
        whereParams: {
          comparisons: [["FK_ReferencedElectronicBill", "=", id]],
        },
      },
      fetchPolicy: "no-cache",
      context: {
        clientName: "apiEB",
      },
    },
  );

  const creditNotesSortedAscendant = sortArrayItemsByDate(
    creditNotesData?.getCreditNotesNew,
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "EmitedDay",
    },
  );
  useEffect(() => {
    const active = creditNotesData?.getCreditNotesNew?.find(
      item => item?.DebitNote === null,
    );
    setActiveNote(active);
  }, [creditNotesData]);

  return (
    <Box className="content-flex-column">
      <Typography
        align="center"
        fontWeight={600}
        typography="modeColor"
        variant="h6"
        width="100%"
      >
        Notas de crédito
      </Typography>
      {isLoading && <LandingPage />}
      <div className="creditNote-page__row">
        {!activeNote && !isLoading && (
          <Button
            className="btn-animate"
            color="secondary"
            size="large"
            variant="contained"
            onClick={() => navigate(`/electronic-bills/${id}/CreditNote`)}
          >
            Crear una nueva
          </Button>
        )}
      </div>
      {creditNotesData?.getCreditNotesNew?.length > 0 ? (
        <div className="content-flex-cards">
          {creditNotesSortedAscendant.map(item => (
            <CreditDebitCard
              key={item.id}
              actions={{
                text: "Crear nota de débito",
                onClick: () =>
                  navigate(
                    `/electronic-bills/${item.id}/DebitNote`, // debit
                  ),
              }}
              item={item}
            />
          ))}
        </div>
      ) : (
        !isLoading && (
          <div className="creditNote-page__message-no-results">
            <Typography typography="modeColor" variant="h6">
              ¡No se han encontrado notas de crédito!
            </Typography>
          </div>
        )
      )}
    </Box>
  );
}
CreditNotes.propTypes = {};

export default CreditNotes;
