/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const CREATE_TIV = gql`
  mutation createTIV($tivInput: TIVInput) {
    createTIV(tivInput: $tivInput) {
      id
      FK_User
      A
      B
      C
      D
      E
      F
      G
      H
      I
      J
      K
      L
      M
      N
      O
      Created_At
      Updated_At
    }
  }
`;
