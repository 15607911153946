import React from "react";
// Components
import { PaymentComponent } from "../../components";
// SCSS
import "./ElectronicBillsPage.scss";

function ElectronicBillsPage() {
  return <PaymentComponent />;
}

export default ElectronicBillsPage;
