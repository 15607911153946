import React, { useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import PropTypes from "prop-types";
import "./ThemeProvider.scss";
import { darkTheme, lightTheme } from "./themeColor";

function ThemeCustom({ children, theme, setTheme }) {
  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <CssBaseline />
      <div
        className={`app-container theme ${theme === "dark" ? "theme-dark" : ""}`}
      >
        {React.Children.map(children, child => React.cloneElement(child))}
      </div>
    </ThemeProvider>
  );
}
ThemeCustom.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
};

export default ThemeCustom;
