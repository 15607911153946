/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_DISTRICTS = gql`
  query getAllDistricts($FK_Canton: ID!) {
    districts: getAllDistricts(FK_Canton: $FK_Canton) {
      id
      Name
      id_MH
    }
  }
`;
