import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
// Components
import { customToast } from "../../utils";
import CustomLoading from "../CustomLoading";
// Assets
import backArrow from "../../assets/icons/left_arrow.svg";
// Styles
import "./ForgotPassword.scss";
import { useAuth } from "../../context";
import InputForm from "../InputForm/InputForm";

function ForgotPassword({ setModalOpen }) {
  const [email, setEmail] = useState("");
  const { handleSendEmailReset, handleCheckEmailAuth } = useAuth();
  const [isloading, setIsloading] = useState(false);

  const handleClick = async () => {
    if (!email) {
      return customToast.error(
        "Por favor, ingrese un correo electrónico válido.",
      );
    }
    const user = await handleCheckEmailAuth(email);
    // Provider_Id;

    if (!user) {
      // if not found a user
      return customToast.error(
        "No se encontró un usuario asociado al correo electrónico suministrado.",
      );
    }
    if (user.Provider_Id !== "password") {
      // if not found a user
      return customToast.error("El usuario está registrado con Google");
    }

    // send email
    setIsloading(true);
    const isSent = await handleSendEmailReset(email, user?.UID);
    setIsloading(isSent);
    if (isSent) {
      customToast.success(
        "Se ha enviando un correo electrónico a la dirección suministrada.",
      );
      return setModalOpen(false);
    }
    return true;
  };

  return !isloading ? (
    <Box className="password-forgot">
      <div
        className="content-return"
        role="button"
        tabIndex={0}
        type="button"
        onClick={() => setModalOpen(false)}
        onKeyDown={() => {}}
      >
        <img
          alt="Regresar"
          className="content-return-img"
          sizes="1"
          src={backArrow}
        />
      </div>
      <div className="password-forgot-input">
        <div className="password-forgot-content-title" type="button">
          <p className="title-password">Restablecer contraseña</p>
        </div>
      </div>
      <InputForm
        id="email"
        label="Correo Electrónico"
        name="email"
        size="small"
        type="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button
        color="primary"
        size="large"
        variant="contained"
        onClick={handleClick}
      >
        Restablecer
      </Button>
    </Box>
  ) : (
    <CustomLoading
      centered
      vCentered
      errorTexts={["Lo sentimos", "Ha ocurrido un error validando los datos"]}
      loadingState={isloading ? "loading" : "warning"}
      loadingTexts={["Enviando email", "Por favor, espera..."]}
      warningTexts={["Algo inesperado ha ocurrido"]}
    />
  );
}

ForgotPassword.propTypes = {
  setModalOpen: PropTypes.func.isRequired,
};

export default ForgotPassword;
