/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// External component
import {
  Autocomplete,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { GET_ACTIVITIES_BY_CRITERIA } from "../../graphql";
// SCSS
import "./ActivityCodeSelect.scss";

function CustomOption(props, option) {
  return (
    <Stack {...props}>
      <Typography typography="modeColor" variant="body1" width="100%">
        <b>{option?.value}</b>
      </Typography>
      <Typography typography="modeColor" variant="body2" width="100%">
        {option.label}
      </Typography>
    </Stack>
  );
}

function ActivityCodeSelect({ handleChange, defaultValue }) {
  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);

  const [getActivities, { data, loading: queryLoading }] = useLazyQuery(
    GET_ACTIVITIES_BY_CRITERIA,
  );

  useEffect(() => {
    if (!inputValue) return;

    getActivities({ variables: { criteria: inputValue } });
  }, [inputValue, getActivities]);

  useEffect(() => {
    if (!data?.activities) return;

    const result = data.activities.map(item => ({
      value: item?.id_MH,
      label: item?.Name,
    }));
    setOptions(result);
  }, [data]);

  return (
    <Autocomplete
      filterOptions={x => x}
      options={options}
      renderInput={params => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: queryLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : null,
          }}
          label="Actividad"
          sx={{
            "borderRadius": 1,
            "& .MuiInputBase-root": {
              height: "48px",
            },
            "& .MuiFilledInput-root": {
              paddingTop: 1.8,
              paddingBottom: 0,
            },
            "& .MuiInputBase-input": {
              padding: "10px 12px",
            },
          }}
          variant="filled"
        />
      )}
      renderOption={(props, option) => CustomOption(props, option)}
      value={defaultValue}
      onChange={handleChange}
      onInputChange={(event, value) => setInputValue(value)}
    />
  );
}

ActivityCodeSelect.propTypes = {
  handleChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.object.isRequired,
};

export default ActivityCodeSelect;
