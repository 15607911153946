import React, { useState } from "react";
// External components
import { Typography } from "@mui/material";
// Context
import { useAuth, useModal } from "../../context";
// Utils
import { customToast as toast } from "../../utils";
// SCSS
import "./changePassword.scss";
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";

function ChangePassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [confirmNewPassword, setconfirmNewPassword] = useState(false);
  const [state, setstate] = useState({
    Password: "",
    NewPassword: "",
    NewPasswordConfirm: "",
  });

  const { handleVerifyPassword, state: authState, changePassword } = useAuth();
  const { setModalOpen } = useModal();

  const handleChange = (name, value) => {
    setstate(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async e => {
    e.preventDefault();

    if (state.NewPassword.length < 8)
      return toast.error(
        "El tamaño de la contraseña debe ser de mínimo 8 dígitos.",
      );
    if (state.NewPassword !== state.NewPasswordConfirm)
      return toast.error("La contraseña y su confirmación no coinciden");
    if (!(await handleVerifyPassword(authState.user.email, state.Password)))
      return toast.error("Contraseña incorrecta");
    await changePassword(state.NewPassword);
    return undefined;
  };

  return (
    <form className="container-modal modal-content-size" onSubmit={handleSave}>
      <Typography
        fontWeight="bold"
        sx={{ marginY: "1rem" }}
        textAlign="center"
        variant="h5"
      >
        Cambio de contraseña
      </Typography>

      <InputForm
        color="primary"
        id="Password"
        label="Contraseña actual"
        name="Password"
        showPassword={showPassword}
        size="small"
        type={showPassword ? "text" : "password"}
        typeInput="password"
        value={state.Password}
        onChange={e => handleChange(e.target.name, e.target.value)}
        onClickPassword={() => setShowPassword(!showPassword)}
      />
      <Typography fontWeight="bold" textAlign="start" variant="subtitle1">
        Digite la nueva contraseña
      </Typography>
      <InputForm
        color="primary"
        id="NewPassword"
        label="Nueva contraseña"
        name="NewPassword"
        showPassword={showNewPassword}
        size="small"
        type={showNewPassword ? "text" : "password"}
        typeInput="password"
        value={state.NewPassword}
        onChange={e => handleChange(e.target.name, e.target.value)}
        onClickPassword={() => setNewShowPassword(!showNewPassword)}
      />
      <InputForm
        color="primary"
        id="NewPasswordConfirm"
        label="Confirme la contraseña"
        name="NewPasswordConfirm"
        showPassword={confirmNewPassword}
        size="small"
        type={confirmNewPassword ? "text" : "password"}
        typeInput="password"
        value={state.NewPasswordConfirm}
        onChange={e => handleChange(e.target.name, e.target.value)}
        onClickPassword={() => setconfirmNewPassword(!confirmNewPassword)}
      />
      <FormWithButtons
        buttonsAlignment="center"
        cancelText="Cancelar"
        submitText="Guardar"
        onCancel={() => setModalOpen(false)}
        onSubmit={handleSave}
      />
    </form>
  );
}

export default ChangePassword;
