import React, { useState } from "react";
import { format } from "date-fns";
// External components
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Box, Typography, Card, Button } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Graphql
import { useLazyQuery } from "@apollo/client";
import { client_EB_api } from "../../graphql/client";
import { GET_ELECTRONICS_BILLS_NEW, GET_MANY_PDFS } from "../../graphql";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Components
import Loading from "../Loading";
import Paginated from "../Paginated";

// Helpers
import { downloadBase64File, validateRoute } from "../../utils/helpers";
import { ElectronicBillErrors } from "../../Enums/Errors";
import { customToast } from "../../utils";
// SCSS
import "./ElectronicBillReport.scss";
import useElectronicBill from "../../hooks/useElectronicBill";
// hooks
import UseCreateReport from "../../hooks/UseCreateReport";

function ElectronicBillReport() {
  const { handleGetElectronicBills } = UseCreateReport();
  const [selectedMonth, setSelectedMonth] = useState(dayjs());
  const { LineDetailPlace, setLineDetailPlace } = useElectronic();
  const { getBillByElectronicBill } = useElectronicBill();

  const [getElectronicBillsNew, { data: electronicBillsData, loading }] =
    useLazyQuery(GET_ELECTRONICS_BILLS_NEW);

  const castElectronicBillsForTable = electronicBills =>
    electronicBills.map(electronicBill => ({
      ...electronicBill,
      Bill: getBillByElectronicBill(electronicBill),
    }));

  const getDates = async () => {
    getElectronicBillsNew({
      variables: {
        getElectronicBillsInput: {
          where: {
            equals: {
              FK_OwnerPlace: LineDetailPlace.id,
              FK_Place: LineDetailPlace.id,
            },
            inMonth: selectedMonth,
          },
          orderBy: [
            {
              columnName: "EmitedDay",
              order: "DESC",
            },
          ],
        },
      },
    });
  };

  const handleSelectStore = () => {
    setLineDetailPlace({ id: 0 });
  };

  const handleSubmit = () => {
    getDates();
    handleGetElectronicBills(selectedMonth, 2);
  };

  const columns = [
    {
      id: "Created_At",

      Header: "Fecha",
      accessor: "Created_At",
      Cell: ({ cell }) =>
        cell?.value && cell?.value !== "-1"
          ? format(new Date(cell.value), "MM/dd/yyyy")
          : "--",
      width: "150px",
    },
    {
      id: "Name_Person",
      Header: "Cliente",
      width: "300px",
      accessor: "Bill.User.Person.Name_Person",
    },

    {
      id: "TotalBill",
      Header: "Total",
      accessor: "Bill.Total_Bill",
      width: "auto",
    },
  ];

  const handleDownloadPDF = async billItem => {
    const { data } = await client_EB_api.query({
      query: GET_MANY_PDFS,
      variables: {
        ids: billItem?.id,
        place_id: LineDetailPlace.id,
      },
    });
    if (data.downloadManyPDF[0].payload) {
      const { payload, params, type } = data.downloadManyPDF[0];
      downloadBase64File(payload, `${params[0]}.${type}`);
      return;
    }
    if (!data.downloadManyPDF[0].error) {
      return;
    }
    const { error } = data.downloadManyPDF[0];
    if (error === ElectronicBillErrors.NOT_FOUND) {
      customToast.error("El archivo no se encontró");
      return;
    }
    customToast.error("Error desconocido");
  };
  const getExtraComponent = row => (
    <Button onClick={() => handleDownloadPDF(row.original)}>
      <FontAwesomeIcon className="awesomeIcon-color" icon="download" />
    </Button>
  );

  return (
    <Box className="content-flex-column">
      {/* FILTER */}
      <Card
        color="tertiary"
        sx={{
          display: "grid",
          gridTemplateColumns: { sm: "3fr 1fr 1fr" },
          flexDirection: { xs: "column", md: "row" },
          gap: { xs: "1rem", md: "5rem" },
          alignItems: "center",
          paddingX: "2rem",
          paddingY: "1rem",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.4rem",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Typography typography="modeColor" variant="subtitle1">
            Buscar facturas electrónicas por su fecha
          </Typography>
          <Typography typography="modeColor" variant="subtitle2">
            Mes y año de emisión
          </Typography>
          <DatePicker
            color="primary"
            label="Mes y año de emisión"
            selectedDate={selectedMonth}
            size="small"
            sx={{
              "& .MuiFormLabel-root": {
                transform: " translate(14px, 5px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                padding: "24px 14px 10px 14px ",
              },
              "maxHeight": "48px",
            }}
            variant="filled"
            views={["month", "year"]}
            onChange={date => setSelectedMonth(date)}
          />
        </Box>
        <Card
          color="primary"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            padding: "1rem",
            width: { xs: "100%", sm: "160px", md: "333px" },
            minHeight: "135px",
            gap: "1rem",
          }}
          onClick={handleSelectStore}
        >
          <Typography
            fontWeight={600}
            typography="modeColor"
            variant="subtitle1"
          >
            Tienda
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "0.4rem",
            }}
          >
            <img
              alt="Logo empresa"
              src={validateRoute(LineDetailPlace)}
              style={{ height: "2.563rem", width: "2.563rem" }}
            />
            <Typography
              fontWeight={500}
              typography="modeColor"
              variant="subtitle1"
            >
              {LineDetailPlace.Name_Place}
            </Typography>
          </Box>
        </Card>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Button
            color="secondary"
            sx={{ width: "6rem" }}
            variant="contained"
            onClick={handleSubmit}
          >
            Buscar
          </Button>
        </Box>
      </Card>
      {loading && <Loading />}
      {electronicBillsData?.getElectronicBillsNew?.ElectronicBills.length >
      0 ? (
        <Paginated
          columns={columns}
          data={castElectronicBillsForTable(
            electronicBillsData.getElectronicBillsNew?.ElectronicBills,
          )}
          getExtraComponent={getExtraComponent}
        />
      ) : (
        <Typography align="left" typography="modeColor" variant="subtitle1">
          Sin facturas que mostrar
        </Typography>
      )}
    </Box>
  );
}

export default ElectronicBillReport;
