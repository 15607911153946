import React from "react";
import PropTypes from "prop-types";
// Components
import { Card, Stack, Typography } from "@mui/material";
// SCSS
import "./InventoryModalCard.scss";

export default function InventoryModalCard({ placeName, placeImage, color }) {
  return (
    <Card
      color={color}
      sx={{
        padding: "20px 24px",
        borderRadius: 2,
        width: "100%",
        justifyContent: "start",
      }}
    >
      <Stack direction="row" spacing={4}>
        <img alt="Logo" className="modal-mode-image" src={placeImage} />
        <Typography
          noWrap
          alignContent="center"
          className="white-text"
          fontWeight={500}
          sx={{ fontSize: 18, maxWidth: "100%" }}
          typography="modeColor"
        >
          {placeName}
        </Typography>
      </Stack>
    </Card>
  );
}

InventoryModalCard.propTypes = {
  placeName: PropTypes.string,
  placeImage: PropTypes.any,
  color: PropTypes.string,
};

InventoryModalCard.defaultProps = {
  placeName: "",
  placeImage: undefined,
  color: "primaryInventory",
};
