/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const REGISTER_NEW_COMPANY = gql`
  mutation createCompany($company: CompanyInput) {
    company: createNewCompany(company: $company) {
      id
      Name_Company
      Description_Company
    }
  }
`;

export const UPDATE_COMPANY = gql`
  mutation updateCompany($company: CompanyInput) {
    company: updateCompanyBySuperadmin(company: $company)
  }
`;
