import React from "react";
import PropTypes from "prop-types";
// External components
import { Box, Card, Tooltip, Typography } from "@mui/material";
// Context
import { useAuth } from "../../../../context";
// Utils
import TransformImages from "../../../../utils/TransformImages";

function ReportProductCard({ productList }) {
  const { formatAmount } = useAuth();

  const getUrl = product => {
    const route = product.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateRoute = product => {
    let route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2Fthumbs%2FNo%20Thumbnail%20Image-min_200x200.jpg?alt=media&token=0315aeb6-211e-4286-be23-39db27a896e7";
    }
    route = TransformImages(product, 1);
    return route;
  };

  const getDetails = product => {
    let details = null;
    product.Details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  return productList.map(product => (
    <Card
      color="primary"
      role="button"
      sx={{
        padding: "1rem",
        display: "flex",
        flexDirection: "column",
        justifyContent: "start",
        gap: "0.3rem",
        overflow: "visible",
        width: { xs: "100%", sm: "333px" },
      }}
    >
      <Box className="content-flex-row-start">
        <img
          alt="imagen del  producto"
          className="card-image"
          src={validateRoute(product?.Files)}
        />
        <Typography typography="modeColor" variant="subtitle1">
          {product?.Product?.Name_Product}
        </Typography>
      </Box>
      <Typography typography="modeColor" variant="subtitle1">
        {product?.Product?.Description_Product}
      </Typography>
      <Tooltip title={getDetails(product)}>
        <Typography
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: { xs: "200px", sm: "300px" },
            height: "100%",
          }}
          typography="modeColor"
          variant="subtitle1"
        >
          {getDetails(product)}
        </Typography>
      </Tooltip>
      <Typography typography="modeColor" variant="subtitle1">
        <b>Unidad: </b>
        {formatAmount(product?.ProductPrice?.Price_ProductPrice)}
      </Typography>
      <Typography typography="modeColor" variant="subtitle1">
        <b>Cabys: </b>
        {product?.ProductPrice?.Cabys?.Code}
      </Typography>
      <Typography typography="modeColor" variant="subtitle1">
        <b>Imp: </b>
        {product?.ProductPrice?.Cabys?.Code} <b>%</b>
      </Typography>
    </Card>
  ));
}

ReportProductCard.propTypes = {
  productList: PropTypes.array,
};
ReportProductCard.defaultProps = {
  productList: [],
};

export default ReportProductCard;
