import React from "react";
import PropTypes from "prop-types";
// External components
import { Input } from "rbx";
// Components
import SelectMonth from "../MonthSelect";
// SCSS
import "./YearMonthSelect.scss";

function YearMonthSelect({ setSelectedDate, selectedDate, className, name }) {
  const max_date = new Date();
  const handleChange = (fieldName, value) => {
    fieldName === "year"
      ? setSelectedDate(
          new Date(
            selectedDate.setFullYear(
              value < max_date.getFullYear() ? value : max_date.getFullYear(),
            ),
          ),
        )
      : setSelectedDate(new Date(selectedDate.setMonth(value - 1)));
  };

  const getValidMonths = () =>
    new Date(selectedDate).getFullYear() === max_date.getFullYear()
      ? max_date.getMonth() + 2
      : 13;

  return (
    <div className={`SelectYearMonth ${className}`}>
      <SelectMonth
        months={getValidMonths()}
        name="month"
        value={new Date(selectedDate).getMonth() + 1}
        onChange={handleChange}
      />
      <Input
        required
        className="iva-report-page-year"
        name="year"
        placeholder="Year"
        type="number"
        value={new Date(selectedDate).getFullYear()}
        onChange={e => handleChange(e.target.name, e.target.value, e.target)}
      />
    </div>
  );
}
YearMonthSelect.propTypes = {
  setSelectedDate: PropTypes.func.isRequired,
  selectedDate: PropTypes.instanceOf(Date).isRequired,
  className: PropTypes.string,
  name: PropTypes.string,
};
YearMonthSelect.defaultProps = {
  name: "",
  className: "",
};
export default YearMonthSelect;
