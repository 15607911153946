import React, { useState, useEffect, useCallback } from "react";
// External components
import { Box, Button, Typography, IconButton, Card } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Components
import {
  CountrySelect,
  ChangePassword,
  Cropper,
  MHData,
  RegisterReceiver,
  CoinSelect,
  SimpleGrid,
} from "../../components";
// Context
import { useAuth, useModal } from "../../context";
// Utils
import { combine, customToast as toast, validateEmail } from "../../utils";
import { CONTACT_TYPE } from "../../utils/contact";
// SCSS
import "./ProfilePage.scss";
import { client_EB_api } from "../../graphql/client";
import { DEFAULT_BILLING_PROFILE } from "../../graphql";
import InputForm from "../../components/InputForm/InputForm";
import FormWithButtons from "../../components/FormWithButttons/FormWithButtons";

const INITIAL_STATE = {
  user: {
    Given_Name: "",
    Family_Name: "",
    BirthDate_Person: "",
    FK_Country: "",
    Phone_Number: "",
    Email: "",
  },
  BillingProfile: null,
};

function ProfilePage() {
  const {
    state: authState,
    handleUpdateUser,
    handleUpdatePicture,
    handleUpdateCoin,
  } = useAuth();
  const [state, setState] = useState(INITIAL_STATE);
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const { setModalOpen } = useModal();
  const [stateChangePassword, setStateChangePassword] = useState(false);
  const [defaultCoin, setDefaultCoin] = useState(
    authState.user.TavuelUser?.DefaultCoin?.id || 1,
  );
  const firstFormatPhoneNumber = state?.user?.Phone_Number.replace(
    /^\+\d+\s*/,
    "",
  ).replace(/\s+/g, "");

  if (authState.user.signInProvider !== "password" && !stateChangePassword) {
    setStateChangePassword(true);
  }

  const updateCoin = useCallback(async () => {
    try {
      await handleUpdateCoin(defaultCoin);
    } catch (err) {
      toast.error("Error al actualizar la moneda");
    }
  }, [handleUpdateCoin, defaultCoin]);

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();
    if (state.user.Email === "") {
      toast.error("El Correo Electrónico es obligatorio");
      return;
    }
    if (!validateEmail(state.user.Email)) {
      toast.error("El correo electrónico no tiene la estructura correcta.");
      return;
    }
    if (state.user.Phone_Number.length < 8) {
      toast.error("El número de teléfono debe contener como mínimo 8 dígitos.");
      return;
    }
    handleUpdateUser(state.user);
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const hadleChangePassword = e => {
    e.preventDefault();
    setModalOpen(true, <ChangePassword onClose={() => setModalOpen(false)} />);
  };

  const handleChangeCoin = ({ target: { name, value } }) => {
    if (name === "FK_Coin") {
      setDefaultCoin(value);
    }
  };
  const handleSelectClick = e => {
    e.preventDefault();
    document.querySelector("#fileSelectorImg").click();
  };

  const handleUpdateImage = useCallback(
    async (url, firebaseId) => {
      try {
        setModalOpen(false);
        await handleUpdatePicture(url, firebaseId);
        toast.success("¡Imagen actualizada con éxito!");
      } catch (err) {
        toast.error("Error al registrar la imagen");
      }
    },
    [handleUpdatePicture, setModalOpen],
  );

  const handleChange = data => setState(prev => combine(prev, data));

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <Cropper
          setUpImg={setUpImg}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
          onSaveImage={handleUpdateImage}
        />,
      );
      setOpen(true);
    }
  }, [handleUpdateImage, open, setModalOpen, upImg]);
  const setBillingProfile = BillingProfile =>
    setState(prv => ({ ...prv, BillingProfile }));

  const getBilling = async () => {
    const {
      data: { BillingProfile },
    } = await client_EB_api.query({
      query: DEFAULT_BILLING_PROFILE,
    });
    setBillingProfile(BillingProfile);
  };

  useEffect(() => {
    setDefaultCoin(authState.user.TavuelUser?.DefaultCoin?.id);
  }, [authState.user.TavuelUser?.DefaultCoin?.id]);

  useEffect(() => {
    if (authState?.user?.TavuelUser?.Person) {
      const {
        Name_Person = "",
        Lastname_Person = "",
        BirthDate_Person = "",
        Country: { id: FK_Country },
        Email = "",
        Contacts,
      } = authState?.user.TavuelUser.Person || {};

      handleChange({
        user: {
          Given_Name: Name_Person,
          Family_Name: Lastname_Person,
          BirthDate_Person: new Date(BirthDate_Person || new Date())
            ?.toISOString()
            ?.substring(0, 10),
          FK_Country,
          Phone_Number:
            Contacts.find(
              ({ ContactType: { id } }) => id === CONTACT_TYPE.PHONE,
            )?.Data_Contact || "",
          Email,
        },
      });

      getBilling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState?.user?.TavuelUser?.id]); // Dependency array must be like this to avoid wrong re-rendering

  return (
    <div className="profile-page-container">
      <form className="profile-page-container" onSubmit={handleSubmit}>
        <Typography
          fontWeight="bold"
          sx={{ marginY: "1rem" }}
          textAlign="center"
          typography="modeColor"
          variant="h6"
        >
          Perfil de usuario
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          <Box
            sx={{
              "position": "relative",
              "display": "flex",
              "justifyContent": "center",
              "flexDirection": "column",
              "alignItems": "center",
              "& .edit-icon": {
                width: "45px",
                height: "45px",
                top: "-2.5rem",
                right: "0.1rem",
                backgroundColor: "white",
                zIndex: 10,
                padding: "0.8rem",
              },
            }}
          >
            <Card
              color="tertiary"
              sx={{
                "margin": "1rem",
                "position": "relative",
                "display": "inline-block",
                "& img": {
                  display: "block",

                  borderRadius: "50%",
                  objectFit: "cover",
                },
              }}
              variant="outlined"
            >
              <input
                accept="image/*"
                className="input-file"
                id="fileSelectorImg"
                type="file"
                onChange={hadleOnSelectFile}
              />
              <img
                alt="foto de perfil"
                src={authState?.user?.photoURL}
                style={{ width: "168px", height: "168px" }}
              />
            </Card>
            <IconButton
              className="button-awesomeIcon edit-icon"
              size="small"
              onClick={handleSelectClick}
            >
              <FontAwesomeIcon
                className="awesomeIcon-color"
                icon="pen"
                size="1x"
              />
            </IconButton>
          </Box>
          <Box className="content-flex-column">
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
              }}
            >
              <InputForm
                color="primary"
                id="Name"
                label="Nombre"
                name="Name"
                size="small"
                type="text"
                value={state.user.Given_Name}
                onChange={({ target: { value } }) =>
                  handleChange({ user: { Given_Name: value } })
                }
              />
              <InputForm
                color="primary"
                id="Family_Name"
                label="Apellidos"
                name="Family_Name"
                size="small"
                type="text"
                value={state.user.Family_Name}
                onChange={({ target: { value } }) =>
                  handleChange({ user: { Family_Name: value } })
                }
              />
              <InputForm
                color="primary"
                id="Email"
                label="Correo electrónico"
                name="Email"
                size="small"
                type="email"
                value={state.user.Email || ""}
                onChange={({ target: { value } }) =>
                  handleChange({ user: { Email: value } })
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
                gap: 2,
              }}
            >
              <CountrySelect
                label="País"
                textColor="grey"
                value={state?.user?.FK_Country || 52}
                onChange={(_, FK_Country) =>
                  handleChange({ user: { FK_Country } })
                }
              />
              <InputForm
                color="primary"
                id="Phone_Number"
                label="Número de teléfono"
                name="Phone_Number"
                size="small"
                type="number"
                value={Number(firstFormatPhoneNumber)}
                onChange={({ target: { value } }) =>
                  handleChange({ user: { Phone_Number: value } })
                }
              />
              <InputForm
                color="primary"
                id="BirthDate_Person"
                label="Fecha de nacimiento"
                name="BirthDate_Person"
                size="small"
                type="date"
                value={state.user.BirthDate_Person}
                onChange={({ target: { value } }) =>
                  handleChange({ user: { BirthDate_Person: value } })
                }
              />
            </Box>
          </Box>
          {authState?.user?.signInProvider === "password" && (
            <FormWithButtons
              buttonsAlignment="end"
              cancelText="Cambiar contraseña"
              submitText="Guardar"
              onCancel={hadleChangePassword}
              onSubmit={handleSubmit}
            />
          )}
          <SimpleGrid>
            <CoinSelect
              color="secondary"
              label="Moneda"
              name="FK_Coin"
              size="small"
              value={defaultCoin}
              onChange={handleChangeCoin}
            />
          </SimpleGrid>
          <div className="content-flex-end ">
            <Button
              color="secondary"
              sx={{
                height: 48,
              }}
              type="submit"
              variant="contained"
              onClick={updateCoin}
            >
              Actualizar moneda
            </Button>
          </div>
        </Box>
      </form>
      <RegisterReceiver
        isAdmin
        FK_User={authState?.user?.TavuelUser?.id}
        handleChangeAddress={() => null}
        initialReceiver={state?.BillingProfile}
        setBillingProfile={setBillingProfile}
        setModalOpen={() => null}
        title="Perfil de facturación"
      />
      <MHData
        billingProfile={state?.BillingProfile}
        setFileEB={FileEB =>
          setState({
            ...state,
            BillingProfile: {
              ...state.BillingProfile,
              MH_User: { ...state.BillingProfile.MH_User, FileEB },
            },
          })
        }
        setMHUser={MH_User =>
          setState({
            ...state,
            BillingProfile: { ...state.BillingProfile, MH_User },
          })
        }
        setModalOpen={setModalOpen}
      />
    </div>
  );
}

ProfilePage.propTypes = {};

export default ProfilePage;
