/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_PAYMENT_METHODS = gql`
  query getAllPaymentMethods {
    paymentMethod: getAllPaymentMethods {
      id
      Name_PaymentMethod
      id_MH
    }
  }
`;
