import { v4 as uuid } from "uuid";
import PropTypes from "prop-types";
// External components
import { Card, Typography, Button } from "@mui/material";
// Context
import { useAuth } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Enums
import { ElectronicBillAcceptanceStates } from "../../Enums/ElectronicBill";
// Utils
import { formatCurrency } from "../../utils";
import { formatData, formatDateString } from "../../utils/helpers";
import useCoinDetails from "../../hooks/useCoinDetails";
import SelectForm from "../SelectForm/SelectForm";

export default function ElectronicBillExpenseCard(props) {
  const {
    currentElectronicBill,
    initialElectronicBill,
    multiSelectData,
    actions,
  } = props;

  const { toLocalExchangeRate } = useCoinDetails();

  const { handleChange: handleChangeSelect, handleSaveEB } = actions;

  const { LineDetailPlace } = useElectronic();

  const { permissions, state: authState } = useAuth();
  const { haveActionPermission } = permissions;

  const canEdit = haveActionPermission("Edit", "/expenses", LineDetailPlace);

  const [emitedDayFormatedDate, emitedDayFormatedTime] = formatDateString(
    currentElectronicBill?.EmitedDay,
  );

  return (
    <Card
      key={uuid()}
      color="primary"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "1rem",
        padding: "1rem",
        width: { xs: "100%", sm: "389px" },
      }}
    >
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Emisor: </span>
        {currentElectronicBill?.Place?.Name_Place}{" "}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Receptor: </span>
        {LineDetailPlace?.Company?.Name_Company}&nbsp;
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Cédula Receptor: </span>
        {authState.user.TavuelUser.Person.DocumentId_Person}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Moneda: </span>
        {currentElectronicBill?.Coin?.Name_Coin}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Total: </span>
        {formatCurrency(
          parseFloat(
            toLocalExchangeRate(
              currentElectronicBill.TotalBill,
              currentElectronicBill?.Coin,
            ),
            10,
          ),
          currentElectronicBill?.Coin?.Code_Coin
            ? currentElectronicBill?.Coin?.Code_Coin
            : "CRC",
        )}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Fecha: </span>
        {emitedDayFormatedDate} - {emitedDayFormatedTime}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Estado de aceptación: </span>
        {initialElectronicBill.FK_AcceptanceState ===
          ElectronicBillAcceptanceStates.ACCEPTED ||
        initialElectronicBill.FK_AcceptanceState ===
          ElectronicBillAcceptanceStates.REJECTED ? (
          <span>
            {
              multiSelectData[
                initialElectronicBill.FK_AcceptanceState
                  ? initialElectronicBill.FK_AcceptanceState - 1
                  : 0
              ].Name
            }
          </span>
        ) : (
          <SelectForm
            color="secondary"
            data={formatData(
              multiSelectData &&
                multiSelectData.map(multiSelectItem => ({
                  ...multiSelectItem,
                  initialElectronicBillAcceptanceState:
                    initialElectronicBill.FK_AcceptanceState,
                })),
              "Name",
            )}
            label="Tipo de documentos"
            name="is_accepted"
            size="small"
            value={
              currentElectronicBill.FK_AcceptanceState ??
              ElectronicBillAcceptanceStates.ACCEPTED
            }
            variant="filled"
            onChange={(name, value) =>
              handleChangeSelect(name, value, currentElectronicBill.id)
            }
          />
        )}
      </Typography>

      {canEdit && (
        <div className="content-flex-end">
          <Button
            color="primary"
            sx={{
              display:
                initialElectronicBill.FK_AcceptanceState ===
                  ElectronicBillAcceptanceStates.ACCEPTED ||
                initialElectronicBill.FK_AcceptanceState ===
                  ElectronicBillAcceptanceStates.REJECTED ||
                (initialElectronicBill.FK_AcceptanceState ===
                  ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED &&
                  currentElectronicBill.FK_AcceptanceState ===
                    ElectronicBillAcceptanceStates.PARTIALLY_ACCEPTED)
                  ? "none"
                  : "block",
            }}
            variant="contained"
            onClick={() => {
              handleSaveEB(currentElectronicBill);
            }}
          >
            Guardar
          </Button>
        </div>
      )}
    </Card>
  );
}

ElectronicBillExpenseCard.propTypes = {
  currentElectronicBill: PropTypes.array.isRequired,
  initialElectronicBill: PropTypes.array.isRequired,
  multiSelectData: PropTypes.array.isRequired,
  actions: PropTypes.object,
};
ElectronicBillExpenseCard.defaultProps = {
  actions: {},
};
