import React from "react";
import PropTypes from "prop-types";
// External components
import { Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// SCSS
import "./NewAccount.scss";
import {
  EmailConnectors,
  emailLoginFunction,
} from "../../../../utils/MailConnector";

function NewAccount({ flow, openModal, CardAccount, AccountManagement }) {
  const emailType = [...EmailConnectors];

  const handleEmailLogIn = account => {
    const login = emailLoginFunction[account.type];
    window.location.href = login();
  };

  const handleIcons = () => {
    if (!flow) openModal(true, <AccountManagement />);
    if (flow) openModal(false, <NewAccount />);
  };

  return (
    <div className="container-modal">
      <div className="modal-content-size">
        <div className="content-flex-end">
          <FontAwesomeIcon
            className="awesomeIcon-color"
            icon="times"
            onClick={handleIcons}
          />
        </div>
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="h6"
          width="100%"
        >
          Seleccione el proveedor de email
        </Typography>
        {emailType.map(email => (
          <CardAccount
            key={email.email}
            account={email}
            handleChange={handleEmailLogIn}
          />
        ))}
      </div>
    </div>
  );
}

NewAccount.propTypes = {
  flow: PropTypes.bool.isRequired,
  openModal: PropTypes.func.isRequired,
  CardAccount: PropTypes.func.isRequired,
  AccountManagement: PropTypes.func.isRequired,
};

export default NewAccount;
