/* eslint-disable no-param-reassign */
import React, { useState } from "react";
// External components
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers";
import { Box, Button, Typography } from "@mui/material";
import { CSVLink } from "react-csv";
// Components
import Loading from "../Loading";
import IvaReportSubsections from "../IvaReportSubsections/IvaReportSubsections";
import CoinSelector from "../CoinSelector";
// Context
import { useElectronic } from "../../context/ElectronicContext";
// Hook
import UseCreateReport from "../../hooks/UseCreateReport";
// Utils
import { d104Headers } from "../../utils/CSVHelper";
// SCSS
import "./SellsReport.scss";

function SellsReport() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const {
    dataToCSV,
    headersToCSV,
    handleGetElectronicBills,
    dataCSV,
    isLoadingBills,
    totals,
    electronicBills,
    services,
    merch,
  } = UseCreateReport();

  const { useCoinDetail } = useElectronic();
  const { currentSymbolCoin } = useCoinDetail;

  const handleSubmit = () => {
    handleGetElectronicBills(selectedDate, 2);
  };

  return (
    <Box className="content-flex-column">
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "1rem",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.2rem",
            justifyContent: "start",
          }}
        >
          <Typography
            fontWeight={600}
            typography="modeColor"
            variant="subtitle1"
            width="100%"
          >
            Escoge una fecha para generar el reporte
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "1rem",
              justifyContent: "start",
            }}
          >
            <DatePicker
              color="primary"
              label="Mes y año de emisión"
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  transform: " translate(14px, 5px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  padding: "24px 14px 10px 14px ",
                },
                "maxHeight": "48px",
              }}
              value={selectedDate}
              variant="filled"
              views={["month", "year"]}
              onChange={newDate => {
                setSelectedDate(newDate);
              }}
            />
            <CoinSelector key="coin-selector" />
          </Box>
        </Box>
        <Button
          color="secondary"
          size="medium"
          sx={{ height: "48px", display: "flex" }}
          variant="contained"
          onClick={handleSubmit}
        >
          Generar reporte
        </Button>
      </Box>
      {isLoadingBills && <Loading />}

      {electronicBills?.length !== 0 && !isLoadingBills && (
        <IvaReportSubsections
          summarizedData={{
            services,
            merch,
          }}
          symbolCoin={currentSymbolCoin}
          totals={totals}
        />
      )}

      {totals.TotalBill !== 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "1rem",
            justifyContent: "end",
          }}
        >
          <Button sx={{ maxWidth: "10rem" }} variant="outlined">
            <CSVLink
              data={dataCSV}
              filename={`Reporte D-104 (${
                selectedDate.month() + 1
              }-${selectedDate.year()})`}
              headers={d104Headers()}
              style={{
                color: "#01234c",
                cursor: "pointer",
              }}
              target="_blank"
            >
              <Typography
                typography="modeColor"
                variant="subtitle1"
                width="100%"
              >
                Descargar CSV
              </Typography>
            </CSVLink>
          </Button>
          <Button variant="contained">
            <CSVLink
              data={dataToCSV}
              filename={`Lista de facturas (${
                selectedDate.month() + 1
              }-${selectedDate.year()})`}
              headers={headersToCSV}
              style={{
                color: "#dbdbdb",
                cursor: "pointer",
                display: "block",
              }}
              target="_blank"
            >
              <Typography variant="subtitle1" width="100%">
                Descargar Lista de Facturas Electrónicas
              </Typography>
            </CSVLink>
          </Button>
        </Box>
      ) : (
        <Typography
          align="left"
          typography="modeColor"
          variant="subtitle1"
          width="100%"
        >
          No se encontraron resultados
        </Typography>
      )}
    </Box>
  );
}
export default SellsReport;
