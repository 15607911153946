/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from "react";
import PropTypes from "prop-types";
import "./SwitchButton.scss";
import moon_light from "../../assets/moon_light.svg";
import sun_light from "../../assets/sun_light.svg";
import moon_dark from "../../assets/moon_dark.svg";
import sun_dark from "../../assets/sun_dark.svg";

function SwitchButton({ onChangeTheme, onMenu }) {
  const [isActive, setIsActive] = useState(() => {
    const savedTheme = localStorage.getItem("theme");
    return savedTheme === "dark";
  });
  const toggleSwitch = () => {
    onChangeTheme(prev => !prev);
    setIsActive(!isActive);
  };

  return (
    <div className="conteiner-switch">
      {isActive || onMenu ? (
        <img alt="Logo Google" className="img" src={sun_dark} />
      ) : (
        <img alt="Logo Google" className="img" src={sun_light} />
      )}
      <div
        className={`switch-button ${isActive ? "active" : ""}`}
        role="button"
        tabIndex={0}
        onClick={toggleSwitch}
        onKeyDown={e => {
          if (e.key === "Enter" || e.key === " ") {
            toggleSwitch();
          }
        }}
      >
        <div className="switch-handle" />
      </div>
      {isActive || onMenu ? (
        <img alt="Logo Google" className="img" src={moon_dark} />
      ) : (
        <img alt="Logo Google" className="img" src={moon_light} />
      )}
    </div>
  );
}
SwitchButton.propTypes = {
  onChangeTheme: PropTypes.func,
  onMenu: PropTypes.bool,
};

SwitchButton.defaultProps = {
  onChangeTheme: () => null,
  onMenu: false,
};

export default SwitchButton;
