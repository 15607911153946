/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { Box, Button, Card, Typography } from "@mui/material";
import { useDropzone } from "react-dropzone";
import * as converter from "xml-js";
import { format } from "date-fns";
// Components
import { Promise as bromise } from "bluebird";
import { BooleanInput, BillUploadDetail } from "../../components";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// Utils
import { formatCurrency, customToast as toast, chunk } from "../../utils";
import { arrayBill } from "../../utils/helpers";
// Enums
import { ELECTRONIC_BILL_XML_TYPES } from "../../Enums/ElectronicBill";
// SCSS
import "./IncomePage.scss";
import "../ProfilePage/ProfilePage.scss";
// icon
import fileIcon from "../../assets/icons/file_icon.svg";

function IncomePage() {
  const [invoices, setInvoices] = useState([]);
  const [allSelected, setAllSelected] = useState(true);

  const { state, permissions } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const navigate = useNavigate();
  const { LineDetailPlace } = useElectronic();
  const { handleElectronicBillsXML } = useElectronicBill();

  const { setModalOpen: setDetailModalOpen } = useModal();

  const canRead = haveActionPermission("Read", "/incomes", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Ingresos"));
    navigate(`/`);
  }

  const handleSelectAllClick = () => {
    // evento del botón "Seleccionar todas"
    // Selecciona todas las facturas en el estado invoices
    setInvoices(prev =>
      prev.map(invoice => ({ ...invoice, selected: !allSelected })),
    );
    setAllSelected(prev => !prev);
  };

  const handleSelect = (name, value) => {
    setInvoices(prev =>
      prev.map(invoice =>
        invoice.Clave._text === name
          ? { ...invoice, selected: value }
          : invoice,
      ),
    );
  };

  const handleCleanBills = () => {
    setInvoices([]);
  };

  const removeDuplicatedXMLByKey = xmls =>
    xmls.reduce(
      (acc, xml) => {
        if (!acc.dataKeys.includes(xml?.Clave?._text)) {
          acc.data.push(xml);
          acc.dataKeys.push(xml?.Clave?._text);
        }
        return acc;
      },
      { data: [], dataKeys: [] },
    );

  const isEBType = EBType =>
    !!Object.values(ELECTRONIC_BILL_XML_TYPES).find(type => type === EBType);

  const normalizeUploadedDocument = uploadedDocument => {
    const documentType = Object.keys(uploadedDocument).find(isEBType);
    return { ...uploadedDocument[documentType], documentType };
  };

  const xmlFilesToJson = async files => {
    const data = await Promise.allSettled(
      files.map(
        file =>
          new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.onload = ({ target: { result } }) => {
              try {
                const bill = normalizeUploadedDocument(
                  JSON.parse(
                    converter.xml2json(result, { compact: true, spaces: 4 }),
                  ),
                );
                resolve(bill);
              } catch (e) {
                reject(e);
              }
            };
            fileReader.onerror = reject;
            fileReader.readAsText(file);
          }),
      ),
    );

    const succses = await data
      .filter(({ status }) => status === "fulfilled")
      .map(({ value }) => value);

    const failed = data.filter(({ status }) => status === "rejected").length;
    failed && toast.error(`No se lograron abrir ${failed} documentos`);

    return succses;
  };

  const validateOwner = dataJSON => {
    try {
      const { DocumentId_Person } = state.user.TavuelUser.Person;
      const DocumentId_Emisor = dataJSON?.Emisor?.Identificacion?.Numero;
      return DocumentId_Person === DocumentId_Emisor._text;
    } catch (err) {
      return false;
    }
  };

  const isAlreadyUploaded = useCallback(
    facturaJSON =>
      invoices
        .map(({ Clave: { _text } }) => _text)
        .includes(facturaJSON?.Clave?._text),
    [invoices],
  );

  const isValidXMLType = type => /text\/(xml)/.test(type);

  const readXml = async files => {
    const { data: uniqueXmls } = removeDuplicatedXMLByKey(
      await xmlFilesToJson(files),
    );

    uniqueXmls.forEach(facturaJSON => {
      if (!validateOwner(facturaJSON)) {
        toast.error(
          "Verifique que todas las facturas coincidan con su perfil de facturación",
        );
        return;
      }
      if (isAlreadyUploaded(facturaJSON)) {
        toast.error(
          `La factura ...${facturaJSON.Clave._text.slice(
            -11,
          )} ya fue subida anteriormente `,
        );
        return;
      }
      setInvoices(prev => [...prev, { ...facturaJSON, selected: true }]);
    });
  };

  const onDrop = Files =>
    readXml(Files.filter(file => isValidXMLType(file.type)));

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop, accept: ".xml" });

  const handleSaveIncomeBill = async () => {
    if (LineDetailPlace.id === 0) {
      toast.error("No se ha seleccionado un Negocio");
      return;
    }
    const request = chunk(invoices, 5).map(async xChucnk =>
      handleElectronicBillsXML(
        state.user.TavuelUser,
        xChucnk,
        LineDetailPlace.id,
        2,
      ),
    );

    const messages = await bromise.all(request, { concurrency: 1 }).reduce(
      (finalMessages, current) => {
        const { response, message, success } = current;
        if (!response) {
          toast.success("Facturas guardadas con éxito");
          return finalMessages;
        }
        if (response === "errors") {
          finalMessages.errors.push(...message);
        }
        if (response === "error") {
          return Object.assign(finalMessages, { error: message });
        }
        if (success) {
          return Object.assign(finalMessages, { success: message });
        }
        return finalMessages;
      },
      { errors: [], sucess: undefined, error: null },
    );

    messages.errors.forEach(bill => {
      if (bill !== "") {
        toast.error(
          `La factura:
        ${bill},
        ya se encuentra registrada,
        favor revisar las facturas`,
        );
      }
    });
    messages.error && toast.error(messages.error);
  };

  return invoices?.length === 0 ? (
    <div className="content-flex-column">
      <Card
        color="secondaryFile"
        {...getRootProps({
          className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
        })}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          gap: "1rem",
          width: { xs: "100%", sm: "365px" },
          padding: "1rem",
        }}
      >
        <div className="content-flex-column">
          <img
            alt="img"
            src={fileIcon}
            style={{ widows: "76px", height: "90px" }}
          />
          <input {...getInputProps()} />
          {isDragActive ? (
            <Typography typography="defaultLight" variant="subtitle1">
              Suelte los archivos aquí...
            </Typography>
          ) : (
            <Typography
              align="center"
              typography="defaultLight"
              variant="subtitle1"
            >
              Arrastre los archivos .xml aquí, o click para seleccionar archivos
            </Typography>
          )}
        </div>
      </Card>
    </div>
  ) : (
    <div className="content-flex-column">
      <div className="content-flex-end">
        <Button
          color="secondary"
          variant="contained"
          onClick={handleCleanBills}
        >
          Limpiar pantalla
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleSelectAllClick}
        >
          {!allSelected ? "Seleccionar todas" : "Deseleccionar todas"}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveIncomeBill}
        >
          Guardar
        </Button>
      </div>
      {invoices.map(bills => (
        <div className="content-flex-cards">
          <Card
            color="secondaryFile"
            {...getRootProps({
              className: `dropzone
                  ${isDragAccept && "dropzoneAccept"}
                  ${isDragReject && "dropzoneReject"}`,
            })}
          >
            <div className="content-flex-column">
              <img
                alt="img"
                src={fileIcon}
                style={{ widows: "76px", height: "90px" }}
              />
              <input {...getInputProps()} />
              {isDragActive ? (
                <Typography typography="defaultLight" variant="subtitle1">
                  Suelte los archivos aquí...
                </Typography>
              ) : (
                <Typography typography="defaultLight" variant="subtitle1">
                  Arrastre los archivos .xml aquí, o click para seleccionar
                  archivos
                </Typography>
              )}
            </div>
          </Card>
          <Card
            key={bills?.Clave?._text}
            color="primary"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              gap: "0.6rem",
              height: "333px",
              width: { xs: "100%", sm: "300px" },
              padding: "1rem",
            }}
          >
            <div className="content-flex-end">
              <BooleanInput
                name={bills?.Clave._text}
                value={bills?.selected}
                onChange={(name, value) => handleSelect(name, value ? 1 : 0)}
              />
            </div>
            <Box
              align="start"
              sx={{
                display: "flex",
                flexDirection: "row",
                gap: "0.1rem",
                overflowX: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                width: { xs: "200px", sm: "250px" },
                height: "26px",
              }}
            >
              <Typography
                fontWeight={600}
                typography="modeColor"
                variant="subtitle1"
              >
                Receptor:
              </Typography>
              <Typography
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
                typography="modeColor"
                variant="subtitle1"
              >
                {bills?.Receptor?.NombreComercial?._text ||
                  bills?.Receptor?.Nombre?._text ||
                  "Sin receptor"}
              </Typography>
            </Box>
            <Typography
              align="start"
              typography="modeColor"
              variant="subtitle1"
            >
              <span className="has-text-weight-bold"> Cédula: </span>{" "}
              {bills?.Receptor?.Identificacion?.Numero?._text || "---"}
            </Typography>
            <Typography typography="modeColor" variant="subtitle1">
              <span className="has-text-weight-bold"> Moneda: </span>{" "}
              {bills?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda?._text}
            </Typography>
            <Typography typography="modeColor" variant="subtitle1">
              <span className="has-text-weight-bold"> Total: </span>{" "}
              {formatCurrency(
                parseFloat(bills?.ResumenFactura?.TotalVentaNeta?._text, 10),
                bills?.ResumenFactura?.CodigoTipoMoneda?.CodigoMoneda?._text ||
                  "CRC",
              )}
            </Typography>
            <Typography typography="modeColor" variant="subtitle1">
              <span className="has-text-weight-bold"> Fecha: </span>{" "}
              {format(new Date(bills?.FechaEmision?._text), "dd/MM/yyyy")}
            </Typography>
            <Typography typography="modeColor" variant="subtitle1">
              {format(new Date(bills?.FechaEmision?._text), "hh:mm a")}
            </Typography>
            <div className="content-flex-end">
              <Button
                color="primary"
                variant="contained"
                onClick={() =>
                  setDetailModalOpen(
                    true,
                    <BillUploadDetail electronicDocument={arrayBill(bills)} />,
                  )
                }
              >
                Ver detalle
              </Button>
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default IncomePage;
