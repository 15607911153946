import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, Control } from "rbx";

function RoleSelect({ rolesByPlaceData, className, onChange, value }) {
  return (
    <div>
      <Control expanded>
        <Select.Container fullwidth>
          <Select
            className={className}
            value={value}
            onChange={e =>
              onChange(
                rolesByPlaceData?.getRolesByPlace?.find(
                  rol => rol.Name_Role === e.target.value,
                ),
              )
            }
          >
            {rolesByPlaceData?.getRolesByPlace?.map(role => (
              <Select.Option key={role.id}>{role.Name_Role}</Select.Option>
            ))}
          </Select>
        </Select.Container>
      </Control>
    </div>
  );
}

RoleSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  rolesByPlaceData: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

RoleSelect.defaultProps = {
  className: "",
};

export default RoleSelect;
