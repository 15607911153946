export const BILL_STATES = {
  CART: 1,
  ON_HOLD: 2,
  ANULLED: 3,
  IN_PROCESS: 4,
  DELIVERED: 5,
  ON_THE_WAY: 6,
  READY_FOR_COLLECTION: 7,
  PAY_AND_COLLECT: 8,
  P_AND_C_LISTO_PARA_ENTREGA: 9,
  PAYMENT_MADE: 10,
  PROCESSING_IN_COMMERCE: 11,
  UNDELIVERED: 12,
  PURACHASE_AT_CHECKOUT: 13,
  DRAFT: 14,
};

export const BILL_TYPES = {
  CASH: 1,
  CREDIT: 2,
  ELECTRONIC: 3,
};
