import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import searchIcon from "../../../assets/icons/search_icon.svg";

function SearchInput({
  onChange,
  value,
  className,
  disableImg,
  placeholder,
  label,
  color,
  name,
}) {
  return (
    <div className="input-container">
      <TextField
        className={` ${className}`}
        color={color}
        label={label}
        name={name}
        placeholder={placeholder}
        size="small"
        sx={{
          borderRadius: 1,
        }}
        type="text"
        value={value}
        variant="filled"
        onChange={onChange}
      />
      {!disableImg && (
        <img alt="Buscar" className="search-icon" src={searchIcon} />
      )}
    </div>
  );
}

SearchInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  disableImg: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  name: PropTypes.string,
};

SearchInput.defaultProps = {
  disableImg: false,
  placeholder: "",
  label: "",
  color: "primary",
  name: "",
};

export default SearchInput;
