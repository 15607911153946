/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const UPDATE_DEFAULT_COIN = gql`
  mutation getUpdatedCoin($FK_Coin: ID) {
    coin: updateDefaultCoin(FK_Coin: $FK_Coin) {
      id
      Name_Coin
      Symbol_Coin
      Sell_Coin
      Buy_Coin
      Code_Coin
    }
  }
`;
