// Disable until there are more helpers here
// eslint-disable-next-line import/prefer-default-export
export const CONTACT_TYPE = {
  // Based on ContactType DB table
  EMAIL: "1",
  PHONE: "2",
  FACEBOOK: "3",
  INSTAGRAM: "4",
  TWITTER: "5",
  LINKEDIN: "6",
  SKYPE: "7",
  ZOOM: "8",
  SNAPSHAT: "9",
  GIT: "10",
  WEB_SITE: "11",
  WHATSAPP: "12",
};
