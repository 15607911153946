/* eslint-disable import/prefer-default-export */

import { gql } from "@apollo/client";

export const SAVE_TEMP_BILL = gql`
  mutation saveBillDraft(
    $bill_new: BillDraftInput
    $detail_new: [BillDetailsDraftInput]
    $config_new: BillConfigurationInput
  ) {
    bill: saveBillDraft(
      bill_new: $bill_new
      detail_new: $detail_new
      config_new: $config_new
    ) {
      id
      Detail {
        id
        ProductFeature {
          id
        }
      }
    }
  }
`;
export const UPDATE_TEMP_CLIENT = gql`
  mutation updateBillUser($id: ID!, $FK_User: ID) {
    Bill: updateBillUser(id: $id, FK_User: $FK_User) {
      id
    }
  }
`;
export const UPDATE_TEMP_BILL_FK = gql`
  mutation updateDraftBillFK($id: ID!, $value: ID!, $field: String) {
    Bill: updateDraftBillFK(id: $id, value: $value, field: $field) {
      id
    }
  }
`;
export const UPDATE_BILL_PAYMENT_METHOD = gql`
  mutation updateDraftBillPaymentMethod(
    $id: ID!
    $FK_PaymentMethod: ID!
    $Payment_Detail: String
  ) {
    Bill: updateDraftBillPaymentMethod(
      id: $id
      FK_PaymentMethod: $FK_PaymentMethod
      Payment_Detail: $Payment_Detail
    ) {
      id
    }
  }
`;
export const UPDATE_BILL_SELL_CONDITION = gql`
  mutation updateDraftBillSellCondition(
    $id: ID!
    $FK_SellCondition: ID!
    $SellCondition_Detail: String
  ) {
    Bill: updateDraftBillSellCondition(
      id: $id
      FK_SellCondition: $FK_SellCondition
      SellCondition_Detail: $SellCondition_Detail
    ) {
      id
    }
  }
`;
export const UPDATE_BILL_DETAIL = gql`
  mutation updateDraftBillDetail(
    $id: ID!
    $detail: PayingBillDetailForCashierInput
  ) {
    detail: updateDraftBillDetail(id: $id, detail: $detail) {
      id
    }
  }
`;
export const UPDATE_DETAILL_QUANTITY = gql`
  mutation quickUpdateBillDetailQuantity(
    $FK_ProductFeature: ID
    $FK_Bill: ID
    $Quantity_BillDetail: Int
  ) {
    saved: quickUpdateBillDetailQuantity(
      FK_ProductFeature: $FK_ProductFeature
      FK_Bill: $FK_Bill
      Quantity_BillDetail: $Quantity_BillDetail
    )
  }
`;
export const DELETE_DRAFT_DETAIL = gql`
  mutation deleteDraftDetail($id: ID) {
    deleted: deleteDraftDetail(id: $id)
  }
`;

export const UPDATE_BILL_STATE = gql`
  mutation updateBillState($id: ID, $FK_BillState: ID) {
    billEdited: updateBillState(id: $id, FK_BillState: $FK_BillState) {
      id
    }
  }
`;

export const RESEND_EMAIL = gql`
  mutation resendBillEmailTo(
    $id: ID!
    $idElectronicBillFeatures: [ID]
    $to: [String]
  ) {
    response: resendNormalBillEmailTo(
      id: $id
      idElectronicBillFeatures: $idElectronicBillFeatures
      to: $to
    )
  }
`;

export const DELETE_BILL_AND_RELATIVES = gql`
  mutation deleteBillAndRelatives($FK_Bill: ID!) {
    response: deleteBillAndRelatives(FK_Bill: $FK_Bill)
  }
`;
export const CREATE_DRAFT_FEC = gql`
  mutation createDraftFEC($createDraftFECInput: createDraftFECInput) {
    createDraftFEC(createDraftFECInput: $createDraftFECInput) {
      id
      Total_Bill
    }
  }
`;

export const CREATE_DRAFT_FEE = gql`
  mutation createDraftFEE($createDraftFEEInput: createDraftFEEInput) {
    createDraftFEE(createDraftFEEInput: $createDraftFEEInput) {
      id
      Total_Bill
    }
  }
`;

export const CREATE_FEC_PRODUCTS = gql`
  mutation createFECProducts($createFECProductsInput: createFECProductsInput) {
    createFECProducts(createFECProductsInput: $createFECProductsInput) {
      id
      Name
      Tax {
        id
        Description_Tax
      }
      tariff {
        id
        Percent
      }
      productFeature_FK
      subTotal
      Name
      MeasurementUnit
      totalPrice
      quantity
      discount
      baseTax
      factor
      FK_Tax
      FK_Tariff
      Cabys
      unitPrice
      inventory
    }
  }
`;
