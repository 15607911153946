/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
// External components
import { DatePicker } from "@mui/x-date-pickers";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import { useAsyncDebounce } from "react-table";
// Graphql
import { useLazyQuery, useMutation } from "@apollo/client";
import { UPDATE_BILL_STATE, GET_PROFORMA_BILL } from "../../graphql";
// Components
import { ConfirmationModal, DraftBill } from "../../components";
// Context
import { useModal } from "../../context";
// Hooks
import useElectronicBill from "../../hooks/useElectronicBill";
// SCSS
import "./ProformaPage.scss";
// Helpers
import { customToast } from "../../utils";
import {
  sortArrayItemsByDate,
  SORT_ARRAY_ITEMS_BY_DATE_MODES,
} from "../../utils/helpers";
import SearchInput from "../../components/Products/components/SearchInput";

function ProformaPage() {
  const [filter, setFilter] = useState({
    name: "",
    date: new Date(),
  });

  const { LineDetailPlace } = useElectronicBill();
  const { setModalOpen } = useModal(false);

  const [deleteBill] = useMutation(UPDATE_BILL_STATE);
  const [getDraftBills, { data: draftBillsData, loading }] =
    useLazyQuery(GET_PROFORMA_BILL);

  const executeQuery = () => {
    getDraftBills({
      variables: {
        eBSearchFormWithClient: {
          place_id: LineDetailPlace.id,
          date: filter.date,
          ClientName: filter.name,
        },
      },
      fetchPolicy: "network-only",
    });
  };

  const debouncedLoad = useAsyncDebounce(() => {
    executeQuery();
  }, 1000);

  const handleChange = (name, value) => {
    setFilter(prev => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    debouncedLoad();
  }, [filter, debouncedLoad, LineDetailPlace]);

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const deleteDraftBill = async id => {
    setModalOpen(false);
    const deleted = await deleteBill({
      variables: {
        id,
        FK_BillState: 3,
      },
      refetchQueries: [
        {
          query: GET_PROFORMA_BILL,
          variables: {
            eBSearchFormWithClient: {
              place_id: LineDetailPlace.id,
              date: filter.date,
              ClientName: filter.name,
            },
          },
        },
      ],
    });
    return !deleted
      ? customToast.success("Ha ocurrido un error al eliminar la factura")
      : customToast.success("Factura eliminada con éxito");
  };

  const handleDelete = bill => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea borrar esta factura? Presione confirmar para eliminar"
        header="¿Desea Borrar esta Factura?"
        onCancel={cancelDelete}
        onConfirm={() => deleteDraftBill(bill.id)}
      />,
    );
  };

  const draftBillsSortedAscendant = sortArrayItemsByDate(
    draftBillsData !== undefined &&
      Array.isArray(draftBillsData.draft_bills) &&
      draftBillsData.draft_bills.length > 0
      ? draftBillsData.draft_bills
      : [],
    {
      mode: SORT_ARRAY_ITEMS_BY_DATE_MODES.ASCENDANT,
      targetKeyName: "Created_At",
    },
  );

  const commonGridProps = {
    item: true,
    lg: 4,
    sm: 6,
    sx: { padding: { xs: 3, sm: 2, md: 4, lg: 3 }, paddingY: { xs: 1 } },
    xl: 3,
    xs: 12,
  };

  return (
    <div className="Proforma">
      <Grid container gap={2}>
        <Grid sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box sx={{ maxWidth: "31rem" }}>
            <SearchInput
              className="searchInput"
              label="Nombre del cliente"
              name="name"
              value={filter.name}
              onChange={({ target: { name, value } }) =>
                handleChange(name, value)
              }
            />
          </Box>
        </Grid>
        <Grid sx={{ width: { xs: "100%", md: "auto" } }}>
          <Box sx={{ maxWidth: "31rem" }}>
            <DatePicker
              color="primary"
              format="MMMM / YYYY"
              label="Mes y año de emisión"
              selectedDate={filter.date}
              size="small"
              sx={{
                "& .MuiFormLabel-root": {
                  transform: " translate(14px, 5px) scale(0.75)",
                },
                "& .MuiInputBase-input": {
                  padding: "24px 14px 10px 14px ",
                },
                "width": "100%",
              }}
              variant="filled"
              views={["month", "year"]}
              onChange={date => handleChange("date", date)}
            />
          </Box>
        </Grid>
      </Grid>
      {loading || (!draftBillsSortedAscendant && <CircularProgress />)}
      <Grid container marginTop={2} padding={0}>
        {draftBillsSortedAscendant &&
          draftBillsSortedAscendant.map(bill => (
            <Grid {...commonGridProps}>
              <DraftBill bill={bill} onDelete={handleDelete} />
            </Grid>
          ))}
      </Grid>
      {draftBillsSortedAscendant && draftBillsSortedAscendant.length === 0 && (
        <Typography className="no-results-text">
          ¡No se han encontrado proformas!
        </Typography>
      )}
    </div>
  );
}

export default ProformaPage;
