/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_SELL_CONDITIONS = gql`
  query getAllSellCondition {
    sellConditions: getAllSellCondition {
      id
      Name_SellCondition
      id_MH
    }
  }
`;
