import styled from "styled-components";

export const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: white;
  height: fit-content;
  text-align: left;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  transform: translate3d(-260px, 0, 0);
  transition: transform 0.3s linear;
  box-shadow: 20px -9px 20px 0px rgb(0 0 0 / 9%);
  color: white;

  @media (max-width: 768px) {
    transform: translate3d(${({ open }) => (open ? "0px" : "-250px")}, 0, 0);
    transition: transform 0.3s linear;
  }
  @media (max-width: 600px) {
    transform: translate3d(${({ open }) => (open ? "0px" : "-350px")}, 0, 0);
    transition: transform 0.3s linear;
  }
`;

export default StyledMenu;
