import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import PropTypes from "prop-types";
import "./NestedMenu.scss";
import { Link, useLocation } from "react-router-dom";

function NestedMenu({ links }) {
  const location = useLocation();

  return links.map(link => (
    <ListItemButton
      key={link.to}
      className="nested-item"
      sx={{
        height: "2rem",
        fontSize: "0.875rem",
      }}
    >
      <Link
        style={{
          display: "flex",
          justifyContent: "start",
          textAlign: "start",
          width: "100%",
        }}
        to={link.to}
      >
        <p
          style={{
            color:
              link.to === location.pathname ? "rgba(9, 25, 40, 1)" : "#fff",
          }}
        >
          {" "}
          {link.name}
        </p>
      </Link>
    </ListItemButton>
  ));
}

NestedMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

NestedMenu.defaultProps = {
  links: [],
};

export default NestedMenu;
