/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_BILLCOFIGURATION_BY_PROFORM_ID = gql`
  query getBillConfigurationByProformId($FK_Bill: ID) {
    getBillConfigurationByProformId(FK_Bill: $FK_Bill) {
      Day
      Month
      Minute
      date
      Hour
      Frequency
      Active
      id
    }
  }
`;

export const GET_CANCELED_SCHEDULED_BILLS = gql`
  query getCanceledScheduledBills($SearchInput: ScheduledBillInput) {
    getCanceledScheduledBills(SearchInput: $SearchInput) {
      id
      date
      Bill {
        id
        User {
          Person {
            Name_Person
          }
        }
      }
    }
  }
`;
