const styleAsynSelect = {
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: "inherit",
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: "none",
  }),
  container: provided => ({
    fontSize: 13,
  }),
  singleValue: provided => ({
    ...provided,
    top: "38%",
  }),
  control: provided => ({
    ...provided,
    "fontSize": "1rem",
    "minHeight": 30,
    "height": 45,
    "width": "100%",
    "minWidth": 180,
    "borderRadius": "4px",
    "border": "0.1rem solid transparent",
    "background": "transparent",
    "&:hover": {
      border: "0.1rem solid transparent",
    },
    "&:focus": {
      border: "0.1rem solid transparent",
    },
  }),

  menu: provided => ({
    ...provided,
    fontSize: 16,
    zIndex: 999,
    width: "auto",
    boxShadow: "4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "transparent",
    backdropFilter: "blur(15px)",
  }),
  menuList: provided => ({
    ...provided,
    display: "flex",
    flexWrap: "wrap",
    fontSize: 16,
    zIndex: 999,
    width: "auto",
    background: "rgba(0, 0, 0, 0.08)",
  }),
  option: (provided, state) => ({
    ...provided,
    "backgroundColor": state.isSelected
      ? "rgba(0, 0, 0, 0.40)"
      : "rgba(0, 0, 0, 0.08)",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.40)",
    },
    "&:focus": {
      backgroundColor: "rgba(0, 0, 0, 0.40)",
    },
  }),
};
export default styleAsynSelect;
