import React from "react";
import PropTypes from "prop-types";
// External components
import { Box, CircularProgress } from "@mui/material";
// Components
import BillResumeCard from "../BillResumeCard";
// SCSS
import "./DocumentSearchBill.scss";

function DocumentSearchBill({ bills, isLoading, handleDeleteDocument }) {
  return isLoading ? (
    <CircularProgress disableShrink sx={{ position: "absolute" }} />
  ) : (
    <Box className="content-flex-cards" sx={{ display: bills ? "" : "none" }}>
      {bills?.map(bill => (
        <BillResumeCard
          key={bill.id}
          bill={bill}
          handleDeleteDocument={handleDeleteDocument}
        />
      ))}
    </Box>
  );
}

DocumentSearchBill.propTypes = {
  bills: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isLoading: PropTypes.bool.isRequired,
  handleDeleteDocument: PropTypes.func.isRequired,
};

DocumentSearchBill.defaultProps = {
  bills: null,
};

export default DocumentSearchBill;
