import PropTypes from "prop-types";
import { useState, useEffect } from "react";
// External components
import { Button, Card, Container, Image, Label, Select, Title } from "rbx";
// Graphql
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_USER_ROLE,
  DElETE_USER_ROLE,
  UPDATE_USER_ROLE,
  GET_ROLES_BY_PLACE,
} from "../../../../graphql";
// Components
import SelectRole from "../../../RoleSelect";
// Context
import { useAuth } from "../../../../context";
import { useElectronic } from "../../../../context/ElectronicContext";
// SCSS
import "./NewOrEditUserRole.scss";
import "../../../../styles/index.scss";
import { customToast } from "../../../../utils";

function NewOrEditUserRole({ setModalOpen, title, user, NewOrEdit, refetch }) {
  const [SelectUserRole, setSelectUserRole] = useState({});

  const { idPlaces } = useAuth();
  const { LineDetailPlace } = useElectronic();

  const [createUserRole] = useMutation(CREATE_USER_ROLE);
  const [updateUserRole] = useMutation(UPDATE_USER_ROLE);
  const [deleteUserRole] = useMutation(DElETE_USER_ROLE);

  const { data: rolesByPlaceData } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: idPlaces,
    },
  });

  useEffect(() => {
    if (!NewOrEdit) {
      let userRol = false;
      user.Roles.forEach(placeRole => {
        if (!userRol && placeRole.Place.id === LineDetailPlace.id) {
          userRol = user.UserRoles.find(r => r.FK_Role === placeRole.id);
          setSelectUserRole(placeRole);
        }
      });
    } else {
      setSelectUserRole(rolesByPlaceData?.getRolesByPlace[0]);
    }
  }, [
    NewOrEdit,
    user.Roles,
    user.UserRoles,
    LineDetailPlace.id,
    rolesByPlaceData.getRolesByPlace,
  ]);

  const onChange = e => {
    setSelectUserRole(e);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  const handleDelete = async () => {
    const result = await deleteUserRole({
      variables: {
        place: idPlaces,
        user: user.id,
      },
    });
    refetch();
    if (result !== null) {
      customToast.success("Usuario eliminado correctamente.");
    } else {
      customToast.error("No se pudo eliminar el usuario correctamente.");
    }
    setModalOpen(false);
  };

  const handleSaveUser = async () => {
    if (NewOrEdit) {
      const NewInfo = {
        FK_User: Number(user.FK_User),
        FK_Role: Number(SelectUserRole?.id),
        FK_GeneralState: Number(1),
      };

      const result = await createUserRole({
        variables: {
          NewInfo,
        },
      });
      refetch();
      if (result !== null) {
        customToast.success("Usuario guardado correctamente.");
      } else {
        customToast.error("No se pudo guardar el usuario correctamente.");
      }
    } else {
      let userRol = false;
      user.Roles.forEach(placeRole => {
        if (!userRol && placeRole.Place.id === LineDetailPlace.id) {
          userRol = user.UserRoles.find(r => r.FK_Role === placeRole.id);
        }
      });

      const NewInfo = {
        FK_User: Number(user.id),
        FK_Role: Number(SelectUserRole?.id),
        FK_GeneralState: Number(1),
      };

      const result = await updateUserRole({
        variables: {
          id: userRol.id,
          NewInfo,
        },
      });
      refetch();
      if (result !== null) {
        customToast.success("Usuario editado correctamente.");
      } else {
        customToast.error("No se pudo editar el usuario correctamente.");
      }
    }
    setModalOpen(false);
  };

  return (
    <Container>
      <Title>{title}</Title>
      <div>
        <Card>
          <div className="title-product">
            <h2>{!NewOrEdit ? user?.FirebaseUser?.Name : user?.Name}</h2>
          </div>
          <div className="cart-box">
            <div className="content-right">
              <div className="product-img ">
                <Image.Container size="32px sq.">
                  <Image
                    src={
                      !NewOrEdit ? user?.FirebaseUser?.Picture : user?.Picture
                    }
                  />
                </Image.Container>
              </div>
            </div>

            {rolesByPlaceData != null && (
              <div>
                <Label>Role</Label>
                <Select.Container fullwidth>
                  <SelectRole
                    className="select-role"
                    rolesByPlaceData={rolesByPlaceData}
                    value={SelectUserRole?.Name_Role}
                    onChange={onChange}
                  />
                </Select.Container>
              </div>
            )}
          </div>
        </Card>
      </div>
      <Container className="Container">
        <div className="buttons">
          <div className="button-left">
            {!NewOrEdit && (
              <Button color="danger" size="medium" onClick={handleDelete}>
                Eliminar
              </Button>
            )}
            <Button
              className="edit-button"
              size="medium"
              onClick={handleCancel}
            >
              Cancelar
            </Button>
          </div>
          <div className="button-rigth">
            <Button color="primary" size="medium" onClick={handleSaveUser}>
              Guardar
            </Button>
          </div>
        </div>
      </Container>
    </Container>
  );
}

NewOrEditUserRole.propTypes = {
  setModalOpen: PropTypes.func,
  user: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  NewOrEdit: PropTypes.bool.isRequired,
  refetch: PropTypes.func.isRequired,
};

NewOrEditUserRole.defaultProps = {
  setModalOpen: e => e,
};

export default NewOrEditUserRole;
