/* eslint-disable react/jsx-props-no-spreading */

/* eslint-disable func-names */
/* eslint new-cap: ["error", { "newIsCap": false }] */

import React, { useEffect, useState } from "react";
// External components
import { Box, Divider, FormControl, Typography } from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { format } from "date-fns";
import AsyncSelect from "react-select/async";
import PropTypes from "prop-types";
// Graphql
import { useApolloClient } from "@apollo/client";
import { GET_PRODUCTS_BY_PLACE_AND_CRITERIA } from "../../../../graphql";
// Context
import { useAuth } from "../../../../context";
import { useElectronic } from "../../../../context/ElectronicContext";
// Utils
import { customToast as toast } from "../../../../utils";
// SCSS
import "react-spring-bottom-sheet/dist/style.css";
import "../../../ProductSelect/ProductSelect.scss";
import styleAsynSelect from "../../../CabysCodeSelect/styles";

function CustomOption({ data, innerProps, validateRoute }) {
  const { formatAmount } = useAuth();
  const getDetails = product => {
    let details = null;
    product.details?.forEach(item => {
      if (item?.Value_ProductFeatureDetail !== null) {
        details = !details
          ? item.Value_ProductFeatureDetail
          : `${details} / ${item.Value_ProductFeatureDetail}`;
      }
    });
    return details;
  };

  const details = getDetails(data);
  const formattedPrice = formatAmount(data?.price);
  const route = validateRoute(data);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          "display": "grid",
          "flexDirection": "row",
          "padding": "1rem",
          "cursor": "pointer",
          "width": "100%",
          "gridTemplateColumns": "repeat(2, 1fr)",
          "alignItems": "center",
          "&:hover": {
            backgroundColor: "rgba(57, 110, 156, 0.5)",
          },
          "&:focus": {
            backgroundColor: "rgba(57, 110, 156, 0.5)",
          },
        }}
        {...innerProps}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography fontWeight={600} typography="modeColor" variant="body1">
            {data?.name}
          </Typography>
          <Typography typography="modeColor" variant="body1">
            {data?.description}
          </Typography>
          <Typography typography="modeColor" variant="body1">
            {details}
          </Typography>

          <Typography typography="modeColor" variant="body1">
            {data?.unit_Measure} {formattedPrice}
          </Typography>
          <Typography typography="modeColor" variant="body1">
            <b>Cabys: </b>
            {data?.cabys?.Code}
          </Typography>
          <Typography typography="modeColor" variant="body1">
            <b>Imp: </b>
            {data?.tariff?.Percent}%
          </Typography>
        </Box>
        <img alt="producto" className="card-image" src={route} />
      </Box>
      <Divider color="primary" sx={{ width: "100%" }} />
    </Box>
  );
}
CustomOption.propTypes = {
  data: PropTypes.any,
  innerProps: PropTypes.any,
  validateRoute: PropTypes.func,
};
CustomOption.defaultProps = {
  data: undefined,
  innerProps: undefined,
  validateRoute: undefined,
};

function SelectFilterProduct() {
  const [defaultOptions, setDefaultOptions] = useState([]);

  const client = useApolloClient();

  const { LineDetailPlace } = useElectronic();
  const { state, formatAmount, formatAmountWithSymbol } = useAuth();
  const user = state.user.TavuelUser;
  const { DefaultCoin } = user;
  const isColon = DefaultCoin?.Symbol_Coin === "₡";

  useEffect(() => {
    setDefaultOptions([]);
  }, [LineDetailPlace.id]);

  const getUrl = product => {
    const route = product.url?.find(item => item.Route_File !== null);
    return route?.Route_File || "";
  };

  const validateRoute = product => {
    const route = getUrl(product);
    if (route === "") {
      return "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
    }
    return route;
  };

  const newImage = src => {
    const image = document.createElement("img");
    if (src === "") {
      image.src =
        "https://firebasestorage.googleapis.com/v0/b/tavuel506.appspot.com/o/conta%2FNo-Thumbnail-Image.png?alt=media";
      return image;
    }
    image.src = src;
    return image;
  };

  const validateData = data => {
    if (data && data !== "") {
      return data;
    }
    return "Sin datos";
  };

  const getImage = product => {
    const source = validateRoute(product);
    const img = newImage(source);
    return img;
  };

  const validateVAT = VAT => {
    if (VAT && VAT !== "") {
      return `${VAT}%`;
    }
    return "Sin datos";
  };

  const generatePDF = product => {
    const doc = new jsPDF();

    doc.setFont("times");
    doc.setFontSize(12);
    doc.text("Conta-Fast", 15, 15);
    doc.text("Información de producto", 15, 20);
    doc.text(`${state?.user?.displayName}`, 15, 25);
    doc.text(`${format(new Date(), "yyyy-MM-dd")}`, 174, 15);
    doc.text("", 15, 30);
    doc.text("", 15, 35);
    const tableRows = [];

    const ticketData = [
      "",
      product?.name || "Sin datos",
      validateData(product?.description) || "Sin datos",

      product?.unit_Measure || "Sin datos",
      isColon
        ? formatAmount(product?.price)
        : formatAmountWithSymbol(product?.price) || "Sin datos",
      product?.cabys?.Code || "Sin datos",
      validateVAT(product?.tariff?.Percent),
    ];
    tableRows.push(ticketData);

    doc.autoTable({
      columns: [
        "Imagen",
        "Producto",
        "Descripción",
        "Unidad de medida",
        isColon ? "Precio (Colones)" : "Precio",
        "Código Cabys",
        "IVA %",
      ],
      body: tableRows,
      margin: { top: 30 },
      startY: 30,
      styles: {
        halign: "center",
        font: "times",
        valign: "middle",
        lineColor: [3, 7, 16],
      },
      headStyles: {
        fillColor: [245, 245, 245],
        textColor: [3, 7, 16],
        lineColor: [3, 7, 16],
        lineWidth: 0.01,
        cellPadding: {
          top: 4,
          right: 1,
          bottom: 4,
          left: 1,
        },
        valign: "middle",
        halign: "center",
      },
      bodyStyles: { minCellHeight: 20 },
      alternateRowStyles: { fillColor: [231, 215, 252] },
      tableLineColor: [1, 35, 76],
      tableLineWidth: 0.1,
      theme: "grid",
      columnStyles: {
        0: {
          fillColor: [245, 245, 245],
        },
        1: {
          fillColor: [245, 245, 245],
        },
        2: {
          fillColor: [245, 245, 245],
        },
        3: {
          fillColor: [245, 245, 245],
        },
        4: {
          fillColor: [245, 245, 245],
        },
        5: {
          fillColor: [245, 245, 245],
        },
        6: {
          fillColor: [245, 245, 245],
        },
      },

      didDrawCell(data) {
        if (data.column.index === 0 && data.cell.section === "body") {
          const img = getImage(product);

          const dim = data.cell.height - data.cell.padding("vertical");

          doc.addImage(img, data.cell.x, data.cell.y + 4, dim, dim);
        }
      },
    });

    doc.save(
      `producto_${validateData(product?.description)}_${
        state?.user?.displayName
      }.pdf`,
    );

    toast.success("Reporte generado con éxito");
  };

  const handleChange = newValue => {
    generatePDF(newValue);
  };

  const loadSelectOptions = async inputValue => {
    const { data } = await client.query({
      query: GET_PRODUCTS_BY_PLACE_AND_CRITERIA,
      variables: {
        id: LineDetailPlace.id,
        criteria: inputValue,
      },
    });

    const products = data?.products;

    const result = Array.isArray(products)
      ? products.map(item => {
          const { Details, Product, ProductPrice } = item;

          return {
            id: item.id,
            name: Product?.Name_Product,
            description: Product?.Description_Product,
            cabys: ProductPrice?.Cabys,
            tariff: ProductPrice?.Tariff,
            price: ProductPrice?.Price_ProductPrice.toFixed(3),
            quantity: 1,
            discount: [0],
            tax: ProductPrice?.Tax,
            taxes: [],
            totalTaxes: 0,
            total: ProductPrice?.Price_ProductPrice.toFixed(3),
            unit_Measure: Product?.InventoryDetail?.UnitOfMeasurement?.Symbol,
            product_Code: "",
            url: item?.Files,
            details: Details,
            value: item.id,
            label: Product?.Description_Product,
          };
        })
      : [];

    setDefaultOptions(result);
    return result;
  };

  const option = props => (
    <CustomOption {...props} validateRoute={validateRoute} />
  );

  return (
    <FormControl sx={{ width: { xs: "full", sm: 10 / 12 } }}>
      <AsyncSelect
        key={LineDetailPlace.id}
        cacheOptions
        className="input principal-input without-padding"
        components={{ Option: option }}
        defaultOptions={defaultOptions}
        loadOptions={loadSelectOptions}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        placeholder="Buscar..."
        styles={styleAsynSelect}
        onChange={handleChange}
      />
    </FormControl>
  );
}

export default SelectFilterProduct;
