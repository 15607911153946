/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_PROFORMA_BILL = gql`
  query getDraftBillsByPlace($eBSearchFormWithClient: EBSearchFormWithClient) {
    draft_bills: getDraftBillsByPlace(
      eBSearchFormWithClient: $eBSearchFormWithClient
    ) {
      id
      Total_Bill
      User {
        id
        NickName_User
        Person {
          ProfileImage
          Email
          Name_Person
        }
      }
      Created_At
      Updated_At
      Detail {
        id
        Total_BillDetail
      }
      Coin {
        Symbol_Coin
        Sell_Coin
      }
    }
  }
`;

export const GET_BILL_BY_ID = gql`
  query getBill($id: ID!) {
    bill: getBill(id: $id) {
      id
      Total_Bill
      Discount
      FK_SellCondition
      FK_Coin
      FK_PaymentMethod
      Payment_Detail
      SellCondition_Detail
      BillConfiguration {
        id
        date
        Day
        Month
        Year
        Minute
        Hour
        Frequency
        Active
      }
      Place {
        id
      }
      User {
        id
        NickName_User
        Person {
          ProfileImage
        }
        UserBillingProfile {
          id
          ID_Number
          Name
          PhoneNumber
          Email
        }
      }
      Created_At
      Updated_At
      Detail {
        id
        Total_BillDetail
        discount_BillDetail
        Quantity_BillDetail
        BaseTax
        Taxed
        Factor
        ProductFeature {
          id
          InventoryDetail {
            Inventory {
              Cellar {
                Place {
                  id
                  Name_Place
                  Company {
                    id
                  }
                }
              }
            }
          }
          Codes {
            id
            FeatureCode
          }
          Details {
            id
            Value_ProductFeatureDetail
          }
          ProductPrice {
            id
            Price_ProductPrice
            Cabys {
              id
              Code
              Name
            }
            Tariff {
              id
              Name
              Code
              Percent
            }
            Tax {
              id
              Description_Tax
              MH_Code
            }
          }
          Product {
            id
            Name_Product
            Description_Product
            ProductState {
              State
            }
          }
          Files {
            id
            Route_File
          }
        }
      }
    }
  }
`;
export const GET_MANY_XML = gql`
  query getmany($keys: [String]!) {
    downloadManyXML(keys: $keys) {
      ... on NotFoundError {
        error
        searchedParam
      }
      ... on XmlSearchResult {
        params
        payload
      }
    }
  }
`;
export const SEND_MANY_XML = gql`
  query getmany($keys: [String]!, $place_id: ID, $emails: [String]) {
    downloadManyXML(keys: $keys, emails: $emails, place_id: $place_id) {
      ... on NotFoundError {
        error
        searchedParam
      }
      ... on XmlSearchResult {
        params
        payload
      }
    }
  }
`;
export const GET_PRESENTATION_DATA = gql`
  query getMHPresentationData(
    $getMHPresentationDataInput: getMHPresentationDataInput
  ) {
    getMHPresentationData(
      getMHPresentationDataInput: $getMHPresentationDataInput
    ) {
      Coin {
        id
        Symbol_Coin
        Name_Coin
      }
      District {
        id
        Name
      }
      Canton {
        id
        Name
      }
      Province {
        id
        Name
      }
      PaymentMethod {
        id
        Name_PaymentMethod
      }
      SellCondition {
        id
        Name_SellCondition
      }
    }
  }
`;

export const GET_BILLS = gql`
  query getBills($getBillsNewInput: getBillsNewInput) {
    getBills(getBillsNewInput: $getBillsNewInput) {
      ... on Error {
        error
      }
      ... on getBillsNew {
        Bills {
          id
          Total_Bill
          FK_SellCondition
          FK_Coin
          FK_PaymentMethod
          Payment_Detail
          SellCondition_Detail
          Place {
            id
            Name_Place
          }
          User {
            id
            NickName_User
            Person {
              ProfileImage
              DocumentId_Person
              Name_Person
            }
            UserBillingProfile {
              id
              ID_Number
              Name
              PhoneNumber
              Email
            }
          }
          Coin {
            id
            Name_Coin
            Symbol_Coin
            Code_Coin
          }
          Created_At
          Updated_At
          Detail {
            id
            Total_BillDetail
            Quantity_BillDetail
            ProductFeature {
              id
              InventoryDetail {
                Inventory {
                  Cellar {
                    Place {
                      id
                      Name_Place
                      Company {
                        id
                      }
                    }
                  }
                }
              }
              Codes {
                id
                FeatureCode
              }
              Details {
                id
                Value_ProductFeatureDetail
              }
              ProductPrice {
                id
                Price_ProductPrice
                Cabys {
                  id
                  Code
                  Name
                }
                Tariff {
                  id
                  Name
                  Code
                  Percent
                }
                Tax {
                  id
                  Description_Tax
                  MH_Code
                }
              }
              Product {
                id
                Name_Product
                Description_Product
                ProductState {
                  State
                }
              }
              Files {
                id
                Route_File
              }
            }
          }
          BillState {
            id
            Name_BillState
          }
        }
      }
    }
  }
`;

export const GET_ALL_BILLS_WITH_BILL_CONFIGURATION = gql`
  query getAllBillsWithBillConfiguration(
    $ConfigurationBillInput: ConfigurationBillInput
  ) {
    getAllBillsWithBillConfiguration(
      ConfigurationBillInput: $ConfigurationBillInput
    ) {
      id
      User {
        Person {
          Name_Person
        }
      }
      Place {
        id
      }
      BillConfiguration {
        id
        Frequency
        Active
        date
        Day
        Month
        Year
        Minute
        Hour
        Next_Execution
      }
    }
  }
`;

export const GET_PENDING_SCHEDULED_BILLS = gql`
  query getPendingScheduledBills($SearchInput: ConfigurationBillInput) {
    getPendingScheduledBills(SearchInput: $SearchInput) {
      id
      User {
        Person {
          Name_Person
        }
      }
      Place {
        id
      }
      BillConfiguration {
        id
        Frequency
        Active
        date
        Day
        Month
        Year
        Minute
        Hour
        Next_Execution
      }
    }
  }
`;
