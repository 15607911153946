const TransformImages = (files, size) => {
  if (files) {
    const file = files[0];
    let newRouteFile = file ? file.Route_File : files[0].Route_File;
    /*
      ***IMPORTANT***
      se valida este rango de fechas porque solo en este existen diferentes tamaños de las imagenes
    */
    if (
      size &&
      file &&
      new Date(file?.Created_At) > new Date("2020-06-09") &&
      new Date(file?.Created_At) < new Date("2022-10-30")
    ) {
      newRouteFile = file.Route_File;
      if (file && size === 1) {
        if (
          !file.Route_File.includes("_200x200.png?") &&
          !file.Route_File.includes("%2Fproducts%2Fthumbs%2F")
        ) {
          newRouteFile = file.Route_File.replace(
            "%2Fproducts%2F",
            "%2Fproducts%2Fthumbs%2F",
          );
          newRouteFile = newRouteFile.replace(".png?", "_200x200.png?");
        }
      }
      if (file && size === 2) {
        if (
          !file.Route_File.includes("_400x400.png?") &&
          !file.Route_File.includes("%2Fproducts%2Fthumbs%2F")
        ) {
          newRouteFile = file.Route_File.replace(
            "%2Fproducts%2F",
            "%2Fproducts%2Fthumbs%2F",
          );
          newRouteFile = newRouteFile.replace(".png?", "_400x400.png?");
        }
      }
    }
    return newRouteFile;
  }
  return files[0].Route_File;
};

export default TransformImages;
