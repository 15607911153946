import { format } from "date-fns";
import PropTypes from "prop-types";
// External components
import { Box, Button, Card, Typography } from "@mui/material";
// Utils
import { formatCurrency } from "../../utils";
// Styles
import "./CreditDebitCard.scss";

function CreditDebitCard({ item, actions }) {
  return (
    <Card
      color="primary"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        gap: "0.1rem",
        height: "189px",
        width: { xs: "100%", sm: "333px" },
        padding: "1rem",
      }}
    >
      <Typography
        align="start"
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          width: { xs: "200px", sm: "300px" },
          height: "100%",
        }}
        typography="modeColor"
        variant="subtitle1"
      >
        <span className="has-text-weight-bold"> Key: </span> {item?.Key}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Moneda: </span>{" "}
        {item?.Coin?.Name_Coin}
      </Typography>
      <Typography align="start" typography="modeColor" variant="subtitle1">
        <span className="has-text-weight-bold"> Total: </span>{" "}
        {formatCurrency(
          parseFloat(item.TotalBill, 10),
          item?.Coin?.Code_Coin ? item?.Coin?.Code_Coin : "CRC",
        )}
      </Typography>
      {item?.EmitedDay && (
        <Typography align="start" typography="modeColor" variant="subtitle1">
          <span className="has-text-weight-bold"> Fecha: </span>{" "}
          {format(new Date(item?.EmitedDay), "dd/MM/yyyy hh:mm a")}
        </Typography>
      )}

      <Box sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
        <Button
          key={item.id}
          outlined
          className="btn-animate"
          color="primary"
          disabled={item?.DebitNote}
          onClick={actions.onClick}
        >
          <Typography
            disabled={item?.DebitNote}
            typography="modeButton"
            variant="subtitle2"
          >
            {actions.text}
          </Typography>
        </Button>
      </Box>
    </Card>
  );
}

CreditDebitCard.propTypes = {
  item: PropTypes.object.isRequired,
  actions: PropTypes.object,
};
CreditDebitCard.defaultProps = {
  actions: {},
};

export default CreditDebitCard;
