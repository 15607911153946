import React, { useState } from "react";
import PropTypes from "prop-types";
// External components
import { Typography } from "@mui/material";
import { Button, Control, Field } from "rbx";
// Assets
import iconGoogle from "../../assets/googleW.svg";
// Styles
import "./Login.scss";
import InputForm from "../InputForm/InputForm";

function Login({
  handleChange,
  handleGoogle,
  setLoginSection,
  loginSection,
  openForgotPassword,
  state,
}) {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div className="content-login">
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        <div className="button__startAuth">
          <Button
            className="landing-page-button-dark button__width__Full"
            type="button"
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              setLoginSection(!loginSection);
            }}
          >
            <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
              Regístrate
            </Typography>
          </Button>
          <Button
            className="landing-page-button-light button__width__Full"
            type="button"
          >
            <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
              Iniciar sesión
            </Typography>
          </Button>
        </div>
        <InputForm
          classNameInput="defaultInput"
          id="Email"
          label="Correo Electrónico"
          name="email"
          size="small"
          value={state.email}
          onChange={e => handleChange(e.target.name, e.target.value)}
        />
        <InputForm
          id="password"
          label="Contraseña"
          name="password"
          size="small"
          type={!showPassword ? "password" : "text"}
          typeInput="password"
          value={state.password}
          onChange={e => handleChange(e.target.name, e.target.value)}
          onClickPassword={() => setShowPassword(!showPassword)}
        />
        <Field>
          <Control>
            <Button
              className="button-forgot"
              type="button"
              onClick={() => openForgotPassword(true)}
            >
              <Typography
                typography="defaultLight"
                variant="fixedSizeSubtitle3"
              >
                Olvidé mi contraseña
              </Typography>
            </Button>
          </Control>
        </Field>
      </div>
      <br />
      <div className="landing-page-buttons">
        <Button
          className="landing-page-button-light button__width__Full"
          type="submit"
        >
          <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
            Continuar
          </Typography>
        </Button>
      </div>
      <div className="content-line">
        <div className="line" />
        <Typography typography="defaultLight" variant="fixedSizeSubtitle1">
          Or
        </Typography>
        <div className="line" />
      </div>
      <div className="landing-page-buttons ">
        <Button
          className="landing-page-google  btn-animate login-buttons"
          type="button"
          onClick={handleGoogle}
        >
          <img
            alt="Logo Google"
            className="landing-page-img"
            src={iconGoogle}
          />
        </Button>
      </div>
    </div>
  );
}

Login.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleGoogle: PropTypes.func.isRequired,
  setLoginSection: PropTypes.func.isRequired,
  loginSection: PropTypes.bool.isRequired,
  openForgotPassword: PropTypes.func.isRequired,
  state: PropTypes.object.isRequired,
};

export default Login;
