import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// External components
import { Box, Grid, Typography } from "@mui/material";
import { useAuth } from "../../context";
import MAIN_ROUTES from "../../components/Sidebar/routes/main";
import configurationIcon from "../../assets/icons/nut_ico.svg";
// SCSS
import "./HomePage.scss";
import { useElectronic } from "../../context/ElectronicContext";

function HomePage() {
  const { LineDetailPlace } = useElectronic();
  const { state: authState, permissions } = useAuth();
  const { isSuperAdminOrHaveAccess } = permissions;
  const [sortedRoutes, setsortedRoutes] = useState(MAIN_ROUTES);

  useEffect(() => {
    const routes = MAIN_ROUTES.filter(r => r.to !== "/notifications");
    const notificationsRoute = MAIN_ROUTES.find(r => r.to === "/notifications");
    setsortedRoutes([...routes, notificationsRoute]);
  }, []);

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className="home-card">
            <Typography
              sx={{
                fontWeight: 700,
              }}
              typography="defaultLight"
              variant="h5"
            >
              Bienvenido(a) {authState?.user?.TavuelUser?.Person?.Name_Person}
            </Typography>
          </div>
        </Grid>
        {sortedRoutes.map(
          r =>
            r.to !== "/profile" &&
            isSuperAdminOrHaveAccess(
              r.to ? r.to : "/electronic-bills",
              LineDetailPlace,
            ) && (
              <Grid item md={3} sm={4} xs={12}>
                <Link
                  className="home-card home-card-item home-card-item-clickable"
                  to={r.to ?? "/electronic-bills"}
                >
                  <Box
                    alt={r.label}
                    component="img"
                    src={r.icon}
                    sx={{
                      width: 65,
                      height: 65,
                      marginX: "auto",
                    }}
                  />
                  <Typography
                    align="center"
                    sx={{
                      width: "100%",
                      fontWeight: 600,
                      color: "#ffff !important",
                    }}
                    typography="defaultLight"
                  >
                    {r.label}
                  </Typography>
                </Link>
              </Grid>
            ),
        )}
        <Grid item md={3} sm={4} xs={12}>
          <Link
            className="home-card home-card-item home-card-item-clickable"
            to="/profile"
          >
            <Box
              alt="Configuración"
              component="img"
              src={configurationIcon}
              sx={{
                width: 65,
                height: 65,
                marginX: "auto",
              }}
            />
            <Typography
              align="center"
              sx={{
                width: "100%",
                fontWeight: 600,
                color: "#ffff !important",
              }}
              typography="defaultLight"
            >
              Configuración
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </Box>
  );
}

export default HomePage;
