/* eslint-disable no-console */
import {
  ApolloClient,
  from,
  HttpLink,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";

import Cookies from "js-cookie";

const { REACT_APP_BOP_API, REACT_APP_EB_API } = process.env;

// this will forward graphql + networking errors to the browser console
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path} 😞`,
        ),
      );
    }
    if (networkError) {
      console.error(`[Network error]: ${networkError} 😞`);
    }
    return forward(operation);
  },
);

const httpLink = new HttpLink({
  uri: REACT_APP_BOP_API,
});

const authLink = new ApolloLink((operation, forward) => {
  const token = Cookies.get("token");
  operation.setContext(({ headers }) => ({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
  }));

  return forward(operation);
});

const apiEB = new HttpLink({
  uri: REACT_APP_EB_API,
});

const authApiEB = new ApolloLink((operation, forward) => {
  const token = Cookies.get("token");
  if (token) {
    operation.setContext({
      headers: {
        authorization: `Bearer ${token}`,
      },
    });
  }
  return forward(operation);
});

export const client_EB_api = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {},
  }),

  link: from([authApiEB, apiEB]),
  name: "EB",
});

const apolloClient = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {},
  }),
  link: ApolloLink.split(
    operation => operation.getContext().clientName !== "apiEB",
    from([errorLink, authLink, httpLink]),
    from([authApiEB, apiEB]),
  ),
});

export default apolloClient;
