import { Button, Control, Field, Input, Title } from "rbx";
import "../../styles/index.scss";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";
import { customToast } from "../../utils";

function ResetPasswordModal() {
  const [state, setstate] = useState({
    NewPassword: "",
    NewPasswordConfirm: "",
  });

  const navigate = useNavigate();

  const { changePassword, handleLogout } = useAuth();

  const handleChange = (name, value) => {
    setstate(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async e => {
    e.preventDefault();
    if (state.NewPassword.length < 8) {
      customToast.error(
        "El tamaño de la contraseña debe ser de mínimo 8 dígitos.",
      );
    } else if (state.NewPassword !== state.NewPasswordConfirm) {
      customToast.error("La contraseña y su confirmación no coinciden");
    } else {
      const change = await changePassword(state.NewPassword);
      if (change) {
        customToast.success("Cambio de contraseña exitoso");
        handleLogout();
        navigate(`/`);
      } else {
        customToast.error("Ocurrió un error al cambiar la contraseña");
      }
    }
  };
  return (
    <form onSubmit={handleSave}>
      <Title aling="center" className="changePassword-title" size={4}>
        Cambio de contraseña
      </Title>

      <Field>
        <Control>
          <Title aling="center" size={5}>
            Digite la nueva contraseña.
          </Title>
          <Input
            required
            name="NewPassword"
            placeholder="Nueva contraseña"
            type="password"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <Field>
        <Control>
          <Input
            required
            name="NewPasswordConfirm"
            placeholder="Confirme la contraseña"
            type="password"
            onChange={e => handleChange(e.target.name, e.target.value)}
          />
        </Control>
      </Field>
      <div className="profile-page-button">
        <Button rounded color="primary" size="large" type="submit">
          Guardar
        </Button>
      </div>
    </form>
  );
}

ResetPasswordModal.propTypes = {};

export default ResetPasswordModal;
