import React from "react";
import PropTypes from "prop-types";
// External components
import { Title, Table, Button } from "rbx";

function ClientsDelete({
  selectedList,
  setModalOpen,
  deleteClientsAndClientsXGroup,
}) {
  const handleConfirm = () => {
    deleteClientsAndClientsXGroup();
    setModalOpen(false);
  };
  return (
    <div>
      <Title className="title-center">
        ¿Desea eliminar de la lista los siguientes clientes?
      </Title>
      <div>
        <Table bordered fullwidth hoverable narrow striped>
          <Table.Head>
            <Table.Row>
              <Table.Heading>
                <abbr>Número de identificación</abbr>
              </Table.Heading>
              <Table.Heading>
                <abbr>Nombre</abbr>
              </Table.Heading>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {selectedList?.map(line => (
              <Table.Row key={line.id}>
                <Table.Heading>{line.ID_Number} </Table.Heading>
                <Table.Cell>{line?.Name}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <div className="container-header">
        <Button
          className="cancel-button"
          type="button"
          onClick={() => {
            setModalOpen(false);
          }}
        >
          Cancelar
        </Button>
        <Button color="primary" type="button" onClick={() => handleConfirm()}>
          Eliminar clientes
        </Button>
      </div>
    </div>
  );
}

ClientsDelete.propTypes = {
  selectedList: PropTypes.array.isRequired,
  setModalOpen: PropTypes.func.isRequired,
  deleteClientsAndClientsXGroup: PropTypes.func.isRequired,
};

export default ClientsDelete;
