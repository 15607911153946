import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
// Graphql
import { client_EB_api } from "../../graphql/client";
import { GET_ALL_PROVINCES } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";
import SelectInput from "../SelectInput/SelectInput";

function ProvinceSelect({ value, name, onChange, label, disabled, className }) {
  const [provinces, setProvinces] = useState([]);

  const getProvinces = async () => {
    const { data } = await client_EB_api.query({
      query: GET_ALL_PROVINCES,
    });
    setProvinces(data.provinces);
  };

  useEffect(() => {
    getProvinces();
  }, []);
  return (
    <SelectInput
      data={provinces}
      disabled={disabled}
      name={name}
      value={value}
      onChange={e => onChange(e.target.name, e.target.value)}
    />
  );
}

ProvinceSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

ProvinceSelect.defaultProps = {
  disabled: false,
  className: "",
};

export default ProvinceSelect;
