import { useApolloClient } from "@apollo/client";
import { useElectronic } from "../context/ElectronicContext";
import { UserErrors } from "../Enums/Errors";
import { CREATE_DEBIT_NOTE, GET_EB_FOR_NOTES } from "../graphql";
import { client_EB_api } from "../graphql/client";

// this is a copy & paste form Creidit notes, we need to define the logic
// that will be used here, this is intended to be a guide for future develop

const validResponse = { message: "", data: "", hasError: false };
const useDebitNote = () => {
  const client = useApolloClient();
  const {
    lineDetail,
    LineDetailPlace,
    electronicBill,
    setElectronicBill,
    setLineDetail,
    setLineDetailPlace,
    setReceiver,
    useCoinDetail,
  } = useElectronic();

  const getEBWithNotes = async id => {
    const {
      data: { bill },
    } = await client.query({
      query: GET_EB_FOR_NOTES,
      variables: {
        id,
      },
    });
    return bill;
  };

  const getValidElectronicBill = async id => {
    const data = await getEBWithNotes(id);
    if (!data) return null;
    if (!data?.ElectronicBillDetail.length === 0) return null;
    const detailsAcc = data.DebitNotes.reduce(
      (acc, { ElectronicBillDetail }) => [...acc, ...ElectronicBillDetail],
      [],
    ).reduce((acc, detail) => {
      const temp = acc?.find(
        x => x.FK_ProductFeature === detail.FK_ProductFeature,
      );
      if (temp) {
        temp.maxQuantity += detail.Quantity;
        temp.accPrice += detail.Price * detail.Quantity;
      } else {
        acc.push({
          FK_ProductFeature: detail.FK_ProductFeature,
          totalQuantity: detail.Quantity,
          accPrice: detail.Price * detail.Quantity,
        });
      }
      return acc;
    }, []);

    return {
      ...data,
      ElectronicBillDetail: data?.ElectronicBillDetail.map(detail => {
        const temp = detailsAcc.find(
          detailAcc => detailAcc.FK_ProductFeature === detail.FK_ProductFeature,
        );

        return {
          ...detail,
          Quantity: 1,
          valid: detail.Price * detail.Quantity - (temp?.accPrice || 0) > 0,
          maxQuantity: detail.Quantity - (temp?.totalQuantity || 0),
        };
      }),
    };
  };

  const saveDebitNote = async noteInfo => {
    const dataToSend = {
      docEB: {
        referenceDocumentId: electronicBill.id,
        reason: noteInfo.reason,
        activityCode: LineDetailPlace.code,
        code: noteInfo.code,
      },
    };
    return client_EB_api.mutate({
      mutation: CREATE_DEBIT_NOTE,
      variables: {
        ...dataToSend,
      },
    });
  };

  const loadDebitNote = async id => {
    const data = await getValidElectronicBill(id);
    if (!data) return;

    const result = data.ElectronicBillDetail.map(item => {
      const {
        ProductFeature: {
          Details,
          Product,
          ProductPrice,
          Files,
          id: productFeature_id,
        },
      } = item;
      return {
        detail_id: item.id,
        id: productFeature_id,
        description: Product?.Description_Product,
        cabys: ProductPrice?.Cabys,
        Tariff: ProductPrice?.Tariff,
        unit_price: ProductPrice?.Price_ProductPrice,
        quantity: item.Quantity,
        discount: [0],
        tax: ProductPrice?.Tax || 0,
        taxes: [],
        totalTaxes: 0,
        total: (ProductPrice?.Price_ProductPrice || 0) * item.Quantity || 0,
        unit_Measure: "",
        product_Code: "",
        url: Files,
        details: Details,
        value: item.id,
        label: Product?.Description_Product,
        valid: item.valid,
        maxQuantity: item.maxQuantity,
      };
    });

    const place =
      data.ElectronicBillDetail[0].ProductFeature.InventoryDetail.Inventory
        .Cellar.Place;
    setLineDetailPlace({
      id: place.id,
      Name_Place: place.Name_Place,
      Company: { id: place.Company.id },
    });
    // this might need a fix
    data?.User?.id &&
      setReceiver({
        id: data?.bill?.User?.id,
        Receiver_Id: data?.bill?.User?.UserBillingProfile.ID_Number,
        Receiver_Email: data?.bill?.User?.UserBillingProfile.Email,
        Receiver_Name: data?.bill?.User?.UserBillingProfile.Name,
        Receiver_PhoneNumber: data?.bill?.User?.UserBillingProfile.PhoneNumber,
      });
    setLineDetail(result);
    setElectronicBill({
      id: data.id,
      Sell_Condition: data.SellCondition,
      Coin: data.Coin.id,
      Payment_Method: data.PaymentMethod,
      OtherPaymentMethod: data.Payment_Detail,
      OtherSellCondition: data.SellCondition_Detail,
      Key: data.Key,
    });
    useCoinDetail.handleCoinChange(data.Coin.id);
  };

  const atLeastOneChecked = () => ({
    hasError: lineDetail.filter(x => x.checked).length === 0,
    message: UserErrors.EMPTY_CHECKED_PRODUCTS,
  });
  const saveDebitNoteValidations = [atLeastOneChecked];

  const canSaveDN = () => {
    const validationFailed = saveDebitNoteValidations
      .map(val => val())
      .find(val => val.hasError);
    return validationFailed || { ...validResponse };
  };

  return {
    getValidElectronicBill,
    saveDebitNote,
    loadDebitNote,
    canSaveDN,
  };
};

export default useDebitNote;
