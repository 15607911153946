import ZohoIcon from "../assets/zohoIcon.png";
import gmailIcon from "../assets/gmail.svg";
import outlookIcon from "../assets/transparent-outlook-icon-2.png";

const getImageEmail = type => {
  if (type === "1") return ZohoIcon;
  if (type === "2") return gmailIcon;
  if (type === "3") return outlookIcon;
  return null;
};

export default getImageEmail;
