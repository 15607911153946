/* eslint-disable react/jsx-props-no-spreading */

import React from "react";
import PropTypes from "prop-types";
// External components
import {
  Checkbox,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
  Select,
  Button,
  Table,
  Card,
  MenuItem,
  Tooltip,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useTable, usePagination } from "react-table";
// Components
// SCSS
import "./Paginated.scss";

function Paginated({
  columns,
  data,
  selectables,
  selectedRows,
  setSelectedRows,
  selectField,
  getExtraComponent,
  maxWidth,
}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state,
    pageSize,
    setPageSize,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    usePagination,
  );

  const { pageIndex } = state;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <TableContainer
        color="primary"
        sx={{
          background: "transparent",
          paddingX: { xs: "0.2rem", sm: "2rem" },
          maxWidth: { maxWidth },
        }}
      >
        <Table {...getTableProps()}>
          <TableHead>
            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {selectables && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={data.every(row =>
                        selectedRows.includes(row[selectField]),
                      )}
                      onChange={() =>
                        setSelectedRows(
                          selectedRows.length === 0
                            ? data.map(val => val[selectField])
                            : [],
                        )
                      }
                    />
                  </TableCell>
                )}
                {headerGroup.headers.map((column, j) => (
                  <TableCell
                    {...column.getHeaderProps()}
                    color="primary"
                    style={{ width: column.width }}
                    sx={{ fontWeight: 600 }}
                  >
                    {column.render("Header")}
                  </TableCell>
                ))}
                {getExtraComponent && (
                  <TableCell color="primary" style={{ width: 30 }} />
                )}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {selectables && (
                    <TableCell color="primary" padding="checkbox">
                      <Checkbox
                        checked={selectedRows.includes(
                          row.original[selectField],
                        )}
                        onChange={() =>
                          setSelectedRows([row.original[selectField]])
                        }
                      />
                    </TableCell>
                  )}
                  {row.cells.map(cell => (
                    <TableCell
                      color="primary"
                      {...cell.getCellProps()}
                      sx={{
                        maxWidth: { xs: "0.5rem", sm: "1rem" },
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Tooltip
                        title={
                          cell.render("Cell").props.cell.value || "Sin datos"
                        }
                      >
                        <span>
                          {cell.render("Cell").props.cell.value !== undefined &&
                          cell.render("Cell").props.cell.value !== null
                            ? cell.render("Cell")
                            : "Sin datos"}
                        </span>
                      </Tooltip>
                    </TableCell>
                  ))}
                  {getExtraComponent && (
                    <TableCell color="primary">
                      {getExtraComponent(row)}
                    </TableCell>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          gap: "1rem",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "706px",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            gap: "1rem",
          }}
        >
          <Button
            color="secondary"
            disabled={!canPreviousPage}
            sx={{
              height: "2.188rem",
              width: "2.188rem",
              minWidth: "2.188rem",
              borderRadius: 0,
            }}
            variant="contained"
            onClick={() => previousPage()}
          >
            <ChevronLeftIcon fontSize="medium" />
          </Button>
          {pageOptions.map(option => (
            <Card
              color="tertiaryOnly"
              sx={{
                height: "2.188rem",
                width: "2.188rem",
                minWidth: "2.188rem",
                borderRadius: 0,
                background:
                  option + 1 === pageIndex + 1 && "rgba(191, 246, 244, 1)",
              }}
              variant="outlined"
            >
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",

                  color: option + 1 === pageIndex + 1 && "rgba(3, 7, 16, 1)",
                }}
                typography="modeColor"
                variant="subtitle1"
              >
                {option + 1}
              </Typography>
            </Card>
          ))}

          <Button
            color="secondary"
            disabled={!canNextPage}
            sx={{
              height: "2.188rem",
              width: "2.188rem",
              minWidth: "2.188rem",
              borderRadius: 0,
            }}
            variant="contained"
            onClick={() => nextPage()}
          >
            <ChevronRightIcon fontSize="medium" />
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: "1rem",
          }}
        >
          <Typography typography="modeColor" variant="subtitle1">
            Página {pageIndex + 1} de {pageOptions.length}
          </Typography>

          <Select
            color="tertiaryOnly"
            defaultValue={10}
            sx={{
              "width": "8.5rem",
              "height": "2.188rem",
              "& .MuiSelect-select": {
                padding: "0px",
                paddingLeft: "32px",
                display: "flex",
                justifyContent: "center",
              },
            }}
            value={pageSize}
            variant="filled"
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[10, 25, 50].map(size => (
              <MenuItem key={size} value={size}>
                Mostrar {size}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </Box>
  );
}

Paginated.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  selectables: PropTypes.bool,
  selectedRows: PropTypes.array,
  setSelectedRows: PropTypes.func,
  selectField: PropTypes.string,
  getExtraComponent: PropTypes.func,
  maxWidth: PropTypes.string,
};
Paginated.defaultProps = {
  selectables: false,
  selectedRows: [],
  setSelectedRows: e => null,
  selectField: "",
  getExtraComponent: null,
  maxWidth: "706px",
};

export default Paginated;
