import React from "react";
import PropTypes from "prop-types";
// External components
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_COINS } from "../../graphql";
// SCSS
import "../CountrySelect/CountrySelect.scss";

function CoinSelect({
  label,
  labelId,
  color,
  disabled,
  name,
  value,
  onChange,
  onFocus,
  size,
}) {
  const { data: getCoins } = useQuery(GET_ALL_COINS);

  return (
    <FormControl fullWidth sx={{ minWidth: 120 }} variant="filled">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        color={color}
        disabled={disabled}
        label={label}
        labelId={labelId}
        name={name}
        size={size}
        value={value}
        variant="filled"
        onChange={onChange}
        onFocus={onFocus}
      >
        {getCoins?.coins
          ?.filter(index => index.id !== 4 && index.id !== "4")
          .map(option => (
            <MenuItem key={option.id} value={option.id}>
              {option.Name_Coin}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
}

CoinSelect.propTypes = {
  label: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  color: PropTypes.oneOf(["primary", "secondary"]),
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,

  size: PropTypes.string,
};

CoinSelect.defaultProps = {
  color: "primary",
  disabled: false,
  size: "",
  onFocus: () => {},
};

export default CoinSelect;
