import { useQuery } from "@apollo/client";
import { GET_PERMISSIONS_BY_USER_ID_AND_PLACE } from "../graphql";

function usePermissions(userId = 0, placeId = 0) {
  const {
    data: permissionsByUserAndPlaceData,
    refetch: refetchPermissionsByUserAndPlace,
  } = useQuery(GET_PERMISSIONS_BY_USER_ID_AND_PLACE, {
    variables: {
      FK_User: userId,
      FK_Place: placeId,
    },
  });

  const isSuperAdmin =
    !!permissionsByUserAndPlaceData?.getPermissionsByUserIdAndPlace.find(
      p => p.Role?.Name_Role === "Superadmin",
    );

  const isSuperAdminOrHaveAccess = (route, place) => {
    if (route === "/profile" || route === "/notifications") return true;
    const queryParams = window.location?.pathname;
    const isResetPassword =
      queryParams.includes("/resetPassword") && route === "/security";
    const haveAccess =
      permissionsByUserAndPlaceData?.getPermissionsByUserIdAndPlace.find(p => {
        const permissions = p.Permissions;
        return permissions?.find(per =>
          isResetPassword
            ? true
            : per.Submodule.Route_Submodule.includes(route),
        );
      });
    return !!(isSuperAdmin || haveAccess);
  };

  const haveActionPermission = (action, route, place) => {
    const havePermission =
      permissionsByUserAndPlaceData?.getPermissionsByUserIdAndPlace.find(p => {
        const permissions = p.Permissions;
        const pp = permissions?.find(per => {
          const actionName = per.ActionName;
          return (
            (actionName === "All" || actionName === action) &&
            per.Submodule.Route_Submodule.includes(route)
          );
        });
        return pp;
      });
    return !!(isSuperAdmin || havePermission);
  };

  const noAccessMessage = (action, module) =>
    `El usuario no tiene permiso de "${action}" ${module}. Consulte con el Administrador`;

  return {
    isSuperAdminOrHaveAccess,
    isSuperAdmin,
    haveActionPermission,
    noAccessMessage,
    refetchPermissionsByUserAndPlace,
  };
}

export default usePermissions;
