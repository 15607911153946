/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const CREATE_USER_ROLE = gql`
  mutation createUserRole($NewInfo: UserRoleInput) {
    create: createUserRole(NewInfo: $NewInfo) {
      id
    }
  }
`;

export const UPDATE_USER_ROLE = gql`
  mutation updateUserRole($id: ID, $NewInfo: UserRoleInput) {
    updated: updateUserRole(id: $id, NewInfo: $NewInfo) {
      id
    }
  }
`;

export const DElETE_USER_ROLE = gql`
  mutation deleteUserRole($place: ID, $user: ID) {
    delete: deleteUserRole(place: $place, user: $user)
  }
`;

export const DELETE_USER_ROLE_BY_PLACE_AND_ROLE = gql`
  mutation deleteUserRoleByPlaceAndRole($user: ID, $role: ID) {
    delete: deleteUserRoleByPlaceAndRole(user: $user, role: $role)
  }
`;
