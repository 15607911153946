/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_NEIGHBORHOODS = gql`
  query getAllNeighborhoods($FK_District: ID!) {
    neighborhoods: getAllNeighborhoods(FK_District: $FK_District) {
      id
      Name
      id_MH
    }
  }
`;
