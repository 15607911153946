import { useQuery } from "@apollo/client";
import { GET_ALL_PLACES_BY_USER } from "../graphql";

function usePlaces() {
  const GetPLacesByUserIdAndModuleId = userId => {
    const {
      loading,
      data: dataPlaces,
      error,
      refetch,
    } = useQuery(GET_ALL_PLACES_BY_USER, {
      variables: {
        FK_User: userId,
        FK_Module: 11,
      },
    });
    return [loading, dataPlaces, error, refetch];
  };
  return { GetPLacesByUserIdAndModuleId };
}

export default usePlaces;
