import incomesIcon from "../../../assets/icons/hand-and-money_ico.svg";
import expensesIcon from "../../../assets/icons/into-stack_ico.svg";
import invoiceIcon from "../../../assets/icons/piece-of-paper_ico.svg";
import reportsIcon from "../../../assets/icons/clipboard_ico.svg";
import placesIcon from "../../../assets/icons/market-local_ico.svg";
import productsIcon from "../../../assets/icons/packages_ico.svg";
import configIcon from "../../../assets/icons/config.svg";
import clientsIcon from "../../../assets/icons/users_ico.svg";
import securityIcon from "../../../assets/icons/padlock_ico.svg";
import notificationsIcon from "../../../assets/icons/bell_ico.svg";

const MAIN_ROUTES = [
  {
    name: "incomes",
    label: "Ingresos",
    icon: incomesIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /incomes/.test(p);
    },
    to: "/incomes",
  },
  {
    name: "expenses",
    label: "Egresos",
    icon: expensesIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /expenses/.test(p);
    },
    to: "/expenses",
  },
  {
    name: "Facturación",
    icon: invoiceIcon,
    label: "Factura electrónica",
    active: location => {
      const [, p] = location.pathname.split("/");
      return /electronic-bills/.test(p);
    },
    links: [
      { name: "Factura Electrónica", to: "/electronic-bills" },
      { name: "Nota de Crédito/Débito", to: "/CreditDebitNote" },
      { name: "Factura Electrónica de Compra", to: "/buy-electronic-bills" },
      { name: "Factura Electrónica de Exportación", to: "/exportation-bill" },
      { name: "Proforma Electrónica", to: "/Proforma" },
      { name: "Buscar Documento", to: "/DocumentSearch" },
      { name: "Facturas Programadas", to: "/ScheduledBill/pendings" },
    ],
  },
  {
    name: "reports",
    label: "Reportes",
    icon: reportsIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /reports/.test(p);
    },
    to: "/reports",
  },
  {
    name: "places",
    label: "Lugares",
    icon: placesIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /places/.test(p);
    },
    to: "/places",
  },
  {
    name: "products",
    label: "Productos/Servicios",
    icon: productsIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /product/.test(p);
    },
    to: "/products",
  },
  {
    name: "clients",
    label: "Clientes",
    icon: clientsIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /clients/.test(p);
    },
    to: "/clients",
  },
  {
    name: "security",
    label: "Seguridad",
    icon: securityIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /security/.test(p);
    },
    to: "/security",
  },
  {
    name: "notifications",
    label: "Notificaciones",
    icon: notificationsIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /notifications/.test(p);
    },
    to: "/notifications",
  },
  {
    name: "profile",
    label: "Configuración",
    icon: configIcon,
    active: location => {
      const [, p] = location.pathname.split("/");
      return /profile/.test(p);
    },
    to: "/profile",
  },
];

export default MAIN_ROUTES;
