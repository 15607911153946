import React from "react";
import PropTypes from "prop-types";
// SCSS
import "./Divider.scss";

function Divider({ type }) {
  return <div className={`divider divider--${type}`} />;
}

Divider.propTypes = {
  type: PropTypes.oneOf(["transparent", "shadow"]),
};
Divider.defaultProps = { type: "" };

export default Divider;
