import React from "react";
import PropTypes from "prop-types";
// External components
import { useQuery } from "@apollo/client";
import { Box, Button } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
// SCSS
import "./DocumentSearchForm.scss";
import "react-datepicker/dist/react-datepicker.css";
import { DOCUMENT_SEARCH_MODULES } from "../../Enums/ElectronicBill";
import InputForm from "../InputForm/InputForm";
import SelectForm from "../SelectForm/SelectForm";
import { formatData } from "../../utils/helpers";
import { GET_ALL_DOCUMENT_TYPES } from "../../graphql";

function DocumentSearchForm({ documentForm, toggleState, onChange, onSearch }) {
  const handleChange = (name, value) => {
    onChange(prev => ({ ...prev, [name]: value }));
  };
  const { data } = useQuery(GET_ALL_DOCUMENT_TYPES);

  return (
    <Box className="content-flex-column">
      <Box
        sx={{
          display: "grid",
          gap: "1rem",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm:
              toggleState === DOCUMENT_SEARCH_MODULES.BILLS
                ? "repeat(4, 1fr)"
                : "repeat(3, 1fr)",
          },
        }}
      >
        <InputForm
          color="primary"
          id="User_Name"
          label="Nombre del usuario"
          name="User_Name"
          size="small"
          type="text"
          value={documentForm?.User_Name}
          onChange={({ target: { value, name } }) => handleChange(name, value)}
        />
        <InputForm
          color="primary"
          id="User_ID"
          label="Cédula del usuario"
          name="User_ID"
          size="small"
          type="text"
          value={documentForm?.User_ID}
          onChange={({ target: { value, name } }) => handleChange(name, value)}
        />
        <div
          style={{
            display:
              toggleState === DOCUMENT_SEARCH_MODULES.BILLS ? "" : "none",
          }}
        >
          <DatePicker
            color="primary"
            label="Mes y año de emisión"
            size="small"
            sx={{
              "width": "100%",
              "& .MuiFormLabel-root": {
                transform: " translate(14px, 5px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                padding: "24px 14px 10px 14px ",
              },
            }}
            value={documentForm?.EmitedDay}
            variant="filled"
            views={["month", "year"]}
            onChange={date => handleChange("EmitedDay", date)}
          />
        </div>
        <Box
          sx={{
            display:
              toggleState === DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES
                ? ""
                : "none",
          }}
        >
          <SelectForm
            key="Document_State"
            color="secondary"
            data={formatData(
              [
                { id: 1, label: "Aceptado" },
                { id: 2, label: "Aceptado parcialmente" },
                { id: 3, label: "Rechazado" },
                { id: null, label: "Cualquiera" },
              ],
              "label",
            )}
            label="Estado del Documento"
            name="Document_State"
            size="small"
            value={documentForm?.Document_State}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
          />
        </Box>
        <Box
          sx={{
            display:
              toggleState === DOCUMENT_SEARCH_MODULES.BILLS ? "flex" : "none",
            justifyContent: { xs: "end", sm: "start" },
          }}
        >
          <Button
            color="secondary"
            size="medium"
            sx={{ height: { xs: "45px", sm: "48px" }, width: "95px" }}
            variant="contained"
            onClick={onSearch}
          >
            Buscar
          </Button>
        </Box>
        <div
          style={{
            display:
              toggleState === DOCUMENT_SEARCH_MODULES.BILLS ||
              toggleState === DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES
                ? "none"
                : "",
          }}
        >
          <SelectForm
            key="Document_Type"
            color="secondary"
            data={formatData(data?.documentTypes, "Description")}
            label="Tipo de documentos"
            name="Document_Type"
            size="small"
            value={documentForm?.Document_Type}
            variant="filled"
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
          />
        </div>
      </Box>
      <Box
        sx={{
          display:
            toggleState === DOCUMENT_SEARCH_MODULES.BILLS ? "none" : "grid",
          gap: "1rem",
          gridTemplateColumns: {
            xs: "repeat(1, 1fr)",
            sm: "repeat(3, 1fr)",
          },
        }}
      >
        <Box
          sx={{
            display:
              toggleState === DOCUMENT_SEARCH_MODULES.ELECTRONIC_BILLS_EXPENSES
                ? "none"
                : "grid",
          }}
        >
          <SelectForm
            key="Document_State"
            color="secondary"
            data={formatData(
              [
                { id: 1, label: "Aceptado" },
                { id: 2, label: "Aceptado parcialmente" },
                { id: 3, label: "Rechazado" },
                { id: null, label: "Cualquiera" },
              ],
              "label",
            )}
            label="Estado del Documento"
            name="Document_State"
            size="small"
            value={documentForm?.Document_State}
            onChange={event =>
              handleChange(event.target.name, event.target.value)
            }
          />
        </Box>
        <DatePicker
          color="primary"
          label="Mes y año de emisión"
          size="small"
          sx={{
            "width": "100%",
            "& .MuiFormLabel-root": {
              transform: " translate(14px, 5px) scale(0.75)",
            },
            "& .MuiInputBase-input": {
              padding: "24px 14px 10px 14px ",
            },
          }}
          value={documentForm?.EmitedDay}
          variant="filled"
          views={["month", "year"]}
          onChange={date => handleChange("EmitedDay", date)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "end", sm: "start" },
          }}
        >
          <Button
            color="secondary"
            size="medium"
            sx={{ height: { xs: "45px", sm: "48px" }, width: "95px" }}
            type="button"
            variant="contained"
            onClick={onSearch}
          >
            Buscar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

DocumentSearchForm.propTypes = {
  documentForm: PropTypes.object.isRequired,
  toggleState: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

DocumentSearchForm.defaultProps = {
  toggleState: 0,
};

export default DocumentSearchForm;
