import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faHome,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileUpload,
  faFileDownload,
  faSignOutAlt,
  faFolderPlus,
  faBars,
  faCalculator,
  faTimes,
  faCogs,
  faCashRegister,
  faSearch,
  faTrashAlt,
  faEdit,
  faArrowLeft,
  faArrowRight,
  faPlus,
  faMinus,
  faTimesCircle,
  faUserPlus,
  faCoins,
  faShoppingBasket,
  faInfoCircle,
  faStore,
  faUser,
  faBuilding,
  faStar,
  faCreditCard,
  faCamera,
  faAddressBook,
  faUsers,
  faDownload,
  faEye,
  faBackspace,
  faMinusCircle,
  faEnvelope,
  faWindowClose,
  faLock,
  faCog,
  faEyeSlash,
  faBell,
  faHouseDamage,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import {
  faStar as farStart,
  faHandshake,
  faCalendar,
} from "@fortawesome/free-regular-svg-icons";

library.add(
  faHome,
  faFileInvoiceDollar,
  faFileInvoice,
  faFileUpload,
  faFileDownload,
  faSignOutAlt,
  faFolderPlus,
  faBars,
  faCalculator,
  faTimes,
  faCogs,
  faSearch,
  faCashRegister,
  faTrashAlt,
  faEdit,
  faArrowLeft,
  faArrowRight,
  faArrowRight,
  faPlus,
  faMinus,
  faTimesCircle,
  faUserPlus,
  faCoins,
  faShoppingBasket,
  faInfoCircle,
  faStore,
  faUser,
  faBuilding,
  faStar,
  farStart,
  faCreditCard,
  faHandshake,
  faCamera,
  faAddressBook,
  faUsers,
  faDownload,
  faEye,
  faBackspace,
  faMinusCircle,
  faEnvelope,
  faWindowClose,
  faLock,
  faCog,
  faEyeSlash,
  faBell,
  faCalendar,
  faHouseDamage,
  faPen,
);
