import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// External components
import { toast } from "react-toastify";
import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { useTheme } from "@emotion/react";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ALL_COMPANIES } from "../../graphql";
// Context
import { useAuth } from "../../context";
// Components
import PlacesUpdate from "../PlacesUpdate";
import CompanyRegister from "../CompanyRegister/CompanyRegister";
import SearchInput from "../Products/components/SearchInput";
// Utils
import { validateRoute } from "../../utils/helpers";
// Hooks
import usePlaces from "../../hooks/usePlaces";
// SCSS
import "../Inventories/Inventories.scss";
import "./PlacesList.scss";
import { useElectronic } from "../../context/ElectronicContext";

function PlacesList() {
  const [placeSelected, setPlaceSelected] = useState();
  const [companySelected, setCompanySelected] = useState();
  const [company, setCompany] = useState();
  const [addNewPlace, setAddNewPlace] = useState(false);
  const [places, setPlaces] = useState(false);
  const [searchValue, setSearchValue] = useState(undefined);
  const { state, permissions } = useAuth();
  const user = state.user.TavuelUser;
  const { GetPLacesByUserIdAndModuleId } = usePlaces();
  const navigate = useNavigate();
  const { LineDetailPlace } = useElectronic();
  const theme = useTheme();

  const { isSuperAdmin, haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/places", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Lugares"));
    navigate(`/`);
  }

  const [loading, dataPlaces, error, refetch] = GetPLacesByUserIdAndModuleId(
    user?.id,
  );

  const { data: getCompanyData, refetch: refetchCompany } = useQuery(
    GET_ALL_COMPANIES,
    {
      variables: {
        id: user?.id,
      },
    },
  );

  if (loading) <h1>Cargando...</h1>;
  if (error) <h1>Error</h1>;

  useEffect(() => {
    setCompany(getCompanyData?.companies[0]);
    if (getCompanyData?.companies.length === 0) {
      setCompany(dataPlaces?.places[0].Company);
    }
    setPlaces(dataPlaces?.places || []);
  }, [dataPlaces?.places, getCompanyData, getCompanyData?.companies]);

  const handleSelectPlace = place => {
    if (!haveActionPermission("Edit", "/places", LineDetailPlace)) return;
    navigate(`update-place/${place.id}`);
  };
  const handleSelectCompany = comp => {
    if (!isSuperAdmin) return;
    setCompanySelected(comp);
  };

  const handleAddPlace = () => {
    navigate("new-place");
    setAddNewPlace(true);
  };

  const handleSearch = value => {
    setSearchValue(value);
    setPlaces(
      dataPlaces?.places?.filter(e =>
        e.Name_Place.toLowerCase().trim().includes(value.trim()),
      ),
    );
  };

  return (
    (!placeSelected && !companySelected && !addNewPlace && (
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item sm={5} xs={12}>
              <SearchInput
                className="full-width-input-search"
                label="Buscar lugares"
                value={searchValue}
                onChange={({ target: { value } }) => handleSearch(value)}
              />
            </Grid>

            {haveActionPermission("Create", "/places", LineDetailPlace) && (
              <Grid
                item
                sm={7}
                sx={{
                  paddingLeft: { xs: 0, sm: 3 },
                  paddingTop: { xs: 3, sm: 0 },
                  display: "flex",
                  justifyContent: { xs: "flex-end", sm: "flex-start" },
                }}
                xs={12}
              >
                <Button
                  className={`places-button${theme.palette.mode === "dark" ? " dark-mode" : ""}`}
                  sx={{ height: "100%", minHeight: 50 }}
                  variant="contained"
                  onClick={handleAddPlace}
                >
                  <Typography typography="defaultDark" variant="subtitle1">
                    Registrar nuevo lugar
                  </Typography>
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item lg={5} md={7} paddingTop={3} sm={9} xs={12}>
          <Button
            className={`company ${theme.palette.mode === "dark" ? "dark-mode" : ""}`}
            sx={{
              width: "100%",
              borderWidth: 1,
              borderStyle: "solid",
              justifyContent: "start",
              borderRadius: 2,
              padding: 3,
            }}
            onClick={() => handleSelectCompany(company)}
          >
            <Grid container margin={0}>
              <Grid item sm={3} xs={5}>
                <Box
                  component="img"
                  src={validateRoute(company)}
                  sx={{ aspectRatio: "square", width: 111, objectFit: "cover" }}
                />
              </Grid>
              <Grid item paddingLeft={6} sm={9} xs={7}>
                <Stack spacing={2} width="100%">
                  <Typography
                    noWrap
                    fontWeight="bold"
                    textAlign="start"
                    typography="modeColor"
                    variant="h6"
                  >
                    {company?.Name_Company}
                  </Typography>
                  <Typography noWrap textAlign="start" typography="modeColor">
                    <b>Descripción: </b>
                    {company?.Description_Company}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Button>
        </Grid>

        <Grid item paddingTop={3} xs={12}>
          <Grid container spacing={3}>
            {places.length > 0 &&
              places?.map(place => (
                <Grid item lg={3} md={4} sm={6} xs={12}>
                  <Button
                    key={place.id}
                    className={`place-card ${theme.palette.mode === "dark" ? "dark-mode" : ""}`}
                    sx={{
                      justifyContent: "start",
                      borderRadius: 2,
                      height: "100%",
                      width: "100%",
                      padding: 3,
                    }}
                    onClick={() => handleSelectPlace(place)}
                  >
                    <Grid container>
                      <Grid item xs={12}>
                        <Stack alignItems="end" direction="row" spacing={3}>
                          <Box
                            component="img"
                            src={validateRoute(place)}
                            sx={{ aspectRatio: "square", width: 41 }}
                          />
                          <Typography
                            fontWeight={600}
                            paddingY={0.5}
                            typography="modeColor"
                          >
                            {place.Name_Place}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid item paddingTop={2} xs={4}>
                        <Typography
                          noWrap
                          fontSize={14}
                          fontWeight={600}
                          textAlign="start"
                          typography="modeColor"
                        >
                          Descripción:
                        </Typography>
                      </Grid>
                      <Grid item paddingLeft={3} paddingTop={2} xs={8}>
                        <Typography
                          noWrap
                          fontSize={14}
                          textAlign="start"
                          typography="modeColor"
                        >
                          {place.Description_Place}
                        </Typography>
                      </Grid>

                      <Grid item paddingTop={2} xs={4}>
                        <Typography
                          noWrap
                          fontSize={14}
                          fontWeight={600}
                          textAlign="start"
                          typography="modeColor"
                        >
                          Código
                        </Typography>
                      </Grid>
                      <Grid item paddingLeft={3} paddingTop={2} xs={8}>
                        <Typography
                          noWrap
                          fontSize={14}
                          textAlign="start"
                          typography="modeColor"
                        >
                          {place.Code}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
              ))}
            {places.length === 0 && (
              <Grid item xs={12}>
                <Typography align="left" typography="modeColor">
                  ¡No se han encontado lugares registrados!
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )) ||
    (placeSelected && (
      <PlacesUpdate
        place={placeSelected}
        refetch={refetch}
        setPlaceSelected={setPlaceSelected}
      />
    )) ||
    (companySelected && (
      <CompanyRegister
        company={company}
        refetchCompany={refetchCompany}
        setCompanySelected={setCompanySelected}
      />
    ))
  );
}

PlacesList.propTypes = {};

PlacesList.defaultProps = {};

export default PlacesList;
