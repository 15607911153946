import React from "react";
import { Routes, Route } from "react-router-dom";
import ClientList from "./components/ClientsList";
import ClientsMaintenance from "./ClientsMaintenance";

function ClientsMaintenanceRouter(props) {
  return (
    <Routes>
      <Route
        element={<ClientList />}
        path="/clients-maintenance/clients-list/:placeId/:placeName/:companyId"
      />
      <Route element={<ClientsMaintenance />} path="/clients-maintenance" />
    </Routes>
  );
}

export default ClientsMaintenanceRouter;
