/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_ALL_OTHERS_CHARGES_DOCUMENT_TYPE = gql`
  query getAllOthersChargesDocumentType {
    DocumentType: getAllOthersChargesDocumentType {
      id
      Name
      Code
    }
  }
`;
