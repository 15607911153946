/* eslint-disable import/prefer-default-export */
import { gql } from "@apollo/client";

export const GET_EMAILS_WITH_ATTACHED = gql`
  query getEmailsWithAttached(
    $idEmail: Int
    $fromDate: DateTime
    $toDate: DateTime
    $limit: Int
    $start: Int
    $nextPage: String
    $type: Int
  ) {
    data: getEmailsWithAttached(
      idEmail: $idEmail
      fromDate: $fromDate
      toDate: $toDate
      limit: $limit
      start: $start
      nextPage: $nextPage
      type: $type
    ) {
      files {
        content
      }
    }
  }
`;

export const GET_EMAILS_BY_GOOGLE_AUTH = gql`
  query getEmailsByGoogleAuth($googleAuthId: ID) {
    data: getEmailsByGoogleAuth(googleAuthId: $googleAuthId) {
      id
      email
      type
      place {
        id
        Code
        Name_Place
        Logo
      }
    }
  }
`;
