import React from "react";
import { Button, Card, Typography } from "@mui/material";
import { useQuery, useMutation, useLazyQuery } from "@apollo/client";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CREATE_NEW_CLIENT,
  DELETE_BILLING_CLIENT,
  GET_ALL_CLIENTS_BY_GROUP,
  GET_ALL_GROUPS_BY_PLACE,
} from "../../../../graphql";
import SelectProfile from "../SelectProfile";
import { customToast as toast } from "../../../../utils";
import { useAuth } from "../../../../context";
import ClientCard from "./ClientCard";
import { useElectronic } from "../../../../context/ElectronicContext";
import clientIcon from "../../../../assets/icons/client_icon.svg";
import "./ClientReceiver.scss";

function ClientReceiver() {
  const { state: authState } = useAuth();
  const { LineDetailPlace, clientGroup, setClientGroup } = useElectronic();
  const [registerClient] = useMutation(CREATE_NEW_CLIENT);
  const [deleteBillingClient] = useMutation(DELETE_BILLING_CLIENT);

  const { data: dataClientsGroups } = useQuery(GET_ALL_GROUPS_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const [getClientsByIdGroup, { data: clientsByIdGroup, loading, error }] =
    useLazyQuery(GET_ALL_CLIENTS_BY_GROUP);

  const getClients = async id => {
    getClientsByIdGroup({
      variables: {
        FK_ClientGroup: id,
      },
    });
  };

  const handleSaveNewClient = async idUser => {
    try {
      const result = await registerClient({
        variables: {
          data: {
            Created_By: authState.user.TavuelUser.id,
            FK_User: idUser,
            FK_Company: LineDetailPlace.Company.id,
          },
        },
        refetchQueries: [
          {
            query: GET_ALL_CLIENTS_BY_GROUP,
            variables: {
              FK_ClientGroup: clientGroup?.id,
            },
          },
        ],
      });
      if (result) {
        toast.success("¡Cliente agregado al grupo con éxito!");
      }
    } catch (err) {
      toast.error("Error al agregar el cliente");
    }
  };

  const deleteFavoriteClient = async idUser => {
    try {
      const result = await deleteBillingClient({
        variables: {
          FK_User: idUser,
          FK_Company: LineDetailPlace?.Company?.id,
        },
        refetchQueries: [
          {
            query: GET_ALL_CLIENTS_BY_GROUP,
            variables: {
              FK_ClientGroup: clientGroup?.id,
            },
          },
        ],
      });
      if (result) {
        toast.success("¡Cliente favorito eliminado!");
      }
    } catch (err) {
      toast.error("Error al eliminar el cliente");
    }
  };

  const handleSelectGroup = groupItem => {
    setClientGroup(groupItem);
    getClients(groupItem?.id);
  };

  return (
    <div className="content-flex-column">
      <SelectProfile
        clientList={clientsByIdGroup?.clients}
        deleteFavoriteClient={deleteFavoriteClient}
        handleSaveNewClient={handleSaveNewClient}
      />
      {clientGroup.id !== 0 && clientsByIdGroup?.clients ? (
        <div className="content-flex-column">
          <div className="content-flex-row-start">
            <Button
              className="button"
              color="primary"
              size="medium"
              onClick={() => setClientGroup({ id: 0 })}
            >
              <FontAwesomeIcon icon="arrow-left" />
            </Button>
            <Typography align="start">
              Clientes de {clientGroup.Name_ClientGroup}
            </Typography>
          </div>
          {clientsByIdGroup?.clients?.length !== 0 && !loading && !error ? (
            <ClientCard clientList={clientsByIdGroup?.clients} />
          ) : (
            <Typography align="center">
              {clientGroup.Name_ClientGroup} no tiene clientes registrados.
            </Typography>
          )}
        </div>
      ) : (
        <div className="content-flex-column">
          {dataClientsGroups?.groups?.length > 0 ? (
            <div className="content-flex-cards">
              {Array.isArray(dataClientsGroups?.groups) &&
                dataClientsGroups?.groups?.length > 0 &&
                dataClientsGroups?.groups?.map(groupItem => (
                  <Card
                    color="primary"
                    sx={{
                      width: "20.813rem",
                      height: "6.938rem",
                      paddingY: "1rem",
                      paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      cursor: "pointer",
                    }}
                    onClick={() => handleSelectGroup(groupItem)}
                  >
                    <div
                      className="content-flex-row-start"
                      role="button"
                      tabIndex={0}
                      onKeyDown={() => null}
                    >
                      <img
                        alt="icono cliente"
                        className="icon-color icon-client"
                        src={clientIcon}
                      />
                      <Typography align="center">
                        {groupItem.Name_ClientGroup}
                      </Typography>
                    </div>
                  </Card>
                ))}
            </div>
          ) : (
            <Typography align="center">
              Aún no hay grupos registrados para {LineDetailPlace.Name_Place}.
            </Typography>
          )}
        </div>
      )}
    </div>
  );
}

ClientReceiver.propTypes = {};

export default ClientReceiver;
