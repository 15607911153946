import React from "react";
import { useNavigate } from "react-router-dom";
// External components
import { Box, Card, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
// Graphql
import { useQuery } from "@apollo/client";
import { GET_ROLES_BY_PLACE } from "../../../graphql/role/role.queries";
// Context
import { useAuth, useModal } from "../../../context";
import { useElectronic } from "../../../context/ElectronicContext";
// Components
import NewOrEditRole from "./NewOrEditRole/NewOrEditRole";

function Roles() {
  const { setModalOpen } = useModal();
  const { LineDetailPlace } = useElectronic();
  const { data: rolesByPlaceData, refetch } = useQuery(GET_ROLES_BY_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  const { permissions } = useAuth();
  const navigate = useNavigate();

  const { haveActionPermission, noAccessMessage } = permissions;

  const canRead = haveActionPermission("Read", "/security", LineDetailPlace);
  if (!canRead) {
    toast.warning(noAccessMessage("Leer", "Roles"));
    navigate(`/`);
  }

  const handleUpdateRole = role => {
    if (!haveActionPermission("Edit", "/security", LineDetailPlace)) return;
    setModalOpen(
      true,
      <NewOrEditRole
        componentMode="update"
        editRole={role}
        refetch={refetch}
        setCloseModal={setModalOpen}
        title="Actualizar Rol"
      />,
    );
  };

  return (
    <div>
      <Box className="content-flex-cards">
        {rolesByPlaceData?.getRolesByPlace.map(role => (
          <Card
            key={role.id}
            color="primary"
            sx={{
              width: "20.813rem",
              height: "6.938rem",
              paddingY: "1rem",
              paddingX: { xs: "1rem", sm: "1rem", md: "1rem" },
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
            onClick={() => handleUpdateRole(role)}
          >
            <Box className="content-flex-row-between">
              <FontAwesomeIcon
                className="awesomeIcon-color icon-size-card"
                icon="cog"
              />
              <Typography typography="modeColor" variant="h6" width="100%">
                {role.Name_Role}
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </div>
  );
}

export default Roles;
