import React, { useCallback, useEffect, useState } from "react";
// External components
import { Box, Button, Card, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { Progress } from "rbx";
import { CSVLink } from "react-csv";
import { registerLocale } from "react-datepicker";
import Es from "date-fns/locale/es";
// Graphql
import { useMutation } from "@apollo/client";
import {
  DELETE_EB_SUBMODULE_PERMISSION,
  GET_EB_SUBMODULE_PERMISSION,
  UDPATE_EB_SUBMODULE_PERMISSION,
} from "../../graphql";
// Components
import ExpansionPanel from "../ExpansionPanel";
import ExpansionPanelHeader from "../ExpansionPanelHeader";
import Inventories from "../Inventories";
import CoinSelector from "../CoinSelector";
import BarChartComponent from "../BarChartComponent";
import IvaReportSubsections from "../IvaReportSubsections";
import ConfirmationModal from "../ConfirmationModal";
// Context
import { useAuth, useModal } from "../../context";
import { useElectronic } from "../../context/ElectronicContext";
import UseCreateReport from "../../hooks/UseCreateReport";
// Utils
import { customToast as toast } from "../../utils";
import { d104Headers, getD104Data } from "../../utils/CSVHelper";
import { ESUBMODULEROUTES } from "../../Enums/Submodules";
// SCSS
import "./IVAReports.scss";

function IVAReport() {
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [previusDate, setPreviusDate] = useState(new Date());
  const [previusPlace, setPreviusPlace] = useState(new Date());

  const { setModalOpen } = useModal();
  const { state: authState } = useAuth();
  const { LineDetailPlace, useCoinDetail } = useElectronic();
  const { toExchangeRate, currentSymbolCoin } = useCoinDetail;

  const { permissions } = useAuth();
  const { haveActionPermission } = permissions;
  const canDelete = haveActionPermission("Delete", "/reports", LineDetailPlace);
  const canEdit = haveActionPermission("Edit", "/reports", LineDetailPlace);

  const [deleteElectronicBillById] = useMutation(
    DELETE_EB_SUBMODULE_PERMISSION,
  );
  const [updateElectronicBill] = useMutation(UDPATE_EB_SUBMODULE_PERMISSION);

  const [emitterList, setEmitter] = useState([]);
  const {
    dataToCSV,
    headersToCSV,
    handleGetElectronicBills,
    dataCSV,
    merch,
    services,
    filterBills,
    setFilterBills,
    isLoadingBills,
    electronicBills,
    setElectronicBills,
    totals,
    setTotals,
    getChartData,
    setDataCSV,
    generateReport,
  } = UseCreateReport();

  registerLocale("Es", Es);

  const initialStates = () => {
    setElectronicBills([]);
    setFilterBills([]);
    setEmitter([]);
    setTotals({
      TotalGrav: 0,
      TotalExempt: 0,
      TotalTaxes: 0,
      TotalNetSell: 0,
      TotalBill: 0,
      TotalServicesTaxed: 0,
      TotalServicesExempt: 0,
      TotalMerchTaxed: 0,
      TotalMerchExempt: 0,
    });
  };

  const handleDeleteElectronicBill = async bill => {
    const { data: wasDeletedData } = await deleteElectronicBillById({
      variables: {
        data: {
          id: bill.id,
          submodulePermissionData: {
            placeId: LineDetailPlace.id,
            submoduleRoute: ESUBMODULEROUTES.REPORTS,
          },
        },
      },
      // si ven esto, es que olvide cambiar esta parter xd
      refetchQueries: [
        {
          query: GET_EB_SUBMODULE_PERMISSION,
          variables: {
            data: {
              searchData: {
                where: {
                  equals: {
                    FK_OwnerPlace: LineDetailPlace.id,
                    BillFlowType: 1,
                  },
                  inMonth: selectedDate,
                },
              },
              submodulePermissionData: {
                placeId: LineDetailPlace.id,
                submoduleRoute: ESUBMODULEROUTES.REPORTS,
              },
            },
          },
        },
      ],
    });

    if (!wasDeletedData || !wasDeletedData.deleteEBSubmodulePermission)
      toast.error("Ocurrió un error al eliminar la factura");

    toast.success("Factura eliminada con éxito");
    setFilterBills(filterBills.filter(filterBill => filterBill.id !== bill.id));
    generateReport(filterBills.filter(filterBill => filterBill.id !== bill.id));
    setModalOpen(false);
  };

  const cancelDelete = () => {
    setModalOpen(false);
  };

  const handleConfirmDelete = bill => {
    setModalOpen(
      true,
      <ConfirmationModal
        body="¿Está seguro desea borrar esta factura? Presione confirmar para eliminar"
        header="¿Desea Borrar esta Factura?"
        onCancel={cancelDelete}
        onConfirm={() => handleDeleteElectronicBill(bill)}
      />,
    );
  };

  useEffect(() => {
    initialStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LineDetailPlace.id]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!LineDetailPlace?.id)
      return toast.warning("Por favor seleccione un lugar.");
    if (authState.user.TavuelUser.id && selectedDate) {
      if (
        previusDate !== selectedDate ||
        previusPlace !== LineDetailPlace?.id
      ) {
        initialStates();
        handleGetElectronicBills(selectedDate, 1);
        setPreviusPlace(LineDetailPlace?.id);
        return setPreviusDate(selectedDate);
      }
    }
    return null;
  };

  const handleUpdateDataCSV = () => {
    const nServices = services.map(service => ({
      ...service,
      Amount: toExchangeRate(service.Amount),
      Total: toExchangeRate(service.Total),
    }));
    const nMerch = merch.map(m => ({
      ...m,
      Amount: toExchangeRate(m.Amount),
      Total: toExchangeRate(m.Total),
    }));
    const nTotals = {
      ...totals,
      TotalBill: toExchangeRate(totals.TotalBill),
      TotalExempt: toExchangeRate(totals.TotalExempt),
      TotalGrav: toExchangeRate(totals.TotalGrav),
      TotalMerchExempt: toExchangeRate(totals.TotalMerchExempt),
      TotalMerchTaxed: toExchangeRate(totals.TotalMerchTaxed),
      TotalNetSell: toExchangeRate(totals.TotalNetSell),
      TotalServicesExempt: toExchangeRate(totals.TotalServicesExempt),
      TotalServicesTaxed: toExchangeRate(totals.TotalServicesTaxed),
      TotalTaxes: toExchangeRate(totals.TotalTaxes),
    };
    getD104Data(setDataCSV, nServices, nMerch, nTotals);
  };

  const reasingPlace = useCallback(
    async (placeId, ebillId) => {
      try {
        setModalOpen(false);
        if (ebillId && placeId) {
          const { data } = await updateElectronicBill({
            variables: {
              data: {
                ebData: {
                  Id: ebillId,
                  FK_OwnerPlace: placeId,
                },
                submodulePermissionData: {
                  placeId: LineDetailPlace.id,
                  submoduleRoute: ESUBMODULEROUTES.REPORTS,
                },
              },
            },
          });

          if (data.updateEBSubmodulePermission) {
            toast.success("Factura trasladada correctamente");
            handleGetElectronicBills(selectedDate, 1);
          } else toast.error("Hubo un error al intentar trasladar la factura");
        }
      } catch (err) {
        toast.error("Error al trasladar la factura");
      }
    },
    [
      LineDetailPlace.id,
      handleGetElectronicBills,
      selectedDate,
      setModalOpen,
      updateElectronicBill,
    ],
  );

  const filterBillsByEmisor = Emitt => {
    setFilterBills(electronicBills.filter(b => b.Place.Name_Place === Emitt));
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        {/* FILTER */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <DatePicker
            color="primary"
            label="Mes y año de emisión"
            size="small"
            sx={{
              "& .MuiFormLabel-root": {
                transform: " translate(14px, 5px) scale(0.75)",
              },
              "& .MuiInputBase-input": {
                padding: "24px 14px 10px 14px ",
              },
              "maxHeight": "48px",
            }}
            value={selectedDate}
            variant="filled"
            views={["month", "year"]}
            onChange={newDate => {
              setSelectedDate(newDate);
            }}
          />
          <CoinSelector key="coin-selector" />
        </Box>
        {/* Download */}
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: "1rem",
            justifyContent: "space-between",
            alignItems: { xs: "start", sm: "center" },
          }}
        >
          {electronicBills.length !== 0 && (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
            >
              <CSVLink
                className="mt-3"
                data={dataCSV}
                filename={`Reporte D-104 (${
                  selectedDate.month() + 1
                }-${selectedDate.year})`}
                headers={d104Headers()}
                style={{
                  paddingRight: 15,
                  color: "#01234c",
                  cursor: "pointer",
                  display: "block",
                }}
                target="_blank"
                onClick={handleUpdateDataCSV}
              >
                <Typography
                  fontWeight={600}
                  sx={{ textAlign: { xs: "start", sm: "end" } }}
                  typography="modeButton"
                  variant="subtitle2"
                  width="100%"
                >
                  Descargar CSV
                </Typography>
              </CSVLink>
              <CSVLink
                data={dataToCSV}
                filename={`Lista de facturas (${
                  selectedDate.month() + 1
                }-${selectedDate.year})`}
                headers={headersToCSV}
                style={{
                  paddingRight: 15,
                  cursor: "pointer",
                  display: "block",
                }}
                target="_blank"
              >
                <Typography
                  fontWeight={600}
                  sx={{ textAlign: { xs: "start", sm: "end" } }}
                  typography="modeButton"
                  variant="subtitle2"
                  width="100%"
                >
                  Descargar Lista de Facturas Electrónicas
                </Typography>
              </CSVLink>
            </Box>
          )}
          <Button
            color="secondary"
            size="medium"
            variant="contained"
            onClick={e => handleSubmit(e)}
          >
            Generar Reporte
          </Button>
        </Box>
      </Box>

      {isLoadingBills && (
        <Progress className="progress-bar" color="info" size="small" />
      )}
      {!isLoadingBills && electronicBills?.length !== 0 && (
        <Card
          color="primary"
          sx={{
            height: "375px",
            display: " flex",
            justifyContent: "center",
            padding: { xs: "0.5rem", sm: "1rem" },
          }}
        >
          <BarChartComponent
            angle={60}
            data={getChartData()}
            dataXKey="date"
            dataYKey="amount"
          />
        </Card>
      )}
      {electronicBills?.length !== 0 && (
        <IvaReportSubsections
          summarizedData={{
            services,
            merch,
          }}
          symbolCoin={currentSymbolCoin}
          totals={totals}
        />
      )}
      <div>
        {electronicBills?.length !== 0 && (
          <div className="mt-2">
            {emitterList?.map((emitter, index) => (
              <div
                key={`${emitter.Emitter}-${index.toString()}`}
                className="iva-report-sub-seccion"
                role="button"
                tabIndex="0"
                onClick={e => {
                  filterBillsByEmisor(emitter.Emitter);
                }}
                onKeyDown={e => {
                  filterBillsByEmisor(emitter.Emitter);
                }}
              >
                <h3>Emisor: {emitter.Emitter}</h3>
                <p>Comercial: {emitter.Company}</p>
                <p>Ced: {emitter.Id}</p>
              </div>
            ))}
          </div>
        )}
      </div>
      {filterBills?.length !== 0 && (
        <Box className="content-grid-column">
          {filterBills.map(bill => (
            <Card
              color="primary"
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: { xs: "0.5rem", sm: "1rem" },
                gap: "1rem",
              }}
            >
              <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
                <Typography
                  fontWeight={600}
                  typography="modeColor"
                  variant="h6"
                  width="100%"
                >
                  Facturas
                </Typography>
                {canEdit && (
                  <Button
                    color="secondary"
                    variant="outlined"
                    onClick={() =>
                      setModalOpen(
                        true,
                        <div className="modal-content-padding">
                          <Inventories
                            isModalMode
                            bill={bill}
                            color="secondaryInventory"
                            setModalClose={setModalOpen}
                            onClickFunction={reasingPlace}
                          />
                        </div>,
                      )
                    }
                  >
                    Trasladar
                  </Button>
                )}
                {canDelete && (
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => handleConfirmDelete(bill)}
                  >
                    Eliminar
                  </Button>
                )}
              </Box>
              <Typography typography="modeColor" variant="body2" width="100%">
                {bill?.Place?.Name_Place}
              </Typography>
              <Tooltip title={bill?.InternConsecutive}>
                <Typography
                  className="text-container"
                  sx={{ minHeight: "16px" }}
                  typography="modeColor"
                  variant="body2"
                  width="100%"
                >
                  {bill?.InternConsecutive}
                </Typography>
              </Tooltip>
              <Tooltip title={bill?.Key}>
                <Typography
                  className="text-container"
                  sx={{ minHeight: "16px" }}
                  typography="modeColor"
                  variant="body2"
                  width="100%"
                >
                  {bill?.Key}
                </Typography>
              </Tooltip>
              <Typography typography="modeColor" variant="body2" width="100%">
                Moneda:
              </Typography>

              <ExpansionPanelHeader bill={bill} />
              <ExpansionPanel bill={bill} />
            </Card>
          ))}
        </Box>
      )}
    </Box>
  );
}
export default IVAReport;
