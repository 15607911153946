import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
// External components
import { Field, Control, Input, Button, Title } from "rbx";
// Graphql
import { useQuery, useMutation } from "@apollo/client";
import {
  GET_CLIENTS_BY_PLACE,
  UPDATE_CLIENT,
  DELETE_MULTIPLE_BILLING_CLIENT,
  DELETE_MULTIPLE_CLIENTXGROUP,
  REGISTER_NEW_CLIENTSXGROUP,
  GET_ALL_CLIENTS_BY_GROUP,
  GET_CLIENT_BY_Id_PLACE,
} from "../../../../graphql";
// Components
import ClientsCard from "../../../ClientsCard";
import ClientsDelete from "../ClientsDelete";
import AddClientsToGroup from "../AddClientsToGroup/AddClientsToGroup";
// Context
import { useModal } from "../../../../context";
import { useElectronic } from "../../../../context/ElectronicContext";
// Utils
import { customToast as toast } from "../../../../utils";
// SCSS
import "./ClientList.scss";

function ClientList() {
  const [clientList, setClientList] = useState([]);
  const [initialList, setInitialList] = useState([]);

  const { placeId, placeName } = useParams();
  const { LineDetailPlace } = useElectronic();
  const { setModalOpen } = useModal();

  const [updateClient] = useMutation(UPDATE_CLIENT);
  const [deleteClients] = useMutation(DELETE_MULTIPLE_BILLING_CLIENT);
  const [addNewClients] = useMutation(REGISTER_NEW_CLIENTSXGROUP);
  const [deleteClientsXGroup] = useMutation(DELETE_MULTIPLE_CLIENTXGROUP);

  const {
    loading,
    data: dataAllClients,
    error,
  } = useQuery(GET_CLIENT_BY_Id_PLACE, {
    variables: {
      FK_Place: LineDetailPlace.id,
    },
  });

  useEffect(() => {
    setClientList(
      dataAllClients?.clients?.map(index => ({
        ...index,
        selected: false,
      })),
    );
    setInitialList(
      dataAllClients?.clients?.map(index => ({
        ...index,
        selected: false,
      })),
    );
  }, [dataAllClients]);

  const editClient = useCallback(
    async (id, billingProfile) => {
      try {
        const { data } = await updateClient({
          variables: {
            id,
            billingProfile,
          },
          refetchQueries: [
            {
              query: GET_CLIENTS_BY_PLACE,
              variables: {
                FK_Place: placeId,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Datos actualizados con éxito!");
        }
      } catch (err) {
        toast.error("Error al registrar los cambios");
      }
    },
    [updateClient, placeId],
  );

  const deleteSelectedClientsXGroup = async FK_Users => {
    const { data } = await deleteClientsXGroup({
      variables: {
        FK_Users,
      },
    });
    return data;
  };

  const deleteSelectedClients = useCallback(
    async ids => {
      try {
        const { data } = await deleteClients({
          variables: {
            ids,
          },
          refetchQueries: [
            {
              query: GET_CLIENTS_BY_PLACE,
              variables: {
                FK_Place: placeId,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Clientes eliminados con éxito!");
        }
      } catch (err) {
        toast.error("Error al eliminar los clientes");
      }
    },
    [deleteClients, placeId],
  );

  const addSelectedClients = useCallback(
    async (clients, FK_ClientGroup) => {
      try {
        const { data } = await addNewClients({
          variables: {
            clients,
          },
          refetchQueries: [
            {
              query: GET_ALL_CLIENTS_BY_GROUP,
              variables: {
                FK_ClientGroup,
              },
            },
          ],
        });
        if (data) {
          toast.success("¡Clientes agregados al grupo con éxito!");
        }
      } catch (err) {
        toast.error("Error al agregar los clientes");
      }
    },
    [addNewClients],
  );

  const addClientsToGroup = FK_Group => {
    const addClientList = [];

    clientList.forEach(client => {
      if (client.selected) {
        addClientList.push({
          FK_User: client?.BillingProfile?.User?.id,
          FK_ClientGroup: FK_Group,
        });
      }
    });
    if (addClientList.length !== 0 && FK_Group !== 0) {
      addSelectedClients(addClientList, FK_Group);
    } else {
      toast.error("Debe seleccionar un grupo de clientes");
    }
  };

  const deleteClientsAndClientsXGroup = () => {
    const deleteClientList = [];
    const deleteClientXGroupList = [];

    clientList.forEach(client => {
      if (client.selected) {
        deleteClientList.push(client?.id);
        deleteClientXGroupList.push(client?.BillingProfile?.User?.id);
      }
    });
    if (deleteClientList.length !== 0 && deleteClientXGroupList.length !== 0) {
      deleteSelectedClients(deleteClientList);
      deleteSelectedClientsXGroup(deleteClientXGroupList);
    }
  };

  const handleAddToGroup = () => {
    const addClientList = [];

    clientList.forEach(client => {
      if (client.selected) {
        addClientList.push(client?.BillingProfile);
      }
    });
    setModalOpen(
      true,
      <AddClientsToGroup
        FK_Place={placeId}
        addClientsToGroup={addClientsToGroup}
        placeName={placeName}
        selectedList={addClientList}
        setModalOpen={setModalOpen}
      />,
    );
  };

  const handleDelete = () => {
    const deleteClientList = [];

    clientList.forEach(client => {
      if (client.selected) {
        deleteClientList.push(client?.BillingProfile);
      }
    });
    setModalOpen(
      true,
      <ClientsDelete
        deleteClientsAndClientsXGroup={deleteClientsAndClientsXGroup}
        selectedList={deleteClientList}
        setModalOpen={setModalOpen}
      />,
    );
  };

  const confirmSelected = () => {
    let clientsSeleted = 0;
    if (clientList) {
      clientList.forEach(item => {
        if (item.selected) {
          clientsSeleted += 1;
        }
      });
    }
    if (clientsSeleted > 0) {
      return false;
    }
    return true;
  };

  const handleFilterClient = value => {
    setClientList(
      initialList.filter(
        index =>
          index?.BillingProfile?.Name.toUpperCase().includes(
            value.toUpperCase(),
          ) ||
          index?.BillingProfile?.ID_Number.toUpperCase().includes(
            value.toString().toUpperCase(),
          ),
      ),
    );
  };

  return (
    <div>
      <Title className="title-center">Mantenimiento de clientes</Title>
      <Field>
        <Control>
          <Input
            name="search"
            placeholder="Buscar cliente"
            type="text"
            onChange={e => handleFilterClient(e.target.value)}
          />
          <div className="container-padding content-flex-end">
            <Button
              className="button-header"
              color="primary"
              onClick={() => handleAddToGroup()}
            >
              Agregar nuevo cliente
            </Button>
            <Button
              className="button-header"
              color="primary"
              disabled={confirmSelected()}
              onClick={() => handleAddToGroup()}
            >
              Agregar a grupo
            </Button>
            <Button
              className="button-header"
              color="primary"
              disabled={confirmSelected()}
              onClick={() => handleDelete()}
            >
              Eliminar Clientes
            </Button>
          </div>

          {clientList?.length !== 0 && !loading && !error && (
            <ClientsCard
              clientList={clientList}
              editClient={editClient}
              setClientList={setClientList}
            />
          )}
        </Control>
      </Field>
    </div>
  );
}

export default ClientList;
