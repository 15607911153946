import React, { useState, useEffect, useRef, useCallback } from "react";
// External components
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Input, Typography } from "@mui/material";
// Graphql
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_PLACE,
  GET_ACTIVITIES_BY_ID_MH,
  GET_PLACE_BY_ID,
} from "../../graphql";
// Components
import SelectActivityCode from "../ActivityCodeSelect";
import CropperPlace from "../CropperPlace";
import Loading from "../Loading";
// Context
import { useAuth, useModal } from "../../context";
// Utils
import { customToast as toast, decrypt, encrypt } from "../../utils";
import { validateRoute } from "../../utils/helpers";
// SCSS
import "./PlacesUpdate.scss";
import "../../pages/ProfilePage/ProfilePage.scss";
import InputForm from "../InputForm/InputForm";
import FormWithButtons from "../FormWithButttons/FormWithButtons";
import { useElectronic } from "../../context/ElectronicContext";

function PlacesUpdate() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [upImg, setUpImg] = useState("");
  const [open, setOpen] = useState(false);
  const [passIsViible, setPassIsVisible] = useState(false);
  const [firebaseURL, setFirebaseURL] = useState("");
  const [firebaseID, setFirebaseId] = useState("");
  const [newPlace, setPlace] = useState({
    id: "",
    Code: "",
    Name_Place: "",
    Description_Place: "",
    FK_Company: "",
    State_Place: "",
    Gmail: "",
    Gmail_App: "",
  });
  const [selectActivityCode, setSelectActivityCode] = useState({
    id_MH: "",
    Name: "",
  });

  const { LineDetailPlace } = useElectronic();
  const { permissions } = useAuth();
  const { haveActionPermission, noAccessMessage } = permissions;
  const canEdit = haveActionPermission("Edit", "/places", LineDetailPlace);
  const { setModalOpen } = useModal();

  const canvas = useRef(null);

  const { loading, data: getActivityData } = useQuery(GET_ACTIVITIES_BY_ID_MH, {
    variables: {
      id_MH: newPlace?.Code,
    },
  });
  const [updatePlace] = useMutation(UPDATE_PLACE);

  const validateImgRoute = () => {
    if (firebaseURL === "") {
      return validateRoute(newPlace);
    }
    return firebaseURL;
  };

  const handleChange = (name, value) => {
    setPlace(prev => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (event, newValue) => {
    if (!newValue) return;
    setPlace(prev => ({ ...prev, Code: newValue.value }));
  };

  const { data: getPlaceById, refetch: refetchPlace } = useQuery(
    GET_PLACE_BY_ID,
    {
      variables: { id },
    },
  );

  useEffect(() => {
    if (getPlaceById) {
      const {
        Code,
        Name_Place,
        Description_Place,
        Company,
        State_Place,
        Gmail,
        Gmail_App,
      } = getPlaceById.place;
      setPlace({
        id,
        Code,
        Name_Place,
        Description_Place,
        FK_Company: Company?.id,
        State_Place,
        Gmail,
        Gmail_App: Gmail_App ? decrypt(Gmail_App) : Gmail_App,
      });
    }

    setSelectActivityCode({
      id_MH: getActivityData?.activity?.id_MH,
      Name: getActivityData?.activity?.Name,
    });
  }, [getPlaceById, getActivityData, id]);

  const defaultValue = selectActivityCode
    ? {
        value: selectActivityCode.id_MH,
        label: selectActivityCode.Name,
      }
    : null;

  const update = useCallback(async () => {
    try {
      const address = {
        id: null,
      };
      const { data } = await updatePlace({
        variables: {
          place: { ...newPlace, Gmail_App: encrypt(newPlace.Gmail_App) },
          address,
        },
      });
      if (data?.place) {
        toast.success("¡Lugar actualizado con éxito!");
        refetchPlace();
        navigate(-1);
      }
    } catch (err) {
      toast.error("Error al actualizar el lugar");
    }
  }, [updatePlace, newPlace, refetchPlace, navigate]);

  const handleSubmit = e => {
    e.preventDefault();
    if (!canEdit) {
      toast.error(noAccessMessage("Edit", "Places"));
      return;
    }
    if (newPlace.Name_Place !== "" && newPlace.Description_Place !== "") {
      update();
    } else {
      toast.error("Debe completar los datos solicitados.");
    }
  };

  const hadleOnSelectFile = e => {
    // Seleccionar foto
    if (e.target.files && e.target.files.length > 0) {
      setOpen(false);
      setUpImg("");
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  useEffect(() => {
    if (upImg !== "" && !open) {
      setModalOpen(
        true,
        <CropperPlace
          canvas={canvas}
          setFirebaseId={setFirebaseId}
          setFirebaseURL={setFirebaseURL}
          upImg={upImg}
          onClose={() => setModalOpen(false)}
        />,
      );
      setOpen(true);
    }
  }, [open, setModalOpen, upImg]);

  useEffect(() => {
    if (firebaseURL && firebaseID) {
      setPlace(prev => ({
        ...prev,
        Files: [
          {
            Route_File: firebaseURL,
            FirebaseId: firebaseID,
            State_File: "New",
            FileType: {
              id: 2,
            },
          },
        ],
      }));
    }
  }, [firebaseURL, firebaseID]);

  useEffect(() => {
    if (getPlaceById) {
      const {
        Code,
        Name_Place,
        Description_Place,
        Company,
        State_Place,
        Gmail,
        Gmail_App,
      } = getPlaceById.place;
      setPlace({
        id,
        Code,
        Name_Place,
        Description_Place,
        FK_Company: Company?.id,
        State_Place,
        Gmail,
        Gmail_App: Gmail_App ? decrypt(Gmail_App) : Gmail_App,
      });
    }

    setSelectActivityCode({
      id_MH: getActivityData?.activity?.id_MH,
      Name: getActivityData?.activity?.Name,
    });
  }, [getPlaceById, getActivityData, id]);

  return (
    <form onSubmit={handleSubmit}>
      <div>
        <Typography
          fontWeight={600}
          marginBottom={5}
          textAlign="center"
          typography="modeColor"
          variant="h6"
        >
          Actualización de Lugares
        </Typography>
        {loading && !newPlace?.id ? (
          <div>
            <Loading />
          </div>
        ) : (
          <Grid container margin={0}>
            <Grid item textAlign="center" xs={12}>
              <Button
                component="label"
                sx={{ aspectRatio: "square", width: 111, listStyle: "none" }}
              >
                <Input hidden type="file" onChange={hadleOnSelectFile} />
                <Box
                  component="img"
                  src={validateImgRoute()}
                  sx={{ objectFit: "cover" }}
                />
              </Button>
            </Grid>

            <Grid item paddingTop={5} xs={12}>
              <Grid container spacing={3}>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    label="Compañía"
                    size="small"
                    type="text"
                    typeInput="text"
                    value={getPlaceById?.place?.Company?.Name_Company}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  {selectActivityCode ? (
                    <SelectActivityCode
                      defaultValue={defaultValue}
                      handleChange={handleSelectChange}
                    />
                  ) : (
                    <div>Cargando...</div>
                  )}
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Name_Place"
                    label="Nombre"
                    name="Name_Place"
                    placeholder="Nombre"
                    size="small"
                    type="text"
                    typeInput="text"
                    value={newPlace?.Name_Place}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Gmail"
                    label="Correo Gmail"
                    name="Gmail"
                    placeholder="Correo Gmail"
                    size="small"
                    type="email"
                    typeInput="email"
                    value={newPlace.Gmail}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Description_Place"
                    label="Descripción"
                    name="Description_Place"
                    placeholder="Descripción"
                    size="small"
                    type="text"
                    typeInput="text"
                    value={newPlace?.Description_Place}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <InputForm
                    color="primary"
                    id="Gmail_App"
                    label="Contraseña de aplicación Gmail"
                    name="Gmail_App"
                    placeholder="Contraseña de aplicación Gmail"
                    size="small"
                    type={passIsViible ? "text" : "password"}
                    typeInput="password"
                    value={newPlace.Gmail_App}
                    onChange={e => handleChange(e.target.name, e.target.value)}
                    onClickPassword={() => setPassIsVisible(prev => !prev)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item paddingTop={5} xs={12}>
              <FormWithButtons
                buttonsAlignment="end"
                buttonsSpace={3}
                onCancel={handleCancel}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </form>
  );
}

// PlacesUpdate.propTypes = {
//   place: PropTypes.object.isRequired,
//   refetch: PropTypes.func.isRequired,
//   setPlaceSelected: PropTypes.func.isRequired,
// };

export default PlacesUpdate;
