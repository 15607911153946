import { BILL_TYPES_URL_PARAMS } from "../Enums/ElectronicBill";

export const formatElectronicBillDetailsFormattedWithMaxQuantity =
  _electronicBill => {
    const detailsAccumulated = Array.isArray(_electronicBill.CreditNotes)
      ? _electronicBill.CreditNotes.reduce(
          (accumulator, { ElectronicBillDetail }) => [
            ...accumulator,
            ...ElectronicBillDetail,
          ],
          [],
        ).reduce((accumulator, detail) => {
          const temporalProductFeature = accumulator?.find(
            x => x.FK_ProductFeature === detail.FK_ProductFeature,
          );
          if (temporalProductFeature) {
            temporalProductFeature.maxQuantity += detail.Quantity;
            temporalProductFeature.accPrice += detail.Price * detail.Quantity;
          } else {
            accumulator.push({
              FK_ProductFeature: detail.FK_ProductFeature,
              totalQuantity: detail.Quantity,
              accPrice: detail.Price * detail.Quantity,
            });
          }
          return accumulator;
        }, [])
      : [];
    return {
      ..._electronicBill,
      ElectronicBillDetail: _electronicBill?.ElectronicBillDetail.map(
        detail => {
          const temporalProductFeature = detailsAccumulated.find(
            detailAcc =>
              detailAcc.FK_ProductFeature === detail.FK_ProductFeature,
          );

          return {
            ...detail,
            Quantity:
              detail.Quantity - (temporalProductFeature?.totalQuantity || 0),
            valid:
              detail.Price * detail.Quantity -
                (temporalProductFeature?.accPrice || 0) >
              0,
            maxQuantity:
              detail.Quantity - (temporalProductFeature?.totalQuantity || 0),
          };
        },
      ),
    };
  };

export const EBTypeToTypesEnum = BillType => {
  if (BillType === "Factura Electronica")
    return BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL;
  if (BillType === "Nota de Debito") return BILL_TYPES_URL_PARAMS.DEBIT_NOTE;
  if (BillType === "Nota de Credito") return BILL_TYPES_URL_PARAMS.CREDIT_NOTE;
  if (BillType === "Tiquete Electronico")
    return BILL_TYPES_URL_PARAMS.ELECTRONIC_BILL;
  if (BillType === "Confirmacion Comprobante Electronico") return "NotDefined";
  if (BillType === "Confirmacion Parcial Comprobante Electronico")
    return "NotDefined";
  // if(BillType==='Rechazo Comprobante Electronico') return ''
  return "NotDefined";
};
