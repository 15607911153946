import React from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";

function SimpleGrid({ children }) {
  const childrenArray = React.Children.toArray(children);

  return (
    <Grid container columns={{ xs: 1, sm: 3, md: 3 }} spacing={2}>
      {childrenArray.map((child, index) => (
        <Grid key={`grd-${child.id ?? index}`} item xs={1}>
          {child}
        </Grid>
      ))}
    </Grid>
  );
}

SimpleGrid.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SimpleGrid;
