import PropTypes from "prop-types";
// External componentsLabel } from "rbx";
import CheckboxCustom from "@mui/material/Checkbox";

function Checkbox({ className, label, onChange, defaultValue, name }) {
  return (
    <div className={`${className}`}>
      <CheckboxCustom
        Value={defaultValue}
        color="success"
        name={name}
        onChange={onChange}
      />
      {label}
    </div>
  );
}

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
};

Checkbox.defaultProps = {
  onChange: e => e,
  label: "",
  className: "",
};

export default Checkbox;
