const SelectStylesDark = {
  MuiSelect: {
    variants: [
      {
        props: props =>
          props.variant === "filled" && props.color === "predetermined",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(8, 22, 35, 1)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#0C2134",
          },
          "&.Mui-focused": {
            background: "#0C2134",
          },
        },
      },
      // WITH COLOR
      {
        props: props => props.variant === "filled" && props.color === "primary",
        style: {
          "& .MuiInputBase-input": {
            color: "#000",
          },
          "background": "rgb(230, 234, 239)",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(199, 221, 240, 0.5)",
          },
          "&.Mui-focused": {
            background: "rgb(230, 234, 239)",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "secondary",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(8, 22, 35, 1)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#0C2134",
          },
          "&.Mui-focused": {
            background: "#0C2134",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "tertiary",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(8, 22, 35, 1)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#0C2134",
          },
          "&.Mui-focused": {
            background: "#0C2134",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "tertiaryOnly",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(18, 59, 100, 1)",
          "borderRadius": "0px",
          "&::before, &::after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(18, 59, 100, 0.5)",
            borderBottom: "none",
          },
          "&.Mui-focused": {
            background: "rgba(18, 59, 100, 0.5)",
            borderBottom: "none",
          },
        },
      },
    ],
  },

  MuiMenu: {
    styleOverrides: {
      paper: {
        "backgroundColor": "transparent",
        "color": "#fff",
        "box-shadow": "4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        "-webkit-backdrop-filter": "blur(10px)",
        "backdrop-filter": "blur(10px)",
        " background-image": "none",
        " border": "1px solid #09192880",
      },
    },
  },
};

const SelectStylesLight = {
  MuiSelect: {
    variants: [
      {
        props: props =>
          props.variant === "filled" && props.color === "predetermined",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(8, 22, 35, 1)",
          "&::before, &::after": {
            borderBottom: "2px solid",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#0C2134",
          },
          "&.Mui-focused": {
            background: "#0C2134",
          },
        },
      },
      // WITH COLOR
      {
        props: props => props.variant === "filled" && props.color === "primary",
        style: {
          "& .MuiInputBase-input": {
            color: "#000",
          },
          "background": "rgb(230, 234, 239)",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(199, 221, 240, 0.5)",
          },
          "&.Mui-focused": {
            background: "rgb(230, 234, 239)",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "secondary",
        style: {
          "color": "#000",
          "background": "rgb(230, 234, 239)",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#ECF0F5",
          },
          "&.Mui-focused": {
            background: "#ECF0F5",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "tertiary",
        style: {
          "color": "#000",
          "background": "#F6F9FE",
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "#ECF0F5",
          },
          "&.Mui-focused": {
            background: "#ECF0F5",
          },
        },
      },
      {
        props: props =>
          props.variant === "filled" && props.color === "tertiaryOnly",
        style: {
          "color": "#ffff",
          "textTransform": "none",
          "background": "rgba(18, 59, 100, 1)",
          "borderRadius": "0px",
          "&::before, &::after": {
            borderBottom: "none",
          },
          "&:hover:not(.Mui-disabled, .Mui-error)": {
            background: "rgba(18, 59, 100, 1)",
            borderBottom: "none",
          },
          "&.Mui-focused": {
            borderRadius: "0px",
            background: "rgba(18, 59, 100, 1)",
            borderBottom: "none",
          },
          "&.Mui-hover": {
            borderRadius: "0px",
            borderBottom: "rgba(18, 59, 100, 1)",
          },
        },
      },
    ],
  },
  MuiMenu: {
    styleOverrides: {
      paper: {
        "backgroundColor": "transparent",
        "color": "#000",
        "box-shadow": "4px 7px 15px 0px rgba(0, 0, 0, 0.08)",
        "-webkit-backdrop-filter": "blur(10px)",
        "backdrop-filter": "blur(10px)",
        " background-image": "none",
        " border": "1px solid #09192880",
      },
    },
  },
};

export { SelectStylesDark, SelectStylesLight };
