import { Title } from "rbx";
import React, { useEffect } from "react";
import { useModal } from "../../context";
import ResetPasswordModal from "./ResetPasswordModal";

function ResetPassword() {
  const { setModalOpen } = useModal();

  useEffect(() => {
    setModalOpen(true, <ResetPasswordModal />);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Title className="landing-page-title">Cambio de contraseña</Title>
    </div>
  );
}

ResetPassword.propTypes = {};

export default ResetPassword;
