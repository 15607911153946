import React from "react";
// Components
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import error404DarkIcon from "../../assets/icons/Dark_404.svg";
import error404LighIcon from "../../assets/icons/Light_404.svg";

function ErrorPage() {
  const navigate = useNavigate();
  const themeColor = localStorage.getItem("theme");
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: { xs: "4rem", sm: "10rem" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          backgroundImage: `url(${themeColor === "light" ? error404LighIcon : error404DarkIcon})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "auto",
          padding: { xs: "1rem", sm: "4rem" },
        }}
      >
        <Typography
          align="center"
          fontWeight={600}
          typography="modeColor"
          variant="h4"
          width="100%"
        >
          Página no encontrada.
        </Typography>
        <Typography align="center" typography="modeColor" variant="body1">
          La página que buscas no existe.
        </Typography>
      </Box>
      <Button color="primary" variant="contained" onClick={() => navigate(`/`)}>
        Volver a página principal
      </Button>
    </Box>
  );
}

export default ErrorPage;
