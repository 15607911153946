const gmailInformation = {
  clientID: process.env.REACT_APP_GMAIL_CLIENT_ID,
  redirectURL: process.env.REACT_APP_GMAIL_REDIRECT,
  scope: "https://mail.google.com/",
};

const getCodeGmail = () =>
  `https://accounts.google.com/o/oauth2/v2/auth?client_id=${gmailInformation.clientID}&` +
  `redirect_uri=${gmailInformation.redirectURL}&response_type=code&scope=${gmailInformation.scope}&` +
  `access_type=offline&include_granted_scopes=true`;

export default getCodeGmail;
