import React from "react";
import PropTypes from "prop-types";
// External components
import { Label } from "rbx";
// Graphql
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_PERMISSION_BY_SUBMODULE_AND_ROLE,
  CREATE_PERMISSION_SUBMODULE_ROL,
} from "../../graphql";
// SCSS
import "./PermissionByModuleAndRole.scss";

function PermissionByModuleAndRole({ selectSubmodule, selectedRole }) {
  const [createPermissionSubmoduleRole] = useMutation(
    CREATE_PERMISSION_SUBMODULE_ROL,
  );

  const handleChangeRole = async e => {
    const rolePermissionSubmodule = {
      FK_Role: Number(selectedRole.checked),
      FK_Submodule: Number(selectSubmodule),
      FK_Permission: Number(e.target.value),
      Linked: e.target.checked,
    };

    await createPermissionSubmoduleRole({
      variables: {
        rolePermissionSubmodule,
      },
      refetchQueries: [
        {
          query: GET_PERMISSION_BY_SUBMODULE_AND_ROLE,
          variables: {
            FK_Submodule: selectSubmodule,
            FK_Role: selectedRole.checked,
          },
        },
      ],
    });
  };

  const { data: Data } = useQuery(GET_PERMISSION_BY_SUBMODULE_AND_ROLE, {
    variables: {
      FK_Submodule: selectSubmodule,
      FK_Role: selectedRole.checked,
    },
  });

  const permissions = Data?.getPermissionsBySubmoduleAndRole || [];

  return (
    <form className="checkbox">
      <Label className="checkbox_label">
        <input
          checked={
            permissions && permissions.some(p => p?.Name_Permission === "Read")
          }
          className="checkbox_input"
          type="checkbox"
          value="1"
          onChange={handleChangeRole}
        />
        <div className="checkbox_box" />
        Read
      </Label>
      <Label className="checkbox_label">
        <input
          checked={
            permissions &&
            permissions.some(p => p?.Name_Permission === "Create")
          }
          className="checkbox_input"
          type="checkbox"
          value="2"
          onChange={handleChangeRole}
        />
        <div className="checkbox_box" />
        Create
      </Label>
      <Label className="checkbox_label">
        <input
          checked={
            permissions && permissions.some(p => p?.Name_Permission === "Edit")
          }
          className="checkbox_input"
          type="checkbox"
          value="3"
          onChange={handleChangeRole}
        />
        <div className="checkbox_box" />
        Edit
      </Label>
      <Label className="checkbox_label">
        <input
          checked={
            permissions &&
            permissions.some(p => p?.Name_Permission === "Delete")
          }
          className="checkbox_input"
          type="checkbox"
          value="4"
          onChange={handleChangeRole}
        />
        <div className="checkbox_box" />
        Delete
      </Label>
      <Label className="checkbox_label">
        <input
          checked={
            permissions && permissions.some(p => p?.Name_Permission === "All")
          }
          className="checkbox_input"
          type="checkbox"
          value="5"
          onChange={handleChangeRole}
        />
        <div className="checkbox_box" />
        All
      </Label>
    </form>
  );
}

PermissionByModuleAndRole.propTypes = {
  selectSubmodule: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectedRole: PropTypes.object.isRequired,
};

export default PermissionByModuleAndRole;
